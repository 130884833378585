import { useFeature } from 'flagged';
import useSelectedPlatform from './useSelectedPlatform';
const useGamePageUrl = (subpage, gameData) => {
    const isPlatformFirstEnabled = !!useFeature('enable_platform_first');
    const { platformSlug, isLoading } = useSelectedPlatform();
    // Data is still loading
    if (!gameData || isLoading) {
        return '#';
    }
    const gameHasPlatform = !!platformSlug && !!gameData.platforms.find((platform) => platform.slug === platformSlug);
    let generatedPath = '';
    if (isPlatformFirstEnabled && gameHasPlatform) {
        generatedPath += `/${platformSlug}`;
    }
    generatedPath += `/${gameData.slug}`;
    if (subpage === 'Game Hub') {
        generatedPath += '/game-hub';
    }
    return generatedPath;
};
export default useGamePageUrl;
