import { captureException } from '@sentry/react';
import { useQuery } from 'react-query';
import { displayMessageFromError } from '@helpers/errorHelper';
import getAdvertisements from '../../api/getAdvertisements';
const useGetAdvertisements = (routeData) => {
    const { data, isLoading, isFetching, error } = useQuery(['advertisements', { routeData }], () => getAdvertisements(routeData), {
        // refetch if query has been stale for 1 day
        staleTime: 1000 * 60 * 60 * 24,
        onError: (callbackError) => {
            captureException(callbackError, {
                extra: {
                    queryKey: 'advertisements',
                    url: '/api/engagement/v1',
                    requestName: 'advertisements_index',
                    filters: routeData,
                    errorMessage: displayMessageFromError(callbackError),
                },
            });
        },
    });
    return { data: data !== null && data !== void 0 ? data : [], isLoading, isFetching, error };
};
export default useGetAdvertisements;
