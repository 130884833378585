export const NOTIFICATION_ICON_KEYS = [
    'verify',
    'wallet',
    'danger',
    'badge',
    'message',
    'trophy',
    'staroutline',
    'star',
    'clock',
    'discovery',
    'scan',
    'repeat',
    'verifiedUserCheckmark',
    'profile',
    'coin',
    'hourglass'
];
export const isNotificationIconKey = (maybeNotificationIconKey) => NOTIFICATION_ICON_KEYS.includes(maybeNotificationIconKey);
export const NOTIFICATION_TYPES = ['generic', 'alert', 'warning', 'info'];
