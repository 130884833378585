import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import { Link } from 'react-router-dom';
const UnstyledLinkButton = React.forwardRef(({ children, className = '', label, id = '', to, disabled, 'data-testid': testID, onClick }, ref) => {
    if (!!to) {
        return (_jsx(Link, { ref: ref, className: className, "aria-label": label, id: id, "data-testid": testID, to: to, rel: "noopener noreferrer", onClick: onClick, children: children }));
    }
    return (_jsx("button", { ref: ref, className: className, "aria-label": label, disabled: disabled, id: id, "data-testid": testID, onClick: onClick, children: children }));
});
export default UnstyledLinkButton;
