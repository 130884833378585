import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, forwardRef } from 'react';
import { List, useMediaQuery, useTheme } from '@mui/material';
import useLocalStorage from '@components/Hooks/useLocalStorage';
import PlatformLinkButton from '@components/Buttons/PlatformLinkButton';
import Dropdown from '@components/UI/Dropdown';
import useSelectedPlatform from '@hooks/useSelectedPlatform';
import usePlatforms from '@hooks/usePlatforms';
import PlatformSelectModal from '@domain/Header/components/Menus/PlatformSelectModal';
const PlatformSelectionMenu = forwardRef(({ className, isOpen = false, onClickOutside, onClose, onOpen, onSelect, shouldAnimate, dropdownClassName }, ref) => {
    const theme = useTheme();
    const { platformSlug, isLoading } = useSelectedPlatform();
    const [_, setSelectedPlatformSlug] = useLocalStorage('selectedPlatformSlug', undefined);
    const buttonRef = useRef(null);
    const platforms = usePlatforms();
    const selectedPlatform = platforms.find((comparePlatform) => comparePlatform.slug === platformSlug);
    const isMobile = useMediaQuery(theme.breakpoints.down('header'));
    const onPlatformSelected = (platform) => {
        setSelectedPlatformSlug(platform.slug);
        onClose();
        onSelect === null || onSelect === void 0 ? void 0 : onSelect();
    };
    const toggleOpen = () => (isOpen ? onClose() : onOpen());
    const handleClickOutside = (event) => {
        onClickOutside(event);
    };
    if (platforms.length === 0 || isLoading) {
        return _jsx(_Fragment, {});
    }
    if (isMobile) {
        return (_jsxs(_Fragment, { children: [_jsx(PlatformLinkButton, { className: className, platform: selectedPlatform, onClick: onOpen, chevron: "down" }), _jsx(PlatformSelectModal, { isOpen: isOpen, onClose: onClose })] }));
    }
    return (_jsx(Dropdown, { className: dropdownClassName, containerClassName: className, buttonRef: buttonRef.current, isOpen: isOpen, onClickOutside: handleClickOutside, placement: "bottom-start", dropdownButton: _jsx(PlatformLinkButton, { iconRef: ref, innerRef: buttonRef, platform: selectedPlatform, chevron: "down", onClick: toggleOpen, minimize: shouldAnimate, size: "sm" }), children: _jsx(List, { component: "nav", children: platforms.map((platform) => (_jsx(PlatformLinkButton, { to: `/${platform.slug}/compete`, onClick: () => onPlatformSelected(platform), platform: platform, chevron: "right", size: "md" }, platform.id))) }) }));
});
export default PlatformSelectionMenu;
