import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from '@mui/material/styles';
const StyledFooter = styled('p') `
  font-size: 11px;
  text-align: center;
  max-width: 1133px;
  margin-left: auto;
  margin-right: auto;
  margin-top: ${(props) => props.theme.spacing(4)};
  margin-bottom: ${(props) => props.theme.spacing(4)};
  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-top: ${(props) => props.theme.spacing(7)};
    margin-bottom: ${(props) => props.theme.spacing(7)};
  } ;
`;
const RegistrationFooter = () => {
    return (_jsxs(StyledFooter, { children: [_jsx("span", { "data-ignore": true, children: "Repeat.gg" }), " is not endorsed by, directly affiliated with, maintained or sponsored by\u00A0", _jsx("span", { "data-ignore": true, children: "Apple Inc" }), ", ", _jsx("span", { "data-ignore": true, children: "Electronic Arts" }), ",\u00A0", _jsx("span", { "data-ignore": true, children: "Activision Blizzard" }), ", ", _jsx("span", { "data-ignore": true, children: "Take-Two Interactive" }), ",\u00A0", _jsx("span", { "data-ignore": true, children: "Riot Games" }), ", ", _jsx("span", { "data-ignore": true, children: "Microsoft" }), ", ", _jsx("span", { "data-ignore": true, children: "Xbox" }), "\u00A0 or", ' ', _jsx("span", { "data-ignore": true, children: "Epic Games" }), ". All content, games titles, trade names and/or trade dress, trademarks, artwork and associated imagery are trademarks and/or copyright material of their respective owners."] }));
};
export default RegistrationFooter;
