import { useEffect } from 'react';
import useGamesList from '@hooks/API/useGamesList';
/**
 * Used by AddGameProvider - This custom hook triggers when the add game provider state is
 * ready.
 * @param callback - Invoked when the provider is ready.
 * @param state - State to observe and determine whether the provider is ready or not
 */
const useAddGameProviderIsReady = (callback, state) => {
    const { isLoading, error } = useGamesList();
    useEffect(() => {
        if (!isLoading && !error && state.requireAgePrompt != null) {
            callback();
        }
    }, [state.requireAgePrompt, isLoading, error]);
};
export default useAddGameProviderIsReady;
