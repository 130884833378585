import RepeatTheme from '@themes/RepeatTheme';
import { css } from '@emotion/react';
/**  Used on some buttons or boxes */
const hoverBorderGradientAnimation = {
    transition: 'background 0.3s ease-out',
    position: 'relative',
    '&:hover': {
        backgroundColor: RepeatTheme.palette.primary.light,
        '&:before': {
            left: 0,
        },
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        right: '0',
        left: '100%',
        bottom: '0',
        background: `linear-gradient(90deg, transparent 0%, ${RepeatTheme.palette.primary.main} 100%)`,
        height: '4px',
        transition: 'left 0.3s ease-out',
        pointerEvents: 'none',
    },
};
export const styledHoverBorderGradientAnimation = css `
  transition: background 0.3s ease-out;
  position: relative;

  &:hover {
    background-color: ${RepeatTheme.palette.primary.light};
    &:before {
      left: 0;
    }
  }

  &:before {
    content: '';
    position: absolute;
    right: 0;
    left: 100%;
    bottom: 0;
    background: linear-gradient(90deg, transparent 0%, ${RepeatTheme.palette.primary.main} 100%);
    height: 4px;
    transition: left 0.3s ease-out;
    pointer-events: none;
  }
`;
export default hoverBorderGradientAnimation;
