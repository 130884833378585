import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack } from '@mui/material';
import CoreButton from "@components/Buttons/CoreButton";
import { styled } from '@mui/system';
const StyledButton = styled(CoreButton) `
  height: ${({ height }) => height};
`;
const MobileModalButtons = ({ height, onApply, onReset }) => {
    return (_jsxs(Stack, { spacing: 1, mt: 1, children: [_jsx(StyledButton, { onClick: () => onApply(), height: height, variant: "primary", appearance: "fill", children: "Apply Filters" }), _jsx(StyledButton, { onClick: () => onReset(), height: height, variant: "grey", appearance: "subdued", children: "Reset Filters" })] }));
};
export default MobileModalButtons;
