import * as React from 'react';
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme) => ({
    modalContent: {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.grey[400],
        fontWeight: 600,
        fontSize: '12px',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: theme.spacing(3),
        '& h2': {
            fontWeight: 700,
            fontSize: '22px',
            lineHeight: '33.5px',
            color: theme.palette.grey[900],
            marginBottom: theme.spacing(3),
            [theme.breakpoints.down('sm')]: {
                fontSize: '19px',
            },
        },
        '& h3': {
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.grey[900],
            marginBottom: theme.spacing(2),
            marginTop: 0,
        },
        '& ul': {
            paddingInlineStart: theme.spacing(2),
            listStyle: 'disc',
        },
        '& ol': {
            paddingInlineStart: theme.spacing(2),
        },
        '& section': {
            padding: theme.spacing(0, 0, 3),
        },
        '& label': {
            fontSize: '12px',
            color: theme.palette.grey[900],
            lineHeight: '23px',
        },
        '& li': {
            fontSize: '12px',
            color: theme.palette.grey[800],
            lineHeight: '23px',
            marginBottom: theme.spacing(1),
        },
        '& p a': {
            fontWeight: 500,
            color: theme.palette.primary.main,
        },
        '& button': {
            fontSize: '14px',
            fontWeight: 700,
        },
    },
    alignCenter: {
        textAlign: 'center',
        alignItems: 'center',
    },
    vAlignTop: {
        justifyContent: 'flex-start',
    },
    vaAlignCenter: {
        justifyContent: 'center',
    },
}));
const ModalContent = ({ align = 'left', verticalAlign, children, className, stepName, asElement = 'div', ...props }) => {
    const { classes, cx } = useStyles();
    const componentProps = {
        ...props,
        className: cx(classes.modalContent, {
            [classes.alignCenter]: align === 'center',
        }, {
            [classes.vAlignTop]: verticalAlign === 'top',
        }, {
            [classes.vaAlignCenter]: verticalAlign === 'center',
        }, className),
        'data-step-name': stepName,
    };
    return React.createElement(asElement, componentProps, children);
};
export default ModalContent;
