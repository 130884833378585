import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from '@mui/system';
import { Drawer } from '@mui/material';
import { CrossCloseIcon } from '@components/UI';
const StyledDrawer = styled(Drawer, { shouldForwardProp: (propName) => propName !== 'minHeight' }) `
  & .MuiPaper-root {
    border-radius: 6px 6px 0 0;
    min-height: ${({ minHeight }) => minHeight}px;
  }
`;
const DrawerHeader = styled('div') `
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(2, 0)};
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[100]};

  svg {
    margin-left: auto;
  }

  h3 {
    margin: 0;
  }
`;
const StyledCrossCloseIcon = styled(CrossCloseIcon) `
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
`;
const DrawerContent = styled('div') `
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  margin: ${({ theme }) => theme.spacing(2)};
`;
const MobileFiltersDrawer = ({ isOpen, onClose, children, minHeight = 0 }) => {
    return (_jsxs(StyledDrawer, { anchor: "bottom", open: isOpen, onClose: onClose, minHeight: minHeight, children: [_jsxs(DrawerHeader, { children: [_jsx("h3", { children: "Filters" }), _jsx(StyledCrossCloseIcon, { action: onClose })] }), _jsx(DrawerContent, { children: children })] }));
};
export default MobileFiltersDrawer;
