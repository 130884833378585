const loadDisplayModeFromStorage = () => {
    try {
        const displayMode = localStorage.getItem('repeatTournamentDisplayMode');
        return (displayMode || 'list'); // defaults to list
    }
    catch (err) {
        return 'list';
    }
};
export default loadDisplayModeFromStorage;
