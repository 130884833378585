import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Button from "@components/Buttons/Button";
import ModalContent from "@components/Modals/components/ModalContent";
import { RealMoney } from '@components/UI';
import AddGameStatusBanner from '@components/AddGame/AddGameStatusBanner';
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme) => ({
    envelope: {
        width: '135px',
        alignSelf: 'center',
    },
    paragraph: {
        lineHeight: 1.5,
        marginBottom: theme.spacing(6),
        '& b': {
            color: theme.palette.grey[900],
        },
    },
    bold: {
        fontWeight: 'bold',
    },
}));
const WithdrawComplete = ({ type, amount, email, onSubmit, palette }) => {
    const { classes } = useStyles();
    return (_jsxs(ModalContent, { align: "center", children: [type === 'SUCCESS' ? (_jsxs(_Fragment, { children: [_jsx(AddGameStatusBanner, { status: "success" }), _jsx("h2", { children: "Request Successful" }), _jsxs("p", { className: classes.paragraph, children: ["Your ", _jsx(RealMoney, { amount: amount, centerVertical: true }), " withdrawal request is successful and is currently in the processing stage. Money should appear within 3-5 business days in your account"] })] })) : (_jsxs(_Fragment, { children: [_jsx("img", { src: "/img/emails/branded-envelope-2.png", alt: "", className: classes.envelope }), _jsx("h2", { children: "Check your email" }), _jsxs("p", { className: classes.paragraph, children: ["Your ", _jsx(RealMoney, { amount: amount, centerVertical: true }), " withdrawal request was successful, in order to finish your transaction, please follow the steps from the email that we've sent to", ' ', _jsx("span", { className: classes.bold, "data-ignore": true, children: email })] })] })), _jsx(Button, { variant: "palette", paletteName: palette, onClick: onSubmit, children: "Close" })] }));
};
export default WithdrawComplete;
