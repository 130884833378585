/**
 * Helper function to determine the condition of the Award/Rank display for the current logged in user.
 * - If there's an user entry.
 * -- show rank if the tournament hasn't finished
 * -- show paidout if the tournament has.
 * - if no user entry, show prizePool
 *
 * @param tournament The tournament
 * @returns 'rank' | 'paidOut' | 'prizePool'
 */
const getTournamentAwardRankType = (tournament) => {
    var _a, _b;
    if (tournament === null || tournament === void 0 ? void 0 : tournament.user_entry) {
        if (!tournament.finished) {
            if (((_a = tournament === null || tournament === void 0 ? void 0 : tournament.user_entry) === null || _a === void 0 ? void 0 : _a.rank) && ((_b = tournament === null || tournament === void 0 ? void 0 : tournament.user_entry) === null || _b === void 0 ? void 0 : _b.eligible)) {
                return 'rank';
            }
            return 'prizePool';
        }
        return 'paidOut';
    }
    return 'prizePool';
};
export default getTournamentAwardRankType;
