import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { captureException } from '@sentry/react';
import isFirebaseAuthError from './isFirebaseAuthError';
import { doTrackEvent } from '../../components';
/**
 * Prompt the user to authenticate with Google
 *
 * @param props The component props
 * @param props.authenticationAction Indicates if this is a login or sign up attempt
 * @param props.scopes The scopes to request from the user
 * @returns Authenticated firebase user
 * @throws unknown
 */
const authenticateWithGoogle = async ({ authenticationAction, scopes, }) => {
    var _a;
    try {
        const auth = firebase.auth();
        const googleProvider = new firebase.auth.GoogleAuthProvider();
        if (scopes) {
            scopes.forEach((scope) => googleProvider.addScope(scope));
        }
        const signInResult = await auth.signInWithPopup(googleProvider);
        doTrackEvent({
            type: 'authenticate_with_provider',
            authenticationAction,
            provider: 'google',
            userId: (_a = signInResult === null || signInResult === void 0 ? void 0 : signInResult.user) === null || _a === void 0 ? void 0 : _a.uid,
        });
        return signInResult;
    }
    catch (error) {
        if (isFirebaseAuthError(error)) {
            // Only capture unexpected errors
            if (error.code !== 'auth/popup-closed-by-user' && error.code !== 'auth/cancelled-popup-request') {
                captureException(error);
            }
        }
        throw error;
    }
};
export default authenticateWithGoogle;
