import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import loadable from '@loadable/component';
import CoreButton from '@components/Buttons/CoreButton';
import LoadingLogo from '@components/Modals/LoadingModal/Components/LoadingLogo';
import { makeStyles } from 'tss-react/mui';
import { styled } from '@mui/material/styles';
const Bell = loadable(() => import('@components/Icons/Bell'));
const useStyles = makeStyles()((theme) => ({
    emptyNotificationsContainer: {
        textAlign: 'center',
        padding: theme.spacing(4, 2),
        '& svg': {
            width: '33px',
            fill: theme.palette.grey[900],
            marginBottom: theme.spacing(2),
        },
        '& a': {
            marginTop: theme.spacing(2),
            fontWeight: 600,
        },
    },
}));
const StyledLoadingContainer = styled('div') `
  min-height: 250px;
  padding: theme.spacing(4, 2);
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    width: 100px;
    height: 100px;
  }
  min-width: 410px;
`;
const NotificationEmpty = ({ isLoading }) => {
    const { classes } = useStyles();
    if (isLoading) {
        return (_jsx(StyledLoadingContainer, { children: _jsx(LoadingLogo, {}) }));
    }
    return (_jsxs("div", { className: classes.emptyNotificationsContainer, children: [_jsx(Bell, {}), _jsx("h3", { children: "You don't have any notifications" }), _jsx("p", { children: "Looks like you don't have any notifications or updates at the moment, we'll update you as soon as we have something for you" }), _jsx(CoreButton, { to: `/tournaments`, children: "Browse Tournaments" })] }));
};
export default NotificationEmpty;
