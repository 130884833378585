import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import LoadingLogo from '@components/Modals/LoadingModal/Components/LoadingLogo';
import { styled } from '@mui/material/styles';
const LoadingContent = styled('div') `
  display: flex;
  align-items: center;
  font-size: 16px;
  text-align: center;
  justify-content: space-around;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(3)};
`;
const LoadingAnimationPlaceholder = ({ title, className }) => {
    return (_jsxs(LoadingContent, { "data-testid": "loading animation", className: className, children: [_jsx("div", { children: _jsx(LoadingLogo, {}) }), _jsx("div", { children: title })] }));
};
export default LoadingAnimationPlaceholder;
