import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { styled } from '@mui/system';
import { ArrowRightCircleIcon } from '@heroicons/react/20/solid';
import { useFeature } from 'flagged';
import PlatformSelectionMenu from '@domain/Header/components/Menus/PlatformSelectionMenu';
import useSelectedPlatform from '@hooks/useSelectedPlatform';
import CoreButton from '@components/Buttons/CoreButton';
import FullLayout from '@components/Layout/FullLayout';
import { usePlatformSelectAnimation } from '@context/PlatformSelectAnimationContext';
import { Global } from '@emotion/react';
import GameTileCarousel from './components/GameTileCarousel';
const desktopDropdownSize = 255;
const StyledLayout = styled(FullLayout) `
  padding-top: 0;
`;
const PositioningBox = styled('div') `
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(5)};
  margin: auto;
  min-height: calc(100vh - ${({ theme }) => theme.layout.headerDesktopHeight}px);
  overflow: hidden;
  padding: ${({ theme }) => theme.spacing(5, 5, 0)};
  text-align: center;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-top: ${({ theme }) => theme.spacing(18)};
  }
`;
const PageTitle = styled('h2') `
  color: ${({ theme }) => theme.palette.common.black};
  font-size: 36px;
  font-weight: 600;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 48px;
  }
`;
const Tagline = styled('p') `
  color: ${({ theme }) => theme.palette.grey[300]};
  font-size: 12px;
  font-weight: 600;
  max-width: 360px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 14px;
  }
`;
const StyledPlatformSelectionMenu = styled(PlatformSelectionMenu) `
  border: 1px solid ${({ theme }) => theme.palette.grey[100]};
  border-radius: ${({ theme }) => theme.spacing(1)};
  width: 224px;
  flex: 1;
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.05);
  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: ${desktopDropdownSize}px;
  }
`;
const StyledIcon = styled(ArrowRightCircleIcon) `
  padding-left: ${({ theme }) => theme.spacing(1)};
`;
const PlatformSelectionRow = styled('div') `
  align-items: stretch;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1.5)};
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    flex-direction: row;
    width: auto;
    min-width: 440px;
    padding: 12px;
  }
`;
const StyledCoreButton = styled(CoreButton) `
  padding: 14px 16px !important;
  border-radius: 6px !important;
`;
const PlatformScreen = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { startAnimation } = usePlatformSelectAnimation();
    const buttonRef = useRef(null);
    const isPlatformFirstEnabled = Boolean(useFeature('enable_platform_first'));
    const { platformSlug, isLoading: isLoadingSelectedPlatform } = useSelectedPlatform();
    const togglePlatformSelect = () => {
        setIsOpen(!isOpen);
    };
    const handleClickOutside = (event) => {
        if (event.target !== buttonRef.current) {
            setIsOpen(false);
        }
    };
    const handleStartPlayingClick = () => {
        if (!platformSlug) {
            togglePlatformSelect();
        }
    };
    if (isPlatformFirstEnabled && !isLoadingSelectedPlatform && platformSlug) {
        return _jsx(Redirect, { to: `/${platformSlug}/compete` });
    }
    return (_jsx(StyledLayout, { children: _jsxs(PositioningBox, { children: [_jsx(PageTitle, { children: "Ready to Compete?" }), _jsx(Tagline, { children: "Play your favorite game titles against millions of players worldwide & win amazing prizes" }), _jsxs(PlatformSelectionRow, { children: [_jsx(Global, { styles: {
                                '.platform-select-menu-dropdown': {
                                    // offset the border width by 1px
                                    width: `${desktopDropdownSize - 1}px`,
                                },
                            } }), _jsx(StyledPlatformSelectionMenu, { dropdownClassName: "platform-select-menu-dropdown", isOpen: isOpen, onClose: () => setIsOpen(false), onOpen: () => setIsOpen(true), onClickOutside: handleClickOutside, onSelect: () => {
                                startAnimation();
                            } }), _jsxs(StyledCoreButton, { ref: buttonRef, size: "lg", to: platformSlug && `/${platformSlug}/compete`, onClick: handleStartPlayingClick, children: ["Start Playing ", _jsx(StyledIcon, { height: 20 })] })] }), _jsx(Link, { to: "/compete", children: "View all games" }), _jsx(GameTileCarousel, {})] }) }));
};
export default PlatformScreen;
