import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
import { styled } from '@mui/system';
import CrossCloseIcon from '@components/UI/CrossCloseIcon';
const useStyles = makeStyles()((theme) => ({
    closeBtn: {
        color: theme.palette.common.white,
        position: 'absolute',
        padding: theme.spacing(1),
        right: 0,
        top: 0,
    },
    headerImage: {
        width: '100%',
        height: '100%',
    },
}));
const WidthWrapper = styled('div') `
  background-color: ${(props) => props.theme.palette.primary.main};
  display: flex;
  justify-content: center;
  overflow: clip;
  position: relative;
`;
const CloseBtn = styled(CrossCloseIcon) `
  color: ${(props) => props.theme.palette.common.white};
  position: absolute;
  padding: ${(props) => props.theme.spacing(1)};
  right: 0;
  top: 0;
`;
const HeaderImage = styled('img') `
  width: 100%;
  height: 100%;
`;
const ModalImageHeader = ({ imageUrl, altText, className, fillColor, onClose }) => {
    return (_jsxs(WidthWrapper, { className: className, children: [_jsx(CloseBtn, { action: onClose, fillColor: fillColor }), _jsx(HeaderImage, { src: imageUrl, alt: altText })] }));
};
export default ModalImageHeader;
