import axios from 'axios';
import queryString from 'query-string';
import validNotifications from '@helpers/Validation/validNotifications';
/**
 * Fetch data from the /notifications/recent endpoint
 *
 * @param params The request parameters
 * @param params.offset The offset index of notifications to return
 * @param params.limit The maximum number of notifications to return
 * @param params.tags Optional: only include notifications with these tags
 * @throws {ErrorWithCause}
 * @returns response from the API with metadata and a payload with activity notifications and site notifications
 */
const fetchNotifications = async ({ offset, limit, tags, }) => {
    return axios
        .get(`${process.env.API_BASE_URL}/notifications/notification?${queryString.stringify({
        offset,
        limit,
        tag: tags,
    })}`)
        .then((response) => ({ ...response.data, payload: validNotifications(response.data.payload) }));
};
export default fetchNotifications;
