import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import PageTitle from '@helpers/PageTitle';
import MySocial from '@domain/User/Profile/components/MySocial';
import UserProfileProvider from '@domain/User/Profile/context/UserProfileContext';
import { useUserState } from '@context/UserContext';
import ConnectSocialMediaAccountsModal from '@domain/User/Profile/SocialAccounts/components/ConnectSocialMediaAccountsModal';
import MyLoginAccounts from '@domain/User/Profile/LoginAccounts/MyLoginAccounts';
import Help from './components/Help';
import { TournamentBanner, RightColumn, LeftColumn, Container } from './styles';
const AccountSocials = () => {
    const [showConnectSocialAccountsModal, setShowConnectSocialAccountsModal] = useState(false);
    const { userProfile } = useUserState();
    const handleConnectSocialAccounts = () => {
        setShowConnectSocialAccountsModal(true);
    };
    const handleCloseSocialAccountsModal = () => {
        setShowConnectSocialAccountsModal(false);
    };
    return (_jsxs(TournamentBanner, { children: [_jsx(ConnectSocialMediaAccountsModal, { open: showConnectSocialAccountsModal, onClose: handleCloseSocialAccountsModal }), _jsxs(Container, { children: [_jsx(PageTitle, {}), _jsx(LeftColumn, { children: _jsxs(UserProfileProvider, { handle: userProfile === null || userProfile === void 0 ? void 0 : userProfile.handle, loggedInUserProfile: userProfile, preview: false, children: [_jsx(MySocial, { handleConnectSocialAccounts: handleConnectSocialAccounts }), _jsx(MyLoginAccounts, {})] }) }), _jsx(RightColumn, { children: _jsx(Help, {}) })] })] }));
};
export default AccountSocials;
