import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme) => ({
    siteAlert: {
        alignItems: 'center',
        background: 'rgba(249, 57, 45, 0.14)',
        color: '#F9392D',
        display: 'flex',
        fontFamily: theme.typography.fontFamily,
        fontWeight: 600,
        justifyContent: 'center',
        padding: theme.spacing(2, 0),
    },
    innerContainer: {
        maxWidth: theme.layout.containerMaxWidth,
        flex: 1,
        padding: theme.spacing(0, 3),
    },
}));
const SiteAlert = ({ children }) => {
    const { classes } = useStyles();
    return (_jsx("div", { role: "alert", className: classes.siteAlert, children: _jsx("div", { className: classes.innerContainer, children: children }) }));
};
export default SiteAlert;
