/**
 * Used to update/reset the entire profile's user summary data
 *
 * @param {UserSummary} profileSummary The profile summary
 * @returns The set profile data action.
 */
export const setProfileDataAction = (profileSummary) => ({
    type: 'SET_PROFILE_DATA',
    profileSummary,
});
export const setStatisticsAction = (statistics) => ({
    type: 'SET_STATISTICS',
    statistics,
});
export const setActiveTournamentsAction = (tournaments) => ({
    type: 'SET_ACTIVE_TOURNAMENTS',
    tournaments,
});
export const setPastTournamentsAction = (tournaments) => ({
    type: 'SET_PAST_TOURNAMENTS',
    tournaments,
});
export const setIsCurrentUserAction = (isCurrentUser) => ({
    type: 'SET_IS_CURRENT_USER',
    isCurrentUser,
});
export const setUserProfileAction = (userProfile) => ({
    type: 'SET_USER_PROFILE',
    userProfile,
});
export const setOverallStatisticsAction = (overallStatistics) => ({
    type: 'SET_OVERALL_STATISTICS',
    overallStatistics,
});
export const setGameStaticsAction = (gameKey, gameStatistics) => ({
    type: 'SET_GAME_STATISTICS',
    gameKey,
    gameStatistics,
});
