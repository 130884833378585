import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CoreButton from "@components/Buttons/CoreButton";
import ModalContent from "@components/Modals/components/ModalContent";
import styled from '@emotion/styled';
import useTrackEvent from '@hooks/useTrackEvent';
const CenterText = styled('p') `
  text-align: center;
`;
const CenterHeading = styled('h2') `
  text-align: center;
`;
const ContentInstruction = ({ stepName, onContinue }) => {
    const trackEvent = useTrackEvent();
    const handleClick = () => {
        trackEvent({
            type: 'game_linking',
            data: {
                eventName: 'game linking',
                label: 'Connect Rocket League',
                moduleName: 'Button',
                position: 0,
            },
        });
        if (onContinue) {
            onContinue();
        }
    };
    return (_jsxs(ModalContent, { stepName: stepName, children: [_jsxs("section", { children: [_jsxs(CenterHeading, { children: ["Connect your ", _jsx("span", { "data-ignore": true, children: "Rocket League" }), " account"] }), _jsx(CenterText, { children: "Add your Game ID to the Repeat profile so you can start competing in the tournaments, our connect process is simple and take about 5 minutes to complete" })] }), _jsx(CoreButton, { type: "button", onClick: handleClick, size: "lg", variant: "rocketLeague", children: _jsxs("span", { children: ["Connect ", _jsx("span", { "data-ignore": true, children: "Rocket League" })] }) })] }));
};
export default ContentInstruction;
