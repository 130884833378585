/**
 * Remove object props that has undefined or null values or any value in the ignore list.
 *
 * @param target The object to remove props from
 * @param ignoreList The props to remove regardless of whether they are empty
 * @returns The target without the empty props or the ones from the ignore list
 */
const removeEmptyProps = (target, ignoreList = [undefined, null]) => {
    const sanitizedTarget = Object.entries(target).filter((targetEntry) => !ignoreList.includes(targetEntry[1]));
    return Object.fromEntries(sanitizedTarget);
};
export default removeEmptyProps;
