import swagbucksTournamentModalLastShown from './swagbucksTournamentModalLastShown';
const showLoLEUSwagbucksTournament = (userContext, gameContext, gameSlug) => {
    var _a, _b, _c, _d, _e;
    const isLoL = gameContext.games.find((game) => game.slug === gameSlug);
    const hasLoL = userContext.userHasGame('league_of_legends');
    // https://bitbucket.org/repeatgg/country-provider/src/master/documents/countries.json
    // UK, Australia, Canada, Germany, France only
    const userIsFromEU = ((_a = userContext === null || userContext === void 0 ? void 0 : userContext.userDetails) === null || _a === void 0 ? void 0 : _a.country) === 'GB' ||
        ((_b = userContext === null || userContext === void 0 ? void 0 : userContext.userDetails) === null || _b === void 0 ? void 0 : _b.country) === 'CA' ||
        ((_c = userContext === null || userContext === void 0 ? void 0 : userContext.userDetails) === null || _c === void 0 ? void 0 : _c.country) === 'AU' ||
        ((_d = userContext === null || userContext === void 0 ? void 0 : userContext.userDetails) === null || _d === void 0 ? void 0 : _d.country) === 'DE' ||
        ((_e = userContext === null || userContext === void 0 ? void 0 : userContext.userDetails) === null || _e === void 0 ? void 0 : _e.country) === 'FR';
    const modalLastShown = swagbucksTournamentModalLastShown();
    if (isLoL && hasLoL && userIsFromEU && (modalLastShown >= 48 || modalLastShown === false)) {
        return true;
    }
    return false;
};
export default showLoLEUSwagbucksTournament;
