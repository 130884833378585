import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { differenceInHours } from 'date-fns';
import { useFeature } from 'flagged';
import useGamesList from '@hooks/API/useGamesList';
import { useUserState } from '@context/UserContext';
import useAppState from '@hooks/API/useAppState';
import showFortniteGBPouchTournament from '../helpers/showFortniteGBPouchTournament';
import showLoLUSSwagbucksTournament from '../helpers/showLoLUSSwagbucksTournament';
import showLoLEUSwagbucksTournament from '../helpers/showLoLEUSwagbucksTournament';
import showLoLUSOperaGXTournament from '../helpers/showLoLUSOperaGXTournament';
import showWarzoneUSOperaGXTournament from '../helpers/showWarzoneUSOperaGXTournament';
import showLoLGBPouchTournament from '../helpers/showLoLGBPouchTournament';
const useTournamentSplashModal = (authenticated, userDetails, dispatch) => {
    var _a;
    const isOperaGXTournamentModalEnabled = useFeature('enable_operagx_tournament_modal');
    const isSwagbucksTournamentModalEnabled = useFeature('enable_swagbucks_tournament_modal');
    const isPouchTournamentGbModalEnabled = useFeature('enable_pouch_tournament_gb_modal');
    const { gameSlug } = useParams();
    const userContextState = useUserState();
    const gameContext = useGamesList();
    const appContext = useAppState();
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
        if (authenticated && userDetails) {
            const brandPouchFortniteTournamentId = (_b = (_a = appContext.tournaments) === null || _a === void 0 ? void 0 : _a.pouch) === null || _b === void 0 ? void 0 : _b.brandPouchFortniteTournamentId;
            const brandPouchLeagueOfLegendsTournamentId = (_d = (_c = appContext.tournaments) === null || _c === void 0 ? void 0 : _c.pouch) === null || _d === void 0 ? void 0 : _d.brandPouchLeagueOfLegendsTournamentId;
            const brandSwagbucksUSLeagueOfLegendsTournamentId = (_f = (_e = appContext.tournaments) === null || _e === void 0 ? void 0 : _e.swagbucks) === null || _f === void 0 ? void 0 : _f.brandSwagbucksUSLeagueOfLegendsTournamentId;
            const brandSwagbucksEULeagueOfLegendsTournamentId = (_h = (_g = appContext.tournaments) === null || _g === void 0 ? void 0 : _g.swagbucks) === null || _h === void 0 ? void 0 : _h.brandSwagbucksEULeagueOfLegendsTournamentId;
            const brandOperaGXUSLeagueOfLegendsTournamentId = (_k = (_j = appContext.tournaments) === null || _j === void 0 ? void 0 : _j.operagx) === null || _k === void 0 ? void 0 : _k.brandOperaGXUSLeagueOfLegendsTournamentId;
            const brandOperaGXUSWarzoneTournamentId = (_m = (_l = appContext.tournaments) === null || _l === void 0 ? void 0 : _l.operagx) === null || _m === void 0 ? void 0 : _m.brandOperaGXUSWarzoneTournamentId;
            const userAccountCreated = userDetails.dateCreated;
            // Show Fortnite Pouch Tournament
            if (isPouchTournamentGbModalEnabled &&
                showFortniteGBPouchTournament(userContextState, gameContext, gameSlug) &&
                brandPouchFortniteTournamentId) {
                localStorage.setItem('pouchTournamentGbModalLastShown', JSON.stringify(new Date()));
                const tournamentLink = `/fortnite/tournaments/${brandPouchFortniteTournamentId}`;
                dispatch({ type: 'showPouchTournamentModal', pouchTournamentLink: tournamentLink });
                // Show LoL Pouch Tournament
            }
            else if (isPouchTournamentGbModalEnabled &&
                showLoLGBPouchTournament(userContextState, gameContext, gameSlug) &&
                brandPouchLeagueOfLegendsTournamentId) {
                localStorage.setItem('pouchTournamentGbModalLastShown', JSON.stringify(new Date()));
                const tournamentLink = `/league_of_legends/tournaments/${brandPouchLeagueOfLegendsTournamentId}`;
                dispatch({ type: 'showPouchTournamentModal', pouchTournamentLink: tournamentLink });
                // Show US LoL Swagbucks Tournament
            }
            else if (isSwagbucksTournamentModalEnabled &&
                showLoLUSSwagbucksTournament(userContextState, gameContext, gameSlug) &&
                brandSwagbucksUSLeagueOfLegendsTournamentId) {
                localStorage.setItem('swagbucksTournamentModalLastShown', JSON.stringify(new Date()));
                const tournamentLink = `/league_of_legends/tournaments/${brandSwagbucksUSLeagueOfLegendsTournamentId}`;
                dispatch({ type: 'showSwagbucksUSTournamentModal', swagbucksUSTournamentLink: tournamentLink });
                // Show EU LoL Swagbucks Tournament
            }
            else if (isSwagbucksTournamentModalEnabled &&
                showLoLEUSwagbucksTournament(userContextState, gameContext, gameSlug) &&
                brandSwagbucksEULeagueOfLegendsTournamentId) {
                localStorage.setItem('swagbucksTournamentModalLastShown', JSON.stringify(new Date()));
                const tournamentLink = `/league_of_legends/tournaments/${brandSwagbucksEULeagueOfLegendsTournamentId}`;
                dispatch({ type: 'showSwagbucksEUTournamentModal', swagbucksEUTournamentLink: tournamentLink });
                // Show US LoL Opera GX Tournament
            }
            else if (isOperaGXTournamentModalEnabled &&
                showLoLUSOperaGXTournament(userContextState, gameSlug) &&
                brandOperaGXUSLeagueOfLegendsTournamentId) {
                localStorage.setItem('operaGXTournamentUSLoLModalLastShown', JSON.stringify(new Date()));
                const tournamentLink = `/league_of_legends/tournaments/${brandOperaGXUSLeagueOfLegendsTournamentId}`;
                dispatch({ type: 'showOperaGXUSLoLTournamentModal', operaGXUSLoLTournamentLink: tournamentLink });
                // Show US Warzone Opera GX Tournament
            }
            else if (isOperaGXTournamentModalEnabled &&
                showWarzoneUSOperaGXTournament(userContextState, gameSlug) &&
                brandOperaGXUSWarzoneTournamentId) {
                localStorage.setItem('operaGXTournamentUSWarzoneModalLastShown', JSON.stringify(new Date()));
                const tournamentLink = `/cod-warzone/tournaments/${brandOperaGXUSWarzoneTournamentId}`;
                dispatch({ type: 'showOperaGXUSWarzoneTournamentModal', operaGXUSWarzoneTournamentLink: tournamentLink });
                // Show verify email modal
            }
            else if (userAccountCreated && !((_o = userDetails.verification) === null || _o === void 0 ? void 0 : _o.hasVerifiedEmail)) {
                const lastVerifyEmailNotificationDate = localStorage.getItem('repeatVerifyEmail');
                // If a previous notification has been sent, ensure 24 hours has passed before showing again otherwise, trigger modal
                const hoursSinceAccountCreated = differenceInHours(new Date(), new Date(userAccountCreated));
                if (lastVerifyEmailNotificationDate) {
                    const hoursSinceLastNotification = differenceInHours(new Date(), new Date(JSON.parse(lastVerifyEmailNotificationDate)));
                    if (hoursSinceAccountCreated > 4 && hoursSinceLastNotification > 24) {
                        localStorage.setItem('repeatVerifyEmail', JSON.stringify(new Date()));
                        dispatch({ type: 'showVerifyEmailModal' });
                    }
                }
                else if (hoursSinceAccountCreated > 4) {
                    localStorage.setItem('repeatVerifyEmail', JSON.stringify(new Date()));
                    dispatch({ type: 'showVerifyEmailModal' });
                }
            }
        }
    }, [
        authenticated,
        userDetails === null || userDetails === void 0 ? void 0 : userDetails.dateCreated,
        (_a = userDetails === null || userDetails === void 0 ? void 0 : userDetails.verification) === null || _a === void 0 ? void 0 : _a.hasVerifiedEmail,
        gameContext.isLoading,
        appContext.tournaments,
        isSwagbucksTournamentModalEnabled,
        isPouchTournamentGbModalEnabled,
        gameSlug,
    ]);
};
export default useTournamentSplashModal;
