import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classnames from 'classnames';
import { styled } from '@mui/system';
function shouldForwardProp(propName) {
    return !['disabled'].includes(propName);
}
const CheckboxLabel = styled('label', { shouldForwardProp }) `
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  input[type='checkbox'] {
    height: 20px;
    line-height: 1;
    margin: ${({ theme }) => theme.spacing(0, 1, 0, 0)};
  }

  span {
    text-align: start;
  }
`;
const HelperText = styled('div') `
  text-align: right;
  font-weight: 600;
  margin-top: 9px;

  .error {
    color: ${({ theme }) => theme.palette.error.main};
    font-size: 11px;
  }

  i {
    margin-left: 6px;
    font-weight: 600;
  }
`;
const CheckboxField = ({ name, onChange, value, label, hasError = false, helperText, className = '', placeholder, checked, disabled = false, }) => {
    return (_jsxs(_Fragment, { children: [_jsxs(CheckboxLabel, { disabled: disabled, className: className, htmlFor: name, children: [_jsx("input", { type: "checkbox", name: name, onChange: onChange, value: value ? 'true' : '', className: classnames(hasError && 'error'), "aria-label": placeholder, placeholder: placeholder, id: name, checked: checked }), _jsx("span", { children: label })] }), helperText && (_jsx(HelperText, { className: classnames('helperText', hasError && 'error'), "data-testid": "checkboxFieldHelperText", children: _jsx("p", { children: helperText }) }))] }));
};
export default CheckboxField;
