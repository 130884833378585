import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useUserState } from '@context/UserContext';
const ABTestingBrancher = ({ segment, options, children, forceOption }) => {
    var _a;
    const userState = useUserState();
    // Nothing to render
    if (children.length === 0) {
        return _jsx(_Fragment, {});
    }
    const segmentValue = forceOption !== null && forceOption !== void 0 ? forceOption : (_a = userState.userDetails) === null || _a === void 0 ? void 0 : _a.segments[segment];
    // Default to first child
    if (!segmentValue) {
        return _jsx(_Fragment, { children: children[0] });
    }
    // Find matching child
    const childIndex = options.indexOf(segmentValue);
    // No match was found
    if (childIndex === -1) {
        return _jsx(_Fragment, { children: children[0] });
    }
    return _jsx(_Fragment, { children: children[childIndex] });
};
export default ABTestingBrancher;
