/**
 * links for make stats public guide
 * content/how-to-add-fortnite
 * content/how-to-add-league-of-legends
 * content/how-to-add-dota2
 * content/how-to-add-pubg
 * content/how-to-add-warzone
 */
function getMakeStatsPublicGuide(gameKey) {
    const baseUrl = `/content/how-to-add-`;
    switch (gameKey) {
        case 'fortnite':
        case 'pubg':
            return baseUrl + gameKey;
        case 'league_of_legends':
            return `${baseUrl}league-of-legends`;
        case 'pc-dota_2':
            return `${baseUrl}dota2`;
        case 'cod-warzone':
            return `${baseUrl}warzone`;
        default:
            return undefined;
    }
}
export default getMakeStatsPublicGuide;
