import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
import { useUserState } from '@context/UserContext';
import LinkButton from '@components/Buttons/LinkButton';
import TournamentBannerButton from './TournamentBannerButton';
import trackHotjarEvent from '@components/Analytics/trackHotjarEvent';
import DownloadIcon from '@components/Icons/DownloadIcon';
const useStyles = makeStyles()((theme) => ({
    tournamentsBanner: {
        alignItems: 'center',
        color: '#fff',
        display: 'flex',
        marginBottom: '20px',
        padding: theme.spacing(4, 0),
        '& h2': {
            marginTop: '0px',
            marginBottom: '0px',
            display: 'flex',
            flex: 1,
            fontFamily: '"Teko", sans-serif',
            fontWeight: 'bold',
            fontSize: '30px',
            [theme.breakpoints.up('sm')]: {
                fontSize: '44px',
            },
            alignItems: 'center',
            '& > img': {
                height: '42px',
                marginRight: '25px',
            },
        },
    },
    downloadLink: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
        position: 'relative',
        padding: '0px',
        paddingLeft: '40px',
        border: '1px solid #1BB2F2',
        lineHeight: '1.7em',
        '& i': {
            fontSize: '18px',
        },
        '& span': {
            display: 'flex',
            padding: '10px 16px',
            borderRadius: '0px 4px 4px 0px',
        },
        '& span:first-of-type': {
            borderRadius: '4px',
            position: 'absolute',
            left: '-1px',
            backgroundColor: '#1BB2F2',
        },
    },
    downloadLinkLoL: {
        border: `1px solid ${theme.palette.lol.main}`,
        '& span, & span:first-of-type': {
            backgroundColor: '#fff',
            color: theme.palette.lol.main,
            border: `1px solid ${theme.palette.lol.main}`,
        },
        '&:hover': {
            background: theme.palette.lol.main,
        },
    },
}));
const TournamentBanner = ({ gameData, onAddGameClick }) => {
    const { classes, cx } = useStyles();
    const isLoggedIn = useUserState().authenticated;
    return (_jsxs("div", { className: classes.tournamentsBanner, "data-testid": "tournaments header", children: [_jsxs("h2", { "data-notranslate": true, children: [_jsx("img", { src: `/img/game_icons/${gameData.gameKey}.svg`, alt: gameData.name }), gameData.name] }), gameData.downloadLink && (_jsxs(LinkButton, { className: cx(classes.downloadLink, gameData.gameKey === 'league_of_legends' && classes.downloadLinkLoL), href: gameData.downloadLink, target: "_blank", onClick: () => trackHotjarEvent(`Click download game ${gameData.gameKey}`), children: [_jsx("span", { children: _jsx(DownloadIcon, {}) }), _jsx("span", { children: "Download Game" })] })), isLoggedIn && _jsx(TournamentBannerButton, { gameData: gameData, onAddGameClick: onAddGameClick })] }));
};
export default TournamentBanner;
