import { differenceInHours } from 'date-fns';
const showLoLUSOperaGXTournament = (userContext, gameSlug) => {
    var _a;
    const isLoL = gameSlug === 'league-of-legends';
    const hasLoL = userContext.userHasGame('league_of_legends');
    const userIsFromUS = ((_a = userContext === null || userContext === void 0 ? void 0 : userContext.userDetails) === null || _a === void 0 ? void 0 : _a.country) === 'US';
    const operaGXTournamentUSModalLastShownDate = localStorage.getItem('operaGXTournamentUSLoLModalLastShown');
    const lastShown = operaGXTournamentUSModalLastShownDate
        ? differenceInHours(new Date(), new Date(JSON.parse(operaGXTournamentUSModalLastShownDate)))
        : false;
    if (isLoL && hasLoL && userIsFromUS && (lastShown >= 48 || lastShown === false)) {
        return true;
    }
    return false;
};
export default showLoLUSOperaGXTournament;
