import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from '@mui/system';
import HelpToolTip from '@components/UI/HelpToolTip';
const StyledH3 = styled('h3') `
  display: inline-flex;
  & svg {
    margin-left: 4px;
  }
`;
const HelpHeader = ({ help, children }) => {
    return (_jsxs(StyledH3, { style: { display: 'inline-flex' }, children: [_jsx("span", { children: children }), help && _jsx(HelpToolTip, { title: help })] }));
};
export default HelpHeader;
