import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import loadable from '@loadable/component';
import { styled } from '@mui/system';
import toPlayMoney from '@helpers/toPlayMoney';
const Coins = loadable(() => import('@components/Icons/Coins'));
function shouldForwardProp(propName) {
    return !['iconSize', 'iconGap'].includes(propName);
}
const Wrapper = styled('span', { shouldForwardProp }) `
  align-items: center;
  display: inline-flex;
  & svg {
    width: ${({ iconSize }) => iconSize}px;
    height: ${({ iconSize }) => iconSize}px;
    margin-right: ${({ iconGap }) => iconGap}px;
  }
  & img {
    width: ${({ iconSize }) => iconSize}px;
    height: ${({ iconSize }) => iconSize}px;
    margin-right: ${({ iconGap }) => iconGap}px;
  }
`;
const PlayMoney = ({ amount, className, optimise = false, hideIcon = false, iconSize = 16, iconGap = 5, }) => {
    return (_jsxs(Wrapper, { iconSize: iconSize, iconGap: iconGap, "data-ignore": true, "data-testid": "PM", className: className, children: [!hideIcon && (optimise ? _jsx("img", { src: "/img/icons/coins.svg", alt: "coins" }) : _jsx(Coins, {})), toPlayMoney(amount)] }));
};
export default PlayMoney;
