import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from '@mui/material/styles';
import { ToggleButton, ToggleButtonGroup, alpha } from '@mui/material';
const StyledToggleButtonGroup = styled(ToggleButtonGroup) `
  display: flex;
  overflow-x: auto;

  & .MuiToggleButtonGroup-grouped {
    font-size: 14px;
    color: black;
    border: 1px solid ${({ theme }) => theme.palette.common.black};
    margin: ${({ theme }) => theme.spacing(1)};
    padding: ${({ theme }) => theme.spacing(1, 2, 1, 2)};

    &:not(:first-of-type) {
      border-radius: ${({ theme }) => theme.spacing(10)};
      border: 1px solid ${({ theme }) => theme.palette.common.black};
    }
    &:first-of-type {
      border-radius: ${({ theme }) => theme.spacing(10)};
      border: 1px solid ${({ theme }) => theme.palette.common.black};
    }
    &:hover {
      background-color: ${({ theme }) => alpha(theme.palette.common.black, 0.1)};
    }
    &.Mui-selected {
      background-color: ${({ theme }) => theme.palette.common.black};
      color: ${({ theme }) => theme.palette.common.white};
    }
  }
`;
const ExclusiveFilterBox = ({ options, selectedId, onSelect }) => {
    const handleChange = (_, value) => {
        if (!value || value === selectedId)
            return;
        onSelect(value);
    };
    return (_jsx(StyledToggleButtonGroup, { value: selectedId, exclusive: true, onChange: handleChange, children: options.map((option) => (_jsx(ToggleButton, { "data-testid": `option-${option.id}`, value: option.id, "aria-label": option.displayValue, children: option.displayValue }, option.id))) }));
};
export default ExclusiveFilterBox;
