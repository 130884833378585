const getFilterOptions = (gameModes, selectedGameModes) => {
    return gameModes.map((gameMode) => {
        var _a;
        return {
            id: gameMode.code,
            value: gameMode.code,
            text: gameMode.name,
            iconUrl: gameMode.iconUrl,
            selected: (_a = selectedGameModes === null || selectedGameModes === void 0 ? void 0 : selectedGameModes.includes(gameMode.code)) !== null && _a !== void 0 ? _a : false,
        };
    });
};
export default getFilterOptions;
