import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme, { variant }) => ({
    tag: {
        textTransform: 'capitalize',
        fontWeight: 600,
        marginLeft: theme.spacing(0.5),
    },
    colorVariant: {
        color: variant ? theme.palette[variant].main : theme.palette.grey[400],
    },
}));
const NotificationTag = ({ variant, children }) => {
    const { classes, cx } = useStyles({ variant });
    return (_jsx("span", { className: cx(classes.tag, {
            [classes.colorVariant]: !!variant,
        }), children: children }));
};
export default NotificationTag;
