import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme) => ({
    modalBody: {
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        fontWeight: 600,
    },
}));
const ModalBody = ({ children, className }) => {
    const { classes, cx } = useStyles();
    return _jsx("div", { className: cx(classes.modalBody, className), children: children });
};
export default ModalBody;
