const messageReactNative = ({ flow, event, errorCode }) => {
    if (window.ReactNativeWebView) {
        const msgContents = [flow, event];
        if (errorCode) {
            msgContents.push(errorCode);
        }
        const message = msgContents.join(':');
        window.ReactNativeWebView.postMessage(message);
    }
};
export default messageReactNative;
