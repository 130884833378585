import { useEffect } from 'react';
import removeQueryParams from '@helpers/removeQueryParams';
import { useHistory, useLocation } from 'react-router-dom';
/**
 * Checks the "action" query param for a specified value,
 * When the action matches, the hook will clear it from the query params and trigger the callback.
 *
 * @param action the action to compare against
 * @param callback triggered when the action matches and is consumed
 */
const useConsumeActionQueryParam = (action, callback) => {
    const history = useHistory();
    const location = useLocation();
    const urlSearchParams = new URLSearchParams(location.search);
    useEffect(() => {
        if (urlSearchParams.has('action') && urlSearchParams.get('action') === action) {
            const { search, hash } = removeQueryParams(['action']);
            history.replace({ search, hash });
            callback();
        }
    }, [urlSearchParams.get('action'), history]);
};
export default useConsumeActionQueryParam;
