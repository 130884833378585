const getGameName = (gameKey) => {
    switch (gameKey) {
        case 'cod-warzone':
            return 'Call of Duty: Warzone Caldera';
        case 'cod-warzone-2':
            return 'Call of Duty: Warzone';
        case 'fortnite':
            return 'Fortnite';
        case 'pc-dota_2':
            return 'Dota 2';
        case 'league_of_legends':
            return 'League of Legends';
        case 'rainbow6':
            return 'Rainbow 6';
        case 'csgo':
            return 'CS:GO';
        case 'overwatch':
            return 'Overwatch';
        case 'valorant':
            return 'Valorant';
        case 'pubg':
            return 'PUBG: BATTLEGROUNDS';
        case 'pubg-mobile':
            return 'PUBG Mobile';
        case 'apex-legends':
            return 'Apex Legends';
        case 'brawl-stars':
            return 'Brawl Stars';
        case 'destiny-2':
            return 'Destiny 2';
        case 'mlb-the-show-23':
            return 'MLB® The Show 23™';
        case 'rocket-league':
            return 'Rocket League';
        default:
            // throw error;
            return '';
    }
};
export default getGameName;
