import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import axios from 'axios';
import { captureException } from '@sentry/react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import ErrorCodeModal from '@components/Modals/ErrorCodeModal';
import SectionContainer from '@components/UI/Section/components/SectionContainer';
import SectionHead from '@components/UI/Section/components/SectionHead';
import SectionHeading from '@components/UI/Section/components/SectionHeading';
import SectionSubheading from '@components/UI/Section/components/SectionSubheading';
import ErrorWithCause from '@helpers/ErrorWithCause';
import { isAxiosError } from '@helpers/errorHelper';
import { isServiceKey } from '@Types/ServiceKey';
import { getAccountLoginSocialMediaTypes } from '../../constants';
import useUserLinkedServices from '../../../../Hooks/API/useUserLinkedServices';
import NoLinkedAccounts from '../components/NoLinkedAccounts';
import SocialMediaAccountItem from '../SocialAccounts/components/SocialMediaAccountItem';
import AddSocialMediaAccountItem from '../SocialAccounts/components/AddSocialMediaAccountItem';
import ConnectLoginAccountModal from './ConnectLoginAccountModal';
import getConnectedFirebaseAccounts from '../helpers/getConnectedFirebaseAccounts';
import ConfirmDeleteSocialMediaAccountModal from '../SocialAccounts/components/ConfirmDeleteSocialMediaAccountModal';
import ConfirmDeleteLastSocialMediaAccountModal from '../SocialAccounts/components/ConfirmDeleteLastSocialMediaAccountModal';
const useStyles = makeStyles()((theme) => {
    return {
        bodyWrapper: {
            fontFamily: theme.typography.fontFamily,
            padding: theme.spacing(0, 3, 3),
        },
    };
});
const MyLoginAccounts = ({ className }) => {
    var _a, _b, _c;
    const { classes, cx } = useStyles();
    const socialMediaAccounts = getConnectedFirebaseAccounts();
    const [accountToDelete, setAccountToDelete] = useState(undefined);
    const [deleting, setDeleting] = useState(false);
    const [adding, setAdding] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showConfirmLastSocialMediaAccount, setShowConfirmLastSocialMediaAccount] = useState(false);
    const { getLinkedServices, deleteLinkedService } = useUserLinkedServices();
    const linkedServices = ((_a = getLinkedServices.data) !== null && _a !== void 0 ? _a : []).map((linkedService) => {
        return {
            id: linkedService.id,
            type: linkedService.serviceKey,
            username: linkedService.displayName,
        };
    });
    const mergedLinkedAndSocialAccounts = [...linkedServices, ...socialMediaAccounts];
    const unlinkFirebaseAccount = async (providerId) => {
        try {
            setDeleting(true);
            const auth = firebase.auth();
            if (!auth.currentUser) {
                throw new Error('Must be logged in');
            }
            await auth.currentUser.unlink(providerId);
            setAccountToDelete(undefined);
        }
        catch (err) {
            captureException(err, { extra: { providerId, file: 'MyLoginAccounts.tsx' } });
        }
        finally {
            setDeleting(false);
        }
    };
    const handleDeleteAccount = async (account) => {
        if (!account) {
            return;
        }
        if (account.type === 'google') {
            await unlinkFirebaseAccount('google.com');
            setShowConfirmLastSocialMediaAccount(false);
            return;
        }
        if (account.type === 'facebook') {
            await unlinkFirebaseAccount('facebook.com');
            setShowConfirmLastSocialMediaAccount(false);
            return;
        }
        if (account.type === 'apple') {
            await unlinkFirebaseAccount('apple.com');
            setShowConfirmLastSocialMediaAccount(false);
            return;
        }
        if (isServiceKey(account.type)) {
            deleteLinkedService.mutate(account.id);
            setAccountToDelete(undefined);
            setShowConfirmLastSocialMediaAccount(false);
            return;
        }
        try {
            setDeleting(true);
            const response = await axios.delete(`/api/user/social_accounts/v1/${account.type}`);
            if (response.status === 200) {
                setAccountToDelete(undefined);
            }
        }
        catch (error) {
            const err = new ErrorWithCause(error);
            captureException(err, { extra: { request: 'delete_user_social_media_account', account } });
        }
        finally {
            setDeleting(false);
            setShowConfirmLastSocialMediaAccount(false);
        }
    };
    const handleConfirmModalClose = () => {
        setAccountToDelete(undefined);
        setShowConfirmLastSocialMediaAccount(false);
    };
    const handleConfirmDelete = async () => {
        if (mergedLinkedAndSocialAccounts.length === 1) {
            setShowConfirmLastSocialMediaAccount(true);
        }
        else {
            await handleDeleteAccount(accountToDelete);
        }
    };
    useEffect(() => {
        if (deleteLinkedService.isError) {
            setShowErrorModal(true);
        }
    }, [deleteLinkedService.isError]);
    return (_jsxs(_Fragment, { children: [_jsx(ConnectLoginAccountModal, { hiddenTypes: mergedLinkedAndSocialAccounts.map((account) => account.type), open: adding, onClose: () => setAdding(false) }), _jsx(ConfirmDeleteSocialMediaAccountModal, { accountToDelete: accountToDelete, deleting: deleting, handleClose: handleConfirmModalClose, handleConfirm: handleConfirmDelete }), _jsx(ConfirmDeleteLastSocialMediaAccountModal, { show: showConfirmLastSocialMediaAccount, accountToDelete: accountToDelete, deleting: deleting, handleClose: handleConfirmModalClose, handleConfirm: () => handleDeleteAccount(accountToDelete) }), getAccountLoginSocialMediaTypes().length > 0 && (_jsxs(SectionContainer, { className: cx(className), children: [_jsxs(SectionHead, { children: [_jsx(SectionHeading, { children: "Login Accounts" }), _jsx(SectionSubheading, { children: "Login accounts I've connected" })] }), _jsx("div", { className: classes.bodyWrapper, children: mergedLinkedAndSocialAccounts.length === 0 ? (_jsx(NoLinkedAccounts, { handleConnectAccounts: () => setAdding(true), isCurrentUser: true })) : (_jsxs(_Fragment, { children: [mergedLinkedAndSocialAccounts.map((socialMediaAccount) => (_jsx(SocialMediaAccountItem, { socialMediaAccount: socialMediaAccount, handleDeleteAccount: (account) => setAccountToDelete(account) }, `${socialMediaAccount.type}${socialMediaAccount.username}`))), _jsx(AddSocialMediaAccountItem, { handleConnectAccounts: () => setAdding(true) })] })) })] })), deleteLinkedService.isError && showErrorModal && (_jsx(ErrorCodeModal, { onClose: () => setShowErrorModal(false), title: isAxiosError(deleteLinkedService.error) && ((_b = deleteLinkedService.error.response) === null || _b === void 0 ? void 0 : _b.data.message)
                    ? deleteLinkedService.error.response.data.message
                    : 'An error occurred', errorCode: (isAxiosError(deleteLinkedService.error) && ((_c = deleteLinkedService.error.response) === null || _c === void 0 ? void 0 : _c.data.errorCode)) || undefined }))] }));
};
export default MyLoginAccounts;
