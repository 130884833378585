import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from '@mui/material/styles';
const StyledPaper = styled('section') `
  box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.05);
  background-color: ${(props) => props.theme.palette.common.white};
  border-radius: 4px;
`;
const SectionContainer = ({ children, className = '', 'data-testid': testID }) => {
    return (_jsx(StyledPaper, { className: className, "data-testid": testID, children: children }));
};
export default SectionContainer;
