import getBreadcrumbIndexById from './getBreadcrumbIndexById';
/**
 * @param {ModalBreadcrumbMeta[]} breadcrumbs The original breadcrumbs array
 * @param {string} id breadcrumb id to find.
 * @returns breadcrumb upto and including the element found
 */
const getBreadcrumbsUpTo = (breadcrumbs, id) => {
    const destinationIndex = getBreadcrumbIndexById(breadcrumbs, id);
    return breadcrumbs.slice(0, destinationIndex >= 0 ? destinationIndex + 1 : 1);
};
export default getBreadcrumbsUpTo;
