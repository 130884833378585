import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from '@mui/system';
import AddLinkedAccountRow from './AddLinkedAccountRow';
import GameAccountItem from './GameAccountItem';
import NoLinkedAccounts from './NoLinkedAccounts';
import SyncIcon from '@components/Icons/Sync';
const LoadingPanel = styled('div') `
  display: flex;
  justify-content: center;

  & > div {
    margin: 4px;
  }
`;
const Container = styled('div') `
  padding: ${({ theme }) => theme.spacing(0, 3, 3)};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
`;
const GameAccountsBody = ({ onConnectAccountsClick, onDeleteAccountClick, userGames, loading, isCurrentUser, userHandle, }) => {
    const handleDeleteAccountClick = (userGame) => {
        onDeleteAccountClick(userGame);
    };
    if (loading) {
        return (_jsx(Container, { children: _jsxs(LoadingPanel, { "data-testid": "loading accounts", children: [_jsx("div", { children: _jsx(SyncIcon, {}) }), _jsx("div", { children: "Loading Accounts" })] }) }));
    }
    if (!loading && userGames.length === 0) {
        return (_jsx(Container, { children: _jsx(NoLinkedAccounts, { handleConnectAccounts: onConnectAccountsClick, isCurrentUser: isCurrentUser, userHandle: userHandle }) }));
    }
    return (_jsxs(Container, { children: [userGames.map((userGame) => (_jsx(GameAccountItem, { isCurrentUser: isCurrentUser !== null && isCurrentUser !== void 0 ? isCurrentUser : false, userGameRegion: userGame, onActionPress: () => handleDeleteAccountClick(userGame) }, userGame.id))), isCurrentUser && _jsx(AddLinkedAccountRow, { onPress: onConnectAccountsClick })] }));
};
export default GameAccountsBody;
