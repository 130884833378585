import { QueryClient } from 'react-query';
import { isAxiosError } from '@helpers/errorHelper';
import triggerLoggedOutRedirect from '@domain/User/Login/helpers/triggerLoggedOutRedirect';
export const onRetryHandler = (failureCount, error) => {
    var _a;
    if (!!error && isAxiosError(error) && ((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) && error.response.status < 500) {
        return false;
    }
    // retry 3 times for other errors
    if (failureCount > 2) {
        return false;
    }
    return true;
};
export const onErrorHandler = (error) => {
    var _a;
    if (!!error && isAxiosError(error) && ((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) && error.response.status === 401) {
        triggerLoggedOutRedirect();
    }
};
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: onRetryHandler,
            onError: onErrorHandler,
        },
    },
});
export default queryClient;
