export const getTournamentServersDisplay = (tournament, gameData, limit, overLimitText) => {
    const { regions } = tournament.score_template;
    // No region data
    if (regions.length === 0) {
        return 'All Servers';
    }
    // Global region specified
    if ((regions.length === 1 && regions[0].regionCode === 'global') ||
        ((gameData === null || gameData === void 0 ? void 0 : gameData.regions.length) === 1 && gameData.regions[0].code === 'global')) {
        return 'Global';
    }
    // All regions that exist were specified
    if ((gameData === null || gameData === void 0 ? void 0 : gameData.regions.length) === regions.length) {
        return 'All Servers';
    }
    const limitedRegions = regions.slice(0, limit);
    // Custom list of regions
    const customList = limitedRegions
        .map((region) => (region.platform ? `${region.platform.abbreviation}-` : '') + region.regionCode.toLocaleUpperCase())
        .join(' • ');
    if (regions.length > limitedRegions.length) {
        if (overLimitText) {
            return overLimitText;
        }
        const difference = regions.length - limitedRegions.length;
        return `${customList}  •  +${difference}`;
    }
    return customList;
};
export const getTournamentServerTooltips = (tournament, gameData, limit) => {
    const { regions } = tournament.score_template;
    if (!limit ||
        regions.length === 0 ||
        (regions.length === 1 && regions[0].regionCode === 'global') ||
        ((gameData === null || gameData === void 0 ? void 0 : gameData.regions.length) === 1 && gameData.regions[0].code === 'global') ||
        (gameData === null || gameData === void 0 ? void 0 : gameData.regions.length) === regions.length) {
        return undefined;
    }
    return regions
        .map((region) => (region.platform ? `${region.platform.abbreviation}-` : '') + region.regionCode.toLocaleUpperCase())
        .join(' • ');
};
export default getTournamentServersDisplay;
