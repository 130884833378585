import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()(() => ({
    srOnly: {
        border: '0',
        clip: 'rect(1px, 1px, 1px, 1px)',
        clipPath: 'inset(50%)',
        height: '1px',
        margin: '-1px',
        overflow: 'hidden',
        padding: '0',
        position: 'absolute',
        width: '1px',
        whiteSpace: 'nowrap',
        '&:focus, &:active': {
            clip: 'auto',
            clipPath: 'none',
            height: 'auto',
            margin: 'auto',
            overflow: 'visible',
            width: 'auto',
            whiteSpace: 'normal',
        },
    },
}));
export const SROnly = ({ children }) => {
    const { classes } = useStyles();
    return _jsx("div", { className: classes.srOnly, children: children });
};
export default SROnly;
