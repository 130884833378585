import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
import { useAddGameContext } from '@components/AddGame/context/AddGameProvider';
import ModalContent from '@components/Modals/components/ModalContent';
import CheckboxField from '@components/Fields/CheckboxField';
import { Button } from '@components/Buttons/Button';
import { setIsOverConsentAgeAction } from '../../../AddGame/context/AddGameStepsActions';
const useStyles = makeStyles()((theme) => ({
    checkBox: {
        fontSize: '12px',
        fontFamily: theme.typography.fontFamily,
        fontWeight: 600,
        lineHeight: '23.31',
        border: `1px solid ${theme.palette.grey[200]}`,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(2),
        textAlign: 'left',
    },
    requirementText: {
        textAlign: 'center',
        color: theme.palette.error.main,
    },
}));
export const GameAgeConsent = ({ className, stepName, onContinue }) => {
    const { dispatch, isOverConsentAge } = useAddGameContext();
    const { classes } = useStyles();
    const handleChange = () => {
        dispatch(setIsOverConsentAgeAction(!isOverConsentAge));
    };
    const handleClick = () => {
        if (onContinue && isOverConsentAge) {
            onContinue();
        }
    };
    return (_jsxs(ModalContent, { className: className, stepName: stepName, children: [_jsxs("section", { children: [_jsx("h3", { children: "This game has an age restriction" }), _jsx(CheckboxField, { label: "I have the consent of my parent/guardian to play this game.", onChange: handleChange, value: isOverConsentAge || false, className: classes.checkBox, name: "agreeEnterTournament", checked: isOverConsentAge !== null && isOverConsentAge !== void 0 ? isOverConsentAge : false }), _jsx("p", { className: classes.requirementText, children: "*This checkbox is required in order to proceed" })] }), _jsx(Button, { type: "button", onClick: handleClick, size: "md", variant: "palette", paletteName: "cod", disable: !isOverConsentAge, children: "Continue" })] }));
};
export default GameAgeConsent;
