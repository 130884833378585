import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme) => ({
    banner: {
        position: 'relative',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        minHeight: '110px',
        // '& svg': {
        //   height: '100%',
        //   minHeight: '110px',
        //   width: 'auto',
        // },
    },
}));
export const ModalIconBanner = ({ className, children }) => {
    const { classes, cx } = useStyles();
    return _jsx("div", { className: cx(classes.banner, className), children: children });
};
export default ModalIconBanner;
