/**
 * Gets game data based on the selected game key.
 *
 * @param selectedGameKey string of any available GameKey.
 * @returns a RepeatGame or undefined if not found.
 */
const getSelectedGameData = (games, selectedGameKey) => {
    var _a;
    return (_a = games.find((game) => game.gameKey === selectedGameKey)) !== null && _a !== void 0 ? _a : undefined;
};
export default getSelectedGameData;
