import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import OtpInput from 'react-otp-input';
import { styled } from '@mui/system';
const shouldForwardProp = (prop) => prop !== 'invalidCode';
const StyledInput = styled(`input`, { shouldForwardProp }) `
  border: ${(props) => (props.invalidCode ? '1px solid red' : '1px solid #DFDFDF')};
  border-radius: 4px;
  flex: 1 1 0%;
  height: 50px;
  margin: 0 5px;
  max-width: 50px;
  outline-color: rgb(0, 172, 246);
  text-align: center;
  width: 100%;
`;
const StyledErrorText = styled('span') `
  text-align: left;
  display: block;
  width: 100%;
  color: red;
`;
const OtpInputField = ({ value, onChange, onPaste, numInputs, otpInputDisabled, invalidCode, errorMsg, }) => {
    return (_jsxs(_Fragment, { children: [_jsx(OtpInput, { containerStyle: {
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    gap: '10px',
                }, value: value, onChange: onChange, onPaste: onPaste, numInputs: numInputs, renderInput: (props) => _jsx(StyledInput, { ...props, disabled: otpInputDisabled, invalidCode: invalidCode }), shouldAutoFocus: true }), !!errorMsg && _jsx(StyledErrorText, { children: errorMsg })] }));
};
export default OtpInputField;
