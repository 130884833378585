import { jsx as _jsx } from "react/jsx-runtime";
import classnames from 'classnames';
import { styled } from '@mui/system';
const styleOptions = (prop) => {
    return !['bannerImage', 'paletteName'].includes(prop);
};
const Banner = styled('div', {
    shouldForwardProp: styleOptions,
}) `
  background-color: ${({ theme, paletteName }) => theme.palette[paletteName].main};
  height: 175px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  &.bannerBackground {
    background-position: center;
    background-size: cover;
    background-image: url(${(props) => props.bannerImage});
  }
`;
const TournamentBoxBanner = ({ bannerImage, paletteName = 'primary', className, children, }) => {
    return (_jsx(Banner, { paletteName: paletteName, bannerImage: bannerImage, className: classnames(className, { bannerBackground: bannerImage }), children: children }));
};
export default TournamentBoxBanner;
