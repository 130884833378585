/**
 * Whether this game is a game that should appear in  the menu
 *
 * @param game - The game to evaluate
 * @returns true if the game should appear in the menu
 */
const isMenuGame = (game) => {
    return ['active', 'beta', 'prelaunch', 'paused'].includes(game.status);
};
export default isMenuGame;
