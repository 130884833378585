import { makeStyles } from 'tss-react/mui';
const useWithdrawModalStyles = makeStyles()((theme) => ({
    toggleTile: {
        fontSize: '20px',
    },
    bodyContent: {
        padding: '30px 44px',
        paddingTop: '20px',
        margin: '10px',
        '& > p': {
            textAlign: 'center',
        },
        maxWidth: '400px',
    },
    starTick: {
        fill: theme.palette.primary.main,
        height: '12px',
    },
    dataTable: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        gridAutoRows: 'min-content',
        flex: 1,
    },
    editDetailsButton: {
        marginBottom: '11px',
        borderColor: theme.palette.grey[100],
        background: theme.palette.grey[100],
        color: theme.palette.grey[400],
        fontSize: '11px',
    },
    col2: {
        textAlign: 'end',
        color: theme.palette.grey[900],
        fontFamily: 'Montserrat',
        fontWeight: 600,
        fontSize: '14px',
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '175px',
        '& svg': {
            [theme.breakpoints.up('sm')]: {
                left: '40px',
            },
        },
    },
    errorText: {
        color: theme.palette.error.main,
        display: 'block',
        opacity: 1,
        fontSize: '11px',
        fontWeight: 600,
        textAlign: 'right',
        margin: 0,
    },
    feeLabel: {
        color: theme.palette.grey[400],
        fontSize: '9px',
        fontWeight: 600,
        textAlign: 'center',
    },
    paragraph: {
        flex: 1,
        fontSize: '11px',
        color: theme.palette.grey[400],
        lineHeight: '17px',
    },
    invalidChoice: {
        opacity: 0.5,
    },
}));
export default useWithdrawModalStyles;
