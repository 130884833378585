import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import { ClickAwayListener, Drawer, Popper, useMediaQuery, useTheme } from '@mui/material';
import classnames from 'classnames';
import CoreButton from "@components/Buttons/CoreButton";
import VerifiedCheckmark from './VerifiedCheckmark';
import useStyles from './useStyles';
import CrossCloseIcon from '../CrossCloseIcon';
import SROnly from '../SROnly';
const getVariantTitle = (variant) => {
    if (variant === 'partner') {
        return 'Partner';
    }
    return 'Verified';
};
const getVariantText = (variant) => {
    if (variant === 'partner') {
        return (_jsxs(_Fragment, { children: ["This user is a verified ", _jsx("span", { "data-ignore": true, children: "Repeat" }), " partner who is a professional gamer, content creator, streamer or person of importance within gaming.", ' ', _jsx("a", { target: "_blank", rel: "noreferrer noopener", href: "/content/repeat-partners/", children: "Learn more" })] }));
    }
    return (_jsxs(_Fragment, { children: ["This user has been verified on ", _jsx("span", { "data-ignore": true, children: "Repeat" }), " and has received a prize or made a withdrawal.", ' ', _jsx("a", { target: "_blank", rel: "noreferrer noopener", href: "/content/repeat-verification/", children: "Learn more" })] }));
};
const UserVerificationBadge = ({ variant, className = '' }) => {
    const { classes, cx } = useStyles();
    const buttonRef = useRef(null);
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const handleButtonClick = () => setOpen(!open);
    const closePopper = () => setOpen(false);
    const paperProps = {
        classes: {
            root: classes.mobilePaperStyles,
        },
    };
    return (_jsx(ClickAwayListener, { mouseEvent: "onClick", onClickAway: closePopper, touchEvent: "onTouchEnd", children: _jsxs("span", { children: [isMobile ? (_jsx(Drawer, { PaperProps: paperProps, variant: "temporary", anchor: "bottom", onClose: closePopper, open: open, className: classes.drawer, children: _jsxs("div", { className: classes.popper, children: [_jsx(CrossCloseIcon, { className: classes.popperCloseButton, action: closePopper }), _jsx(VerifiedCheckmark, { className: classes.popperIcon, variant: variant }), _jsx("h3", { className: classes.popperTitle, children: getVariantTitle(variant) }), _jsx("p", { className: classes.popperText, children: getVariantText(variant) }), _jsx(CoreButton, { onClick: closePopper, className: classes.mobileButton, variant: "grey", block: true, appearance: "subdued-outline", children: "Close" })] }) })) : (_jsx(Popper, { placement: "bottom", anchorEl: buttonRef.current, open: open, children: _jsxs("div", { className: classnames(classes.popper, classes.popperDesktop), children: [_jsx(VerifiedCheckmark, { className: classes.popperIcon, variant: variant }), _jsx("h3", { className: classes.popperTitle, children: getVariantTitle(variant) }), _jsx("p", { className: classes.popperText, children: getVariantText(variant) })] }) })), _jsxs("button", { className: cx(classes.button, className), type: "button", onClick: handleButtonClick, ref: buttonRef, children: [_jsx(SROnly, { children: variant }), _jsx(VerifiedCheckmark, { className: classes.icon, variant: variant })] })] }) }));
};
export default UserVerificationBadge;
