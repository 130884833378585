import { formatDistanceToNowStrict } from "date-fns";
const getTournamentCountdown = (startTime, endTime) => {
    const current = new Date().valueOf();
    if (current < startTime) {
        return {
            title: 'Tournament Starts in',
            content: formatDistanceToNowStrict(startTime),
        };
    }
    if (current > startTime && current < endTime) {
        return {
            title: 'Tournament Ends in',
            content: formatDistanceToNowStrict(endTime),
        };
    }
    return {
        title: 'Tournament Closed',
        content: 'Closed',
    };
};
export default getTournamentCountdown;
