import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from '@mui/material/styles';
const StyledTournamentMetric = styled('div') `
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 12px 0;
`;
const Caption = styled('div') `
  color: ${(props) => props.theme.palette.grey[400]};
  font-size: 12px;
  font-weight: 600;
`;
const TournamentMetric = ({ id, children, caption, className = '', ariaLabel = '', }) => {
    const ariaProps = ariaLabel
        ? {
            'aria-label': ariaLabel,
        }
        : {
            'aria-labelledby': id,
        };
    return (_jsxs(StyledTournamentMetric, { className: className, children: [_jsx("div", { ...ariaProps, children: children }), caption && _jsx(Caption, { id: id, children: caption })] }));
};
export default TournamentMetric;
