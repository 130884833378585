import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
import Currency from '@components/UI/Currency';
import Button from "@components/Buttons/Button";
import ModalContent from "@components/Modals/components/ModalContent";
import ModalStatusBanner from "@components/Modals/components/ModalStatusBanner";
const useStyles = makeStyles()((theme) => ({
    heading: {
        '& span svg': {
            width: '26px',
            height: '26px',
            marginRight: theme.spacing(1),
        },
    },
    currencyText: {
        '& span': {
            display: 'inline',
            whiteSpace: 'nowrap',
            '& svg': {
                position: 'relative',
                top: '4px',
                marginRight: '2px',
            },
        },
    },
}));
const Complete = ({ onClose, onRetry, paymentStatus, currency, transactionAmount, currencyAmount, error, }) => {
    const { classes, cx } = useStyles();
    if (paymentStatus === 'ERROR' || !currencyAmount) {
        return (_jsxs(ModalContent, { align: "center", children: [_jsx(ModalStatusBanner, { status: "error" }), _jsx("h3", { children: "An Error Occurred" }), _jsx("p", { children: error.errorMessage }), error.allowRetry && (_jsx(Button, { onClick: onRetry, variant: "palette", size: "lg", paletteName: "secondary", children: "Retry" })), !error.allowRetry && (_jsx(Button, { onClick: onClose, variant: "palette", size: "lg", paletteName: "error", children: "Close" }))] }));
    }
    if (paymentStatus === 'COMPLETE') {
        return (_jsxs(ModalContent, { align: "center", children: [_jsx(ModalStatusBanner, { status: "success" }), _jsxs("h2", { className: cx(classes.currencyText, classes.heading), children: [_jsx(Currency, { amount: currencyAmount, currency: currency }), " Purchased"] }), _jsxs("section", { children: [currency === 'PM' && (_jsxs("p", { className: classes.currencyText, children: ["Congrats, you've just purchased ", _jsx(Currency, { amount: currencyAmount, currency: currency, hideIcon: true }), ' ', "Coins for ", _jsx(Currency, { amount: transactionAmount, currency: "USD" })] })), currency === 'USD' && (_jsxs("p", { className: classes.currencyText, children: ["Congrats, you've just topped up ", _jsx(Currency, { amount: transactionAmount, currency: "USD" }), ", lets go!"] }))] }), _jsx(Button, { onClick: onClose, variant: "palette", size: "lg", paletteName: "primary", children: "Close" })] }));
    }
    // if no condition met?
    return _jsx(_Fragment, {});
};
export default Complete;
