import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CoreButton from '@components/Buttons/CoreButton';
import { CrossCloseIcon } from '@components/UI';
import { ClockIcon } from '@heroicons/react/24/outline';
import { styled } from '@mui/system';
import GenericModal from '../GenericModal';
import OtpInputField from './OtpInputField';
import { useOtpModal } from './useOtpModal';
const ContentWrapper = styled('div') `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(4)};
  text-align: center;
`;
const StyledImage = styled('img') `
  width: 100%;
  max-width: calc(100% - 180px);
`;
const StyledHeading = styled('h1') `
  color: ${(props) => props.theme.palette.grey[900]};
  font-size: 18px;
  font-weight: 600;
`;
const StyledSubHeading = styled('p') `
  color: ${(props) => props.theme.palette.grey[400]};
  font-size: 12px;
`;
const StyledCountDownText = styled('div') `
  color: ${(props) => props.theme.palette.orange.main};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};

  & svg {
    width: 18px;
    stroke-width: 1.5;
  }

  & span {
    font-size: 12px;
    font-weight: 600;
  }
`;
const ButtonsWrapper = styled('div') `
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid #e1e8e9;
  padding-top: 20px;
  margin-top: 5px;

  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing(1)};
  }

  & > button {
    margin-top: ${({ theme }) => theme.spacing(1)};
    display: flex;
    flex: 1;
  }
`;
const StyledCloseButton = styled(CrossCloseIcon) `
  background: transparent;
  border: none;
  height: 30px;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 30px;
`;
const OtpModal = ({ userEmail = '', open = false, onClose, onSuccess }) => {
    const { otpInputValue, setOtpInputValue, otpCodeExpiration, otpInputDisabled, loading, isOtpValid, otpErrorMessage, onSubmitHandler, resendBtn: { disableResendButton, resendCodeHandler, resendBtnRemainingSeconds, resendBtnError, resendBtnLoading }, resetOtpInputAndError, } = useOtpModal({ onSuccess });
    let errorMsg = isOtpValid === false ? 'The code is invalid. Try resending verification code' : undefined;
    let resendBtnText = disableResendButton
        ? `Code Sent! Resend again in ${resendBtnRemainingSeconds}s`
        : 'Resend Verification Code';
    if (resendBtnError === 'requestRateExceeded' || otpErrorMessage === 'requestRateExceeded') {
        errorMsg = 'You have requested too many codes, please retry later.';
        resendBtnText = 'Retry';
    }
    if (otpErrorMessage === 'validationError') {
        errorMsg = 'You have tried too many times. Wait for 10 minutes and try again.';
    }
    const codeExpirationRemainingTime = otpCodeExpiration.remainingSeconds > 60
        ? `${otpCodeExpiration.remainingSecondsFormatted} minutes`
        : `${otpCodeExpiration.remainingSeconds} seconds`;
    return (_jsxs(GenericModal, { show: open, size: 'medium', disableBackdropClick: false, onClose: onClose, mobileFullScreen: true, onExited: resetOtpInputAndError, children: [_jsx(StyledCloseButton, { action: onClose }), _jsxs(ContentWrapper, { children: [_jsx(StyledImage, { src: '/img/messaging/otp-envelope.png' }), _jsx(StyledHeading, { children: `We've sent a verification code to ${userEmail}` }), _jsx(StyledSubHeading, { children: "To ensure the safety of your data and protect your account, please enter your verification code below." }), _jsxs(StyledCountDownText, { children: [_jsx(ClockIcon, {}), _jsx("span", { children: `Code will expire in ${codeExpirationRemainingTime}` })] }), _jsx(OtpInputField, { value: otpInputValue, onChange: setOtpInputValue, onPaste: onSubmitHandler, numInputs: 6, otpInputDisabled: otpInputDisabled, invalidCode: isOtpValid === false, errorMsg: errorMsg }), _jsxs(ButtonsWrapper, { children: [_jsx(CoreButton, { variant: "primary", size: "lg", onClick: onSubmitHandler, ariaLabel: 'continue', loading: loading, children: "Continue" }), _jsx(CoreButton, { appearance: "outline", disabled: disableResendButton, size: "lg", onClick: resendCodeHandler, ariaLabel: 'Resend Verification Code', loading: resendBtnLoading, children: resendBtnText })] })] })] }));
};
export default OtpModal;
