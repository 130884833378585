import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useUserState } from '@context/UserContext';
import OtpModal from './OtpModal';
import { useFeature } from 'flagged';
import { useValidateOtpModal } from './useOtpModal';
import { useState } from 'react';
import LoadingModal from '../LoadingModal/LoadingModal';
// This component will wrap the OTP functionality:
// When disabled:
// - it will render nothing
// When enabled:
// - when rendered, it will request the back end to send an OTP code
// - show the OTP modal
// - validate the OTP code
// - show the resend button, use resend logic
const ValidateOtpModal = ({ handleClose, children, handleSuccess }) => {
    const isOtpEnabled = !!useFeature('enable_otp');
    const { userDetails } = useUserState();
    const { recentlyValidated, isRecentlyValidatedLoading } = useValidateOtpModal();
    const [otpIsValid, setOtpIsValid] = useState(false);
    const handleValidationSuccess = () => {
        setOtpIsValid(true);
        // Side effects
        if (handleSuccess) {
            handleSuccess();
        }
    };
    // Must be logged in with a known login provider
    if (!userDetails) {
        return _jsx(_Fragment, {});
    }
    // Support for when the feature flag is not enabled
    if (isOtpEnabled === false) {
        return children || _jsx(_Fragment, {});
    }
    if (isRecentlyValidatedLoading) {
        return _jsx(LoadingModal, { show: true, loadingText: "Loading..." });
    }
    if (recentlyValidated || otpIsValid) {
        if (handleSuccess) {
            // Tipalti requires a navigation to a different route, there is nothing to render.
            handleSuccess();
        }
        return children || _jsx(_Fragment, {});
    }
    return _jsx(OtpModal, { open: true, onSuccess: handleValidationSuccess, onClose: handleClose });
};
export default ValidateOtpModal;
