import { useEffect, useState } from 'react';
import getBreadcrumbIndexById from '@helpers/Modal/getBreadcrumbIndexById';
import getBreadcrumbsUpTo from '@helpers/Modal/getBreadcrumbsUpTo';
/**
 * This hook is used for managing common breadcrumb state for AddGameModals' steps
 * It manages common state changes such as require age check prompt (which will update the steps)
 * Or whether or not the steps should include "Select"
 *
 * @param {number} startingIndex The starting breadcrumb index when it resets. Default is 0. In the case of AddGame, this should be set to 1, because of the "Select" step.
 * @param {ModalBreadcrumbMeta[]} gameStepBreadcrumbs Breadcrumbs for the game step to be added to the hook. Game steps are added after "Select", "Game Step", and "Age". If all three are available.
 * @param {boolean} includeSelectBreadcrumb Include the "Select" step. Checked at initialisation.
 * @param {boolean | null} requireAgePrompt Similar to above, but for "age check" step sits in an useEffect to accommodate for state change and can be null.
 * @param {boolean} isVisible Defaults to true. Used to determine whether the returned "breadcrumbIsVisible" state be visible.
 */
const useAddGameBreadcrumbManager = ({ startingIndex = 0, gameStepBreadcrumbs, includeSelectBreadcrumb = true, requireAgePrompt = false, isVisible = true, }) => {
    const [breadcrumbIndex, setBreadcrumbIndex] = useState(0);
    const [breadcrumbIsVisible, setBreadcrumbIsVisible] = useState(isVisible);
    const [availableBreadcrumbs, setAvailableBreadcrumbs] = useState([
        ...(includeSelectBreadcrumb
            ? [
                {
                    id: 'select',
                    component: 'Select',
                },
            ]
            : []),
        {
            id: 'age',
            component: 'Age',
        },
        ...gameStepBreadcrumbs,
    ]);
    // list of breadcrumbs shown. Note that this is progressively added by each steps
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    useEffect(() => {
        // If require age prompt is not required, remove it from available breadcrumbs
        const validBreadcrumbs = !requireAgePrompt
            ? availableBreadcrumbs.filter((breadcrumb) => breadcrumb.id !== 'age')
            : availableBreadcrumbs;
        // Sets the default available breadcrumb to be without requireAgePrompt.
        setAvailableBreadcrumbs(validBreadcrumbs);
        setBreadcrumbs(validBreadcrumbs.slice(0, startingIndex + 1));
        setBreadcrumbIndex(startingIndex); // Using indexes because the step name may change. (Depends on the agePrompt)
    }, [requireAgePrompt]);
    const displayBreadcrumbsUpTo = (breadcrumbId, ignoreIds, activeBreadcrumbId) => {
        const validBreadcrumbs = ignoreIds && ignoreIds.length > 0
            ? availableBreadcrumbs.filter((breadcrumb) => !ignoreIds.includes(breadcrumb.id))
            : availableBreadcrumbs;
        const activeBreadcrumbIndex = getBreadcrumbIndexById(validBreadcrumbs, activeBreadcrumbId || breadcrumbId);
        setBreadcrumbs(getBreadcrumbsUpTo(validBreadcrumbs, breadcrumbId));
        setBreadcrumbIndex(activeBreadcrumbIndex);
    };
    const getIndexOfBreadcrumb = (breadcrumbId, ignoreIds) => {
        const validBreadcrumbs = ignoreIds && ignoreIds.length > 0
            ? availableBreadcrumbs.filter((breadcrumb) => !ignoreIds.includes(breadcrumb.id))
            : availableBreadcrumbs;
        return getBreadcrumbIndexById(validBreadcrumbs, breadcrumbId);
    };
    return {
        breadcrumbs,
        breadcrumbIndex,
        breadcrumbIsVisible,
        setBreadcrumbIsVisible,
        setBreadcrumbs,
        displayBreadcrumbsUpTo,
        getIndexOfBreadcrumb,
    };
};
export default useAddGameBreadcrumbManager;
