import ErrorWithCause from '@helpers/ErrorWithCause';
import axios from 'axios';
const getAppContext = async () => {
    try {
        const { data } = await axios.get('/api/app-context/v1');
        return data;
    }
    catch (error) {
        throw new ErrorWithCause(error);
    }
};
export default getAppContext;
