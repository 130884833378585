import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import GenericModal from "@components/Modals/GenericModal";
import Button from "@components/Buttons/Button";
import ModalTextContent from '../components/ModalTextContent';
import ModalImageHeader from '../components/ModalImageHeader';
const useStyles = makeStyles()((theme) => ({
    widthWrapper: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        [theme.breakpoints.up(450)]: {
            maxWidth: '430px',
        },
    },
    button: {
        margin: '20px',
    },
}));
const CongratulationsModal = ({ headerImageUrl, headerImageAltText, titleContent, paragraphContent, buttonContent = "Awesome, Let's Play!", testId = "congratulations-modal", onClose, }) => {
    const { classes } = useStyles();
    const theme = useTheme();
    const isFullscreen = useMediaQuery(theme.breakpoints.down(450));
    return (_jsx(GenericModal, { "data-testid": testId, show: true, children: _jsxs("div", { className: classes.widthWrapper, children: [_jsx(ModalImageHeader, { onClose: onClose, imageUrl: headerImageUrl, altText: headerImageAltText }), _jsx(ModalTextContent, { title: titleContent, paragraph: paragraphContent, fillVertical: isFullscreen }), _jsx(Button, { onClick: onClose, size: "md", className: classes.button, children: buttonContent })] }) }));
};
export default CongratulationsModal;
