import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { NavLink, useHistory } from 'react-router-dom';
import CoreButton from '@components/Buttons/CoreButton';
import { doTrackEvent } from '@components/Analytics';
import useCartApi from '@hooks/API/useCartApi';
import AddFunds from '@components/Modals/AddFunds/AddFunds';
import { useUserState } from '@context/UserContext';
import { Feature, useFeature } from 'flagged';
import loadable from '@loadable/component';
const CartDuoColour = loadable(() => import('@components/Icons/CartDuoColour'));
const useStyles = makeStyles()((theme) => ({
    MarketplaceMenuContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& ul': {
            flex: 1,
        },
    },
    buttons: {
        display: 'flex',
        alignItems: 'center',
        '& button': {
            color: theme.palette.common.white,
        },
        '& button:hover': {
            color: theme.palette.common.white,
        },
        '& button:focus': {
            color: theme.palette.common.white,
        },
        '& button:first-child': {
            marginRight: theme.spacing(1),
        },
        '& svg': {
            fill: theme.palette.common.white,
            width: '15px',
            marginRight: theme.spacing(1),
        },
    },
    buyCoinsButton: {
        border: `1px solid #ECAA3D`,
    },
    cartButton: {
        color: theme.palette.common.white,
    },
    searchContainer: {
        alignSelf: 'stretch',
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1),
        transition: 'background 0.2s, max-width .3s',
        width: '235px',
        overflow: 'hidden',
    },
    searchInput: {
        border: 'none',
        background: 'none',
        color: 'transparent',
        display: 'inline-flex',
        backgroundColor: '#D49730',
        marginLeft: '187px',
        transition: 'background 0.2s, width .3s, margin .3s',
        width: '235px',
        backgroundRepeat: 'no-repeat',
        backgroundPositionY: 'center',
        backgroundPositionX: theme.spacing(2),
        backgroundImage: 'url(/img/svg/search-white.svg)',
        paddingLeft: '38px',
        alignSelf: 'stretch',
        cursor: 'pointer',
        '&:active, &:focus': {
            cursor: 'text',
            color: 'white',
            marginLeft: '0px',
        },
    },
    searchInputOpen: {
        cursor: 'text',
        color: 'white',
        marginLeft: '0px',
    },
    searchButton: {
        borderColor: theme.palette.common.white,
        marginLeft: '-80px',
    },
}));
const MarketplaceMenu = () => {
    const rewardsEnabled = !!useFeature('enable_rewards');
    const { classes, cx } = useStyles();
    const history = useHistory();
    const { authenticated } = useUserState();
    const { quantity } = useCartApi();
    const [showAddCoinsModal, setShowAddCoinsModal] = useState(false);
    const handleAddCoinsModalShow = () => {
        setShowAddCoinsModal(true);
    };
    const handleAddCoinsModalClose = () => {
        setShowAddCoinsModal(false);
    };
    const [searchVal, setSearchVal] = useState('');
    const handleSearch = () => {
        setSearchVal('');
    };
    const handleSubmitSearch = (e) => {
        e.preventDefault();
        if (searchVal.length) {
            setSearchVal('');
            history.push(`/marketplace/search/?search=${searchVal}`);
        }
    };
    return (_jsxs("div", { className: classes.MarketplaceMenuContainer, children: [_jsxs("ul", { children: [_jsx("li", { children: _jsx(NavLink, { exact: true, to: "/marketplace", activeClassName: "selected", onClick: () => {
                                doTrackEvent({
                                    type: 'interact_navigation',
                                    interaction: 'click',
                                    region: 'header',
                                    location: 'marketplace',
                                    value: 'Marketplace Hub',
                                });
                            }, children: "Overview" }) }), _jsx("li", { children: _jsx(NavLink, { to: "/marketplace/gift-cards", activeClassName: "selected", onClick: () => {
                                doTrackEvent({
                                    type: 'interact_navigation',
                                    interaction: 'click',
                                    region: 'header',
                                    location: 'marketplace',
                                    value: 'gift-cards',
                                });
                            }, children: "Gift Cards" }) }), _jsx(Feature, { name: "enable_cosmetics", children: _jsx("li", { children: _jsx(NavLink, { to: "/marketplace/cosmetics", activeClassName: "selected", onClick: () => {
                                    doTrackEvent({
                                        type: 'interact_navigation',
                                        interaction: 'click',
                                        region: 'header',
                                        location: 'marketplace',
                                        value: 'cosmetics',
                                    });
                                }, children: "Cosmetics" }) }) }), rewardsEnabled && authenticated && (_jsx("li", { children: _jsx(NavLink, { to: "/marketplace/claim-prizes", activeClassName: "selected", onClick: () => {
                                doTrackEvent({
                                    type: 'interact_navigation',
                                    interaction: 'click',
                                    region: 'header',
                                    location: 'marketplace',
                                    value: 'claim-prizes',
                                });
                            }, children: "Claim Prizes" }) }))] }), _jsxs("form", { className: classes.searchContainer, onSubmit: handleSubmitSearch, children: [_jsx("input", { id: "searchbox", name: "searchbox", value: searchVal, type: "search", className: cx(classes.searchInput, searchVal.length > 0 && classes.searchInputOpen), onChange: (event) => setSearchVal(event.target.value) }), _jsx(CoreButton, { size: "sm", appearance: "outline", variant: "white", className: classes.searchButton, to: `/marketplace/search?search=${searchVal}`, onClick: handleSearch, disabled: !searchVal.length, children: "Search" })] }), authenticated && (_jsxs("div", { className: classes.buttons, children: [_jsx(CoreButton, { size: "sm", appearance: "outline", variant: "orange", className: classes.buyCoinsButton, onClick: handleAddCoinsModalShow, children: "+ Buy Coins" }), _jsxs(CoreButton, { size: "sm", variant: "orange", className: classes.cartButton, to: "/marketplace/cart", children: [_jsx(CartDuoColour, {}), " My Cart ", _jsxs("span", { "data-ignore": true, children: ["(", quantity, ")"] })] })] })), showAddCoinsModal && _jsx(AddFunds, { onClose: handleAddCoinsModalClose, selectedCurrency: "PM" })] }));
};
export default MarketplaceMenu;
