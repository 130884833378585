const getAddGameCallbackParams = () => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has('added_game')) {
        const gameKey = queryParams.get('added_game');
        if (!gameKey) {
            return null;
        }
        const gameConnectStatus = !queryParams.has('add_game_error') ? 'success' : 'error';
        const addGameRegionCode = queryParams.get('add_game_region_code');
        const addGameServiceKey = queryParams.get('add_game_service_key');
        const successButtonLabel = queryParams.get('auto-join') === 'true' ? 'Continue' : 'Close';
        return {
            gameKey: gameKey,
            errorMessage: queryParams.get('add_game_error'),
            status: gameConnectStatus,
            isSuccess: gameConnectStatus === 'success',
            region: addGameRegionCode
                ? {
                    regionCode: addGameRegionCode,
                    region_code: addGameRegionCode,
                }
                : null,
            gameService: addGameServiceKey
                ? {
                    gameServiceKey: addGameServiceKey,
                }
                : null,
            successButtonLabel,
        };
    }
    return null;
};
export default getAddGameCallbackParams;
