import { useEffect } from 'react';
/**
 * @param state Add Game context state
 * @param onDefaultPlatformChanged Effect invoked when the 'default' platform has changed - i.e. the available platforms have changed and there is only one
 */
const useDefaultPlatformChanged = (state, onDefaultPlatformChanged) => {
    var _a;
    // Set the default platform if there is only one
    useEffect(() => {
        var _a, _b;
        if ((state === null || state === void 0 ? void 0 : state.connectPlatform) || ((_b = (_a = state === null || state === void 0 ? void 0 : state.connectGameService) === null || _a === void 0 ? void 0 : _a.platforms) === null || _b === void 0 ? void 0 : _b.length) !== 1) {
            return;
        }
        if (!(state === null || state === void 0 ? void 0 : state.connectPlatform)) {
            onDefaultPlatformChanged(state.connectGameService.platforms[0]);
        }
    }, [JSON.stringify((_a = state === null || state === void 0 ? void 0 : state.connectGameService) === null || _a === void 0 ? void 0 : _a.platforms)]);
};
export default useDefaultPlatformChanged;
