import { jsx as _jsx } from "react/jsx-runtime";
import { StepConnector, Stepper, Step, StepLabel } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { makeStyles } from 'tss-react/mui';
import StepIcon from './components';
const useStyles = makeStyles()((theme) => ({
    stepIcon: {
        width: '8px',
        height: '8px',
        margin: theme.spacing(0.5),
        background: theme.palette.success.main,
        borderRadius: '50%',
    },
    stepLabel: {
        fontSize: '12px',
        fontWeight: '600 !important',
        color: `${theme.palette.grey[900]} !important`,
    },
    stepLabelCompleted: {
        color: `${theme.palette.grey[400]} !important`,
    },
}));
const StyledConnector = withStyles(StepConnector, (theme) => ({
    root: {
        padding: 0,
        marginLeft: theme.spacing(1),
    },
    line: {
        borderColor: theme.palette.grey[200],
        borderWidth: 2,
    },
}));
const Steps = ({ steps, className }) => {
    const { classes, cx } = useStyles();
    return (_jsx(Stepper, { orientation: "vertical", connector: _jsx(StyledConnector, {}), classes: {
            root: cx(className),
        }, children: steps.map(({ label, state }) => (_jsx(Step, { completed: state === 'finished', active: state === 'active', children: _jsx(StepLabel, { StepIconComponent: StepIcon, classes: {
                    label: classes.stepLabel,
                    completed: classes.stepLabelCompleted,
                }, children: label }) }, String(label)))) }));
};
export default Steps;
