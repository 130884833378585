/**
 * Gets game data based on the selected game key.
 *
 * @param selectedGameKey string of any available GameKey.
 * @returns a RepeatGame or undefined if not found.
 */
const getVisiblePageNumbers = (pages, currentPage) => {
    if (Number.isNaN(pages) || !Number.isFinite(pages)) {
        return [];
    }
    // Small number of pages
    if (pages <= 3) {
        return [...Array(pages).keys()];
    }
    // First page
    if (currentPage === 0) {
        return [0, 1, 2];
    }
    // Last page
    if (currentPage === pages - 1) {
        return [pages - 3, pages - 2, pages - 1];
    }
    // Anywhere else
    return [currentPage - 1, currentPage, currentPage + 1];
};
export default getVisiblePageNumbers;
