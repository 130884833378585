import { css, styled } from '@mui/material/styles';
const IconBackground = styled('div', {
    shouldForwardProp: (prop) => prop !== 'platformSlug' && prop !== 'isDisabled' && prop !== 'size',
}) `
  width: ${({ size }) => (size === 'sm' ? '28px' : '36px')};
  height: ${({ size }) => (size === 'sm' ? '28px' : '36px')};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  border-radius: 4px;
  border: none;
  background-color: ${({ theme, platformSlug }) => theme.palette[platformSlug !== null && platformSlug !== void 0 ? platformSlug : 'primary'].main};
  &:disabled {
    background-color: grey;
    cursor: initial;
  }
  ${(props) => props.isDisabled &&
    css `
      opacity: 0.2;
    `};
  svg {
    fill: ${({ theme }) => theme.palette.common.white};
    color: ${({ theme }) => theme.palette.common.white};
    width: ${({ size }) => (size === 'sm' ? '20px' : '24px')};
    height: ${({ size }) => (size === 'sm' ? '20px' : '24px')};
  }
`;
export default IconBackground;
