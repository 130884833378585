import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import useResizeObserver from 'use-resize-observer';
const useStyles = makeStyles()((theme) => ({
    wrapper: {
        padding: theme.spacing(0, 2),
        '& ins': {
            backgroundColor: 'transparent',
        },
        '& > ins': {
            display: 'flex',
        },
    },
    bigWrapper: {
        padding: theme.spacing(2),
    },
}));
const AdsenseAd = ({ adData, className = '' }) => {
    var _a, _b;
    const { classes, cx } = useStyles();
    useEffect(() => {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, []);
    const { ref, height = 0 } = useResizeObserver();
    return (_jsx("div", { "data-ignore": true, ref: ref, className: cx(classes.wrapper, height > 0 && className, height > 0 && classes.bigWrapper), "data-testid": "adsense-wrapper", children: _jsx("ins", { className: "adsbygoogle", style: { display: 'flex', justifyContent: 'center' }, "data-ad-client": adData.adClient, "data-ad-slot": adData.adSlot, "data-ad-format": (_a = adData.adFormat) !== null && _a !== void 0 ? _a : 'auto', "data-full-width-responsive": JSON.stringify((_b = adData.responsiveWidth) !== null && _b !== void 0 ? _b : true) }) }));
};
export default AdsenseAd;
