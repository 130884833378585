export const AddGameStepsReducer = (state, action) => {
    switch (action.type) {
        case 'SET_CURRENT_STEP':
            return {
                ...state,
                currentStep: action.currentStep,
            };
        case 'SET_SELECTED_GAME_STEPS':
            return {
                ...state,
                steps: action.steps,
            };
        case 'SET_IS_LOADING':
            return {
                ...state,
                isLoading: action.isLoading,
            };
        case 'SET_FETCHED_RESPONSE':
            const { type, ...restOfAction } = action;
            return {
                ...state,
                fetched: {
                    ...restOfAction,
                },
            };
        case 'SET_SELECTED_GAME':
            return {
                ...state,
                selectedGame: action.selectedGame,
            };
        case 'SET_IS_OVER_CONSENT_AGE':
            return {
                ...state,
                isOverConsentAge: action.isOverConsentAge,
            };
        case 'SET_INITIAL_STATE':
            return {
                ...action.initialState,
            };
        case 'SET_GAME_CONNECT_STATUS':
            return {
                ...state,
                gameConnectionStatus: action.gameConnectionStatus,
            };
        case 'SET_REQUIRE_AGE_PROMPT':
            return {
                ...state,
                requireAgePrompt: action.requireAgePrompt,
            };
        case 'SET_CONNECT_PLATFORM':
            return {
                ...state,
                connectPlatform: action.platform,
            };
        case 'SET_CONNECT_GAME_SERVICE':
            return {
                ...state,
                connectGameService: action.gameService,
            };
        case 'SET_CONNECT_REGION':
            return {
                ...state,
                connectGameRegion: action.region,
            };
        case 'SET_AVAILABLE_GAME_SERVICES':
            return {
                ...state,
                availableGameServices: action.availableGameServices,
            };
        case 'RESET_CONNECT_SELECTION': {
            return {
                ...state,
                connectGameService: null,
                connectPlatform: null,
                connectGameRegion: null,
            };
        }
        case 'SET_IS_PROVIDER_READY':
            return {
                ...state,
                isProviderReady: action.isProviderReady,
            };
        default:
            return state;
    }
};
export default AddGameStepsReducer;
