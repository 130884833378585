import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme) => ({
    wrapper: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: 0,
    },
    iframeLoaded: {
        height: 'auto',
    },
    iframe: {
        margin: theme.spacing(2, 0),
    },
}));
const AdsTerraAd = ({ adData, className = '' }) => {
    const { classes, cx } = useStyles();
    const { adKey, width, height } = adData;
    const [loaded, setLoaded] = useState(false);
    const onLoaded = () => {
        setLoaded(true);
    };
    return (_jsx("div", { "data-ignore": true, "data-testid": "adsterra-wrapper", className: cx(classes.wrapper, loaded && className, loaded && classes.iframeLoaded), children: _jsx("iframe", { className: cx(classes.iframe), role: "presentation", src: `//www.creativedisplayformat.com/watchnew?key=${adKey}`, width: width, height: height, frameBorder: "0", scrolling: "no", title: adKey, onLoad: onLoaded }) }));
};
export default AdsTerraAd;
