import { isAfter, addMinutes, subMinutes, formatDistanceToNowStrict } from 'date-fns';
import axios from 'axios';
import { captureException } from '@sentry/react';
import ErrorWithCause from '../../../helpers/ErrorWithCause';
// Store a new cookie in the browser
const setCookie = (name, value, days) => {
    let expires = '';
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = `; expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${value || ''}${expires}; path=/`;
};
// Get a cookie from the browser
export const getCookie = (name) => {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i += 1) {
        let c = ca[i];
        while ((c === null || c === void 0 ? void 0 : c.charAt(0)) === ' ')
            c = c.substring(1, c.length);
        if ((c === null || c === void 0 ? void 0 : c.indexOf(nameEQ)) === 0)
            return c.substring(nameEQ.length, c.length);
    }
    return null;
};
// Delete a cookie from the browser
const eraseCookie = (name) => {
    document.cookie = `${name}=; Path=/; Max-Age=-99999999;`;
};
export const tokeniseDate = (dateString) => {
    const date = new Date(dateString);
    return [date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()];
};
export const isTournamentStarted = (startTime) => {
    const start = new Date(startTime);
    const now = new Date();
    return isAfter(now, start);
};
export const getTournamentStartTime = (startTime) => {
    return new Date(startTime);
};
export const getTournamentFinishTime = (startTime, lengthMinutes) => {
    const start = new Date(startTime);
    return addMinutes(start, lengthMinutes);
};
export const isTournamentFinished = (startTime, lengthMinutes) => {
    const finishTime = getTournamentFinishTime(startTime, lengthMinutes);
    const now = new Date();
    return isAfter(now, finishTime);
};
export const getTournamentCloseTime = (startTime, length, registrationEndingMinutes) => {
    return subMinutes(getTournamentFinishTime(startTime, length), registrationEndingMinutes);
};
export const isTournamentClosed = (startTime, length, registrationEndingMinutes) => {
    const closeTime = getTournamentCloseTime(startTime, length, registrationEndingMinutes);
    const now = new Date();
    return isAfter(now, closeTime);
};
export const isFull = (tournament) => {
    if (tournament.slots === null) {
        return false;
    }
    return tournament.entries_count >= tournament.slots;
};
export const getTournamentState = (tournament, registrationEndingMinutes) => {
    const hasFinished = isTournamentFinished(tournament.start_time, tournament.length_minutes);
    const hasClosed = isTournamentClosed(tournament.start_time, tournament.length_minutes, registrationEndingMinutes);
    const hasStarted = isTournamentStarted(tournament.start_time);
    if (hasFinished) {
        return 'finished';
    }
    if (hasClosed) {
        return 'closed';
    }
    if (isFull(tournament)) {
        return 'full';
    }
    if (hasStarted) {
        return 'started';
    }
    return 'open';
};
export const isTournamentExitable = (startTime, timeLeftToExitSeconds = 0) => {
    if (!isTournamentStarted(startTime)) {
        return true;
    }
    return timeLeftToExitSeconds > 0;
};
export const getTournamentRegistrationStatus = (tournament, registrationEndingMinutes) => {
    const now = new Date();
    if (isAfter(now.getTime(), getTournamentCloseTime(tournament.start_time, tournament.length_minutes, registrationEndingMinutes).getTime())) {
        return 'closed';
    }
    if (isFull(tournament)) {
        return 'full';
    }
    return 'open';
};
export const getTournamentEndsInDisplay = (tournament) => {
    const endTime = getTournamentFinishTime(tournament.start_time, tournament.length_minutes);
    if (isAfter(new Date(), endTime)) {
        return 'Finished';
    }
    return formatDistanceToNowStrict(endTime);
};
export const getStandardEntryTypes = (entryTypes) => {
    return entryTypes.filter((entry) => entry.type !== 'cash_password');
};
export const getTournamentColorPaletteName = (tournament) => {
    // Featured
    if (tournament.featured) {
        return 'secondary';
    }
    // Password
    if (tournament.entry_types[0]) {
        if (tournament.entry_types[0].type === 'password') {
            return 'primary';
        }
    }
    // Game colour
    switch (tournament.score_template.game.game_key) {
        case 'cod-warzone':
            return 'cod';
        case 'cod-warzone-2':
            return 'cod-2';
        case 'fortnite':
            return 'fortnite';
        case 'pc-dota_2':
            return 'dota2';
        case 'league_of_legends':
            return 'lol';
        case 'valorant':
            return 'valorant';
        default:
            return 'primary';
    }
};
// Send a POST request to an internal Symfony endpoint to ask for our scores to be updated.
export const updateUserResults = async (gameId, callback) => {
    try {
        const response = await axios.post('/update_user_results', { gameId });
        callback(response);
    }
    catch (error) {
        callback(false);
        const e = new ErrorWithCause(error);
        captureException(e);
        throw e;
    }
};
// Send a GET request to an internal Symfony endpoint to ask if we have had our results updated.
export const checkUserResultsUpdated = async (callback) => {
    try {
        const response = await axios.get('/check_user_results_updated');
        callback(response.data);
    }
    catch (error) {
        callback(false);
        const e = new ErrorWithCause(error);
        captureException(e);
        throw e;
    }
};
// Store the intial lock time of a request to update the scores in a cookie
export const storeIntialLockTime = () => {
    const timestampNow = Date.now();
    setCookie('intialLockTime', timestampNow, 1);
};
// Calculate the time difference between now and when the button was intially locked
export const getTimePassed = (defaultTime) => {
    var _a;
    if (getCookie('intialLockTime') !== null) {
        const timestampNow = Date.now();
        const timeDifference = Math.round((timestampNow - parseInt((_a = getCookie('intialLockTime')) !== null && _a !== void 0 ? _a : '', 10)) / 1000);
        if (timeDifference > 0) {
            return timeDifference;
        }
    }
    return defaultTime;
};
// Reset the button lock timer by deleting the cookie related to it.
export const resetTimer = () => {
    eraseCookie('intialLockTime');
};
