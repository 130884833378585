import { useMemo } from 'react';
import useGamesList from './API/useGamesList';
const platformSortList = {
    playstation: 0,
    pc: 1,
    mobile: 2,
    xbox: 3,
    switch: 4,
};
const usePlatforms = () => {
    const { games } = useGamesList();
    const platforms = useMemo(() => {
        const gamePlatforms = games.reduce((result, currentGame) => {
            currentGame.platforms.forEach((platform) => result.set(platform.id, platform));
            return result;
        }, new Map());
        const platformArray = Array.from(gamePlatforms.values());
        return platformArray.sort((a, b) => {
            return platformSortList[a.slug] - platformSortList[b.slug];
        });
    }, [games]);
    return platforms;
};
export default usePlatforms;
