import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import loadable from '@loadable/component';
import ConfirmModal from '@components/Modals/ConfirmModal';
import getSocialMediaServiceNameFromType from '@helpers/SocialMedia/getSocialMediaServiceNameFromType';
import isMultiColorIcon from '@helpers/isMultiColorIcon';
import { styled } from '@mui/material/styles';
import NoWrap from '@components/UI/NoWrap';
const Icon = loadable(() => import('@components/Icons/Icon'));
const shouldForwardProp = (props) => props !== 'iconBackground';
const DeleteIcon = styled('div', { shouldForwardProp }) `
  width: 86px;
  height: 86px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.iconBackground ? props.theme.palette[props.iconBackground].main : 'transparent'};
  & svg {
    height: 40px;
    fill: ${(props) => props.theme.palette.common.white};
  }
`;
const ConfirmDeleteSocialMediaAccountModal = ({ show, accountToDelete, deleting, handleConfirm, handleClose, }) => {
    var _a;
    const iconBackground = (accountToDelete === null || accountToDelete === void 0 ? void 0 : accountToDelete.type) && !isMultiColorIcon(accountToDelete === null || accountToDelete === void 0 ? void 0 : accountToDelete.type) ? accountToDelete.type : undefined;
    const icon = (_jsx(DeleteIcon, { iconBackground: iconBackground, children: _jsx(Icon, { icon: (_a = accountToDelete === null || accountToDelete === void 0 ? void 0 : accountToDelete.type) !== null && _a !== void 0 ? _a : '' }) }));
    const heading = accountToDelete ? (_jsxs(_Fragment, { children: ["Delete ", _jsx(NoWrap, { "data-ignore": true, children: getSocialMediaServiceNameFromType(accountToDelete === null || accountToDelete === void 0 ? void 0 : accountToDelete.type) }), "?"] })) : (_jsx(_Fragment, {}));
    const description = accountToDelete && (_jsxs(_Fragment, { children: ["Warning, if you delete this login account you will be unable to login.", _jsx("br", {}), _jsx("br", {}), "If you change your mind in the future, you can perform a password reset to login again."] }));
    return (_jsx(ConfirmModal, { show: show, icon: icon, heading: heading, description: description, loading: deleting, confirmText: "Delete", confirmColor: accountToDelete === null || accountToDelete === void 0 ? void 0 : accountToDelete.type, handleConfirm: handleConfirm, handleClose: handleClose }));
};
export default ConfirmDeleteSocialMediaAccountModal;
