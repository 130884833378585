import { useEffect } from 'react';
/**
 * Used by AddGameProvider - This custom hook invokes the add game success/error events when
 * the fetched state of add game context changes. It provides and additional callback function,
 * which is invoked if state.fetch is not undefined.
 * @param config
 * @param config.state - State of the AddGame context
 * @param config.onAddGameSuccess - Invoked when add game gets a successful response.
 * @param config.onAddGameError - Invoked when add game gets an error response.
 * @param config.callback - Invoked when fetch is not undefine, regardless of whether the response was successful or not.
 */
const useHandleAddGameEvents = ({ state, onAddGameSuccess, onAddGameError, callback, }) => {
    /**
     * Dependencies: state.fetched
     * Used to handle fetched results.
     * - Setting isLoading to false
     * - Invoke onAddGameSuccess and onAddGameError
     */
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (state.fetched) {
            if (!state.fetched.isError && onAddGameSuccess) {
                onAddGameSuccess(state.fetched, {
                    region: (_b = (_a = state.connectGameRegion) !== null && _a !== void 0 ? _a : state.connectGameRegionInfo) !== null && _b !== void 0 ? _b : null,
                    gameService: (_d = (_c = state.connectGameService) !== null && _c !== void 0 ? _c : state.connectGameServiceInfo) !== null && _d !== void 0 ? _d : null,
                });
            }
            else if (state.fetched.isError && onAddGameError) {
                onAddGameError(state.fetched, {
                    region: (_f = (_e = state.connectGameRegion) !== null && _e !== void 0 ? _e : state.connectGameRegionInfo) !== null && _f !== void 0 ? _f : null,
                    gameService: (_h = (_g = state.connectGameService) !== null && _g !== void 0 ? _g : state.connectGameServiceInfo) !== null && _h !== void 0 ? _h : null,
                });
            }
            if (callback) {
                callback();
            }
        }
    }, [state.fetched]);
};
export default useHandleAddGameEvents;
