import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';
import { styled } from '@mui/system';
import trackHotjarEvent from '@components/Analytics/trackHotjarEvent';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
const StyledSearchIcon = styled(MagnifyingGlassIcon) `
  pointer-events: none;
  font-size: 19px;
  color: ${({ theme }) => theme.palette.grey[400]};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 14px;
  }

  height: 16px;
  width: 16px;
`;
const SearchInput = styled('input') `
  height: 100%;
  padding-left: 60px;
  border: 0;
  background: transparent;
  font-weight: 600;
  width: 100%;

  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder,
  &:-moz-placeholder {
    height: 32px;
    color: ${({ theme }) => theme.palette.grey[200]};
    font-weight: 600;
    padding-top: ${({ theme }) => theme.spacing(1)};
  }

  &::-webkit-search-cancel-button {
    height: 19px;
    width: 19px;
    filter: grayscale(100%);
  }
`;
/**
 * Handles the UI and get_tournaments compatible output data for the search filter
 *
 * @param searchFilterProps - Props for the search filter.
 * @param searchFilterProps.initialValue - Initial value for the search filter.
 * @param searchFilterProps.onSearch - Function invoked when search term changes after 750ms
 * @param searchFilterProps.onClear - Function invoked when search term is reset
 * @param searchFilterProps.className - Optional CSS class to add to component.
 * @param searchFilterProps.placeholder - A single string to go into the input's placeholder or an object with "desktop" and "mobile" props for different variants
 * @param searchFilterProps.ariaLabel - Aria label for the component
 * @param searchFilterProps.delayMs - MS for the debounce.
 * @returns Component
 */
const SearchFilter = ({ initialValue, onSearch, className, ariaLabel, placeholder = {
    desktop: 'Search Tournaments',
    mobile: 'Search...',
}, delayMs = 750, noTranslate = false, }) => {
    const [searchTerm, setSearchTerm] = useState(initialValue || '');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const debouncedCallback = useDebouncedCallback((searchValue) => {
        if (onSearch) {
            trackHotjarEvent('Search tournament text');
            onSearch(searchValue);
        }
    }, delayMs);
    // If the initial value resets, reset internal state
    useEffect(() => {
        if (!initialValue) {
            setSearchTerm('');
        }
        if (initialValue) {
            onSearch(initialValue || '');
        }
    }, [initialValue]);
    const handleChange = (e) => {
        setSearchTerm(e.currentTarget.value);
        debouncedCallback(e.currentTarget.value);
    };
    const mobilePlaceholderText = typeof placeholder === 'string' ? placeholder : placeholder.mobile;
    const desktopPlaceholderText = typeof placeholder === 'string' ? placeholder : placeholder.desktop;
    return (_jsxs("div", { className: className, "data-notranslate": noTranslate, children: [_jsx(StyledSearchIcon, { className: "styled-search-icon" }), _jsx(SearchInput, { id: "search-filter", placeholder: isMobile ? mobilePlaceholderText : desktopPlaceholderText, "aria-label": ariaLabel, value: searchTerm, name: "search", onChange: handleChange, type: "search", "data-testid": "search filter" })] }));
};
export default SearchFilter;
