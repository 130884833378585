import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme) => ({
    drawer: {
        zIndex: '999999 !important',
    },
    icon: {
        width: 16,
    },
    popperIcon: {
        width: 24,
    },
    button: {
        background: 'transparent',
        border: 'none',
        cursor: 'pointer',
        margin: 0,
        padding: 0,
    },
    tooltip: {
        fontSize: 12,
    },
    popper: {
        fontFamily: theme.typography.fontFamily,
        padding: theme.spacing(2),
        position: 'relative',
        textAlign: 'center',
    },
    popperDesktop: {
        background: theme.palette.common.white,
        border: `1px solid ${theme.palette.grey[100]}`,
        borderRadius: 6,
        boxShadow: '0px 4px 8px 1px rgba(0,0,0,.05)',
        maxWidth: 240,
    },
    popperTitle: {
        color: theme.palette.grey[900],
        fontSize: 14,
        fontWeight: 'bold',
    },
    popperText: {
        color: theme.palette.grey[400],
        fontSize: 12,
        fontWeight: 600,
        margin: 0,
    },
    popperCloseButton: {
        position: 'absolute',
        right: 0,
        top: 0,
    },
    mobilePaperStyles: {
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
    },
    mobileButton: {
        marginTop: theme.spacing(3),
    },
}));
export default useStyles;
