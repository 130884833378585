import { differenceInHours } from 'date-fns';
const showWarzoneUSOperaGXTournament = (userContext, gameSlug) => {
    var _a;
    const isWarzone = gameSlug === 'cod-warzone';
    const hasWarzone = userContext.userHasGame('cod-warzone');
    const userIsFromUS = ((_a = userContext === null || userContext === void 0 ? void 0 : userContext.userDetails) === null || _a === void 0 ? void 0 : _a.country) === 'US';
    const operaGXTournamentUSModalLastShownDate = localStorage.getItem('operaGXTournamentUSWarzoneModalLastShown');
    const lastShown = operaGXTournamentUSModalLastShownDate
        ? differenceInHours(new Date(), new Date(JSON.parse(operaGXTournamentUSModalLastShownDate)))
        : false;
    if (isWarzone && hasWarzone && userIsFromUS && (lastShown >= 48 || lastShown === false)) {
        return true;
    }
    return false;
};
export default showWarzoneUSOperaGXTournament;
