import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { List, Divider } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useUserState } from '@context/UserContext';
import NavItem from './NavItem';
import trackHotjarEvent from '@components/Analytics/trackHotjarEvent';
const useStyles = makeStyles()((theme) => ({
    container: {
        fontFamily: theme.typography.fontFamily,
        padding: theme.spacing(1),
        border: `1px solid ${theme.palette.grey[100]}`,
    },
    link: {
        width: '180px',
        color: theme.palette.grey[600],
        padding: theme.spacing(1, 1.5),
        borderRadius: '4px',
        '& span': {
            fontSize: '12px',
            fontWeight: 600,
        },
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.main,
        },
    },
    divider: {
        margin: theme.spacing(1, 0),
    },
    myTournaments: {
        display: 'none',
        '@media (max-width: 1400px)': {
            display: 'flex',
        },
    },
    rewards: {
        display: 'none',
        '@media (max-width: 1180px)': {
            display: 'flex',
        },
    },
}));
/**
 * A drop down menu that is displayed when the "..." button is clicked Generally used as utility to allow users to quickly navigate their support options
 */
export const OtherMenu = () => {
    const { classes, cx } = useStyles();
    const { authenticated, userProfile } = useUserState();
    const handleSupportClick = () => {
        var _a;
        if (window.zE) {
            const widget = ((_a = window.zE) === null || _a === void 0 ? void 0 : _a.widget) === 'messenger' ? 'messenger' : 'webWidget';
            window.zE(widget, 'show');
            window.zE(widget, 'open');
            trackHotjarEvent(`Click support`);
        }
    };
    return (_jsxs(List, { component: "nav", classes: { root: classes.container }, children: [authenticated && userProfile && (_jsx(NavItem, { className: cx(classes.link, classes.myTournaments), to: `/user/profile/${encodeURI(userProfile.handle)}/tournaments`, linkText: "My Tournaments" })), authenticated && (_jsx(NavItem, { className: cx(classes.link, classes.rewards), href: "/coins", linkText: "Rewards" })), _jsx(NavItem, { href: "/content", linkText: "News", className: classes.link }), _jsx(NavItem, { to: "/brands", linkText: "Brands", className: classes.link }), _jsx(Divider, { className: classes.divider }), _jsx(NavItem, { to: "/about/terms-and-conditions", linkText: "Terms of Service", className: classes.link }), _jsx(NavItem, { to: "/about/privacy-policy", linkText: "Privacy Policy", className: classes.link }), _jsx(NavItem, { button: true, onClick: handleSupportClick, linkText: "Help", className: classes.link }), _jsx(NavItem, { href: "https://support.repeat.gg/hc/en-us", target: "_blank", linkText: "Knowledge Base", className: classes.link })] }));
};
export default OtherMenu;
