import DataLayer from '@sie/global-data-layer';
import { useFeature } from 'flagged';
import { useCallback } from 'react';
/**
 * Dynamic Values as per the Repeat.gg Telemetry Spec v1.0
 * [ASSET NAME] - name of the asset being used on the banner
 * [LABEL] - the English description of the banner
 * [LINK URL] - the link to which the element clicks out to if one exists
 * [MODULE NAME] - the name of the component
 * [PERS NAME]  - can be left empty
 * [POSITION] - position number that the element shows up in on the page (ex.: 1, 2, 3)
 * [PARTNER NAME] - The name of the partner

 */
const events = {
    tournament_banner_impression: {
        eventType: 'impression',
        elementType: 'image',
    },
    tournament_banner_click: {
        eventType: 'click',
        elementType: 'image',
    },
    initiate_account_registration: {
        eventType: 'click',
        elementType: 'button',
    },
    account_registration: {
        eventType: 'click',
        elementType: 'button',
    },
    user_engagement: {
        eventType: 'click',
        elementType: 'link',
    },
    CTA_selection: {
        eventType: 'click',
        elementType: 'button',
    },
    game_linking: {
        eventType: 'click',
        elementType: 'button',
    },
};
const useTrackEvent = () => {
    const isAdobeAnalyticsEnabled = !!useFeature('enable_adobe_analytics');
    const trackEvent = useCallback((event) => {
        var _a, _b;
        if (!isAdobeAnalyticsEnabled) {
            return;
        }
        const { eventType, ...staticEventData } = events[event.type];
        const { eventName, component, ...eventData } = event.data;
        const combinedEventData = {
            ...staticEventData,
            ...eventData,
        };
        if (component === null || component === void 0 ? void 0 : component.componentName) {
            DataLayer.component.generic(component.componentName, component.componentCategory);
        }
        DataLayer.event[eventType](eventName, combinedEventData);
        if (eventType === 'click' && event.data.partnerName) {
            // eslint-disable-next-line no-underscore-dangle
            (_a = window._satellite) === null || _a === void 0 ? void 0 : _a.setVar('partner-clicks', { partnerName: event.data.partnerName });
        }
        // eslint-disable-next-line no-underscore-dangle
        (_b = window._satellite) === null || _b === void 0 ? void 0 : _b.track(event.type);
    }, [isAdobeAnalyticsEnabled]);
    return trackEvent;
};
export default useTrackEvent;
