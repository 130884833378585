import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import SelectField from '@components/Fields/SelectField';
import Button from "@components/Buttons/Button";
import ChoiceSelectButton from './ChoiceSelectButton';
import ChoiceGridButton from './ChoiceGridButton';
const useStyles = makeStyles()((theme) => ({
    root: {
        alignSelf: 'stretch',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2, '1px'),
    },
    contentGrid: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        width: '100%',
        gridRowGap: theme.spacing(2),
        gridColumnGap: theme.spacing(2),
        padding: '0px',
    },
    contentButtons: {},
    confirmButton: {
        width: '100%',
    },
    footer: {},
}));
/**
 * Choice - Render a selection component, that will display differently based on number of options:
 *  1 - 4: As buttons
 *  4 - 6: As buttons in a grid
 *  6+   : As a select drop down with button
 * @param choices - Array of { value, name, icon? } object. If href is passed in.
 * @param onSelect - Event when a selection is commited. (For select, it's when the select button is clicked)
 * @param selectButtonLabel - Defaults to "Select". Changes the button label when it's a select control.
 * @param initialSelection - Optional default selection.
 */
export const Choice = ({ choices, className, onSelect, selectButtonLabel = 'Select', selectLabel, initialSelection = '', resolveChoiceMode, defaultPaletteName = 'primary', ariaLabelledBy, ariaLabel, }) => {
    const { classes, cx } = useStyles();
    const [selected, setSelected] = useState(initialSelection);
    const getChoiceMode = useCallback(resolveChoiceMode !== null && resolveChoiceMode !== void 0 ? resolveChoiceMode : ((choiceCount) => {
        if (choiceCount > 6) {
            return 'select';
        }
        if (choiceCount > 4) {
            return 'grid';
        }
        return 'buttons';
    }), [choices.length, resolveChoiceMode]);
    const choiceMode = getChoiceMode(choices.length);
    const handleChange = (selection) => {
        if (selection === null || selection === void 0 ? void 0 : selection.value) {
            setSelected(selection.value);
        }
    };
    const handleClick = () => {
        if (onSelect) {
            onSelect(selected);
        }
    };
    const handleButtonSelect = (selection) => {
        setSelected(selection);
        if (onSelect) {
            onSelect(selection);
        }
    };
    const renderSelectionButtons = () => choiceMode === 'buttons'
        ? choices.map((option) => (_jsx(ChoiceSelectButton, { option: option, choiceMode: choiceMode, onSelect: handleButtonSelect, disabled: option === null || option === void 0 ? void 0 : option.disabled }, option.id)))
        : choices.map((option) => (_jsx(ChoiceGridButton, { selected: option.value === selected, option: option, onSelect: handleButtonSelect, className: option.className }, option.id)));
    return (_jsxs("div", { className: cx(classes.root, className), role: "group", "aria-label": ariaLabel, "aria-labelledby": ariaLabelledBy, children: [_jsx("div", { className: cx(classes.content, {
                    [classes.contentButtons]: choiceMode === 'buttons',
                    [classes.contentGrid]: choiceMode === 'grid',
                }), children: choiceMode === 'select' ? (_jsx(SelectField, { label: selectLabel || 'Select an option', hideLabel: true, value: selected, onChange: handleChange, options: choices, id: `${selectLabel !== null && selectLabel !== void 0 ? selectLabel : 'choice'}` })) : (renderSelectionButtons()) }), _jsx("div", { className: classes.footer, children: choiceMode === 'select' && (_jsx(Button, { type: "button", onClick: handleClick, size: "md", variant: "palette", disable: !selected, paletteName: defaultPaletteName, className: classes.confirmButton, children: selectButtonLabel })) })] }));
};
export default Choice;
