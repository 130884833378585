import getSummaryStatistics from '@api/getSummaryStatistics';
import { useQuery } from 'react-query';
/**
 * Request data from User Summary APi
 *
 * @param handle The user handle to fetch the summary for
 * @returns The user summary
 */
const useFetchSummaryStatistics = (handle) => useQuery(['fetchSummaryStatistics', handle], () => {
    return getSummaryStatistics({
        userHandle: handle,
    });
});
export default useFetchSummaryStatistics;
