import { useEffect } from 'react';
import firebase from 'firebase/compat/app';
import { useQueryClient } from 'react-query';
import doTrackEvent from "@components/Analytics/doTrackEvent";
/**
 * This hook will listen for a Firebase logout event, then clears
 * all user specific data sitting in the React-Query cache.
 *
 * Technically this is not required if we can guarantee all logout events
 * trigger a page refresh - but this may be a dangerous assumption.
 */
const useClearReactQueryCacheOnLogout = () => {
    const queryClient = useQueryClient();
    useEffect(() => {
        const auth = firebase.auth();
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user == null) {
                queryClient.clear();
                doTrackEvent({ type: 'logout' });
            }
            else {
                doTrackEvent({ type: 'login', userId: user.uid });
            }
        });
        return unsubscribe;
    }, []);
};
export default useClearReactQueryCacheOnLogout;
