import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from '@mui/material/styles';
import CoreButton from "@components/Buttons/CoreButton";
import Dropdown from '@components/UI/Dropdown';
import useGameModeFilterState from './hooks/useGameModeFilterState';
import GameModeFilterOptions from './GameModeFilterOptions';
import FilterToggleButton from '../FilterToggleButton';
const Wrapper = styled('div') `
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
  padding-block: ${({ theme }) => theme.spacing(1)};
`;
const Separator = styled('hr') `
  border: 0;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[200]};
  height: 1px;
  margin: 0;
  width: 100%;
`;
const DesktopGameModeFilter = ({ gameKey, selectedModes, onChange, onClear }) => {
    const { isOpen, displayText, displayIcon, options, buttonRef, close, toggleOpen } = useGameModeFilterState(gameKey, selectedModes);
    const hasSelection = !!(selectedModes === null || selectedModes === void 0 ? void 0 : selectedModes.length);
    const handleClear = () => {
        close();
        onClear();
    };
    return (_jsx(Dropdown, { buttonRef: buttonRef.current, isOpen: isOpen, onClickOutside: close, placement: "bottom-start", dropdownButton: _jsx(FilterToggleButton, { text: displayText, title: "Mode", size: "sm", onClick: toggleOpen, ref: buttonRef, iconUrl: displayIcon }), children: _jsxs(Wrapper, { children: [_jsx("span", { children: "Mode" }), _jsx(Separator, {}), _jsx(GameModeFilterOptions, { onChange: onChange, options: options }), _jsx(Separator, {}), _jsx(CoreButton, { onClick: handleClear, variant: hasSelection ? 'error' : 'grey', appearance: hasSelection ? 'fill' : 'outline', disabled: !hasSelection, size: "md", block: true, children: "Clear Filters" })] }) }));
};
export default DesktopGameModeFilter;
