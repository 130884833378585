import { isBefore } from 'date-fns';
import { getTournamentFinishTime, getTournamentCloseTime } from './tournamentUtils';
export const getTournamentState = (tournament, registrationEndingMinutes) => {
    const now = new Date();
    if (tournament.finished) {
        return 'finished';
    }
    // Before registrations open
    const open = new Date(tournament.registration_opens);
    if (isBefore(now, open)) {
        return 'closed';
    }
    // Before tournament starts
    const start = new Date(tournament.start_time);
    if (isBefore(now, start)) {
        return 'open';
    }
    // Before registration closes
    const close = getTournamentCloseTime(tournament.start_time, tournament.length_minutes, registrationEndingMinutes);
    if (isBefore(now, close)) {
        return 'open_started';
    }
    // Before tournament ends
    const end = getTournamentFinishTime(tournament.start_time, tournament.length_minutes);
    if (isBefore(now, end)) {
        return 'started';
    }
    // Tournament has ended
    return 'ended';
};
export const getTournamentStatusVariant = (status) => {
    switch (status) {
        case 'open':
        case 'open_started':
            return 'success';
        case 'closed':
        case 'started':
            return 'warning';
        case 'ended':
        case 'finished':
            return 'error';
        default:
            throw new Error(`Tournament status "${status}" was not recognised`);
    }
};
