import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Popper, Fade, useTheme, useMediaQuery, ClickAwayListener, Paper, Collapse, FormControlLabel, Checkbox, } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Button from '@components/Buttons/Button';
import hoverBorderGradientAnimation from '@components/Styles/hoverBorderGradientAnimation';
import ApplyFilterButton from '../ApplyFilterButton';
import { styled } from '@mui/system';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
const Container = styled('div') `
  height: 100%;
  width: 100%;
  border-bottom: 1px solid #ececec;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: auto;
    padding: 0;
    border: 0;
  }

  ${hoverBorderGradientAnimation};
`;
const useStyles = makeStyles()((theme) => ({
    tournamentTypeWrapper: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
        padding: '0 16px',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'center',
            width: 'auto',
        },
        // label text
        '& span': {
            fontSize: '12px',
        },
        '& p': {
            margin: '0',
        },
        '& i': {
            marginLeft: '30px',
            color: '#A5A5A5',
        },
    },
    tournamentTypeButton: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            paddingTop: '15px',
            paddingBottom: '15px',
        },
    },
    tournamentTypeButtonText: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    popper: {
        zIndex: 10,
    },
    tournamentTypeOptions: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        // label text
        '& span': {
            fontSize: '12px',
        },
        '& > label': {
            padding: '0 20px',
            [theme.breakpoints.down('md')]: {
                padding: '8px 20px',
                margin: '0',
                borderBottom: '1px solid #ECECEC',
            },
        },
        '& > label:first-child': {
            paddingTop: '9px',
            [theme.breakpoints.down('md')]: {
                borderTop: '1px solid #ECECEC',
            },
        },
        '& > label:last-child': {
            paddingBottom: '9px',
        },
        // This is the checkbox icon
        '& svg': {
            height: '17px',
            width: '17px',
        },
    },
    highlightSelectedFilter: {
        color: theme.palette.primary.main,
    },
    heading: {
        color: theme.palette.grey[400],
        fontWeight: 600,
        fontSize: '12px',
        marginBottom: '8px',
    },
    filterText: {
        color: theme.palette.grey[900],
        fontSize: '12px',
        fontWeight: 600,
    },
    hideOnMobile: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));
/** Handles the UI and get_tournaments endpoint data for the tournament type filters */
const TournamentTypeFilter = ({ dispatch, tournamentTypesFilters, triggerFilterChange, }) => {
    const { classes, cx } = useStyles();
    const [tournamentTypesChecked, setTournamentTypesChecked] = useState(tournamentTypesFilters);
    // During subsequent re-renders, the first value prop by useState will persist.
    // These use effects will ensure the render updates with outside state changes.
    // https://reactjs.org/docs/hooks-reference.html#usestate
    // useEffect(() => {
    //   if (!triggerFilterChange) {
    //     setTournamentTypesChecked(tournamentTypesFilters);
    //   }
    // }, [tournamentTypesFilters]);
    const [selectedText, setSelectedText] = useState('All Types');
    useEffect(() => {
        // When filters change, determine the filter text by seeing how many filters are active
        const activeFilters = tournamentTypesFilters.filter((filter) => filter.checked);
        // If a single filter is active, display the filter title
        if (activeFilters.length === 1) {
            const tournamentFilter = tournamentTypesFilters.find((filter) => filter.key === activeFilters[0].key);
            if (tournamentFilter) {
                setSelectedText(tournamentFilter.name);
            }
        }
        else if (activeFilters.length > 1) {
            setSelectedText(`${activeFilters.length} selected`);
        }
        else {
            setSelectedText('All Types');
        }
    }, [tournamentTypesFilters]);
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [tournamentTypesAnchorEl, setFilterAnchorEl] = useState(null);
    const handleFilterClick = (e) => {
        e.preventDefault();
        setFilterAnchorEl(e.currentTarget);
        setShowFilterMenu(!showFilterMenu);
    };
    const handleFilterMenuClose = () => {
        setShowFilterMenu(false);
    };
    const handleResetFilterState = () => {
        setTournamentTypesChecked(tournamentTypesFilters);
        handleFilterMenuClose();
    };
    const handleApplyFilters = () => {
        dispatch({ type: 'setTournamentTypes', payload: tournamentTypesChecked });
        handleFilterMenuClose();
    };
    useEffect(() => {
        dispatch({ type: 'setTournamentTypes', payload: tournamentTypesChecked });
    }, [triggerFilterChange]);
    const handleFilterCheck = (selectedFilter) => {
        // rebuild state tournament types array and reverse selectedFilter
        const newFilters = tournamentTypesChecked.reduce((curr, filter) => {
            if (filter.key === selectedFilter.key) {
                curr.push({
                    ...filter,
                    checked: !filter.checked,
                });
            }
            else {
                curr.push(filter);
            }
            return curr;
        }, []);
        setTournamentTypesChecked(newFilters);
    };
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (_jsx(ClickAwayListener, { onClickAway: () => {
            if (!isMobile) {
                handleResetFilterState();
            }
        }, children: _jsxs(Container, { children: [_jsx(Button, { variant: "transparent", type: "button", onClick: (e) => handleFilterClick(e), className: classes.tournamentTypeButton, children: _jsxs("div", { className: classes.tournamentTypeWrapper, children: [_jsxs("div", { className: classes.tournamentTypeButtonText, children: [_jsx("p", { className: classes.heading, children: "Tournament Type" }), _jsx("p", { className: cx(classes.filterText, selectedText !== 'All Types' && classes.highlightSelectedFilter), children: selectedText })] }), _jsx(ChevronDownIcon, { height: 16, width: 16 })] }) }), !isMobile ? (_jsx(Popper, { placement: "bottom-start", transition: true, anchorEl: tournamentTypesAnchorEl, open: showFilterMenu, className: classes.popper, children: ({ TransitionProps }) => (_jsx(Fade, { ...TransitionProps, timeout: 350, children: _jsxs(Paper, { square: true, children: [_jsx("div", { className: classes.tournamentTypeOptions, children: tournamentTypesChecked.map((filter) => (_jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: filter.checked, onChange: () => handleFilterCheck(filter), name: filter.key, color: "primary" }), label: filter.name }, filter.key))) }), _jsx(ApplyFilterButton, { onClick: handleApplyFilters })] }) })) })) : (_jsx(Collapse, { in: showFilterMenu, timeout: "auto", unmountOnExit: true, children: _jsxs("div", { className: classes.tournamentTypeOptions, children: [tournamentTypesChecked.map((filter) => (_jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: filter.checked, onChange: () => handleFilterCheck(filter), name: filter.key, color: "primary" }), label: filter.name }, filter.key))), _jsx(ApplyFilterButton, { onClick: handleApplyFilters, className: classes.hideOnMobile })] }) }))] }) }));
};
export default TournamentTypeFilter;
