import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import * as React from 'react';
import { ContentCarousel } from '@components/UI';
import ModalBreadcrumb from './ModalBreadcrumb';
/**
 * Modal for quickly creating a multi-step flow using ModalBreadcrumb and ContentCarousel components
 * @param name string - consumed by ContentCarousel.
 * @param pageKeys Array<PageKeys> - current keys of pages appearing in the breadcrumb and carousel.
 * @param renderPage function - renderer for each page in the carousel by PageKey.
 * @param renderBreadcrumb optional function - renderer for each breadcrumb item by PageKey. Defaults to rendering just the PageKey.
 * @param onPop function - callback for when a breadcrumb is clicked. Passes an estimate of the next pageKeys, and the index of the breadcrumb that was clicked.
 * @param hideBreadcrumb optional - used to hide breadcrumb component.
 * @param loading optional - disable breadcrumb navigation while true
 * @param onTransitionEnd function - triggered every time page transition completes
 */
function ModalCarouselContent({ name, pageKeys, renderPage, renderBreadcrumb, onPop, hideBreadcrumb = false, loading = false, onTransitionEnd, }) {
    const breadcrumbs = pageKeys.map((b, index) => {
        var _a;
        return ({
            id: String(index),
            component: (_a = renderBreadcrumb === null || renderBreadcrumb === void 0 ? void 0 : renderBreadcrumb(b)) !== null && _a !== void 0 ? _a : b,
        });
    });
    const onPopPages = (index) => {
        onPop(pageKeys.slice(0, index + 1), index);
    };
    const handleTransitionEnded = () => {
        if (onTransitionEnd) {
            onTransitionEnd(pageKeys[pageKeys.length - 1]);
        }
    };
    // Deselect elements when page changes
    useEffect(() => {
        if (document.activeElement instanceof HTMLElement)
            document.activeElement.blur();
    }, [pageKeys]);
    // Do not render anything if there are no pages
    if (pageKeys.length === 0) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs("div", { children: [_jsx(ModalBreadcrumb, { currentIndex: pageKeys.length - 1, breadcrumbs: breadcrumbs, onClick: onPopPages, isVisible: !hideBreadcrumb, loading: loading }), _jsx(ContentCarousel, { name: name, currentStep: pageKeys.length - 1, onTransitionEnd: handleTransitionEnded, children: pageKeys.map((pageName) => (_jsx(React.Fragment, { children: renderPage(pageName) }, pageName))) })] }));
}
export default ModalCarouselContent;
