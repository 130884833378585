import axios from 'axios';
import { getAuth, signOut as firebaseSignOut } from 'firebase/auth';
const signOut = async () => {
    const auth = getAuth();
    await firebaseSignOut(auth);
    // Hitting the logout endpoint to ensure PHP session ends
    const serverLogoutUrl = `/logout`;
    await axios.get(serverLogoutUrl);
};
export default signOut;
