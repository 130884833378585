const removeQueryParams = (paramNames, locationSearch) => {
    const queryParams = new URLSearchParams(locationSearch || window.location.search);
    paramNames.forEach(paramName => queryParams.delete(paramName));
    const search = queryParams.toString();
    return {
        url: `${window.location.pathname}?${search}${window.location.hash}`,
        search,
        hash: window.location.hash,
    };
};
export default removeQueryParams;
