import { jsx as _jsx } from "react/jsx-runtime";
import { useLocation } from 'react-router-dom';
import OauthResultModal from './OauthResultModal';
const QueryActionModals = () => {
    const { search } = useLocation();
    const urlSearchParams = new URLSearchParams(search);
    const queryAction = urlSearchParams.get('action');
    switch (queryAction) {
        case 'link_service_success':
        case 'link_service_error':
            return _jsx(OauthResultModal, {});
        default:
            return null;
    }
};
export default QueryActionModals;
