import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import SaveGameDetails from '../SaveGameDetails';
import { useAddGameContext } from '../context/AddGameProvider';
import useTrackEvent from '@hooks/useTrackEvent';
import ModalContent from "@components/Modals/components/ModalContent";
import TextInput from "@components/Fields/TextInput";
const ContentPUBGConnect = ({ className, onSubmit, stepName }) => {
    const { connectGameService } = useAddGameContext();
    const [username, setUsername] = useState('');
    const trackEvent = useTrackEvent();
    const handleChange = (e) => {
        setUsername(e.target.value);
    };
    const handleSubmit = () => {
        trackEvent({
            type: 'game_linking',
            data: {
                eventName: 'game linking',
                label: 'Connect',
                moduleName: 'Button',
                position: 0,
            },
        });
        if (onSubmit) {
            onSubmit(username);
        }
    };
    // Clears the username when game service changes.
    useEffect(() => {
        setUsername('');
    }, [connectGameService === null || connectGameService === void 0 ? void 0 : connectGameService.gameServiceKey]);
    const guideLink = '/content/how-to-add-pubg/';
    const handleGuideClick = () => {
        trackEvent({
            type: 'user_engagement',
            data: {
                eventName: 'select text link',
                label: 'Click here for a guide on how to make your results public.',
                moduleName: 'Text link',
                position: 1,
                linkURL: guideLink,
            },
        });
    };
    return (_jsxs(ModalContent, { stepName: stepName, className: className, verticalAlign: "top", children: [_jsxs("section", { children: [_jsxs("h3", { children: ["How to connect a ", connectGameService === null || connectGameService === void 0 ? void 0 : connectGameService.name, " account"] }), _jsxs("ol", { children: [_jsx("li", { children: "Login to the game" }), _jsx("li", { children: "Find your player name and copy it." }), _jsx("li", { children: "Paste it below" })] }), _jsx(TextInput, { id: "game-service-user-name", "data-ignore": true, placeholder: "Player name", value: username, onChange: handleChange, "aria-label": "Player name" }), _jsx("a", { href: guideLink, target: "_blank", onClick: handleGuideClick, children: "Click here for a guide on how to find your player name." })] }), _jsx(SaveGameDetails, { lookupValue: username, onClick: handleSubmit })] }));
};
export default ContentPUBGConnect;
