import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
import ModalContent from "@components/Modals/components/ModalContent";
import { Choice } from '@components/UI';
const useStyles = makeStyles()((theme) => ({
    closeButton: {
        height: '30px',
    },
    icon: {
        display: 'flex',
        flexDirection: 'column',
        '& img': {
            height: '20px',
            marginBottom: theme.spacing(1),
        },
    },
    label: {
        display: 'inline-flex',
        '& img': {
            height: '14px',
            marginRight: theme.spacing(0.5),
        },
    },
}));
const Amount = ({ onSelect }) => {
    const { classes } = useStyles();
    const choices = [
        {
            id: '50k',
            value: '5',
            icon: (_jsxs("span", { className: classes.icon, children: [_jsx("img", { src: "/img/icons/coins.svg", alt: "coins" }), "50,000"] })),
            label: (_jsxs("span", { className: classes.label, children: [_jsx("img", { src: "/img/icons/dollar.svg", alt: "dollar" }), "5.00"] })),
            paletteName: 'secondary',
        },
        {
            id: '100k',
            value: '10',
            icon: (_jsxs("span", { className: classes.icon, children: [_jsx("img", { src: "/img/icons/coins.svg", alt: "coins" }), "100,000"] })),
            label: (_jsxs("span", { className: classes.label, children: [_jsx("img", { src: "/img/icons/dollar.svg", alt: "dollar" }), "10.00"] })),
            paletteName: 'secondary',
        },
        {
            id: '250k',
            value: '25',
            icon: (_jsxs("span", { className: classes.icon, children: [_jsx("img", { src: "/img/icons/coins.svg", alt: "coins" }), "250,000"] })),
            label: (_jsxs("span", { className: classes.label, children: [_jsx("img", { src: "/img/icons/dollar.svg", alt: "dollar" }), "25.00"] })),
            paletteName: 'secondary',
        },
        {
            id: '500k',
            value: '50',
            icon: (_jsxs("span", { className: classes.icon, children: [_jsx("img", { src: "/img/icons/coins.svg", alt: "coins" }), "500,000"] })),
            label: (_jsxs("span", { className: classes.label, children: [_jsx("img", { src: "/img/icons/dollar.svg", alt: "dollar" }), "50.00"] })),
            paletteName: 'secondary',
        },
        {
            id: '1m',
            value: '100',
            icon: (_jsxs("span", { className: classes.icon, children: [_jsx("img", { src: "/img/icons/coins.svg", alt: "coins" }), "1,000,000"] })),
            label: (_jsxs("span", { className: classes.label, children: [_jsx("img", { src: "/img/icons/dollar.svg", alt: "dollar" }), "100.00"] })),
            paletteName: 'secondary',
        },
        {
            id: '5m',
            value: '500',
            icon: (_jsxs("span", { className: classes.icon, children: [_jsx("img", { src: "/img/icons/coins.svg", alt: "coins" }), "5,000,000"] })),
            label: (_jsxs("span", { className: classes.label, children: [_jsx("img", { src: "/img/icons/dollar.svg", alt: "dollar" }), "500.00"] })),
            paletteName: 'secondary',
        },
    ];
    const handleSelection = (selectionValue) => {
        onSelect(parseFloat(selectionValue));
    };
    return (_jsx(ModalContent, { align: "center", children: _jsx(Choice, { choices: choices, onSelect: handleSelection, resolveChoiceMode: () => 'grid' }) }));
};
export default Amount;
