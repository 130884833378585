const getNotificationStatus = (notification) => {
    const { opened, isNewNotification, priority } = notification;
    if (priority > 0) {
        return 'pinned';
    }
    if (isNewNotification) {
        return 'new';
    }
    if (opened) {
        return 'opened';
    }
    return 'unopened';
};
export default getNotificationStatus;
