import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
import { useInView } from 'react-intersection-observer';
import LoadingPanel from '@domain/Tournament/components/LoadingPanel';
const useStyles = makeStyles()(() => ({
    loading: {
        minHeight: '465px',
    },
}));
const LoadWhenInView = ({ children }) => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        rootMargin: '200px 0px',
    });
    const { classes } = useStyles();
    if (!inView) {
        return _jsx(LoadingPanel, { className: classes.loading, ref: ref });
    }
    return _jsx(_Fragment, { children: children });
};
export default LoadWhenInView;
