const getBracketPositionIcon = (bracketPosition) => {
    switch (bracketPosition) {
        case 1:
            return '/img/ranks/threshold/bracket-gold.png';
        case 2:
            return '/img/ranks/threshold/bracket-blue.png';
        case 3:
            return '/img/ranks/threshold/bracket-orange.png';
        default:
            return '/img/ranks/threshold/bracket-silver.png';
    }
};
export default getBracketPositionIcon;
