import { isAxiosError } from './errorHelper';
import ErrorWithCause from './ErrorWithCause';
const errorCodeFromError = (error) => {
    var _a, _b, _c, _d, _e;
    if (error instanceof ErrorWithCause) {
        return errorCodeFromError(error.cause);
    }
    if (!isAxiosError(error)) {
        return undefined;
    }
    return ((_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.errorCode) || ((_e = (_d = (_c = error.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.errorCode) || undefined;
};
export default errorCodeFromError;
