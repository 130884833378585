/**
 * Checks user age and game age rating
 *
 * @param selectedGame The game in question
 * @param userAge The age of the user
 * @returns {boolean} true if user passes age consent
 */
const checkAgeConsent = (selectedGame, userAge) => {
    var _a;
    if (!userAge) {
        return false;
    }
    // Default to zero age rating if none is specified
    const gameAgeRating = (_a = selectedGame === null || selectedGame === void 0 ? void 0 : selectedGame.ageRating) !== null && _a !== void 0 ? _a : 0;
    return userAge >= gameAgeRating || userAge >= 18;
};
export default checkAgeConsent;
