import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from '@components/Buttons/Button';
import ModalContent from '@components/Modals/components/ModalContent';
import RealMoney from '@components/UI/RealMoney';
import toRealMoney from '@helpers/toRealMoney';
import useAppState from '@hooks/API/useAppState';
import useWithdrawModalStyles from '../hooks/useWithdrawModalStyles';
const WithdrawConfirmPayment = ({ type, amount, loading = false, onSubmit, error, }) => {
    var _a;
    const { classes } = useWithdrawModalStyles();
    const { paymentMethods } = useAppState();
    const { fixedFee = 0.3, percentFee = 2.9 } = (_a = paymentMethods === null || paymentMethods === void 0 ? void 0 : paymentMethods.paypal) !== null && _a !== void 0 ? _a : {};
    return (_jsxs(ModalContent, { children: [_jsx("h3", { children: "Confirm Your Payment" }), _jsx("p", { children: "Before finishing your payment, please confirm the below details are correct" }), _jsxs("div", { className: classes.dataTable, children: [_jsx("p", { children: "Selected amount" }), _jsx("p", { className: classes.col2, children: _jsx(RealMoney, { amount: amount, centerVertical: true }) }), _jsx("p", { children: "Processing time" }), _jsx("p", { className: classes.col2, children: "3-5 Business Days" })] }), _jsxs("p", { className: classes.feeLabel, children: ["Processing Fee is $", toRealMoney(fixedFee), " + ", percentFee, "%"] }), _jsx(Button, { loading: loading, variant: "palette", paletteName: type === 'paypal' ? 'paypal' : 'warning', onClick: onSubmit, children: "Confirm Payment" }), error && (_jsx("span", { role: "alert", className: classes.errorText, children: error }))] }));
};
export default WithdrawConfirmPayment;
