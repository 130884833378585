import axios from 'axios';
import ErrorWithCause from '@helpers/ErrorWithCause';
const getGameModes = async (gameKey) => {
    try {
        const { data } = await axios.get('/api/gamemode/v1', {
            params: {
                gameKey,
            },
        });
        return data;
    }
    catch (error) {
        throw new ErrorWithCause(error);
    }
};
export default getGameModes;
