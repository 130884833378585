const getSocialMediaServiceBaseUrlFromType = (type) => {
    // YouTube is not added here, as we store exact URL
    switch (type) {
        case 'twitch':
            return 'twitch.tv';
        case 'twitter':
            return 'twitter.com';
        case 'youtube':
            return 'youtube.com';
        case 'instagram':
            return 'instagram.com';
        case 'facebook':
            return 'facebook.com';
        case 'discord':
            return 'discord.com';
        case 'reddit':
            return 'reddit.com';
        case 'google':
            return 'google.com';
        case 'psn':
            return 'playstation.com';
        default:
            return '';
    }
};
export default getSocialMediaServiceBaseUrlFromType;
