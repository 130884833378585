import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Link } from 'react-router-dom';
const useStyles = makeStyles()((theme) => ({
    support: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: theme.spacing(2),
    },
    resetPasswordBtn: {
        fontSize: '11px',
        fontWeight: 600,
        color: theme.palette.grey[400],
        display: 'flex',
        alignItems: 'center',
        padding: `6px 8px`,
        '&:hover': {
            textDecoration: 'underline',
            backgroundColor: 'transparent',
        },
    },
    getSupportBtn: {
        fontSize: '11px',
        fontWeight: 600,
        color: theme.palette.primary.main,
        '&:hover': {
            textDecoration: 'underline',
            backgroundColor: 'transparent',
        },
    },
}));
const AuthenticationSupport = ({ closeAction }) => {
    const { classes } = useStyles();
    const handleSupportClick = () => {
        var _a;
        closeAction();
        if (window.zEmbed) {
            const widget = ((_a = window.zEmbed) === null || _a === void 0 ? void 0 : _a.widget) === 'messenger' ? 'messenger' : 'webWidget';
            window.zEmbed(widget, 'show');
            window.zEmbed(widget, 'open');
        }
    };
    return (_jsxs("div", { className: classes.support, children: [_jsx(Link, { to: "/user/reset", className: classes.resetPasswordBtn, onClick: closeAction, children: "Forgot Password?" }), _jsx(Button, { variant: "text", disableElevation: true, disableTouchRipple: true, disableFocusRipple: true, onClick: handleSupportClick, className: classes.getSupportBtn, children: "Need Support?" })] }));
};
export default AuthenticationSupport;
