const getTournamentStatusVariant = (tournamentState) => {
    switch (tournamentState) {
        case 'full':
            return 'warning';
        case 'finished':
        case 'closed':
            return 'error';
        case 'started':
        default:
            return 'success';
    }
};
export default getTournamentStatusVariant;
