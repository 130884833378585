import axios from 'axios';
import ErrorWithCause from '../helpers/ErrorWithCause';
const getSummaryStatistics = async ({ userHandle }) => {
    const requestUrl = userHandle ? `/api/user/profile_summary/${userHandle}/v1` : `/api/user/profile_summary/v1`;
    try {
        const response = await axios.get(requestUrl);
        return response.data;
    }
    catch (error) {
        throw new ErrorWithCause(error);
    }
};
export default getSummaryStatistics;
