import SectionHead from '@components/UI/Section/components/SectionHead';
import SectionHeading from '@components/UI/Section/components/SectionHeading';
import Tag from '@components/UI/Tag';
import { css, styled } from '@mui/material/styles';
export const StyledSectionHeading = styled(SectionHeading) `
  text-transform: capitalize;
  color: #939aa1;
  font-size: 10px;
`;
export const CenteredSectionHeading = styled(StyledSectionHeading) `
  text-align: center;
`;
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const columnSizing = (theme) => css `
  grid-template-columns: 42% 18% 13% 14% 14%;
  ${theme.breakpoints.up('lg')} {
    grid-template-columns: 50% 16% 11% 12% 11%;
  }
`;
export const StyledSectionContainer = styled(SectionHead) `
  display: grid;
  ${(props) => columnSizing(props.theme)};
  padding: 18px;
`;
export const StyledTag = styled(Tag) `
  background-color: unset;
  font-size: 10px;
  padding: 0px 6px;
  margin: unset;
  margin-top: 4px;
  border-left: 1px solid #dee3e9;
  border-radius: 0;
  > span {
    color: ${({ theme }) => theme.palette.grey[700]};
  }
  &:first-of-type {
    padding-left: 0;
    border-left: unset;
  }
`;
export const TagContainer = styled('div') `
  display: flex;
`;
