import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import loadable from '@loadable/component';
import { Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Link } from 'react-router-dom';
import TagList from '@components/UI/TagList';
import useAppState from '@hooks/API/useAppState';
import { getTournamentColorPaletteName, getTournamentState } from '@domain/Tournament/helpers/tournamentUtils';
import getTournamentPlatformsDisplay from '@domain/Tournament/helpers/getTournamentPlatformsDisplay';
import getTournamentBoxBannerImage from '@domain/Tournament/helpers/getTournamentBoxBannerImage';
import getTournamentDefaultBanner from '@domain/Tournament/helpers/getTournamentDefaultBanner';
import getTournamentTags from '@domain/Tournament/helpers/getTournamentTags';
import getTournamentTagsV2 from '@domain/TournamentV2/TournamentTable/getTournamentTags';
import useGame from '@hooks/API/useGame';
import TournamentEntryFee from '@components/Tournament/TournamentEntryFee';
import TournamentAwardRankCell from '@components/Tournament/TournamentAwardRankCell';
import TournamentBoxBanner from './TournamentBoxBanner';
import TournamentProgressBar from '../../TournamentProgressBar';
import TournamentEligibility from '@components/Tournament/TournamentEligibility';
import doTrackEvent, { extractTournamentAnalyticsData } from '@components/Analytics/doTrackEvent';
import useTournamentDetailsPageUrl from '@hooks/useTournamentDetailsPageUrl';
import TournamentBoxPillGameRank from './TournamentBoxPillGameRank';
import TournamentBoxPillGameMode from '@domain/Tournament/components/TournamentGrid/TournamentBox/TournamentBoxPillGameMode';
import useTournamentsV2Designs from '@domain/Tournament/hooks/useTournamentV2Designs';
const GameIcon = loadable(() => import('@components/Icons/GameIcon'));
const useStyles = makeStyles()((theme) => ({
    tournamentContainer: {
        display: 'flex',
        border: `1px solid ${theme.palette.grey[100]}`,
        fontFamily: theme.typography.fontFamily,
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        minWidth: 0,
        '& .tournamentMetric': {
            margin: 0,
        },
        '& .hover': {
            position: 'absolute',
            top: '0px',
            left: '0px',
            right: '0px',
            bottom: '0px',
            transform: 'translateX(105%)',
            transition: 'transform 0.3s',
        },
        '&:hover .hover': {
            transform: 'translateX(0%)',
        },
    },
    tournamentContent: {
        display: 'flex',
        flexDirection: 'column',
        borderBottom: `1px solid ${theme.palette.grey[100]}`,
    },
    tournamentBanner: {
        height: '180px',
        width: '100%',
        position: 'relative',
        alignItems: 'flex-end',
        padding: '20px',
    },
    tournamentHeading: {
        display: 'flex',
        flexDirection: 'column',
        color: theme.palette.grey[900],
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        '& > img': {
            marginRight: theme.spacing(2),
        },
    },
    spacing: {
        padding: theme.spacing(2),
        margin: 0,
    },
    tournamentName: {
        fontSize: '14px',
        fontWeight: 600,
        marginBottom: theme.spacing(0.5),
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    tournamentProgress: {
        borderBottom: `1px solid ${theme.palette.grey[100]}`,
        padding: theme.spacing(2),
    },
    viewTournamentWrapper: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
    },
    viewTournament: {
        textAlign: 'center',
        flexGrow: 1,
        '& p': {
            color: theme.palette.success.main,
            fontSize: '14px',
            fontWeight: 600,
            margin: 0,
        },
    },
    gameIcon: {
        background: 'white',
        borderRadius: theme.shape.borderRadius,
        padding: '5px',
        width: '30px',
        height: '30px',
    },
    bannerStatus: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    headingTagList: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        overflow: 'hidden',
    },
    tooltip: {
        fontSize: '12px',
    },
    paddedBanner: {
        padding: '16px',
        paddingBottom: '0px',
        '& > img': {
            padding: '0px',
        },
    },
    tagV2: {
        fontSize: '12px',
        background: 'none',
        padding: theme.spacing(0.5, 1, 0.5, 0.5),
        borderRadius: 0,
        borderRight: '1px solid #dee3e9',
        '&:first-child': {
            paddingLeft: 0,
        },
        '&:last-child': {
            marginRight: 0,
            paddingRight: 0,
            borderRight: 0,
        },
    },
}));
const TournamentBox = ({ tournament, compact = false, className, section, listIndex }) => {
    const paletteName = getTournamentColorPaletteName(tournament);
    const { classes, cx } = useStyles();
    const gameKey = tournament.score_template.game.game_key;
    const gameData = useGame(gameKey);
    const detailsLink = useTournamentDetailsPageUrl(tournament.id, gameData);
    const appState = useAppState();
    const tournamentState = getTournamentState(tournament, appState.registrationEndingMinutes);
    const tournamentImageUrl = getTournamentBoxBannerImage(tournament);
    const defaultTournamentImageUrl = gameKey ? getTournamentDefaultBanner(gameKey, tournament, true) : '';
    const hasEntered = tournament.user_entry;
    const isTournamentV2Design = useTournamentsV2Designs(gameKey);
    const bannerTopLeft = isTournamentV2Design ? (_jsx(TournamentBoxPillGameMode, { tournament: tournament })) : (_jsx(GameIcon, { optimise: true, game: tournament.score_template.game.game_key, className: classes.gameIcon }));
    const bannerTopRight = isTournamentV2Design ? (_jsx(TournamentBoxPillGameRank, { tournament: tournament })) : (_jsx(TagList, { tags: getTournamentPlatformsDisplay(tournament) }));
    const tags = isTournamentV2Design
        ? getTournamentTagsV2(tournament, gameData, true).map((tag) => ({ ...tag, palette: undefined }))
        : getTournamentTags(tournament, gameData);
    const onBoxClick = () => {
        doTrackEvent({
            ...extractTournamentAnalyticsData(tournament, appState.registrationEndingMinutes),
            type: 'open_tournament',
            section,
            listIndex: listIndex.toString(),
            viewType: compact ? 'compact' : 'box',
        });
    };
    return (_jsxs(Link, { to: detailsLink, onClick: onBoxClick, className: cx(classes.tournamentContainer, className), children: [_jsxs("div", { className: cx(classes.tournamentContent), children: [compact ? (_jsxs("div", { className: cx(classes.bannerStatus, classes.paddedBanner), children: [bannerTopLeft, bannerTopRight] })) : (_jsx(TournamentBoxBanner, { bannerImage: tournamentImageUrl || defaultTournamentImageUrl, paletteName: paletteName, className: classes.tournamentBanner, children: _jsxs("div", { className: classes.bannerStatus, children: [bannerTopLeft, bannerTopRight] }) })), _jsxs("div", { className: cx(classes.tournamentHeading, classes.spacing), children: [_jsx(Tooltip, { title: tournament.name, classes: {
                                    tooltip: classes.tooltip,
                                }, children: _jsx("h4", { className: classes.tournamentName, "data-notranslate": true, children: tournament.name }) }), _jsx(TagList, { tags: tags, className: classes.headingTagList, classNameTag: isTournamentV2Design ? classes.tagV2 : undefined })] })] }), !hasEntered ? (_jsx(TournamentProgressBar, { currentEntries: tournament.entries_count, maxEntries: tournament.slots, tournamentState: tournamentState, className: classes.tournamentProgress, maxPrizePositions: tournament.maximum_prize_positions })) : (_jsx(TournamentEligibility, { tournament: tournament, className: classes.tournamentProgress, align: "left" })), _jsxs("div", { className: classes.viewTournamentWrapper, children: [_jsx(TournamentAwardRankCell, { isTournamentBoxUI: true, tournament: tournament, align: isTournamentV2Design ? 'left' : 'center' }), _jsx(TournamentEntryFee, { tournament: tournament, align: isTournamentV2Design ? 'right' : 'center' })] })] }));
};
export default TournamentBox;
