// Add future social media types to this array
// This constant probably should come from API, or a helper or constants file
export const SocialMediaTypes = [
    'twitch',
    'twitter',
    'youtube',
    'instagram',
    'facebook',
    'reddit',
    'discord',
    'google',
    'psn',
    'apple',
];
export const ConnectableSocialMediaTypes = ['twitch', 'youtube', 'instagram'];
export const getAccountLoginSocialMediaTypes = (authAction) => {
    const loginSocialMediaTypes = [
        window.feature_flags.includes('enable_playstation_auth_provider') && 'psn',
        window.feature_flags.includes('enable_google_auth_provider') && 'google',
        window.feature_flags.includes('enable_facebook_auth_provider') && 'facebook',
        window.feature_flags.includes('enable_twitter_auth_provider') && 'twitter',
        window.feature_flags.includes('enable_apple_sign_in') && 'apple',
    ];
    if (authAction === 'register') {
        return loginSocialMediaTypes.filter((socialType) => !window.feature_flags.includes(`disable_${socialType}_login`) && !!socialType);
    }
    return loginSocialMediaTypes.filter((socialType) => !!socialType);
};
