export const SupportedLanguages = [
    {
        code: 'en',
        imgUrl: '/img/flags/flags_iso/language_flags/english_flag.svg',
        country: 'USA',
        label: 'English',
        abbrev: 'Eng',
    },
    {
        code: 'es-LA',
        imgUrl: '/img/flags/flags_iso/language_flags/espanol_flag.svg',
        country: 'Spain',
        label: 'Spanish',
        abbrev: 'Spa',
    },
    {
        code: 'ru',
        imgUrl: '/img/flags/flags_iso/language_flags/russian_flag.svg',
        country: 'Russia',
        label: 'Russian',
        abbrev: 'Rus',
    },
    {
        code: 'pt-BR',
        imgUrl: '/img/flags/flags_iso/language_flags/brasil_flag.svg',
        country: 'Brazil',
        label: 'Brazilian Portuguese',
        abbrev: 'Por',
    },
    {
        code: 'fr',
        imgUrl: '/img/flags/flags_iso/language_flags/french_flag.svg',
        country: 'France',
        label: 'French',
        abbrev: 'Fra',
    },
    {
        code: 'de',
        imgUrl: '/img/flags/flags_iso/language_flags/german_flag.svg',
        country: 'Germany',
        label: 'German',
        abbrev: 'Ger',
    },
    {
        code: 'ja',
        imgUrl: '/img/flags/flags_iso/language_flags/japanese_flag.svg',
        country: 'Japan',
        label: 'Japanese',
        abbrev: '日本語',
    },
    {
        code: 'zh',
        imgUrl: '/img/flags/flags_iso/language_flags/chinese_flag.svg',
        country: 'China',
        label: 'Chinese',
        abbrev: '中文',
    },
];
