const getPaymentStatusFromOrderState = (orderState) => {
    switch (orderState) {
        case 'error': {
            return 'ERROR';
        }
        case 'completed': {
            return 'COMPLETE';
        }
        default:
            return 'WAITING';
    }
};
export default getPaymentStatusFromOrderState;
