import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme, { paletteName }) => ({
    container: {
        fontFamily: theme.typography.fontFamily,
        display: 'flex',
        flexDirection: 'column',
        background: 'none',
        alignItems: 'center',
        border: `1px solid ${theme.palette.grey[100]}`,
        borderRadius: '4px',
        padding: '0px',
    },
    selected: {
    // background: theme.palette.grey[200],
    },
    section: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    primary: {
        height: '80px',
        borderBottom: `1px solid ${theme.palette.grey[100]}`,
        fontSize: '16px',
        color: theme.palette.grey[900],
        width: '100%',
        '&.center': {
            flexDirection: 'column',
        },
        '&>svg': {
            fill: theme.palette[paletteName].main,
            width: '40px',
            height: 'auto',
        },
    },
    secondary: {
        height: '35px',
        fontSize: '11px',
        color: theme.palette.grey[400],
    },
}));
const ChoiceGridButton = ({ layout, option, onSelect, selected, className }) => {
    var _a;
    const { classes, cx } = useStyles({ paletteName: (_a = option.paletteName) !== null && _a !== void 0 ? _a : 'primary' });
    return (_jsxs("button", { type: "button", className: cx(classes.container, className, { [classes.selected]: selected }), onClick: () => onSelect(option.value), "data-testid": "grid button", children: [_jsx("span", { className: cx(classes.section, classes.primary, layout), children: option.icon }), _jsx("span", { className: cx(classes.section, classes.secondary), children: option.label })] }));
};
export default ChoiceGridButton;
