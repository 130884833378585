import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { UnderAgeMessage, StyledCheckbox, CheckboxMessage } from './styles';
const AgeSpecificContent = ({ onChange, isOverConsentAge }) => {
    const handleChange = (e) => onChange(e.currentTarget.checked);
    if (!isOverConsentAge) {
        return (_jsx(UnderAgeMessage, { children: "You do not meet the age requirements for competing in Call of Duty: Warzone tournaments." }));
    }
    return (_jsxs(_Fragment, { children: [_jsx(StyledCheckbox, { onChange: handleChange, label: "I confirm that I am over 18 years of age", name: "agreeEnterTournament", value: true }), _jsx(CheckboxMessage, { children: "* This checkbox is required in order to proceed" })] }));
};
export default AgeSpecificContent;
