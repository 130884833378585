import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import loadable from '@loadable/component';
import ModalIconBanner from '@components/Modals/components/ModalIconBanner';
import ModalContent from '@components/Modals/components/ModalContent';
import { styled } from '@mui/system';
import ConnectGameButton from '@components/AddGame/ConnectGameButton';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import NoWrap from '@components/UI/NoWrap';
const Playstation = loadable(() => import('@components/UI/Icon/Icons/Playstation'));
const StyledConnectGameButton = styled(ConnectGameButton) `
  align-self: stretch;
`;
/**
 * This is one of the steps in the add social media account modal flow
 *
 * @returns The component
 */
const ConnectPlayStationAccount = () => {
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    return (_jsxs(ModalContent, { stepName: "connect-psn", align: "center", children: [_jsx(ModalIconBanner, { children: _jsx(Playstation, { height: 85 }) }), _jsxs("h2", { children: [_jsx(NoWrap, { "data-ignore": true, children: "PlayStation Network" }), " Login"] }), _jsx("section", { children: _jsxs("p", { children: ["You'll be redirected to the\u00A0", _jsx(NoWrap, { "data-ignore": true, children: "PlayStation Network" }), "\u00A0Login page in order to complete this process."] }) }), _jsx(StyledConnectGameButton, { isLoading: isLoading, paletteName: "psn", onClick: () => setIsLoading(true), href: `/user/authenticate/psn?return_path=${location.pathname}`, children: _jsxs("span", { "data-ignore": true, children: ["Login to ", _jsx("span", { "data-ignore": true, children: "PlayStation Network" })] }) })] }));
};
export default ConnectPlayStationAccount;
