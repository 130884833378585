import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import CoreButton from '@components/Buttons/CoreButton';
import SectionContent from '@components/UI/Section/components/SectionContent';
import BracketPositionIcon from '@domain/Tournament/TournamentDetails/TournamentEntry/styled/BracketIcon';
import ThresholdPrizePoolModal from '@domain/Tournament/TournamentDetails/TournamentPrizePool/Components/ThresholdPrizePoolModal/ThresholdPrizePoolModal';
import { TierComparisonProvider } from '@domain/Tournament/TournamentDetails/TournamentPrizePool/Components/ThresholdPrizePoolModal/TierComparisonProvider';
import { GiftIcon } from '@heroicons/react/24/outline';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
const StyledSectionContent = styled(SectionContent) `
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(2)};
`;
const Text = styled('p') `
  font-weight: 600;
  color: ${({ theme }) => theme.palette.grey[900]};
  font-size: 14px;
`;
const Subtext = styled('p') `
  font-weight: 500;
  color: ${({ theme }) => theme.palette.grey[400]};
  font-size: 12px;
`;
const StyledCoreButton = styled(CoreButton) `
  margin-left: auto;
  white-space: nowrap;

  svg {
    width: 14px;
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
`;
const TournamentThresholdPosition = ({ userEntryBracketPosition, brackets, currentUserScore }) => {
    var _a;
    const nextBracketPosition = !userEntryBracketPosition ? undefined : Math.max(1, userEntryBracketPosition - 1);
    const [openModal, setOpenModal] = useState(false);
    const sortedBrackets = brackets.sort((a, b) => a.bracketPosition - b.bracketPosition);
    const lastBracket = sortedBrackets[sortedBrackets.length - 1];
    const nextTier = !nextBracketPosition
        ? lastBracket
        : (_a = brackets.find((bracket) => bracket.bracketPosition === nextBracketPosition)) !== null && _a !== void 0 ? _a : lastBracket;
    const nextTierPoints = nextTier.scoreThreshold - currentUserScore;
    const handleClose = () => {
        setOpenModal(false);
    };
    const handleOpen = () => {
        setOpenModal(true);
    };
    return (_jsxs(StyledSectionContent, { children: [_jsx(BracketPositionIcon, { bracketPosition: nextTier.bracketPosition, children: _jsx("span", { "data-testid": "next-bracket-position", children: nextTier.bracketPosition }) }), _jsx("div", { children: userEntryBracketPosition === 1 ? (_jsxs(_Fragment, { children: [_jsx(Text, { children: "Congratulations! You're in tier 1" }), _jsx(Subtext, { children: "You'll receive the top prize when the tournament ends" })] })) : (_jsxs(_Fragment, { children: [_jsxs(Text, { children: ["Next bracket - Tier ", nextTier.bracketPosition] }), _jsxs(Subtext, { children: ["Achieve ", nextTierPoints, " more points to get placed in tier ", nextTier.bracketPosition] })] })) }), _jsxs(StyledCoreButton, { onClick: handleOpen, children: [_jsx(GiftIcon, {}), " View Prizes"] }), _jsx(TierComparisonProvider, { userEntryBracketPosition: userEntryBracketPosition, brackets: brackets, currentUserScore: currentUserScore, children: _jsx(ThresholdPrizePoolModal, { open: openModal, onClose: handleClose, currentUserScore: currentUserScore, userEntryBracketPosition: userEntryBracketPosition, brackets: sortedBrackets }) })] }));
};
export default TournamentThresholdPosition;
