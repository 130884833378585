import { isNotificationIconKey } from '@Types/Notification';
import { isPaletteName } from '@themes/RepeatTheme';
import { captureMessage } from '@sentry/react';
/**
 * @param unverifiedPaletteName A possibly invalid palette name
 * @returns The valid palette name or 'default' and a flag indicating whether the data provided was valid already
 */
const validPaletteName = (unverifiedPaletteName) => {
    const wasValid = unverifiedPaletteName === 'default' || isPaletteName(unverifiedPaletteName);
    return { wasValid, data: wasValid ? unverifiedPaletteName : 'default' };
};
/**
 * Takes a notification action that may have invalid data and replaces any invalid value with defaults
 *
 * @param unverifiedNotificationAction A possibly invalid notification action
 * @returns A valid notification action based on the possibly invalid notification action and a flag indicating whether the data provided was valid already
 */
const validNotificationAction = (unverifiedNotificationAction) => {
    const { wasValid, data: style } = validPaletteName(unverifiedNotificationAction.style);
    return {
        wasValid,
        data: {
            ...unverifiedNotificationAction,
            style,
        },
    };
};
/**
 * Takes a notification that may have some invalid data and replaces any invalid value with defaults
 *
 * @param unverifiedNotification A possibly invalid notification
 * @returns A valid notification based on the possibly invalid notification and a flag indicating whether the data provided was valid already
 */
const validNotification = (unverifiedNotification) => {
    const wasValidActions = unverifiedNotification.actions.map(validNotificationAction);
    const anyActionWasInvalid = wasValidActions.some((action) => !action.wasValid);
    const actions = wasValidActions.map((wasValid) => wasValid.data);
    const { wasValid: wasIconPaletteValid, data: iconPalette } = validPaletteName(unverifiedNotification.iconPalette);
    const wasNotificationIconKeyValid = isNotificationIconKey(unverifiedNotification.icon);
    return {
        wasValid: !anyActionWasInvalid && wasIconPaletteValid && wasNotificationIconKeyValid,
        data: {
            ...unverifiedNotification,
            actions,
            icon: wasNotificationIconKeyValid ? unverifiedNotification.icon : 'message',
            iconPalette,
        },
    };
};
/**
 * Takes an array of notifications that may have some invalid data and replaces any invalid value with defaults
 *
 * @param unverifiedNotifications An array of possibly invalid notifications
 * @returns Valid notifications based on the possibly invalid notifications
 */
const validNotifications = (unverifiedNotifications) => {
    const validatedNotifications = unverifiedNotifications.map(validNotification);
    const notificationIdsThatWereInvalid = validatedNotifications
        .filter((item) => !item.wasValid)
        .map((item) => item.data.id);
    const notificationsThatWereInvalid = unverifiedNotifications.filter((item) => notificationIdsThatWereInvalid.includes(item.id));
    if (notificationsThatWereInvalid.length > 0) {
        captureMessage('One or more notifications had invalid data (e.g. incorrect palette or icon names)', {
            extra: {
                notificationsThatWereInvalid,
            },
        });
    }
    return validatedNotifications.map((item) => item.data);
};
export default validNotifications;
