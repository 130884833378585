import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
import { SROnly } from '@components/UI';
import { Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/solid';
const useStyles = makeStyles()((theme, _params, classes) => ({
    label: {
        width: '100%',
        fontSize: '12px',
        marginBottom: '0px',
        display: 'flex',
        height: '100%',
        '& > div': {
            display: 'flex',
        },
        '& button': {
            width: '44px',
            background: 'none',
            border: `none`,
            '& .fal': {
                fontWeight: 600,
            },
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                [`& .${classes.icons}`]: {
                    color: `${theme.palette.common.white}`,
                },
            },
        },
    },
    container: {
        display: 'inline-block',
        border: `1px solid ${theme.palette.grey[100]}`,
        background: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        '&:focus': {
            boxShadow: `0 0 0 1px ${theme.palette.primary.main} !important`,
            border: `1px solid ${theme.palette.primary.main} !important`,
        },
        '&:enabled:hover': {
            boxShadow: `0 0 0 1px ${theme.palette.grey[300]}`,
            border: `1px solid ${theme.palette.grey[300]}`,
        },
    },
    input: {
        fontSize: '12px',
        fontWeight: 600,
        fontFamily: theme.typography.fontFamily,
        padding: `11px`,
        color: theme.palette.grey[900],
        width: '46px',
        margin: 0,
        flex: 1,
        border: `1px ${theme.palette.grey[100]}`,
        borderStyle: `none solid`,
        textAlign: 'center',
        MozAppearance: 'textfield',
        '&:disabled': {
            backgroundColor: theme.palette.common.white,
            border: 'none',
        },
        '&::placeholder': {
            color: theme.palette.grey[400],
        },
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
        },
    },
    inputError: {
        boxShadow: `0 0 0 1px ${theme.palette.error.main}`,
        border: `1px solid ${theme.palette.error.main}`,
        margin: theme.spacing(2, 0, 1, 0),
        '&:hover': {
            marginBottom: theme.spacing(1),
        },
        '&:focus': {
            marginBottom: theme.spacing(1),
        },
    },
    error: {
        opacity: 0,
        transition: 'opacity 0.3s',
        color: theme.palette.error.main,
    },
    showError: {
        display: 'block',
        opacity: 1,
        fontSize: '11px',
        fontWeight: 600,
        textAlign: 'right',
        paddingBottom: theme.spacing(1),
    },
    disabledInput: {
        pointerEvents: 'none',
        backgroundColor: `${theme.palette.grey[100]} !important`,
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: `${theme.palette.grey[100]} !important`,
            [`& .${classes.icons}`]: {
                color: `${theme.palette.grey[200]} !important`,
            },
        },
    },
    icons: {
        color: theme.palette.grey[200],
    },
}));
const StyledMinusIcon = styled(MinusIcon) `
  color: ${({ theme }) => theme.palette.grey[200]};
  height: 24px;
  width: 24px;
`;
const StyledPlusIcon = styled(PlusIcon) `
  color: ${({ theme }) => theme.palette.grey[200]};
  height: 24px;
  width: 24px;
`;
const QuantityInput = ({ id = 'quantity-input', label, value, onChange, placeholder, readOnly = false, className = '', maxQuantity, isCheckoutView = false, }) => {
    const { classes, cx } = useStyles();
    const isMaxQuantity = !!maxQuantity && value >= maxQuantity;
    const handleChange = (newValue) => {
        const restrictedQty = maxQuantity && maxQuantity > 0 ? Math.min(maxQuantity, newValue) : newValue;
        onChange(restrictedQty);
    };
    // don't render QTY for prize items in the checkout view
    const isPrize = value === 0 && readOnly;
    if (isCheckoutView && isPrize) {
        return _jsx(_Fragment, {});
    }
    return (_jsx("div", { className: cx(classes.container, className), children: _jsxs("label", { className: classes.label, htmlFor: id, children: [_jsx("div", { children: _jsxs("button", { type: "button", disabled: readOnly || value === 1, onClick: () => handleChange(Math.max(1, value - 1)), className: readOnly || value <= 1 ? classes.disabledInput : '', children: [_jsx(SROnly, { children: "Subtract One" }), _jsx(StyledMinusIcon, {})] }) }), label, _jsx("input", { id: id, name: id, value: value, type: "number", className: classes.input, placeholder: placeholder, onChange: (event) => handleChange(Math.max(1, Number(event.target.value))), disabled: readOnly, autoComplete: "off", max: maxQuantity }), _jsx(Tooltip, { disableFocusListener: !isMaxQuantity, disableTouchListener: !isMaxQuantity, disableHoverListener: !isMaxQuantity, title: maxQuantity ? `This item has a maximum transaction quantity of ${maxQuantity} ` : '', arrow: true, placement: "top", children: _jsx("div", { children: _jsxs("button", { type: "button", onClick: () => handleChange(value + 1), disabled: readOnly || isMaxQuantity, className: readOnly || isMaxQuantity ? classes.disabledInput : '', children: [_jsx(SROnly, { children: "Add One" }), _jsx(StyledPlusIcon, {})] }) }) })] }) }));
};
export default QuantityInput;
