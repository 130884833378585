import { useRef, useState } from 'react';
import useGameModes from '@hooks/API/useGameModes';
import getDisplayText from './helpers/getDisplayText';
import getDisplayIcon from './helpers/getDisplayIcon';
import getFilterOptions from './helpers/getFilterOptions';
const useGameModeFilterState = (gameKey, selectedModes) => {
    const { gameModes } = useGameModes(gameKey);
    const buttonRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const displayText = getDisplayText(gameModes, selectedModes);
    const displayIcon = getDisplayIcon(gameModes, selectedModes);
    const options = getFilterOptions(gameModes, selectedModes);
    return {
        isOpen,
        displayText,
        displayIcon,
        options,
        buttonRef,
        close: () => setIsOpen(false),
        toggleOpen: () => setIsOpen(!isOpen),
    };
};
export default useGameModeFilterState;
