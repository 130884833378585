import { addSeconds, formatDuration, intervalToDuration } from 'date-fns';
const formatDurationFromNow = (totalSeconds, shortFormat) => {
    const { years, months, days, hours, minutes, seconds } = intervalToDuration({
        start: new Date(),
        end: addSeconds(new Date(), totalSeconds),
    });
    const replaceFormat = (text) => {
        return shortFormat
            ? text
                .replace(' years', 'y')
                .replace(' year', 'y')
                .replace(' months', 'm')
                .replace(' month', 'm')
                .replace(' days', 'd')
                .replace(' day', 'd')
                .replace(' hours', 'h')
                .replace(' hour', 'h')
                .replace(' minutes', 'm')
                .replace(' minute', 'm')
                .replace(' seconds', 's')
                .replace(' second', 's')
            : text
                .replace('year', 'Year')
                .replace('month', 'Month')
                .replace('day', 'Day')
                .replace('hour', 'Hour')
                .replace('minute', 'Minute')
                .replace('second', 'Second');
    };
    if (years && years >= 1) {
        // show days + hours
        return replaceFormat(formatDuration({ years, months }));
    }
    if (months && months >= 1) {
        // show days + hours
        return replaceFormat(formatDuration({ months, days }));
    }
    if (days && days >= 1) {
        // show days + hours
        return replaceFormat(formatDuration({ days, hours }));
    }
    if (hours && hours >= 1) {
        // show hours + minutes
        return replaceFormat(formatDuration({ hours, minutes }));
    }
    if (minutes && minutes >= 10) {
        // show hours
        return replaceFormat(formatDuration({ minutes }));
    }
    if (minutes && minutes >= 1) {
        // show minutes + seconds
        return replaceFormat(formatDuration({ minutes, seconds }));
    }
    // show seconds
    return replaceFormat(formatDuration({ seconds }, { zero: true }));
};
export default formatDurationFromNow;
