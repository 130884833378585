import axios from 'axios';
import ErrorWithCause from '@helpers/ErrorWithCause';
import { isAxiosError } from '@helpers/errorHelper';
const loadGameData = async () => {
    var _a;
    try {
        const { data } = await axios.get('/api/game/list/v1');
        return data;
    }
    catch (error) {
        if (isAxiosError(error) && ((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 403) {
            return [];
        }
        throw new ErrorWithCause(error);
    }
};
export default loadGameData;
