import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import LinkButton from '@components/Buttons/LinkButton';
import getOAuthReturnPath from '@helpers/getOAuthReturnPath';
import getSocialMediaServiceOAuthUrlFromType from '@helpers/SocialMedia/getSocialMediaServiceOAuthUrlFromType';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid';
const useStyles = makeStyles()((theme) => ({
    button: {
        '&>span': {
            marginLeft: theme.spacing(1),
        },
        '&>i': {
            color: 'white',
        },
    },
}));
export const ConnectTwitchLink = ({ className, target, onClick }) => {
    const { classes, cx } = useStyles();
    const [loading, setLoading] = useState(false);
    const handleClick = () => {
        setLoading(true);
        if (onClick) {
            onClick();
        }
    };
    const oauthUrl = getSocialMediaServiceOAuthUrlFromType('twitch', getOAuthReturnPath());
    return (_jsxs(LinkButton, { size: "lg", variant: "palette", paletteName: "twitch", href: oauthUrl, className: cx(classes.button, className), onClick: handleClick, target: target, loading: loading, children: [_jsx(ArrowTopRightOnSquareIcon, { width: 24, height: 24 }), _jsxs("span", { children: ["Connect your ", _jsx("span", { "data-ignore": true, children: "Twitch" })] })] }));
};
export default ConnectTwitchLink;
