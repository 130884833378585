import { jsx as _jsx } from "react/jsx-runtime";
import loadable from '@loadable/component';
import { makeStyles } from 'tss-react/mui';
const CaretDown = loadable(() => import('@components/Icons/CaretDown'));
const useStyles = makeStyles()((theme) => ({
    caretDown: {
        height: '8px',
        width: '10px',
        transition: 'transform 0.5s',
        fill: theme.palette.grey[200],
    },
    caretDownOpen: {
        transform: 'rotate(-180deg)',
        fill: theme.palette.grey[900],
    },
}));
const CaretDownRotate = ({ rotate, className }) => {
    const { classes, cx } = useStyles();
    return (_jsx(CaretDown, { className: cx(classes.caretDown, className, {
            [classes.caretDownOpen]: rotate,
        }) }));
};
export default CaretDownRotate;
