import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { useState, useEffect } from 'react';
import { captureException, captureMessage } from '@sentry/react';
/**
 * Gets the login provider that was used for the current session.
 * @returns The login provider as a supported social media type: ('psn', 'google', 'facebook') or 'Email'.
 */
export const getCurrentLoginProvider = async () => {
    var _a, _b, _c;
    const result = await ((_a = firebase.auth().currentUser) === null || _a === void 0 ? void 0 : _a.getIdTokenResult());
    if (result === undefined) {
        return undefined;
    }
    switch (result === null || result === void 0 ? void 0 : result.signInProvider) {
        case 'apple.com':
            return 'apple';
        case 'google.com':
            return 'google';
        case 'facebook.com':
            return 'facebook';
        case 'password':
            return 'Email';
        case 'custom':
            return (_c = (_b = result.claims.custom) === null || _b === void 0 ? void 0 : _b.service_key) !== null && _c !== void 0 ? _c : 'custom';
        default:
            captureMessage(`sign in provider is missing in switch statement: ${result === null || result === void 0 ? void 0 : result.signInProvider}`, {
                extra: { action: 'getCurrentLoginProvider' },
            });
            return undefined;
    }
};
/**
 * Retrieves the current login provider from the user's firebase token.
 * The operation may take time so provides a 'loading' status while retrieving it.
 * @returns The current login provider and/or the status of fetching it
 */
const useCurrentLoginProvider = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [currentLoginProvider, setCurrentLoginProvider] = useState(undefined);
    useEffect(() => {
        const get = async () => {
            setIsLoading(true);
            try {
                const provider = await getCurrentLoginProvider();
                setCurrentLoginProvider(provider);
            }
            catch (error) {
                captureException(error, { extra: { action: 'getCurrentLoginProvider' } });
            }
            finally {
                setIsLoading(false);
            }
        };
        get();
        return () => {
            setCurrentLoginProvider(undefined);
        };
    }, []);
    return {
        isLoading,
        currentLoginProvider,
    };
};
export default useCurrentLoginProvider;
