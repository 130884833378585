const getGameServicePalette = (gameServiceKey) => {
    switch (gameServiceKey) {
        case 'riot':
            return 'riot';
        case 'psn':
            return 'playstation';
        case 'supercell':
            return 'brawlStars';
        default:
            return 'grey';
    }
};
export default getGameServicePalette;
