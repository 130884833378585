/**
 * Get a tournament banner image for the box view.
 *
 * @param {TournamentData} tournament A valid tournament
 * @returns URL string of tournament banner_small or ads with position banner
 */
const getTournamentBoxBannerImage = (tournament) => {
    var _a, _b, _c;
    if (tournament === null || tournament === void 0 ? void 0 : tournament.banner_small) {
        return tournament.banner_small;
    }
    if (tournament === null || tournament === void 0 ? void 0 : tournament.ads) {
        if (Array.isArray(tournament.ads)) {
            return ((_a = tournament.ads.find((ad) => ad.position === 'banner')) === null || _a === void 0 ? void 0 : _a.image_url) || null;
        }
        return ((_c = (_b = tournament.ads) === null || _b === void 0 ? void 0 : _b.banner) === null || _c === void 0 ? void 0 : _c.image_url) || null;
    }
    return null;
};
export default getTournamentBoxBannerImage;
