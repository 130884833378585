import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import LoadingModal from "@components/Modals/LoadingModal/LoadingModal";
import { useUpdateUserState, useUserState } from '@context/UserContext';
import isRecentlyAuthenticated from '@helpers/isRecentlyAuthenticated';
import useGetRecaptureEnabled from '@components/Modals/AuthenticationModal/Hooks/useGetRecaptureEnabled';
import useCurrentLoginProvider from '@components/Modals/AuthenticationModal/Hooks/useCurrentLoginProvider';
import ControlledReauthenticateModal from './ControlledReauthenticateModal';
const ReauthenticateModal = ({ handleClose, children, handleSuccess }) => {
    const { userDetails } = useUserState();
    const { refresh: refreshUserState } = useUpdateUserState();
    const { isLoading: isLoadingCurrentLoginProvider, currentLoginProvider } = useCurrentLoginProvider();
    const lastLogin = userDetails === null || userDetails === void 0 ? void 0 : userDetails.lastLogin;
    // only request recaptcha if there is a valid lastLogin
    const enableRecapture = Boolean(lastLogin && !isRecentlyAuthenticated(new Date(lastLogin)) && currentLoginProvider === 'Email');
    const { isLoading: isLoadingRecapture } = useGetRecaptureEnabled(enableRecapture);
    const handleFirebaseAuthSuccess = () => {
        // Invalidate user cache to get new user last login time
        refreshUserState();
        if (handleSuccess) {
            handleSuccess();
        }
    };
    if (isLoadingRecapture || isLoadingCurrentLoginProvider) {
        return _jsx(LoadingModal, { "data-testid": "loadingModal", show: true, loadingText: "Loading..." });
    }
    // Must be logged in with a known login provider
    if (!userDetails || !lastLogin || !currentLoginProvider) {
        return _jsx(_Fragment, {});
    }
    // Bypass re-authentication if recently authenticated
    // Bypass re-authentication if the user did not log in with email/password
    if (currentLoginProvider !== 'Email' || isRecentlyAuthenticated(new Date(lastLogin))) {
        if (handleSuccess) {
            // If there is no need to reauthenticate the modal won't show - but that should
            // count as 'success' - so we need to call the handle success function if it was provided
            handleSuccess();
        }
        return children || _jsx(_Fragment, {});
    }
    return _jsx(ControlledReauthenticateModal, { show: true, handleSuccess: handleFirebaseAuthSuccess, handleClose: handleClose });
};
export default ReauthenticateModal;
