import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/prop-types */
import loadable from '@loadable/component';
import getGamePalette from '@helpers/getGamePalette';
import BoxButton from '../Buttons/BoxButton';
import { styled } from '@mui/material';
import CrossCloseIcon from '../UI/CrossCloseIcon';
import GenericModal from '@components/Modals/GenericModal';
const ErrorFill = loadable(() => import('@components/UI/Icon/Icons/ErrorFill'));
const SuccessButton = styled(BoxButton) `
  align-self: stretch;
`;
const StyledHeader = styled('div') `
  align-self: flex-end;
`;
const StyledMessageHeader = styled('h2') `
  margin-top: 36px;
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 18px;
`;
const StyledModalBody = styled('div') `
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;
const HeroContent = styled('div') `
  margin-bottom: 36px;
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.grey[400]};
`;
const AddGameDisallowed = ({ open, resetModal, onClose }) => {
    const handleClose = () => {
        if (onClose) {
            onClose(false);
        }
    };
    return (_jsxs(GenericModal, { show: open, size: 'small', disableBackdropClick: false, onClose: handleClose, mobileFullScreen: true, onExited: resetModal, children: [_jsx(StyledHeader, { children: _jsx(CrossCloseIcon, { action: handleClose }) }), _jsxs(StyledModalBody, { children: [_jsx(ErrorFill, { width: 138, color: 'error' }), _jsx(StyledMessageHeader, { children: "This action is prohibited while you are suspended" }), _jsx(HeroContent, { children: "Your account is currently suspended and you are restricted from managing your game accounts. Please wait for your suspension to be lifted to perform this action." }), _jsx(SuccessButton, { onClick: handleClose, variant: getGamePalette('primary'), size: "md", children: "Close" })] })] }));
};
export default AddGameDisallowed;
