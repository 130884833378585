import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
import GenericModal from '@components/Modals/GenericModal';
import CoreButton from '@components/Buttons/CoreButton';
import ModalImageHeader from '@components/Modals/components/ModalImageHeader';
import ModalTextContent from '@components/Modals/components/ModalTextContent';
const useStyles = makeStyles()((theme) => ({
    heading: {
        position: 'relative',
    },
    headerImage: {
        width: '100%',
        height: '100%',
    },
    button: {
        margin: '24px',
        backgroundColor: theme.palette.swagbucksDark.main,
        color: 'white',
    },
    bgStyle: {
        backgroundColor: theme.palette.common.black,
        color: 'white',
    },
    content: {
        backgroundColor: theme.palette.common.black,
        color: 'white',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
        '& h2': {
            color: 'white',
            [theme.breakpoints.down('md')]: {
                display: 'none',
            },
        },
    },
}));
const SwagbucksEUTournamentModal = ({ show, tournamentLink, className, handleClose, }) => {
    const { classes, cx } = useStyles();
    return (_jsxs(GenericModal, { className: cx(className, classes.bgStyle), show: show, mobileFullScreen: false, children: [_jsx(ModalImageHeader, { imageUrl: "/img/modals/swagbucks-eu-tournament-heading.jpg", onClose: handleClose }), _jsx(ModalTextContent, { title: _jsxs(_Fragment, { children: ["Enter the 5,000,000 Coin ", _jsx("span", { "data-ignore": true, children: "Swagbucks" }), " tournament"] }), paragraph: "This free entry event is exclusive to your region and is currently live! Join now and climb the leaderboard", className: classes.content }), _jsx(CoreButton, { variant: "swagbucksDark", to: tournamentLink, className: classes.button, children: "Enter for Free" })] }));
};
export default SwagbucksEUTournamentModal;
