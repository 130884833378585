import { styled } from '@mui/material/styles';
import TournamentAwardRankCell from '@components/Tournament/TournamentAwardRankCell';
import rightBorder from "./RightBorder";
const StyledTournamentAwardRankCell = styled(TournamentAwardRankCell) `
  justify-content: center;
  padding: 0 0 0 20px;
  margin: 8px 0;
  ${(props) => rightBorder(props)}
`;
export default StyledTournamentAwardRankCell;
