import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classnames from 'classnames';
import { styled } from '@mui/material';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import TournamentMetric from '../../domain/Tournament/components/TournamentMetric';
import GamesPlayed from './GamesPlayed';
import ProgressDots from '../UI/ProgressDots';
import TournamentThresholdPosition from './TournamentThresholdPosition';
import { isTournamentFinished } from '@domain/Tournament/helpers/tournamentUtils';
const StyledTournamentMetric = styled(TournamentMetric) `
  font-size: 16px;
  color: ${({ theme }) => theme.palette.grey[900]};
  font-weight: 600;
  padding: ${({ theme }) => theme.spacing(0, 3)};
  text-align: center;

  & img {
    height: 20px;
  }

  &.left {
    text-align: left;
  }

  &.right {
    text-align: right;
  }
`;
const StyledProgressDots = styled(ProgressDots) `
  margin-top: 13px;
`;
const QualificationText = styled('p') `
  > span {
    color: ${(props) => props.theme.palette.primary.main};
  }

  text-align: left;
`;
const EligibilityDisplay = styled('span', { shouldForwardProp: (prop) => prop != 'isEligible' }) `
  display: flex;
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.grey[900]};
  align-items: center;
  justify-content: space-between;
  color: ${({ isEligible, theme }) => (isEligible ? theme.palette.primary.main : undefined)};
`;
const NotQualifiedText = styled('div') `
  fill: ${({ theme }) => theme.palette.grey[400]};
  color: ${({ theme }) => theme.palette.grey[400]};
  font-size: 12px;
  display: flex;

  & svg {
    width: 18px;
    margin-right: ${({ theme }) => theme.spacing(0.5)};
  }
`;
const StyledQualifiedText = styled('span') `
  color: ${({ theme }) => theme.palette.primary.main};
  display: inline-flex;
`;
const getEligibilityText = (tournament) => {
    var _a, _b;
    const currentGames = (_b = (_a = tournament.user_entry) === null || _a === void 0 ? void 0 : _a.gamesPlayed) !== null && _b !== void 0 ? _b : 0;
    const requiredGames = tournament.score_template.num_games || 1;
    return _jsx(StyledProgressDots, { current: currentGames, total: requiredGames });
};
const getEligibilityDisplay = (isEligible, currentGames, requiredGames) => {
    return (_jsxs(_Fragment, { children: [_jsx(GamesPlayed, { gamesPlayed: currentGames, requiredGames: requiredGames }), _jsx(NotQualifiedText, { children: isEligible ? (_jsxs(StyledQualifiedText, { children: [_jsx(CheckCircleIcon, {}), " Qualified"] })) : (_jsxs(_Fragment, { children: [_jsx(XCircleIcon, {}), " Not Qualified"] })) })] }));
};
const TournamentEligibility = ({ tournament, align = 'center', className, userEntryBracketPosition, payoutTemplateInfo, }) => {
    var _a, _b, _c, _d, _e, _f;
    const isEligible = (_b = (_a = tournament.user_entry) === null || _a === void 0 ? void 0 : _a.eligible) !== null && _b !== void 0 ? _b : false;
    const currentGames = (_d = (_c = tournament.user_entry) === null || _c === void 0 ? void 0 : _c.gamesPlayed) !== null && _d !== void 0 ? _d : 0;
    const requiredGames = tournament.score_template.num_games || 1;
    const currentUserScore = parseInt((_f = (_e = tournament.user_entry) === null || _e === void 0 ? void 0 : _e.score) !== null && _f !== void 0 ? _f : '0', 10);
    const isFinished = isTournamentFinished(tournament.start_time, tournament.length_minutes);
    if (isEligible && (payoutTemplateInfo === null || payoutTemplateInfo === void 0 ? void 0 : payoutTemplateInfo.payoutTemplateType) === 'threshold') {
        return (_jsx(TournamentThresholdPosition, { brackets: payoutTemplateInfo.currentBrackets, userEntryBracketPosition: userEntryBracketPosition !== null && userEntryBracketPosition !== void 0 ? userEntryBracketPosition : null, currentUserScore: currentUserScore }));
    }
    const caption = (_jsxs(_Fragment, { children: [getEligibilityText(tournament), !isEligible && !isFinished && (_jsxs(QualificationText, { children: ["Once you\u2019ve qualified you\u2019ll receive a score and rank in this tournament. Only", ' ', _jsx("span", { children: Math.max(requiredGames - currentGames, 0) }), " games to go."] }))] }));
    return (_jsx(StyledTournamentMetric, { id: `${tournament.id}-entry-metric`, caption: caption, className: classnames(className, align), ariaLabel: "Eligibility", children: _jsx(EligibilityDisplay, { isEligible: isEligible, children: getEligibilityDisplay(isEligible, currentGames, requiredGames) }) }));
};
export default TournamentEligibility;
