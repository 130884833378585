import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid';
import { Spinner } from '@components/Icons/Spinner';
const useStyles = makeStyles()((theme, { paletteName = 'primary' }) => {
    return {
        buttonBase: {
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontFamily: theme.typography.fontFamily,
            fontSize: '12px',
            border: 'none',
            borderRadius: theme.shape.borderRadius,
            '&:hover': {
                color: 'currentColor',
            },
            '& i': {
                marginRight: '6px',
            },
        },
        success: {
            background: theme.palette.success.main,
            color: theme.palette.common.white,
            '&:hover': {
                background: theme.palette.success.dark,
                color: theme.palette.common.white,
            },
        },
        brand: {
            background: theme.palette.primary.main,
            color: theme.palette.common.white,
            '&:hover': {
                background: theme.palette.primary.dark,
                color: theme.palette.common.white,
            },
        },
        danger: {
            background: theme.palette.error.main,
            color: theme.palette.common.white,
            '&:hover': {
                background: theme.palette.error.dark,
                color: theme.palette.common.white,
            },
        },
        gray: {
            background: theme.palette.grey[200],
            color: theme.palette.grey[900],
            '&:hover': {
                background: theme.palette.grey[300],
                color: theme.palette.grey[900],
            },
        },
        palette: {
            background: theme.palette[paletteName].main,
            color: theme.palette.common.white,
            '&:visited': {
                color: theme.palette.common.white,
            },
            '&:hover': {
                background: theme.palette[paletteName].dark,
                color: theme.palette.common.white,
            },
        },
        disabled: {
            opacity: 0.5,
            cursor: 'not-allowed',
            pointerEvents: 'none',
        },
        block: {
            width: '100%',
        },
        sm: {
            padding: '6px 16px',
            // 35px
        },
        base: {
            padding: '8px 24px',
            // 40px
        },
        md: {
            padding: '12px 32px',
            // 45px
        },
        lg: {
            padding: '14px 40px',
            height: '50px',
        },
    };
});
const StyledSpinner = styled(Spinner) `
  width: 1.2em;
  height: 1.2em;
`;
const StyledArrowTopRightOnSquareIcon = styled(ArrowTopRightOnSquareIcon) `
  width: 1.2em;
  height: 1.2em;
  margin-right: 6px;
`;
const LinkButton = ({ variant = 'brand', children, href, className = '', loading = false, disable = false, label, size = 'base', id = '', 'data-testid': testID, paletteName, block = false, target = '', component = null, isExternalLink, onClick = () => undefined, }) => {
    const { classes, cx } = useStyles({ paletteName });
    if (component === 'Link') {
        return (_jsx(Link, { className: cx(classes.buttonBase, classes[size], variant !== 'none' && classes[variant], className, disable && classes.disabled, block && classes.block), "aria-label": label, id: id, "data-testid": testID, to: href, target: target, rel: "noopener noreferrer", onClick: onClick, children: loading ? _jsx(StyledSpinner, { title: "loading" }) : children }));
    }
    return (_jsxs("a", { className: cx(classes.buttonBase, classes[size], variant !== 'none' && classes[variant], className, disable && classes.disabled, block && classes.block), "aria-label": label, id: id, "data-testid": testID, href: href, target: target, rel: "noopener noreferrer", onClick: onClick, children: [isExternalLink && !loading && _jsx(StyledArrowTopRightOnSquareIcon, { viewBox: "0 0 24 24" }), loading ? _jsx(StyledSpinner, { title: "loading" }) : children] }));
};
export default LinkButton;
