import { jsx as _jsx } from "react/jsx-runtime";
import { Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import QuestionCircle from './Icon/Icons/QuestionCircle';
const useStyles = makeStyles()((theme) => ({
    tooltip: {
        background: theme.palette.grey[900],
        color: theme.palette.common.white,
        fontSize: '10px',
        margin: '0 0',
    },
    popper: {
        zIndex: 9999999 + 10, // Above GenericModal
    },
}));
const HelpToolTip = ({ title }) => {
    const { classes } = useStyles();
    return (_jsx(Tooltip, { title: title, classes: classes, placement: "top", arrow: true, children: _jsx("span", { children: _jsx(QuestionCircle, { width: 16, color: "primary" }) }) }));
};
export default HelpToolTip;
