import { styled } from '@mui/system';
const shouldForwardProp = (prop) => {
    const disallowedHtmlAttributes = [
        'wrap',
        'flexDirection',
        'flexBasis',
        'flexDirection',
        'flexGrow',
        'fillRule',
        'align',
        'textAlign',
        'justify',
    ];
    return !disallowedHtmlAttributes.includes(prop);
};
const Flex = styled('div', { shouldForwardProp }) `
  display: flex;
  text-align: ${({ textAlign = 'left' }) => textAlign};
  flex-direction: ${({ flexDirection = 'row' }) => flexDirection};
  flex-basis: ${({ flexBasis = 'auto' }) => flexBasis};
  flex-wrap: ${({ wrap = 'nowrap' }) => wrap};
  flex-grow: ${({ flexGrow = 0 }) => flexGrow};
  gap: ${({ theme, gap = 1 }) => theme.spacing(gap)};
  justify-content: ${({ justify = 'normal' }) => justify};
  align-items: ${({ align = 'normal' }) => align};
`;
export default Flex;
