import { browserPopupRedirectResolver, getAuth, OAuthProvider, signInWithPopup } from 'firebase/auth';
import { captureException } from '@sentry/react';
import doTrackEvent from '@components/Analytics/doTrackEvent';
import isFirebaseAuthError from './isFirebaseAuthError';
/**
 * Prompt the user to authenticate with Apple
 *
 * @param props The component props
 * @param props.authenticationAction Indicates if this is a login or sign up attempt
 * @param props.scopes The scopes to request from the user
 * @returns UserCredential Authenticated firebase user
 * @throws unknown
 */
const authenticateWithApple = async ({ authenticationAction, scopes, }) => {
    try {
        const auth = getAuth();
        const provider = new OAuthProvider('apple.com');
        if (scopes) {
            scopes.forEach((scope) => provider.addScope(scope));
        }
        const signInResult = await signInWithPopup(auth, provider, browserPopupRedirectResolver);
        doTrackEvent({
            type: 'authenticate_with_provider',
            authenticationAction,
            provider: 'apple',
            userId: signInResult.user.uid,
        });
        return signInResult;
    }
    catch (error) {
        if (isFirebaseAuthError(error)) {
            // Only capture unexpected errors
            if (error.code !== 'auth/popup-closed-by-user' && error.code !== 'auth/cancelled-popup-request') {
                captureException(error);
            }
        }
        throw error;
    }
};
export default authenticateWithApple;
