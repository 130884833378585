import { useUserState } from '@context/UserContext';
import { useFeature } from 'flagged';
import { useLocation } from 'react-router-dom';
import useSelectedPlatform from './useSelectedPlatform';
const useRootRedirectUrl = () => {
    const { authenticated } = useUserState();
    const isPlayStationPlatform = navigator.userAgent.includes('PlayStation');
    const isPlatformFirstEnabled = Boolean(useFeature('enable_platform_first'));
    const { platformSlug } = useSelectedPlatform();
    const location = useLocation();
    if (!authenticated) {
        return '/';
    }
    if (isPlatformFirstEnabled && platformSlug) {
        return `/${platformSlug}/compete`;
    }
    if (isPlatformFirstEnabled && !platformSlug) {
        return '/platform';
    }
    if (isPlatformFirstEnabled && isPlayStationPlatform && location.pathname === '/') {
        return '/playstation';
    }
    return '/tournaments';
};
export default useRootRedirectUrl;
