import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
import SROnly from './SROnly';
const useStyles = makeStyles()((theme, { progress }) => ({
    entriesProgressBar: {
        marginTop: '10px',
        background: theme.palette.grey[100],
        width: '100%',
        height: '5px',
        borderRadius: '3px',
        '&:before': {
            backgroundColor: theme.palette.primary.main,
            borderRadius: '3px',
            width: `${progress}%`,
            height: '5px',
            content: "''",
            display: 'block',
        },
    },
}));
const ProgressBar = ({ currentValue, maxValue = 100, className }) => {
    const progress = currentValue > 0 ? Math.round((currentValue / maxValue) * 100) : 0;
    const { classes, cx } = useStyles({ progress });
    return (_jsx("div", { className: cx(classes.entriesProgressBar, className), children: _jsx(SROnly, { children: _jsx("progress", { max: maxValue, value: currentValue }) }) }));
};
export default ProgressBar;
