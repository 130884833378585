import { captureException } from '@sentry/react';
const trackHotjarEvent = (event) => {
    var _a;
    try {
        (_a = window.hj) === null || _a === void 0 ? void 0 : _a.call(window, 'event', event);
    }
    catch (error) {
        captureException(error);
    }
};
export default trackHotjarEvent;
