import { useEffect } from 'react';
/**
 * @param state Add Game context state
 * @param onSelectedRegionChanged Effect invoked when the selected region has changed
 */
const useSelectedRegionChanged = (state, onSelectedRegionChanged) => {
    var _a, _b, _c;
    // Set the selected region detail
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g;
        if (!((_a = state === null || state === void 0 ? void 0 : state.connectPlatform) === null || _a === void 0 ? void 0 : _a.regions)) {
            return;
        }
        if ((state === null || state === void 0 ? void 0 : state.connectGameRegionInfo) &&
            (((_b = state === null || state === void 0 ? void 0 : state.connectGameRegion) === null || _b === void 0 ? void 0 : _b.regionCode) !== ((_c = state === null || state === void 0 ? void 0 : state.connectGameRegionInfo) === null || _c === void 0 ? void 0 : _c.regionCode) ||
                ((_d = state === null || state === void 0 ? void 0 : state.connectGameRegion) === null || _d === void 0 ? void 0 : _d.region_code) !== ((_e = state === null || state === void 0 ? void 0 : state.connectGameRegionInfo) === null || _e === void 0 ? void 0 : _e.region_code))) {
            onSelectedRegionChanged((_g = (_f = state === null || state === void 0 ? void 0 : state.connectPlatform) === null || _f === void 0 ? void 0 : _f.regions.find((region) => {
                var _a, _b;
                return region.regionCode === ((_a = state === null || state === void 0 ? void 0 : state.connectGameRegionInfo) === null || _a === void 0 ? void 0 : _a.regionCode) ||
                    region.region_code === ((_b = state === null || state === void 0 ? void 0 : state.connectGameRegionInfo) === null || _b === void 0 ? void 0 : _b.region_code);
            })) !== null && _g !== void 0 ? _g : null);
        }
    }, [
        JSON.stringify((_a = state === null || state === void 0 ? void 0 : state.connectPlatform) === null || _a === void 0 ? void 0 : _a.regions),
        (_b = state === null || state === void 0 ? void 0 : state.connectGameRegionInfo) === null || _b === void 0 ? void 0 : _b.regionCode,
        (_c = state === null || state === void 0 ? void 0 : state.connectGameRegionInfo) === null || _c === void 0 ? void 0 : _c.region_code,
    ]);
};
export default useSelectedRegionChanged;
