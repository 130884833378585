import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CoreButton from "@components/Buttons/CoreButton";
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        '&>button': {
            marginTop: theme.spacing(3),
        },
        '&>span': {
            fontSize: 14,
            fontWeight: 500,
        },
    },
    connectButton: {
        height: '44px',
    },
}));
const NoLinkedAccounts = ({ handleConnectAccounts, isCurrentUser, userHandle }) => {
    const { classes } = useStyles();
    const addGameButtonLabel = '+ Connect Accounts';
    return (_jsxs("div", { className: classes.container, children: [_jsx("h2", { children: "No accounts found" }), isCurrentUser && _jsx("span", { children: "You haven't connected any accounts to your Repeat profile" }), !isCurrentUser && userHandle && _jsxs("span", { children: [userHandle, " hasn't connected any accounts to their Repeat profile"] }), !isCurrentUser && !userHandle && _jsx("span", { children: "This user hasn't connected any accounts to their Repeat profile" }), isCurrentUser && (_jsx(CoreButton, { type: "button", onClick: () => handleConnectAccounts(addGameButtonLabel), className: classes.connectButton, children: addGameButtonLabel }))] }));
};
export default NoLinkedAccounts;
