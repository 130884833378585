// Add more service keys to this array as support is added
export const SERVICE_KEYS = ['psn'];
export const isServiceKey = (maybeServiceKey) => {
    if (!maybeServiceKey) {
        return false;
    }
    return SERVICE_KEYS.includes(maybeServiceKey);
};
export const serviceProperties = {
    psn: { displayName: 'PlayStation Network' },
};
