export const convertToTreeOption = (gameRank) => ({
    id: gameRank.gameRankKey,
    name: gameRank.displayName,
    iconUrl: gameRank.iconUrl,
});
export const convertToTreeOptions = (gameRanks) => !gameRanks
    ? []
    : gameRanks
        .sort((a, b) => b.position - a.position)
        .reduce((rootOption, gameRankItem) => {
        if (gameRankItem.groupKey) {
            let group = rootOption.find((option) => gameRankItem.groupKey === option.id);
            if (!group) {
                group = {
                    id: gameRankItem.groupKey,
                    name: gameRankItem.displayGroup,
                    iconUrl: gameRankItem.iconUrl,
                    children: [],
                };
                rootOption.push(group);
            }
            if (!group.children)
                group.children = [];
            group.children.push(convertToTreeOption(gameRankItem));
        }
        else {
            rootOption.push(convertToTreeOption(gameRankItem));
        }
        return rootOption;
    }, []);
