import axios from 'axios';
import ErrorWithCause from '@helpers/ErrorWithCause';
const getSuggestedTournaments = async (gameKey, limit) => {
    try {
        const response = await axios.get('/api/tournaments/v1/suggested', {
            params: {
                gameKey,
                limit,
            },
        });
        return response.data;
    }
    catch (error) {
        throw new ErrorWithCause(error);
    }
};
export default getSuggestedTournaments;
