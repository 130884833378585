import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import LoadingLogo from '@components/Modals/LoadingModal/Components/LoadingLogo';
import { useUserState } from '@context/UserContext';
import { useLocalAppContext } from '@context/LocalAppContext';
import usePlatformGamesList from '@hooks/usePlatformGamesList';
import { styled } from '@mui/material/styles';
import { PlusCircleIcon } from '@heroicons/react/20/solid';
import { Divider, useMediaQuery, useTheme } from '@mui/material';
import GameIconButton, { StyledCoreButton } from '@components/Buttons/GameIconButton';
import useSelectedPlatform from '@hooks/useSelectedPlatform';
import { useFeature } from 'flagged';
import { useLocation } from 'react-router-dom';
import useTrackEvent from '@hooks/useTrackEvent';
const PlatformGamesMenuWrapper = styled('div') `
  position: sticky;
  width: 68px;
  height: calc(100vh - ${({ theme }) => theme.layout.headerDesktopHeight}px);
  top: ${({ theme }) => theme.layout.headerDesktopHeight}px;
  background-color: ${({ theme }) => theme.palette.common.white};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(1)};
  align-items: center;
  border-right: 1px solid #e0e7ed;
  border-top: 1px solid #e0e7ed;
`;
const StyledDivider = styled(Divider) `
  margin: ${({ theme }) => theme.spacing(1)};
  width: 80%;
  border-color: #e0e7ed;
`;
const PlatformGamesMenu = () => {
    const { authenticated } = useUserState();
    const { isLoading, userConnectedGames, userUnconnectedGames, error } = usePlatformGamesList();
    const { pathname } = useLocation();
    const theme = useTheme();
    const { platformSlug: selectedPlatformSlug } = useSelectedPlatform();
    const isPlatformFirstEnabled = !!useFeature('enable_platform_first');
    const isMobile = useMediaQuery(theme.breakpoints.down('header'));
    const { openModal } = useLocalAppContext();
    const trackEvent = useTrackEvent();
    const invalidPaths = ['', '/'];
    const isInfluencerRoute = pathname.startsWith('/influencer-page/') || pathname.startsWith('/ip/');
    const showPlatformMenu = !isMobile &&
        isPlatformFirstEnabled &&
        selectedPlatformSlug &&
        !invalidPaths.includes(pathname) &&
        !isInfluencerRoute;
    const addGameButtonLabel = 'Add game';
    const handleShowAddGameModal = () => {
        openModal({ type: 'add-game', filterByPlatform: true, onClose: () => { } });
        trackEvent({
            type: 'game_linking',
            data: {
                eventName: 'initiate game linking',
                label: addGameButtonLabel,
                moduleName: 'game linking',
                position: 0,
            },
        });
    };
    if (!showPlatformMenu || error) {
        return _jsx(_Fragment, {});
    }
    if (showPlatformMenu && isLoading) {
        return (_jsx(PlatformGamesMenuWrapper, { children: _jsx(LoadingLogo, {}) }));
    }
    return (_jsxs(PlatformGamesMenuWrapper, { children: [authenticated && (_jsxs(_Fragment, { children: [_jsx(StyledCoreButton, { variant: "grey", appearance: "subdued", onClick: handleShowAddGameModal, children: _jsx(PlusCircleIcon, { title: addGameButtonLabel }) }), userConnectedGames.map((game) => (_jsx(GameIconButton, { game: game }, `connected-${game.gameKey}`))), userUnconnectedGames.length > 0 && _jsx(StyledDivider, { orientation: "horizontal" })] })), userUnconnectedGames.map((game) => (_jsx(GameIconButton, { game: game }, `unconnected-${game.gameKey}`)))] }));
};
export default PlatformGamesMenu;
