import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { Dialog, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import getModalWidth from './helpers/getModalWidth';
import SheetModal from './SheetModal';
const useStyles = makeStyles()((theme) => ({
    modal: {
        zIndex: `${theme.zIndex.genericModal} !important`, // to overlap the zendesk support icon
    },
    modalChildrenWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    small: {
        width: getModalWidth('small'),
    },
    medium: {
        width: getModalWidth('medium'),
    },
    large: {
        width: getModalWidth('large'),
    },
    extraLarge: {
        width: getModalWidth('extraLarge'),
    },
}));
const GenericModal = ({ children, show, className, size = 'medium', 'data-testid': testID, disableBackdropClick = false, disableEscapeKeyDown = false, onClose = () => undefined, ariaLabelledBy, ariaDescribedBy, onExited, mobileFullScreen = true, mobileBottomSheet = false, }) => {
    const { classes, cx } = useStyles();
    const theme = useTheme();
    const isFullWidth = useMediaQuery(theme.breakpoints.down('sm'));
    const widthStyle = classes[size];
    if (isFullWidth && mobileBottomSheet) {
        return (_jsx(SheetModal, { onClose: onClose, isOpen: show, children: _jsx(_Fragment, { children: children }) }));
    }
    const handleClose = (event, reason) => {
        if (disableBackdropClick && reason === 'backdropClick') {
            return;
        }
        onClose(event);
    };
    return (_jsx(Dialog, { open: show, onClose: handleClose, TransitionProps: { onExited }, closeAfterTransition: true, classes: {
            root: classes.modal,
            paper: cx(classes.modalChildrenWrapper, className, {
                [widthStyle]: !isFullWidth || !mobileFullScreen,
            }),
        }, className: cx(classes.modal), disableEscapeKeyDown: disableEscapeKeyDown, maxWidth: false, "data-testid": testID, fullScreen: isFullWidth && mobileFullScreen, "aria-labelledby": ariaLabelledBy, "aria-describedby": ariaDescribedBy, children: children }));
};
export default GenericModal;
