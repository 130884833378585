import { useEffect, useState } from 'react';
/**
 * Simple hook to help track appearing/dissapearing modals.
 * Does not trigger a tracking event on initial render.
 *
 * @param visible is modal currently visible
 * @param onChange callback function that triggers every time modal appears/dissappears
 */
const useTrackModalVisibilityEvent = (visible, onChange) => {
    const [previousState, setPreviousState] = useState();
    useEffect(() => {
        if (previousState !== undefined) {
            onChange(visible);
        }
        setPreviousState(visible);
    }, [visible]);
};
export default useTrackModalVisibilityEvent;
