const useThresholdBracketBreakdown = (brackets) => {
    let lastBracketScore = 0;
    return brackets.map((payoutTemplateBracket, index) => {
        const rankDisplay = index === 0
            ? `${payoutTemplateBracket.scoreThreshold}+`
            : `${lastBracketScore - 1}-${payoutTemplateBracket.scoreThreshold}`;
        lastBracketScore = payoutTemplateBracket.scoreThreshold;
        const { rewardItems } = payoutTemplateBracket;
        return {
            ...payoutTemplateBracket,
            rankDisplay,
            prize: {
                payoutTemplateSlug: payoutTemplateBracket.payoutTemplateSlug,
                bracketPosition: payoutTemplateBracket.bracketPosition,
                rewardItems: rewardItems,
                totalValue: payoutTemplateBracket.totalValue,
            },
        };
    });
};
export default useThresholdBracketBreakdown;
