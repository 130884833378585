import axios from 'axios';
import ErrorWithCause from '@helpers/ErrorWithCause';
const getTournaments = async (filters, pageNumber, resultsPerPage) => {
    try {
        const { data } = await axios.get('/api/tournaments/v1', {
            params: {
                filters: JSON.stringify(filters),
                pageNumber,
                resultsPerPage,
            },
        });
        return data;
    }
    catch (error) {
        throw new ErrorWithCause(error);
    }
};
export default getTournaments;
