import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { styled } from '@mui/system';
const HelpContainer = styled('div') `
  & > button {
    font-size: 11px;
    font-weight: 600;
    color: ${({ theme }) => theme.palette.primary.main};
    &:hover {
      text-decoration: underline;
    }
    background: transparent;
    border: none;
    padding: 0;
  }
  & > p {
    font-size: 11px;
    font-weight: 600;
    color: ${({ theme }) => theme.palette.grey[400]};
    margin-top: ${({ theme }) => theme.spacing(1)};
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }
`;
/**
 * Simple text help display
 * @param param0.label Text to display on the toggle button
 * @param props.helpContent Content to display when help toggle is open
 */
const HelpTextContent = ({ label, helpContent }) => {
    const [showHelpContent, setShowHelpContent] = useState(false);
    return (_jsxs(HelpContainer, { children: [_jsx("button", { type: "button", onClick: () => setShowHelpContent(!showHelpContent), "aria-label": label, children: label }), showHelpContent && _jsx("p", { children: helpContent })] }));
};
export default HelpTextContent;
