import firebase from 'firebase/compat/app';
import { useEffect, useState } from 'react';
import postFirebaseIdToken from '@helpers/postFirebaseIdToken';
import { captureException } from '@sentry/react';
import ErrorWithCause from '@helpers/ErrorWithCause';
const idTokenChangedListener = async (user) => {
    if (user) {
        const idTokenResult = await user.getIdTokenResult();
        await postFirebaseIdToken(idTokenResult.token);
    }
};
/**
 * Hook to subscribe to the firebase onIdTokenChanged event
 * to post firebase id token to the authCookie endpoint
 * if firebase auth is enabled. Returns whether the check (of user logged in or not),
 * is invoked.
 *
 * @returns FirebaseAppState with props:
 * - isFirebaseReady - Is firebase ready to be checked for id token
 */
const usePostFirebaseTokenOnChange = () => {
    const [isFirebaseReady, setIsFirebaseReady] = useState(false);
    const auth = firebase.auth();
    useEffect(() => {
        const unsubscribeFirebase = auth.onIdTokenChanged(() => {
            setIsFirebaseReady(true);
            idTokenChangedListener(auth.currentUser).catch((e) => {
                const error = new ErrorWithCause(e);
                captureException(e, { extra: { function: 'unsubscribeFirebase' } });
                throw error;
            });
        });
        return unsubscribeFirebase;
    }, [auth]);
    return {
        isFirebaseReady,
    };
};
export default usePostFirebaseTokenOnChange;
