import { useEffect } from 'react';
import { useAddGameContext } from '../context/AddGameProvider';
import { setConnectGameServiceAction, setConnectPlatformAction, setConnectRegionAction, } from '../context/AddGameStepsActions';
/**
 * Selects the first available game service and platform as the default
 * in the game context. This is used when games only support one game service and game platform
 */
const useSetDefaultGameService = () => {
    const { dispatch, availableGameServices } = useAddGameContext();
    useEffect(() => {
        if (availableGameServices) {
            const defaultGameService = availableGameServices.gameServices[0];
            const defaultPlatform = defaultGameService.platforms[0];
            const defaultRegion = defaultPlatform.regions[0];
            dispatch(setConnectGameServiceAction(defaultGameService));
            dispatch(setConnectPlatformAction(defaultPlatform));
            dispatch(setConnectRegionAction(defaultRegion));
        }
    }, [availableGameServices]);
};
export default useSetDefaultGameService;
