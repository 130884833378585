import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ModalContent from '@components/Modals/components/ModalContent';
import useGamesList from '@hooks/API/useGamesList';
import usePlatformGamesList from '@hooks/usePlatformGamesList';
import useSelectedPlatform from '@hooks/useSelectedPlatform';
import { useFeature } from 'flagged';
import NativeSelect from '@components/Fields/NativeSelect';
import { styled } from '@mui/material';
import { useEffect } from 'react';
import useTrackEvent from '@hooks/useTrackEvent';
const StyledNativeSelect = styled(NativeSelect) `
  & select {
    min-width: 280px;
    font-size: 15px;
  }
  & select option {
    background-color: white;
    color: ${({ theme }) => theme.palette.grey[400]};
  }
`;
const SelectGames = ({ onSelect, filterByPlatform, handleGameStepChange }) => {
    const { games } = useGamesList();
    const isPlatformFirstEnabled = !!useFeature('enable_platform_first');
    const { platformSlug } = useSelectedPlatform();
    const { platformGames } = usePlatformGamesList();
    const trackEvent = useTrackEvent();
    const filteredGames = isPlatformFirstEnabled && filterByPlatform && platformSlug ? platformGames : games;
    const selectableGames = filteredGames
        .filter((game) => !['paused', 'prelaunch'].includes(game.status))
        .map((game) => ({
        value: game.gameKey,
        label: game.shortName,
    }));
    const handleSelect = (selectedValue) => {
        const selectedGame = games.find((game) => game.gameKey === selectedValue);
        if (selectedGame) {
            if (onSelect) {
                onSelect(selectedValue, selectedGame);
            }
            trackEvent({
                type: 'game_linking',
                data: {
                    moduleName: 'dropdown',
                    label: selectedGame.name,
                    eventName: 'game linking',
                    position: 0,
                    component: {
                        componentCategory: 'game key',
                        componentName: selectedGame.gameKey,
                    },
                },
            });
        }
    };
    useEffect(() => {
        handleGameStepChange();
    }, []);
    return (_jsxs(ModalContent, { align: "center", children: [_jsx("h2", { children: "Game Accounts" }), _jsx("p", { children: "Please select the game that you want to connect. You can add more than one account for each game title" }), _jsx(StyledNativeSelect, { onSelect: handleSelect, name: "gameSelect", id: "gameSelect", label: "Select a game", placeholder: "Select a game", hideLabel: true, options: selectableGames.map((game) => ({ label: game.label, value: game.value })) })] }));
};
export default SelectGames;
