import useUserGamesListener from "@components/Hooks/useUserGamesListener";
import { useUserState } from '@context/UserContext';
import getMenuGames from '@helpers/getMenuGames';
import useGamesList from '@hooks/API/useGamesList';
import { useMemo } from 'react';
import useSelectedPlatform from './useSelectedPlatform';
const usePlatformGamesList = () => {
    const { platformSlug, isLoading: isLoadingPlatform } = useSelectedPlatform();
    const { allGames, isLoading: isLoadingGames, error } = useGamesList();
    const { userProfile, isLoading: isLoadingUserState } = useUserState();
    const { allUserGameRegions, loading: isLoadingGameListener } = useUserGamesListener(userProfile === null || userProfile === void 0 ? void 0 : userProfile.handle);
    const userGameData = useMemo(allUserGameRegions, [allUserGameRegions]);
    const platformGames = useMemo(() => getMenuGames(allGames).filter((game) => game.platforms.some((platform) => platform.slug === platformSlug)), [platformSlug, allGames]);
    const { userConnectedGames, userUnconnectedGames } = useMemo(() => {
        if (!platformSlug) {
            return { userConnectedGames: [], userUnconnectedGames: [] };
        }
        const { connectedGames, unconnectedGames } = platformGames.reduce((prev, game) => {
            if (userGameData.some((ugr) => ugr.game.gameKey === game.gameKey)) {
                prev.connectedGames.push(game);
            }
            else {
                prev.unconnectedGames.push(game);
            }
            return prev;
        }, { connectedGames: [], unconnectedGames: [] });
        return {
            userConnectedGames: connectedGames,
            userUnconnectedGames: unconnectedGames,
        };
    }, [platformGames, allGames, userGameData, platformSlug]);
    return {
        platformSlug,
        platformGames,
        userConnectedGames,
        userUnconnectedGames,
        isLoading: isLoadingGames || isLoadingPlatform || isLoadingGameListener || isLoadingUserState,
        error,
    };
};
export default usePlatformGamesList;
