import axios from 'axios';
import { getStandardEntryTypes } from '@domain/Tournament/helpers/tournamentUtils';
const getTournament = async (tournamentId) => {
    const response = await axios.get('/api/tournament/v1', {
        params: {
            id: tournamentId,
        },
    });
    const hasCashPassword = response.data.entry_types.some((entry) => entry.type === 'cash_password');
    response.data.hasCashPassword = hasCashPassword;
    response.data.entry_types = getStandardEntryTypes(response.data.entry_types);
    return response;
};
export default getTournament;
