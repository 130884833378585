import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classnames from 'classnames';
import CoreButton from "@components/Buttons/CoreButton";
import useStyles from '../useStyles';
const FooterCallout = ({ footerCalloutImage, accentColor, handleJoinClick }) => {
    const footerBackgroundGradient = typeof accentColor === 'string'
        ? `linear-gradient(0deg, ${accentColor} 0%, ${accentColor} 80%)`
        : accentColor('gradient');
    const footerBackgroundColor = typeof accentColor === 'string' ? `linear-gradient(0deg, ${accentColor}, ${accentColor})` : accentColor('solid');
    const { classes } = useStyles({ footerBackgroundGradient, footerBackgroundColor });
    return (_jsxs("section", { className: classnames(classes.container, classes.footerCallout), children: [_jsx("img", { src: footerCalloutImage, alt: "" }), _jsxs("div", { className: classnames(classes.whiteText, classes.textShadow, classes.stackedContent), children: [_jsxs("h2", { className: classnames(classes.heading), children: ["Stop Scrolling,", _jsx("br", {}), " Start Playing"] }), _jsx("p", { children: "Create your account now and earn 2,000 coins" }), _jsx("p", { children: _jsx(CoreButton, { className: classnames(classes.buttonOverride, classes.blackButton), onClick: handleJoinClick, addFancyHover: true, children: _jsx("span", { children: "Join Now" }) }) })] })] }));
};
export default FooterCallout;
