import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ClickAwayListener, Popper, Fade, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
const StyledPopper = styled(Popper) `
  z-index: ${({ theme }) => theme.zIndex.appBar + 2};
  & > div {
    margin: ${({ theme }) => theme.spacing(1.5, 0)};
    padding: ${({ theme }) => theme.spacing(0, 1)};
    border-radius: 6px;
    min-width: 230px;
  }
`;
const StyledPaper = styled(Paper) `
  z-index: ${({ theme }) => theme.zIndex.appBar + 2};
`;
const Dropdown = ({ buttonRef, children, dropdownButton, className = '', containerClassName = '', isOpen, onClickOutside, placement = 'bottom-end', }) => (_jsx(ClickAwayListener, { onClickAway: onClickOutside, children: _jsxs("div", { className: containerClassName, children: [dropdownButton, _jsx(StyledPopper, { placement: placement, transition: true, anchorEl: buttonRef, open: isOpen, className: className, popperOptions: { strategy: 'fixed' }, children: ({ TransitionProps }) => (_jsx(Fade, { ...TransitionProps, timeout: 350, children: _jsx(StyledPaper, { children: children }) })) })] }) }));
export default Dropdown;
