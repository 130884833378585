import { useContext, useEffect } from 'react';
import { AddGameContext } from '../context/AddGameProvider';
import { setIsLoadingAction, setCurrentStepAction } from '../context/AddGameStepsActions';
/**
 * Revert loading state on unmount.
 * This prevents getting stuck in a loading state if user hits the back button.
 * XYG-1619
 */
const useRevertAddGameLoadingStateOnUnmount = () => {
    const context = useContext(AddGameContext);
    if (context === undefined) {
        throw new Error('useRevertAddGameLoadingStateOnUnmount must be used within a AddGameProvider');
    }
    useEffect(() => {
        return () => {
            if (context.isLoading) {
                context.dispatch(setIsLoadingAction(false));
                context.dispatch(setCurrentStepAction(context.currentStep - 1));
            }
        };
    });
};
export default useRevertAddGameLoadingStateOnUnmount;
