import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import NoWrap from '@components/UI/NoWrap';
import removeQueryParams from '@helpers/removeQueryParams';
import { isServiceKey, serviceProperties } from '@Types/ServiceKey';
import { useHistory, useLocation } from 'react-router-dom';
import ErrorCodeModal from '../ErrorCodeModal';
import ResultModal from '../ResultModal';
const OauthResultModal = () => {
    const { search } = useLocation();
    const history = useHistory();
    const urlSearchParams = new URLSearchParams(search);
    const serviceKey = urlSearchParams.get('service_key');
    const displayName = urlSearchParams.get('display_name');
    const errorCode = urlSearchParams.get('error_code');
    const handleCloseModal = () => {
        const newParams = removeQueryParams(['action', 'service_key', 'display_name'], search);
        history.replace({ search: newParams.search, hash: newParams.hash });
    };
    if (!isServiceKey(serviceKey) || !displayName || errorCode) {
        return (_jsx(ErrorCodeModal, { onClose: handleCloseModal, title: 'Unable to verify account ownership', errorCode: errorCode !== null && errorCode !== void 0 ? errorCode : undefined }));
    }
    const serviceDisplayName = serviceProperties[serviceKey].displayName;
    const message = (_jsxs(_Fragment, { children: ["You have successfully linked your ", _jsx(NoWrap, { children: serviceDisplayName }), " account: ", displayName] }));
    return _jsx(ResultModal, { status: "success", message: message, show: true, onClose: handleCloseModal });
};
export default OauthResultModal;
