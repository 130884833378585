import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { Redirect, Route, useLocation } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
const PrivateRoute = ({ children, redirectRoute, ...routerProps }) => {
    const { search } = useLocation();
    const auth = getAuth();
    // If a custom token is in the url params, CustomAuthenticationModal is about to authenticate the user.
    // Do not redirect.
    const urlSearchParams = new URLSearchParams(search);
    if (urlSearchParams.has('firebase_custom_token')) {
        return _jsx(_Fragment, {});
    }
    if (!auth.currentUser) {
        return _jsx(Redirect, { to: redirectRoute !== null && redirectRoute !== void 0 ? redirectRoute : '/' });
    }
    return _jsx(Route, { ...routerProps, children: children });
};
export default PrivateRoute;
