import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme, { invertColors, paletteName }) => {
    return {
        statusWrapper: {
            alignItems: 'center',
            display: 'inline-flex',
            color: !invertColors ? theme.palette[paletteName].main : theme.palette[paletteName].light,
            background: !invertColors ? undefined : theme.palette[paletteName].main,
        },
        statusDot: {
            background: 'currentColor',
            borderRadius: '50%',
            display: 'inline-block',
            height: '6px',
            margin: theme.spacing(1, 1, 1, 0),
            width: '6px',
        },
        statusDotShadow: {
            boxShadow: `0px 2px 4px rgba(1, 211, 135, 0.25)`,
        },
        statusWrapperShadow: {
            textShadow: `0px 2px 4px rgba(1, 211, 135, 0.25)`,
        },
    };
});
const StatusIndicator = ({ children, status, className = '', testID = '', dropShadow = false, invertColors = false, }) => {
    const getPaletteNameForStatus = () => {
        if (status instanceof Object) {
            const { paletteName } = status;
            return paletteName || 'dota2';
        }
        switch (status) {
            case 'warning':
                return 'secondary';
            default:
                return status;
        }
    };
    const { classes, cx } = useStyles({ paletteName: getPaletteNameForStatus(), invertColors });
    return (_jsxs("span", { className: cx(className, classes.statusWrapper, {
            [classes.statusWrapperShadow]: dropShadow,
        }), role: "status", "data-status": status instanceof Object ? status.name : status, "data-testid": testID, children: [_jsx("span", { className: cx(classes.statusDot, {
                    [classes.statusDotShadow]: dropShadow,
                }) }), children] }));
};
export default StatusIndicator;
