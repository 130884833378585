import { jsx as _jsx } from "react/jsx-runtime";
import { SwipeableDrawer } from '@mui/material';
import { styled } from '@mui/system';
const StyledSwipeableDrawer = styled(SwipeableDrawer) `
  z-index: 999999 !important; // to overlap the zendesk support icon

  .MuiDrawer-paper {
    border-radius: ${({ anchor }) => {
    switch (anchor) {
        case 'top':
            return '0px 0px 6px 6px';
        case 'left':
            return '6px 0px 6px 0px';
        case 'right':
            return '0px 6px 0px 6px';
        default:
            return '6px 6px 0px 0px';
    }
}};
  }
`;
/**
 * Acts similarly to the Material_UI bottom sheet modal
 * https://www.material.io/components/sheets-bottom#modal-bottom-sheet
 */
const SheetModal = ({ className = '', onClose, children, isOpen = true, anchor = 'bottom' }) => {
    return (_jsx(StyledSwipeableDrawer, { variant: "temporary", anchor: anchor, onClose: onClose, onOpen: () => { }, open: isOpen, className: className, children: children }));
};
export default SheetModal;
