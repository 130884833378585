import { useLayoutEffect } from 'react';
/**
 * Some pages have hardcoded padding applied to them to prevent overlapping with
 * older navigation menus and sidebars. Currently the only way to remove this padding
 * is to manually edit the classes of the parent document.
 *
 * Call this hook from a React component and it will automatically remove these padding styles
 * any time it is rendered.
 *
 * @param enabled if false, will not apply style changes
 */
const useRemoveWrapperPadding = (enabled = true) => {
    useLayoutEffect(() => {
        if (enabled) {
            const pageWrapper = document.querySelector('#page-wrapper');
            const contentWrapper = document.querySelector('#page-content-wrapper');
            if (pageWrapper) {
                pageWrapper.setAttribute('style', 'padding: 0px !important;');
            }
            if (contentWrapper) {
                contentWrapper.setAttribute('style', 'max-width: unset;');
            }
            return () => {
                if (pageWrapper) {
                    pageWrapper.removeAttribute('style');
                }
                if (contentWrapper) {
                    contentWrapper.removeAttribute('style');
                }
            };
        }
        return () => undefined;
    }, [enabled]);
};
export default useRemoveWrapperPadding;
