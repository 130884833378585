const getTileHeroImageByGameKey = (game) => {
    switch (game) {
        case 'pubg':
            return '/img/games/fps/pubg/featured_587x900.png';
        case 'pubg-mobile':
            return '/img/games/fps/pubg-mobile/pubg-mobile-prelaunch-featured.png';
        case 'league_of_legends':
            return '/img/games/strategy/league_of_legends/league-of-legends-featured.png';
        case 'fortnite':
            return '/img/landing/game_tile_character_fortnite.png';
        case 'rocket-league':
            return '/img/games/sports/rocket_league/rocket-league-featured-2.png';
        case 'brawl-stars':
            return '/img/landing/game_tile_character_brawl-stars.png';
        case 'pc-dota_2':
            return '/img/games/strategy/dota/dota2-featured.png';
        case 'cod-warzone-2':
            return '/img/landing/game_tile_character_cod-warzone-2.png';
        case 'destiny-2':
            return '/img/landing/game_tile_character_destiny-2.png';
        default:
            return `/img/landing/game_tile_character_${game}.png`;
    }
};
export default getTileHeroImageByGameKey;
