import { makeStyles } from 'tss-react/mui';
import WebFont from 'webfontloader';
WebFont.load({
    google: {
        families: ['Bai Jamjuree:500,600,700'],
    },
    events: false,
    classes: false,
});
const cols = (columnCount) => `${(100 / 12) * columnCount}%`;
const useStyles = makeStyles()((theme, { accentColor, brandFooterBackground, connectGameBackground, footerBackgroundColor, footerBackgroundGradient, gameConnectMobileBackground, headerBackground, staticSectionBackground, }) => ({
    /* width container */
    container: {
        maxWidth: 1420,
        margin: 'auto',
        width: '100%',
    },
    narrowContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(8, 4),
        width: '100%',
        '& > img': {
            maxWidth: '100%',
            marginBottom: theme.spacing(8),
        },
        '& > div': {
            maxWidth: '100%',
        },
        '@media (min-width: 740px)': {
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            margin: 'auto',
            maxWidth: 1420,
            padding: 0,
            '& > img': {
                flexBasis: cols(4),
                marginBottom: 'unset',
                width: cols(4),
            },
            '& > div': {
                flexBasis: cols(5),
                width: cols(5),
            },
        },
    },
    narrowContainerReverse: {
        '@media (min-width: 740px)': {
            flexDirection: 'row-reverse',
        },
    },
    /* Typography */
    title: {
        fontSize: 48,
        fontWeight: 'bold',
        lineHeight: 1.2,
        '@media (min-width: 740px)': {
            fontSize: 72,
        },
    },
    headingText: {
        color: theme.palette.grey[900],
        fontWeight: 'bold',
    },
    heading: {
        fontSize: 55,
        fontWeight: 'bold',
        lineHeight: 1,
        '@media (max-width: 960px)': {
            fontSize: 30,
        },
    },
    subheading: {
        fontSize: 28,
        fontWeight: 'bold',
        lineHeight: 1,
        margin: 0,
    },
    bodyText: {
        color: theme.palette.grey[500],
        fontSize: 16,
        fontFamily: 'Bai Jamjuree',
        fontWeight: 600,
        lineHeight: 1.45,
    },
    whiteText: {
        color: theme.palette.common.white,
    },
    textShadow: {
        textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
    /* Components */
    logo: {
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
        display: 'inline-flex',
        justifyContent: 'center',
        marginRight: theme.spacing(3),
        padding: theme.spacing(2),
        '& img': {
            width: 35,
        },
    },
    loginButton: {
        marginRight: theme.spacing(3),
    },
    overviewTile: {
        background: theme.palette.grey[100],
        padding: theme.spacing(2, 3),
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        '& img': {
            marginRight: theme.spacing(2),
        },
        '& *': {
            margin: 0,
        },
    },
    overviewTileHeadline: {
        fontSize: 12,
        margin: 0,
    },
    overviewTileContent: {
        fontSize: 22,
        color: theme.palette.grey[900],
    },
    iconWrapper: {
        width: '50px',
        height: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: theme.spacing(4),
    },
    buttonOverride: {
        borderRadius: '0px!important',
        borderWidth: '2px',
        fontWeight: 600,
        fontSize: '14px',
        padding: theme.spacing(2, 8),
    },
    blackButton: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.grey[900],
        borderColor: theme.palette.grey[900],
        '&:hover': {
            color: theme.palette.grey[900],
        },
    },
    bannerButtonWrapper: {
        alignItems: 'center',
        display: 'inline-flex',
        flexDirection: 'column',
        '@media (min-width: 740px)': {
            display: 'block',
        },
    },
    bannerButton: {
        margin: theme.spacing(0, 2, 2),
    },
    bgGrey: {
        background: theme.palette.grey[100],
        '@media (min-width: 740px)': {
            '& > div > div': {
                padding: theme.spacing(16, 0),
            },
            '& img': {
                transform: `translateY(${theme.spacing(16)})`,
            },
            '& + div': {
                alignItems: 'center',
                paddingBottom: theme.spacing(16),
                paddingTop: theme.spacing(16),
            },
        },
    },
    stackedContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        '@media (min-width: 740px)': {
            textAlign: 'left',
        },
        '& > *': {
            margin: theme.spacing(5, 0),
        },
        '& > *:first-child, & > *:last-child': {
            margin: 0,
        },
    },
    gameNameButtonWrapper: {
        '@media (min-width: 1440px)': {
            height: 0,
        },
    },
    gameNameButton: {
        color: theme.palette.grey[400],
        display: 'inline-flex',
        flexDirection: 'column',
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
        '& svg': {
            fill: theme.palette.common.white,
            height: '44px',
        },
        '@media (min-width: 1440px)': {
            position: 'relative',
            transform: `translate(${theme.spacing(4)}, calc(-${theme.spacing(4)} - 50% - 10px))`,
            '&:after': {
                background: theme.palette.grey[900],
                content: '""',
                display: 'block',
                height: '20px',
                left: '100%',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                width: '1000px',
                zIndex: -1,
            },
        },
        '&:hover': {
            color: theme.palette.grey[400],
        },
    },
    /* PAGE SECTIONS */
    masthead: {
        background: headerBackground,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '70vh',
    },
    bannerWrapper: {
        overflowX: 'hidden',
        '@media (min-width: 740px)': {
            transform: `translateY(${theme.spacing(16)})`,
        },
    },
    bannerContent: {
        alignItems: 'flex-end',
        display: 'flex',
        flexDirection: 'column-reverse',
        justifyContent: 'space-between',
        padding: theme.spacing(4, 4, 0),
        '& div': {
            paddingBottom: theme.spacing(4),
        },
        '& > img': {
            maxWidth: '100%',
        },
        '@media (min-width: 900px)': {
            flexDirection: 'row',
            '& > img': {
                flexBasis: cols(5),
                maxWidth: 'unset',
                width: cols(5),
            },
            '& div': {
                flexBasis: cols(6),
                width: cols(6),
            },
        },
    },
    navigation: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingRight: theme.spacing(3),
        '& div': {
            display: 'flex',
            alignItems: 'center',
        },
        '@media (min-width: 1420px)': {
            paddingRight: 0,
        },
    },
    tournamentOverviewWrapper: {
        background: theme.palette.common.white,
        padding: theme.spacing(4),
        position: 'relative',
        '@media (min-width: 740px)': {
            '&:before': {
                background: theme.palette.common.black,
                bottom: 0,
                content: '""',
                display: 'block',
                height: 75,
                left: -20,
                position: 'absolute',
                transform: `translateY(calc(-${theme.spacing(16)} - 1px))`,
                width: 20,
            },
            '&:after': {
                background: theme.palette.common.white,
                content: '""',
                display: 'block',
                height: '100%',
                left: '100%',
                position: 'absolute',
                top: 0,
                width: '1000px',
                zIndex: -1,
            },
        },
    },
    tournamentOverviewIntro: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        '& > div:first-child': {
            paddingBottom: theme.spacing(4),
            '& p': {
                margin: 0,
            },
        },
        '@media (min-width: 740px)': {
            flexDirection: 'row',
            justifyContent: 'space-between',
            textAlign: 'left',
            '& > div:first-child': {
                paddingBottom: 0,
            },
        },
    },
    tournamentOverviewTiles: {
        display: 'grid',
        gridTemplateColumns: 'repeat(1, 1fr)',
        gridGap: theme.spacing(4),
        paddingTop: theme.spacing(4),
        '& svg': {
            fill: accentColor,
        },
        '@media (min-width: 480px)': {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        '@media (min-width: 740px)': {
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
    },
    gameConnectWrapper: {
        backgroundColor: gameConnectMobileBackground,
        padding: theme.spacing(8, 4, 0),
        '@media (min-width: 740px)': {
            padding: theme.spacing(20, 0, 0, 0), // Account for the transform of the banner content
        },
        '@media (min-width: 1100px)': {
            backgroundColor: `${theme.palette.common.white}!important`,
        },
    },
    gameConnect: {
        display: 'flex',
        flexDirection: 'column',
        '@media (min-width: 740px)': {
            padding: theme.spacing(0, 8),
        },
        '@media (min-width: 1100px)': {
            alignItems: 'flex-end',
            backgroundImage: `url(${connectGameBackground})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            flexDirection: 'row',
            justifyContent: 'space-between',
            minHeight: 540,
            padding: theme.spacing(0, 16),
        },
    },
    gameConnectImageWrapper: {
        display: 'flex',
        alignItems: 'flex-end',
        '@media (min-width: 1100px)': {
            alignItems: 'normal',
            flexBasis: cols(6),
            width: cols(6),
        },
        '& img': {
            maxWidth: '75%',
            margin: '0 auto',
            '@media (min-width: 1100px)': {
                maxWidth: '540px',
            },
        },
    },
    gameConnectTextWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: theme.spacing(4, 0, 8),
        textAlign: 'center',
        '@media (min-width: 1100px)': {
            flexBasis: cols(6),
            margin: theme.spacing(8, 0),
            paddingRight: theme.spacing(8),
            textAlign: 'left',
            width: cols(6),
        },
    },
    staticContent: {
        backgroundColor: staticSectionBackground,
        '& > div:first-child': {
            backgroundColor: theme.palette.grey[100],
        },
    },
    footerCallout: {
        display: 'grid',
        gridTemplateAreas: '"img" "content"',
        textAlign: 'center',
        backgroundImage: footerBackgroundGradient,
        backgroundPosition: 'bottom',
        backgroundSize: '100% 80%',
        backgroundRepeat: 'no-repeat',
        '@media (min-width: 960px)': {
            gridTemplateColumns: '1fr 1fr',
            gridTemplateRows: '1fr 4fr',
            gridTemplateAreas: '"img ." "img content"',
        },
        '@media (min-width: 740px) and (max-width: 960px)': {
            gridTemplateAreas: '"img ." "img content"',
            gridTemplateColumns: '1fr 2fr',
            gridTemplateRows: '1fr 5fr',
        },
        '@media( max-width: 960px)': {
            backgroundImage: footerBackgroundColor,
        },
        '& div': {
            alignSelf: 'center',
            gridArea: 'content',
            padding: theme.spacing(0, 4),
            '@media (max-width: 960px)': {
                padding: theme.spacing(4),
                '& > p': {
                    margin: theme.spacing(3, 0),
                },
            },
        },
        '& > img': {
            gridArea: 'img',
            justifySelf: 'center',
            alignSelf: 'center',
            maxWidth: '100%',
        },
    },
    brandFooter: {
        alignItems: 'center',
        background: brandFooterBackground,
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(5),
        textAlign: 'center',
        '& *': {
            margin: 0,
        },
        '& img': {
            maxHeight: 90,
        },
    },
    displayInline: {
        display: 'inline',
    },
    hiddenSM: {
        display: 'none',
        '@media (min-width: 840px)': {
            display: 'inherit',
        },
    },
    hiddenXS: {
        display: 'none',
        '@media (min-width: 600px)': {
            display: 'inherit',
        },
    },
}));
export default useStyles;
