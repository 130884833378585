import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme) => ({
    alert: {
        fontFamily: theme.typography.fontFamily,
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '16px',
        paddingRight: '16px',
        fontWeight: 600,
        marginBottom: theme.spacing(2),
        '&.info': {
            background: 'rgba(188, 233, 253, 0.46)',
            borderLeft: '7px solid #00ACF6',
            color: '#00ACF6',
        },
        '&.error': {
            background: 'rgba(249, 57, 45, 0.14)',
            borderLeft: '7px solid #F9392D',
            color: '#F9392D',
        },
        '&.success': {
            background: 'rgba(1, 211, 135, 0.14)',
            borderLeft: '7px solid #01BA77',
            color: '#01BA77',
        },
    },
}));
/**
 * A prominent message intended to inform users of important information.
 * Usecase: Error messages, instructions, outage messages.
 */
const AlertBar = ({ variant, children, className = '', 'data-testid': testID }) => {
    const { classes, cx } = useStyles();
    return (_jsx("div", { role: "alert", className: cx(classes.alert, variant, className), "data-testid": testID, children: children }));
};
export default AlertBar;
