import { displayMessageFromError, isAxiosError } from './errorHelper';
class SaveGameClientError extends Error {
    constructor(err) {
        const message = displayMessageFromError(err);
        super(message);
        this.cause = err;
        this.isAxiosError = isAxiosError(err) && !!err.isAxiosError;
        this.response = isAxiosError(err) ? err.response : undefined;
    }
}
export default SaveGameClientError;
