import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Popper, Fade, useTheme, useMediaQuery, ClickAwayListener, Paper, Collapse, FormControlLabel, Checkbox, Divider, Slider, } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Button from '@components/Buttons/Button';
import hoverBorderGradientAnimation from '@components/Styles/hoverBorderGradientAnimation';
import ApplyFilterButton from '../ApplyFilterButton';
import { styled } from '@mui/system';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
const Container = styled('div') `
  height: 100%;
  width: 100%;
  border-bottom: 1px solid #ececec;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: auto;
    padding: 0;
    border: 0;
  }

  ${hoverBorderGradientAnimation};
`;
const useStyles = makeStyles()((theme) => ({
    entryFeeTypeWrapper: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 16px',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'center',
            width: 'auto',
        },
        // label text
        '& span': {
            fontSize: '12px',
        },
        '& p': {
            margin: '0',
        },
        '& i': {
            marginLeft: '30px',
            color: '#A5A5A5',
        },
    },
    entryFeeTypeButton: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    entryFeeTypeButtonText: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            paddingTop: '15px',
            paddingBottom: '15px',
        },
    },
    popper: {
        zIndex: 10,
        minWidth: '215px',
    },
    entryFeeTypeOptions: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        // label text
        '& span': {
            fontSize: '12px',
        },
        '& > label': {
            padding: '0 20px',
            [theme.breakpoints.down('md')]: {
                padding: '8px 20px',
                margin: '0',
                borderBottom: '1px solid #ECECEC',
            },
        },
        '& > label:first-child': {
            paddingTop: '9px',
            [theme.breakpoints.down('md')]: {
                borderTop: '1px solid #ECECEC',
            },
        },
        '& > label:last-child': {
            paddingBottom: '9px',
        },
    },
    entryFeeRangeTypeOptions: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        // label text
        '& span': {
            fontSize: '12px',
        },
        '& > div': {
            padding: '0 20px',
            [theme.breakpoints.down('md')]: {
                padding: '8px 20px',
                margin: '0',
                borderBottom: '1px solid #ECECEC',
            },
        },
        '& > div:first-child': {
            paddingTop: '9px',
        },
        '& > div:last-child': {
            paddingBottom: '12px',
        },
    },
    highlightSelectedFilter: {
        color: theme.palette.primary.main,
    },
    sliderContentWrapper: {
        marginTop: '13px',
    },
    sliderImageWrapper: {
        marginRight: '10px',
        '& img': {
            height: '17px',
        },
    },
    sliderIcon: {
        color: theme.palette.primary.main,
    },
    sliderText: {
        color: theme.palette.grey[200],
        fontWeight: 500,
        fontSize: '12px',
    },
    sliderValue: {
        color: '#333',
        fontWeight: 500,
        fontSize: '12px',
    },
    heading: {
        color: theme.palette.grey[400],
        fontWeight: 600,
        fontSize: '12px',
        marginBottom: '8px',
    },
    filterText: {
        color: theme.palette.grey[900],
        fontSize: '12px',
        fontWeight: 600,
    },
    hideOnMobile: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));
/** Handles the UI and get_tournaments endpoint data for the entry fee filter */
/**
 * @deprecated - RPT-4140: This component have no use. Consider to remove it.
 */
const EntryFeeFilter = ({ dispatch, entryFeesFilters, entryFeeRangeFilters, triggerFilterChange, }) => {
    const { classes, cx } = useStyles();
    const [entryFeeTypes, setEntryFeeTypes] = useState(entryFeesFilters);
    const [entryFeeRangeTypes, setEntryFeeRangeTypes] = useState(entryFeeRangeFilters);
    const [selectedText, setSelectedText] = useState('All');
    // During subsequent re-renders, the first value prop by useState will persist.
    // These use effects will ensure the render updates with outside state changes.
    // https://reactjs.org/docs/hooks-reference.html#usestate
    useEffect(() => {
        setEntryFeeTypes(entryFeesFilters);
    }, [entryFeesFilters]);
    useEffect(() => {
        setEntryFeeRangeTypes(entryFeeRangeFilters);
    }, [entryFeeRangeFilters]);
    useEffect(() => {
        // If a single filter is active, display the filter title
        // Should this account for range selections?
        const activeFilters = entryFeesFilters.filter((filter) => filter.checked);
        if (activeFilters.length === 1) {
            const tournamentFilter = activeFilters.find((filter) => filter.key === activeFilters[0].key);
            if (tournamentFilter) {
                setSelectedText(tournamentFilter.name);
            }
        }
        else if (activeFilters.length > 1) {
            setSelectedText(`${activeFilters.length} selected`);
        }
        else {
            setSelectedText('All');
        }
    }, [entryFeesFilters, triggerFilterChange]);
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [entryFeeAnchorEl, setFilterAnchorEl] = useState(null);
    const handleFilterClick = (e) => {
        e.preventDefault();
        setFilterAnchorEl(e.currentTarget);
        setShowFilterMenu(!showFilterMenu);
    };
    const handleFilterMenuClose = () => {
        setShowFilterMenu(false);
        // When closing the menu, reset the filter state to previously known state.
        setEntryFeeTypes(entryFeesFilters);
        setEntryFeeRangeTypes(entryFeeRangeFilters);
    };
    const handleApplyFilters = () => {
        dispatch({ type: 'setEntryFees', payload: entryFeeTypes });
        dispatch({ type: 'setEntryFeeRanges', payload: entryFeeRangeTypes });
        handleFilterMenuClose();
    };
    useEffect(() => {
        handleApplyFilters();
    }, [triggerFilterChange]);
    const handleFilterCheck = (selectedFilter) => {
        // rebuild state tournament types array and reverse selectedFilter
        const newFilters = entryFeeTypes.reduce((curr, filter) => {
            if (filter.key === selectedFilter.key) {
                curr.push({
                    ...filter,
                    checked: !filter.checked,
                });
            }
            else {
                curr.push(filter);
            }
            return curr;
        }, []);
        setEntryFeeTypes(newFilters);
    };
    const handleFilterRange = (selectedFilter, value) => {
        // rebuild state tournament types array and reverse selectedFilter
        const newFilters = entryFeeRangeTypes.reduce((curr, filter) => {
            if (filter.key === selectedFilter.key) {
                curr.push({
                    ...filter,
                    range: value,
                });
            }
            else {
                curr.push(filter);
            }
            return curr;
        }, []);
        setEntryFeeRangeTypes(newFilters);
    };
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const activeFilters = entryFeeTypes.filter((filter) => filter.checked);
    return (_jsx(ClickAwayListener, { onClickAway: () => {
            if (!isMobile) {
                handleFilterMenuClose();
            }
        }, children: _jsxs(Container, { children: [_jsx(Button, { onClick: (e) => handleFilterClick(e), className: classes.entryFeeTypeButton, "data-testid": "entry fee filters", type: "button", variant: "transparent", children: _jsxs("div", { className: classes.entryFeeTypeWrapper, children: [_jsxs("div", { className: classes.entryFeeTypeButtonText, children: [_jsx("p", { className: classes.heading, children: "Entry Fee" }), _jsx("p", { className: cx(classes.filterText, selectedText !== 'All' && classes.highlightSelectedFilter), children: selectedText })] }), _jsx(ChevronDownIcon, { height: 16, width: 16 })] }) }), !isMobile ? (_jsx(Popper, { placement: "bottom-start", transition: true, anchorEl: entryFeeAnchorEl, open: showFilterMenu, className: classes.popper, children: ({ TransitionProps }) => (_jsx(Fade, { ...TransitionProps, timeout: 350, children: _jsx(Paper, { square: true, children: _jsxs("div", { children: [_jsx("div", { className: classes.entryFeeTypeOptions, children: entryFeeTypes.map((filter) => (_jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: filter.checked, onChange: () => handleFilterCheck(filter), name: filter.key, color: "primary" }), label: filter.name }, filter.key))) }), _jsx(Divider, {}), _jsx("div", { className: classes.entryFeeRangeTypeOptions, children: entryFeeRangeTypes.map((filter) => {
                                            var _a;
                                            const hasIcon = filter.icon;
                                            return (_jsxs("div", { children: [_jsxs("div", { className: classes.sliderContentWrapper, children: [_jsx("span", { className: classes.sliderImageWrapper, children: hasIcon ? (_jsx("i", { className: cx(`fas fa-${filter.icon}`, classes.sliderIcon) })) : (_jsx("img", { src: filter.img, alt: filter.name })) }), _jsx("span", { className: classes.sliderText, children: "From: " }), _jsx("span", { className: classes.sliderValue, children: filter.entryType === 'PM'
                                                                    ? filter.range[0].toLocaleString(undefined)
                                                                    : filter.range[0].toLocaleString(undefined, {
                                                                        maximumFractionDigits: 2,
                                                                        minimumFractionDigits: 2,
                                                                    }) }), _jsx("span", { className: classes.sliderText, children: " to: " }), _jsx("span", { className: classes.sliderValue, children: filter.entryType === 'PM'
                                                                    ? filter.range[1].toLocaleString(undefined)
                                                                    : filter.range[1].toLocaleString(undefined, {
                                                                        maximumFractionDigits: 2,
                                                                        minimumFractionDigits: 2,
                                                                    }) })] }), _jsx(Slider, { value: filter.range, onChange: (e, value) => handleFilterRange(filter, value), "aria-labelledby": "range-slider", min: filter.min, max: filter.max, step: (_a = filter.step) !== null && _a !== void 0 ? _a : 1, marks: filter.marks ? filter.marks : false, valueLabelDisplay: "off", disabled: 
                                                        // If for example, cash only is true, then disable the coin only slider
                                                        activeFilters.length > 0 &&
                                                            !entryFeeTypes.find((entryFeeFilter) => entryFeeFilter.checked && entryFeeFilter.entryType === filter.entryType) })] }, filter.key));
                                        }) }), _jsx(ApplyFilterButton, { onClick: handleApplyFilters })] }) }) })) })) : (_jsx(Collapse, { in: showFilterMenu, timeout: "auto", unmountOnExit: true, children: _jsxs("div", { className: classes.entryFeeTypeOptions, children: [entryFeeTypes.map((filter) => (_jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: filter.checked, onChange: () => handleFilterCheck(filter), name: filter.key, color: "primary" }), label: filter.name }, filter.key))), _jsx(Divider, {}), _jsx("div", { className: classes.entryFeeRangeTypeOptions, children: entryFeeRangeTypes.map((filter) => {
                                    var _a;
                                    const hasIcon = filter.icon;
                                    return (_jsxs("div", { children: [_jsxs("div", { className: classes.sliderContentWrapper, children: [_jsx("span", { className: classes.sliderImageWrapper, children: hasIcon ? (_jsx("i", { className: cx(`fas fa-${filter.icon}`, classes.sliderIcon) })) : (_jsx("img", { src: filter.img, alt: filter.name })) }), _jsx("span", { className: classes.sliderText, children: "From: " }), _jsxs("span", { className: classes.sliderValue, children: [filter.range[0], " "] }), _jsx("span", { className: classes.sliderText, children: "to: " }), _jsxs("span", { className: classes.sliderValue, children: [filter.range[1], " "] })] }), _jsx(Slider, { value: filter.range, onChange: (e, value) => handleFilterRange(filter, value), "aria-labelledby": "range-slider", min: filter.min, max: filter.max, step: (_a = filter.step) !== null && _a !== void 0 ? _a : 1, marks: filter.marks ? filter.marks : false, valueLabelDisplay: "off", disabled: 
                                                // If for example, cash only is true, then disable the coin only slider
                                                activeFilters.length > 0 &&
                                                    !entryFeeTypes.find((entryFeeFilter) => entryFeeFilter.checked && entryFeeFilter.entryType === filter.entryType) })] }, filter.key));
                                }) }), _jsx(ApplyFilterButton, { onClick: handleApplyFilters, className: classes.hideOnMobile })] }) }))] }) }));
};
export default EntryFeeFilter;
