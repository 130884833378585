import Validator from 'validatorjs';
import { differenceInYears } from 'date-fns';
const customMessages = {
    required: 'This is a required field',
    required_if: 'This is a required field',
    'regex.handle': 'Handle accepts numbers, letters, spaces and the characters -_~',
    min: 'Must contain at least :min characters',
    same: 'The Confirm Password and Password fields must match.',
};
// The FormRules<TForm> type constrains the fields in the rules
// object to those on the form - so we can't set a rule for a field
// that doesn't exist. But because you don't *have* to have rules
// for each field, it should be a subset of the fields on the form.
// I couldn't figure out how to describe that with typescript, so
// use the Omit type to remove the fields we don't want rules for.
const ignorePasswordRules = {
    email: 'required|email',
    // Limit special characters to -_~ and a space at the end.
    handle: 'required|regex:/^[a-zA-Z0-9-_~ ]+$/',
    DOBMonth: 'required|over13',
    DOBDay: 'required|over13',
    DOBYear: 'required|over13',
    country: 'required|size:2',
    state: [{ required_if: ['country', 'US'] }],
    terms: 'required',
    ageCheck: 'required',
};
const rules = {
    ...ignorePasswordRules,
    password: 'required|min:6',
    verifyPassword: 'required|min:6|same:password',
};
export const under13ErrorMessage = "We're not able to complete your registration at this time.";
const validateRegister = (data, ignorePassword = false) => {
    const validation = new Validator(data, ignorePassword ? ignorePasswordRules : rules, customMessages);
    Validator.register('over13', () => {
        // Can only validate if all the date fields are provided
        if (!data.DOBYear || !data.DOBMonth || !data.DOBDay) {
            return false;
        }
        // Date months are zero indexed
        const dob = new Date(parseInt(data.DOBYear, 10), parseInt(data.DOBMonth, 10) - 1, parseInt(data.DOBDay, 10));
        return differenceInYears(new Date(), dob) >= 13;
    }, under13ErrorMessage);
    return validation;
};
export default validateRegister;
