import { useFeature } from 'flagged';
import useSelectedPlatform from './useSelectedPlatform';
const useTournamentDetailsPageUrl = (tournamentId, gameData) => {
    const isPlatformFirstEnabled = !!useFeature('enable_platform_first');
    const { platformSlug, isLoading } = useSelectedPlatform();
    // Data is still loading
    if (!tournamentId || !gameData || isLoading) {
        return '#';
    }
    const shouldIncludePlatform = isPlatformFirstEnabled && !!platformSlug && !!gameData.platforms.find((platform) => platform.slug === platformSlug);
    return shouldIncludePlatform
        ? `/${platformSlug}/${gameData.slug}/tournaments/${tournamentId}`
        : `/${gameData.slug}/tournaments/${tournamentId}`;
};
export default useTournamentDetailsPageUrl;
