import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid } from '@mui/material';
import Button from "@components/Buttons/Button";
import LinkButton from "@components/Buttons/LinkButton";
const Error401 = () => {
    const goBack = () => {
        window.history.go(-1);
    };
    return (_jsx("div", { className: "repeat401Wrapper", children: _jsxs("div", { className: "repeat401Content", children: [_jsx("img", { src: "/img/repeat-404.svg", alt: "" }), _jsx("h1", { className: "mb-15", children: "401: Unauthorized" }), _jsx("p", { className: "mb-10", children: "Unauthorized access detected. You probably came here by mistake, and you need to log in. If not, our drones will be arriving at your door momentarily." }), _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, sm: 6, children: _jsx(Button, { type: "button", block: true, size: "lg", variant: "brand", onClick: goBack, children: "Go Back" }) }), _jsx(Grid, { item: true, xs: 12, sm: 6, children: _jsx(LinkButton, { href: "/user/login", block: true, size: "lg", variant: "brand", "data-testid": "login-link", children: "Login" }) })] })] }) }));
};
export default Error401;
