import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import loadable from '@loadable/component';
import ModalHeader from '@components/Modals/components/ModalHeader';
import getGameName from '@helpers/getGameName';
import getGamePalette from '@helpers/getGamePalette';
import useRemoveAddGameParams from '@hooks/useRemoveAddGameParams';
import AddGameOAuthConnectContent from '../AddGameOAuthConnectContent';
import ContentExposeDota2Profile from './ContentExposeDota2Profile';
import ContentUpdateSteamSettings from './ContentUpdateSteamSettings';
import { GameAgeConsent } from '../../Modals/AddGameModal/Components/GameAgeConsent';
import { useAddGameContext } from '../context/AddGameProvider';
import AddGameConnectStatus from '../AddGameConnectStatus';
import AddGameInterstitial from '../AddGameInterstitial';
import useAddGameBreadcrumbManager from '@hooks/useAddGameBreadcrumbManager';
import ModalBreadcrumb from '@components/Modals/components/ModalBreadcrumb';
import ModalBody from '@components/Modals/components/ModalBody';
import ContentCarousel from '@components/UI/ContentCarousel';
import getAnalyticsFlowStep from '@domain/Analytics/helpers/getAnalyticsFlowStep';
import useSetDefaultGameService from '../hooks/useSetDefaultGameService';
const GameIcon = loadable(() => import('@components/Icons/GameIcon'));
const gameStepBreadcrumbs = [
    {
        id: 'oauth-connect',
        component: 'Connect',
        analyticsFlowStep: 'connect game',
    },
    {
        id: 'public-profile',
        component: 'Public Profile',
        analyticsFlowStep: 'update profile settings',
    },
    {
        id: 'public-data',
        component: 'Public Data',
        analyticsFlowStep: 'public data',
    },
];
const AddDota2 = ({ onStepChange, onConnectConfirm, shouldShowSelectBreadcrumb = true, onBackToSelect, successButtonLabel = 'Continue', failButtonLabel = 'Retry', shouldGoToConnectionStatus = false, onClose, }) => {
    const gameKey = 'pc-dota_2';
    const removeAddGameParams = useRemoveAddGameParams();
    const { requireAgePrompt, isProviderReady, gameConnectionStatus, fetched } = useAddGameContext();
    const breadcrumbStepOffset = shouldShowSelectBreadcrumb ? 1 : 0;
    const connectionStatusPage = gameConnectionStatus === 'success' ? 1 : -1;
    const [currentStepIndex, setCurrentStepIndex] = useState(shouldGoToConnectionStatus ? connectionStatusPage : 0);
    // NOTE: Age check for breadcrumb is managed by the hook below.
    const { breadcrumbIndex, breadcrumbs, breadcrumbIsVisible, setBreadcrumbIsVisible, displayBreadcrumbsUpTo, getIndexOfBreadcrumb, } = useAddGameBreadcrumbManager({
        gameStepBreadcrumbs,
        startingIndex: breadcrumbStepOffset,
        includeSelectBreadcrumb: shouldShowSelectBreadcrumb,
        requireAgePrompt,
    });
    useSetDefaultGameService();
    const handleBreadcrumbClick = (idx, breadcrumb) => {
        if (breadcrumb.id === 'select') {
            // Invoke onBackToSelect to dismiss current view
            if (onBackToSelect) {
                onBackToSelect(gameKey);
            }
        }
        else if (idx >= breadcrumbStepOffset) {
            setCurrentStepIndex(idx - breadcrumbStepOffset);
        }
    };
    const handleStepChange = (changedStepIndex, stepName) => {
        if (stepName === 'game-connect-status') {
            setBreadcrumbIsVisible(false);
        }
        else {
            setBreadcrumbIsVisible(true);
            displayBreadcrumbsUpTo(stepName);
        }
        if (onStepChange) {
            const analyticsFlowStep = getAnalyticsFlowStep(gameConnectionStatus, changedStepIndex, gameStepBreadcrumbs);
            onStepChange(changedStepIndex, stepName, analyticsFlowStep, gameStepBreadcrumbs.length);
        }
    };
    const handleContinue = () => {
        setCurrentStepIndex(currentStepIndex + 1);
    };
    const handleConnectSuccessClick = () => {
        if (onConnectConfirm) {
            onConnectConfirm();
        }
    };
    const getStepIndexFromBreadcrumbId = (relatedBreadcrumbName) => getIndexOfBreadcrumb(relatedBreadcrumbName) - breadcrumbStepOffset;
    const handleConnectFailClick = () => {
        removeAddGameParams();
        setCurrentStepIndex(getStepIndexFromBreadcrumbId('oauth-connect'));
    };
    const handleClose = () => {
        const isSuccessPage = breadcrumbIndex === -1;
        if (onClose) {
            onClose(gameConnectionStatus === 'success', isSuccessPage);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { title: _jsx("span", { "data-ignore": true, children: getGameName(gameKey) }), icon: _jsx(GameIcon, { game: gameKey, showGameColor: true }), subtitle: "Connect your account", paletteName: getGamePalette(gameKey), onClose: handleClose }), _jsx(ModalBreadcrumb, { currentIndex: breadcrumbIndex, breadcrumbs: breadcrumbs, onClick: handleBreadcrumbClick, isVisible: breadcrumbIsVisible }), _jsxs(ModalBody, { children: [isProviderReady && (_jsxs(ContentCarousel, { name: "connect-dota2-account", currentStep: currentStepIndex, onStepChange: handleStepChange, children: [requireAgePrompt && _jsx(GameAgeConsent, { stepName: "age-restriction", onContinue: handleContinue }), _jsx(AddGameOAuthConnectContent, { title: _jsxs("span", { children: ["Connect\u00A0", _jsx("span", { "data-ignore": true, children: "Steam" })] }), stepName: "oauth-connect", paletteName: "dota2" }), _jsx(ContentExposeDota2Profile, { stepName: "public-profile", onContinue: handleContinue }), _jsx(ContentUpdateSteamSettings, { stepName: "public-data", onContinue: handleContinue }), _jsx(AddGameConnectStatus, { onConfirm: handleConnectSuccessClick, onRetry: handleConnectFailClick, status: gameConnectionStatus || 'error', gameKey: gameKey, successButtonLabel: successButtonLabel, failButtonLabel: failButtonLabel, errorMessage: (fetched === null || fetched === void 0 ? void 0 : fetched.isError) ? fetched.errorMessage : undefined, stepName: "game-connect-status" })] })), !isProviderReady && _jsx(AddGameInterstitial, {})] })] }));
};
export default AddDota2;
