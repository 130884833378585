import { useQuery } from 'react-query';
import loadGameData from '@api/loadGameData';
const useGamesList = () => {
    const { data, isLoading, error } = useQuery('gamesList', loadGameData, {
        select: (selectData) => {
            const allGames = selectData !== null && selectData !== void 0 ? selectData : [];
            const statusFilter = ['active', 'beta'];
            const games = allGames.filter((game) => statusFilter.includes(game.status));
            const allPlatforms = allGames.reduce((platforms, game) => {
                game.platforms.forEach((gamePlatform) => {
                    const foundPlatform = platforms.find((platform) => platform.slug === gamePlatform.slug);
                    if (!foundPlatform) {
                        platforms.push(gamePlatform);
                    }
                });
                return platforms;
            }, []);
            return {
                games,
                allPlatforms,
                allGames,
            };
        },
        staleTime: 1000 * 60 * 60 * 8, // 8 hours
    });
    return { allGames: [], games: [], allPlatforms: [], ...data, isLoading, error };
};
export default useGamesList;
