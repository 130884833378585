import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CoreButton from '@components/Buttons/CoreButton';
import ModalContent from '@components/Modals/components/ModalContent';
import ModalStatusBanner from '@components/Modals/components/ModalStatusBanner';
import useWithdrawModalStyles from '../hooks/useWithdrawModalStyles';
const WithdrawalServerError = ({ errorMessage, retry, palette }) => {
    const { classes } = useWithdrawModalStyles();
    return (_jsxs(ModalContent, { align: "center", children: [_jsx(ModalStatusBanner, { status: "error" }), _jsx("h2", { children: "An Error Occurred" }), _jsx("p", { className: classes.paragraph, children: errorMessage }), _jsx(CoreButton, { variant: palette, size: "sm", onClick: retry, children: "Retry" })] }));
};
export default WithdrawalServerError;
