import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from '@mui/material/styles';
const Wrapper = styled('div') `
  border: 1px solid #ebeef2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${({ size }) => (size === 'md' ? '296px' : '153px')};
  border-radius: 6px;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const ComingSoonWrapper = styled('div') `
  flex-grow: 1;
`;
const StayTunedWrapper = styled('div') `
  border-top: 1px solid #ebeef2;
  height: 54px;
`;
const Text = styled('p') `
  font-weight: 600;
  color: #c0c6cb;
  margin: 0;
`;
const ComingSoonText = styled(Text) `
  font-size: 20px;
`;
const StayTunedText = styled(Text) `
  font-size: 16px;
`;
const ComingSoonTile = ({ size }) => {
    return (_jsxs(Wrapper, { size: size, children: [_jsx(ComingSoonWrapper, { children: _jsx(ComingSoonText, { children: "Coming Soon" }) }), _jsx(StayTunedWrapper, { children: _jsx(StayTunedText, { children: "Stay Tuned!" }) })] }));
};
export default ComingSoonTile;
