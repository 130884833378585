import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { AddGameContext } from '../context/AddGameProvider';
import useRevertAddGameLoadingStateOnUnmount from '../hooks/useRevertAddGameLoadingStateOnUnmount';
import RegionAvatar from '../RegionAvatar';
import isRegion from '../helpers/isRegion';
import Choice from "@components/UI/Choice/Choice";
import ModalContent from "@components/Modals/components/ModalContent";
const ContentValorantRegions = ({ onRegionSelected, stepName }) => {
    const { connectPlatform, connectGameRegion } = useContext(AddGameContext);
    useRevertAddGameLoadingStateOnUnmount();
    const renderedOptions = (connectPlatform === null || connectPlatform === void 0 ? void 0 : connectPlatform.regions)
        ? connectPlatform.regions.map(({ name, id, regionCode }) => ({
            label: name,
            value: id,
            id,
            paletteName: 'valorant',
            icon: _jsx(RegionAvatar, { regionCode: regionCode }),
        }))
        : [];
    const getSelectedRegion = (selectedRegionId) => {
        if (connectPlatform === null || connectPlatform === void 0 ? void 0 : connectPlatform.regions) {
            const selectedRegion = connectPlatform.regions.filter((region) => {
                return region.id === selectedRegionId;
            });
            return selectedRegion.length > 0 ? selectedRegion[0] : null;
        }
        return null;
    };
    const handleSelect = (selectedRegionId) => {
        const selectedRegion = getSelectedRegion(selectedRegionId);
        if (selectedRegion && onRegionSelected) {
            onRegionSelected(selectedRegion);
        }
    };
    const choiceMode = (count) => 'select';
    return (_jsxs(ModalContent, { stepName: stepName, verticalAlign: "top", children: [_jsx("h3", { id: "add-game-valorant-select-region", children: "Select your game region" }), _jsx(Choice, { resolveChoiceMode: choiceMode, selectLabel: "Region", choices: renderedOptions, onSelect: handleSelect, initialSelection: isRegion(connectGameRegion) ? connectGameRegion.id : '', defaultPaletteName: "valorant", ariaLabelledBy: "add-game-valorant-select-region" })] }));
};
export default ContentValorantRegions;
