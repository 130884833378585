const getGameKeyFromSlug = (slug) => {
    switch (slug) {
        case 'fortnite':
            return 'fortnite';
        case 'dota2':
            return 'pc-dota_2';
        case 'league-of-legends':
            return 'league_of_legends';
        case 'cod-warzone':
            return 'cod-warzone';
        case 'cod-warzone-2':
            return 'cod-warzone-2';
        case 'valorant':
            return 'valorant';
        case 'pubg-mobile':
            return 'pubg-mobile';
        case 'pubg':
            return 'pubg';
        case 'brawl-stars':
            return 'brawl-stars';
        case 'destiny-2':
            return 'destiny-2';
        case 'rocket-league':
            return 'rocket-league';
        case 'mlb-the-show-23':
            return 'mlb-the-show-23';
        default:
            return undefined;
    }
};
export default getGameKeyFromSlug;
