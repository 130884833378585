import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
import ModalContent from '@components/Modals/components/ModalContent';
import LoginServiceIcon from '@components/UI/Icon/LoginServiceIcon';
import Choice from '../../UI/Choice/Choice';
import { useAddGameContext } from '../context/AddGameProvider';
const useStyles = makeStyles()(() => ({
    helpText: {
        textAlign: 'center',
    },
}));
export const ContentCODLoginServices = ({ className, onLoginServiceSelected, stepName, }) => {
    const { classes } = useStyles();
    const { availableGameServices } = useAddGameContext();
    const { gameServices } = availableGameServices || {};
    const loginMethods = gameServices
        ? gameServices.map((gameService) => ({
            id: gameService.gameServiceKey,
            value: gameService.gameServiceKey,
            label: gameService.name,
            icon: _jsx(LoginServiceIcon, { service: gameService.gameServiceKey, color: "cod" }),
            paletteName: 'cod',
        }))
        : [];
    const handleSelect = (selected) => {
        const selectedService = gameServices === null || gameServices === void 0 ? void 0 : gameServices.find((gameService) => gameService.gameServiceKey === selected);
        if (selectedService) {
            onLoginServiceSelected(selectedService);
        }
    };
    return (_jsxs(ModalContent, { className: className, stepName: stepName, verticalAlign: "top", children: [_jsx("h3", { id: "add-game-cod-select-login", children: "Select Login Method" }), _jsx(Choice, { choices: loginMethods, onSelect: handleSelect, selectButtonLabel: "Continue", ariaLabelledBy: "add-game-cod-select-login" }), _jsx("p", { className: classes.helpText, children: "You can connect multiple accounts" })] }));
};
export default ContentCODLoginServices;
