import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
import Tag from '@components/UI/Tag';
const useStyles = makeStyles()((theme) => ({
    titleContainer: {
        flexDirection: 'row',
        display: 'flex',
        color: theme.palette.grey[300],
        '& i:first-of-type': {
            marginLeft: '0px',
        },
        flexWrap: 'wrap',
    },
}));
const TagList = ({ tags, className, classNameTag }) => {
    const { classes, cx } = useStyles();
    const items = tags.map((tagData) => {
        var _a;
        return _jsx(Tag, { tagData: tagData, className: classNameTag }, (_a = tagData.icon) !== null && _a !== void 0 ? _a : tagData.label);
    });
    return (_jsx("div", { "data-notranslate": true, className: cx(className, classes.titleContainer), children: items }));
};
export default TagList;
