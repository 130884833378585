import { getTournamentState } from '@domain/Tournament/helpers/tournamentUtils';
const PredefinedEvents = {
    pageView: {},
    enterTournament: {
        event_category: 'Tournaments',
        event_action: 'click',
        event_label: 'Generic Entry',
    },
    customTournamentModalClick: {
        event_category: 'Tournaments',
        event_action: 'customClick',
        event_label: 'Custom modal shown',
    },
    tournamentUserGameRegionSelectShown: {
        event_category: 'Tournaments',
        event_action: 'regionClick',
        event_label: 'UserGameRegion selection modal shown',
    },
    customTournamentSubmit: {
        event_category: 'Tournaments',
        event_action: 'customSubmit',
        event_label: 'Custom entry submitted',
    },
    customTournamentCancel: {
        event_category: 'Tournaments',
        event_action: 'customCancel',
        event_label: 'Custom modal dismissed',
    },
    exitTournament: {
        event_category: 'Tournaments',
        event_action: 'exit',
        event_label: 'Exit Tournament',
    },
    // These aren't really actions... might need to be dimensions
    tournamentEntryError: {
        event_category: 'Tournaments',
        event_action: 'entryError',
        event_label: 'Entry submission error',
    },
    tournamentEntrySuccess: {
        event_category: 'Tournaments',
        event_action: 'entrySuccess',
        event_label: 'Entry success',
    },
    sign_up: {
        event_category: 'engagement',
        event_action: 'sign_up',
        event_label: 'Registration success',
    },
    login: {
        event_category: 'engagement',
        event_action: 'login',
        event_label: 'Login success',
    },
    open_external_stats: {
        event_category: 'referrals',
        event_action: 'view_user_stats_external',
        evebt_label: 'View User Stats External',
    },
    open_external_link: {
        event_category: 'outbound',
        transport_type: 'beacon',
    },
    show_game_modal: {
        event_category: 'engagement',
    },
    close_game_modal: {
        event_category: 'engagement',
    },
    add_game_modal_success: {
        event_category: 'engagement',
    },
    // Used to track users interaction with the header game links vs tournaments page tabs (Game Hub, Tournaments).
    game_link: {
        event_category: 'link',
        event_action: 'click',
    },
};
export const extractTournamentAnalyticsData = (tournament, registrationEndingMinutes) => {
    var _a;
    return ({
        tournamentId: tournament.id,
        tournamentName: tournament.name,
        tournamentClass: tournament.tournament_class,
        brand: (_a = tournament.brand) === null || _a === void 0 ? void 0 : _a.name,
        entryFee: String(tournament.entry_fee),
        entryTypes: tournament.entry_types.map((entryType) => entryType.type).join(','),
        featured: String(tournament.featured),
        payoutCurrency: tournament.currencyType.abbreviation,
        tournamentState: getTournamentState(tournament, registrationEndingMinutes),
        game: tournament.score_template.game.game_key,
    });
};
// Indicative
// https://support.indicative.com/hc/en-us/articles/360004186991-JavaScript
const trackIndicative = (event) => {
    if (!window.Indicative) {
        return;
    }
    if (event.type === 'login' || event.type === 'sign_up' || event.type === 'authenticate_with_provider') {
        // If it's a login, we can set the ID for all future Indicative tracking calls
        if (event.userId) {
            window.Indicative.setUniqueID(event.userId);
        }
    }
    const { type, ...values } = event;
    window.Indicative.buildEvent(type, values);
};
export const setEventContext = (newContext) => {
    if (window.Indicative) {
        window.Indicative.addProperties(newContext);
    }
};
export const clearEventContext = () => {
    if (window.Indicative) {
        window.Indicative.clearProperties();
    }
};
/**
 * @deprecated - Migrating to useTrackEvent.ts for adobe analytics
 */
export const doTrackEvent = (event) => {
    trackIndicative(event);
};
export default doTrackEvent;
