import axios from 'axios';
import { useEffect } from 'react';
import configureAxiosFirebaseInterceptor from '@helpers/configureAxiosFirebaseInterceptor';
/**
 * This hook will add an interceptor to the default
 * Axios instance to get firebase's user id token and sets it
 * in the Authorization header before sending a request.
 *
 * Internally checks whether firebase auth is enabled before appending the interceptor.
 *
 */
const useAxiosFirebaseInterceptor = () => {
    useEffect(() => {
        const axiosInterceptorId = configureAxiosFirebaseInterceptor();
        return () => axios.interceptors.request.eject(axiosInterceptorId);
    }, []);
};
export default useAxiosFirebaseInterceptor;
