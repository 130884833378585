// obj key pair of mobile in-app browsers to show warnings for
const BROWSERS = {
    facebook: /\bFB[\w]+\//,
    twitter: /\bTwitter/i,
    instagram: /\bInstagram/i,
    tiktok: /\bmusical_ly/i,
    tiktokAndroid: /\bbytedance/i, // Parent company of tiktok
};
/**
 * Whether this browser is a mobile in-app browser
 *
 * @returns true if this browser is a mobile in-app browser
 */
function isMobileInAppBrowser() {
    return Object.values(BROWSERS).some((browser) => browser.test(navigator.userAgent));
}
export default isMobileInAppBrowser;
