import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme) => ({
    core: {
        alignItems: 'center',
        background: 'transparent',
        border: '1px solid currentColor',
        borderRadius: '4px',
        display: 'inline-flex',
        fontFamily: theme.typography.fontFamily,
        justifyContent: 'center',
    },
    primary: {
        color: theme.palette.primary.main,
    },
    brand: {
        color: theme.palette.primary.main,
    },
    secondary: {
        color: theme.palette.secondary.main,
    },
    success: {
        color: theme.palette.success.main,
    },
    error: {
        color: theme.palette.error.main,
    },
    warning: {
        color: theme.palette.warning.main,
    },
    info: {
        color: theme.palette.info.main,
    },
    sm: {
        fontSize: '12px',
        fontWeight: theme.typography.fontWeightMedium,
        height: '35px',
        padding: theme.spacing(1),
    },
    md: {
        fontSize: '12px',
        fontWeight: theme.typography.fontWeightMedium,
        height: '40px',
        padding: theme.spacing(1.5),
    },
    lg: {
        fontSize: '14px',
        fontWeight: theme.typography.fontWeightMedium,
        height: '45px',
        padding: theme.spacing(2),
    },
    xl: {
        fontSize: '16px',
        fontWeight: theme.typography.fontWeightMedium,
        height: '50px',
        padding: theme.spacing(2.5),
    },
    block: {
        width: '100%',
    },
}));
/**
 * @deprecated Try using <BoxButton appearance='outline'>
 * @param param0
 * @returns
 */
export const OutlineButton = ({ children, variant = 'primary', size = 'md', type = 'button', className, onClick, id, block = false, isDisabled = false, label, }) => {
    const { classes, cx } = useStyles();
    return (_jsx("button", { "aria-label": label, disabled: isDisabled, type: type, onClick: onClick, className: cx(classes.core, classes[variant], classes[size], block && classes.block, className), id: id, children: children }));
};
export default OutlineButton;
