import axios from 'axios';
import { captureMessage, captureException } from '@sentry/react';
import triggerLoggedOutRedirect from '../domain/User/Login/helpers/triggerLoggedOutRedirect';
import { isAxiosError } from '../helpers/errorHelper';
import ErrorWithCause from '../helpers/ErrorWithCause';
const getUserGames = async (userHandle) => {
    var _a;
    try {
        const response = await axios.get('/api/user/game_regions/v1', {
            params: { userHandle },
        });
        return (_a = response.data) !== null && _a !== void 0 ? _a : [];
    }
    catch (error) {
        const e = new ErrorWithCause(error);
        if (isAxiosError(e) && e.code === '401') {
            captureMessage(`Failed to get user_game_regions for user with handle: "${userHandle}". User will be logged out.`);
            triggerLoggedOutRedirect();
        }
        else {
            captureException(e, {
                extra: {
                    request: 'get_user_game_regions',
                    userHandle,
                },
            });
        }
        throw e;
    }
};
export default getUserGames;
