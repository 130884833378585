import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import loadable from '@loadable/component';
import { styled } from '@mui/system';
import classnames from 'classnames';
import SROnly from '@components/UI/SROnly';
import getNotificationStatus from '../helpers/getNotificationStatus';
const Pin = loadable(() => import('@components/Icons/Pin'));
const StatusContainer = styled('div') `
  grid-area: status;
  display: flex;
  align-items: center;
  margin-left: auto;
`;
const CircleStatus = styled('i') `
  width: 8;
  height: 8;
  border-radius: 20;
  display: block;

  &.unopenedDot {
    background: ${({ theme }) => theme.palette.primary.main};
  }
  &.openedDot {
    background: ${({ theme }) => theme.palette.grey[200]};
  }
  &.newDot {
    background: ${({ theme }) => theme.palette.error.main};
  }
`;
const StatusText = styled('span') `
  margin-left: ${({ theme }) => theme.spacing(0.5)};

  &.new {
    color: ${({ theme }) => theme.palette.error.main};
  }
  &.pinned {
    color: ${({ theme }) => theme.palette.primary.main};

    &.error {
      color: ${({ theme }) => theme.palette.error.main};
    }
  }
`;
const StyledPin = styled(Pin) `
  fill: ${({ theme }) => theme.palette.primary.main};
  width: 15px;

  &.error {
    fill: ${({ theme }) => theme.palette.error.main};
  }
`;
/**
 * Used to display the status circle for the notification item
 */
const NotificationStatus = ({ notification, className }) => {
    const status = getNotificationStatus(notification);
    const statusUppercased = status.charAt(0).toUpperCase() + status.slice(1);
    return (_jsxs(_Fragment, { children: [status !== 'unopened' && (_jsx(StatusText, { className: classnames({
                    new: status === 'new',
                    pinned: status === 'pinned',
                    error: notification.type === 'alert',
                }), children: statusUppercased })), _jsxs(StatusContainer, { className: classnames(status, className), children: [_jsx(SROnly, { children: statusUppercased }), status === 'pinned' ? (_jsx(StyledPin, { className: classnames({
                            error: notification.type === 'alert',
                        }) })) : (_jsx(CircleStatus, { className: `${status}Dot` }))] })] }));
};
export default NotificationStatus;
