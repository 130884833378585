import { captureException } from '@sentry/react';
import { useQuery } from 'react-query';
import getBrandedTournamentContent from '@api/getBrandedTournamentContent';
const useGetBrandedTournamentContent = (slug) => {
    var _a;
    const { data, isLoading, error, isError } = useQuery(['branded-tournament-page', { slug }], () => getBrandedTournamentContent(slug), { enabled: !!slug });
    if (error && ((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) !== 404) {
        captureException(error, { extra: { request: 'api_influencer_page', slug } });
    }
    return { data: data !== null && data !== void 0 ? data : null, isLoading, error, isError };
};
export default useGetBrandedTournamentContent;
