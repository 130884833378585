import { jsx as _jsx } from "react/jsx-runtime";
import { Paper, styled, Tooltip } from '@mui/material';
import useGameModes from '@hooks/API/useGameModes';
const modeRestrictedGameList = ['rocket-league', 'league_of_legends'];
const StyledImage = styled('img') `
  height: 24px;
  margin: 3px;
`;
const StyledPaper = styled(Paper) `
  display: flex;
  padding: 4px;
`;
const TournamentBoxPillGameMode = ({ tournament, noBorder, }) => {
    var _a, _b;
    const gameKey = tournament.score_template.game.game_key;
    const allowedGameModes = ((_b = (_a = tournament.score_template.json.gameModes) !== null && _a !== void 0 ? _a : tournament.score_template.game_modes) !== null && _b !== void 0 ? _b : []).map((mode) => `${mode}`);
    const { gameModes } = useGameModes(gameKey);
    const availableGameModes = gameModes.filter((gameMode) => allowedGameModes.includes(gameMode.code));
    if (noBorder) {
        return (_jsx("div", { children: availableGameModes.map((gameMode) => (_jsx(Tooltip, { placement: "top", arrow: true, title: gameMode.name, children: _jsx(StyledImage, { src: gameMode.iconUrl, alt: gameMode.name }) }, gameMode.name))) }));
    }
    return modeRestrictedGameList.includes(gameKey) ? (_jsx(StyledPaper, { elevation: 2, children: availableGameModes.map((gameMode) => (_jsx(Tooltip, { placement: "top", arrow: true, title: gameMode.name, children: _jsx(StyledImage, { src: gameMode.iconUrl, alt: gameMode.name }) }, gameMode.name))) })) : (_jsx("div", {}));
};
export default TournamentBoxPillGameMode;
