import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { List } from '@mui/material';
import { useUserState } from '@context/UserContext';
import LogoutButton from '@components/Navigation/LogoutButton';
import NavItem from './NavItem';
import { styled } from '@mui/system';
import { useFeature } from 'flagged';
const Divider = styled('hr') `
  background: rgba(0, 0, 0, 0.12);
  border: 0;
  height: 1px;
  margin: ${({ theme }) => theme.spacing(1, 0)};
`;
const StyledNavItem = styled(NavItem) `
  border-radius: 4px;
  padding: ${({ theme }) => theme.spacing(1, 1.5)};

  & span {
    color: ${({ theme }) => theme.palette.grey[600]};
    font-size: 12px;
    font-weight: 600;
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.light};
  }

  & span:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;
const StyledLogoutButton = styled(LogoutButton) `
  align-items: center;
  background-color: transparent !important;
  border: none !important;
  color: ${({ theme }) => theme.palette.grey[600]}!important;
  font-weight: 600 !important;
  height: 50px;
  justify-content: left !important;
  padding: ${({ theme }) => theme.spacing(1, 1.5)}!important;

  &:hover {
    color: ${({ theme }) => theme.palette.primary.main}!important;
    background-color: ${({ theme }) => theme.palette.primary.light}!important;
  }
`;
const ProfileMenu = ({ onWithdrawClick, showCouponModal }) => {
    const { userProfile } = useUserState();
    const enableCosmetics = !!useFeature('enable_cosmetics');
    const enableTipaltiPayments = !!useFeature('enable_tipalti_payments');
    const profileLink = `/user/profile/${userProfile === null || userProfile === void 0 ? void 0 : userProfile.handle}`;
    return (_jsxs(List, { component: "nav", "aria-label": "profile menu", "data-testid": "profile-menu", children: [_jsx(StyledNavItem, { to: profileLink, linkText: "My Profile" }), _jsx(StyledNavItem, { to: "/account/details", linkText: "Account Settings" }), _jsx(StyledNavItem, { to: "/account/transactions", linkText: "Transaction History" }), enableCosmetics && _jsx(StyledNavItem, { to: "/user/my-collection", linkText: "My Collection" }), _jsx(Divider, {}), _jsx(StyledNavItem, { to: "/coins", linkText: "Refer a Friend" }), _jsx(StyledNavItem, { onClick: showCouponModal, button: true, linkText: "Redeem Coupon" }), enableTipaltiPayments ? (_jsx(StyledNavItem, { to: "/account/transactions/withdrawal", linkText: "Withdraw Funds", "data-testid": "profile-menu-withdraw-funds-button" })) : (_jsx(StyledNavItem, { onClick: onWithdrawClick, button: true, linkText: "Withdraw Funds", "data-testid": "profile-menu-withdraw-funds-button" })), _jsx(Divider, {}), _jsx(StyledLogoutButton, { block: true, children: "Logout" })] }));
};
export default ProfileMenu;
