import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Collapse, alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { CaretDownRotate } from '@components/UI';
const useStyles = makeStyles()((theme, { paletteName }) => ({
    dropdownContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        position: 'relative',
        '& button': {
            display: 'flex',
            padding: theme.spacing(2),
            textAlign: 'left',
            alignItems: 'center',
            color: `${theme.palette.grey[900]} !important`,
            fontWeight: 600,
            fontFamily: theme.typography.fontFamily,
            background: theme.palette.background.default,
            borderLeft: `0px !important`, // Top level menu has overriding styling
        },
        '&:before': {
            content: `""`,
            position: 'absolute',
            height: '100%',
            width: '3px',
            background: paletteName && theme.palette[paletteName].main,
        },
    },
    dropdownActive: {
        '& button': {
            background: paletteName && alpha(theme.palette[paletteName].main, 0.2),
        },
    },
    gameIcon: {
        width: '30px',
    },
    gameMenu: {
        paddingInlineStart: 0,
        '& li': {
            listStyle: 'none',
        },
        '& li:nth-child(odd)': {
            background: theme.palette.grey[50],
        },
        '& li a': {
            display: 'flex',
            width: '100%',
            padding: theme.spacing(2),
            alignItems: 'center',
            color: theme.palette.grey[400],
            fontWeight: 600,
            fontFamily: theme.typography.fontFamily,
        },
        '& li svg': {
            height: '17px',
            fill: theme.palette.grey[400],
            marginRight: theme.spacing(2),
        },
    },
    iconWrapper: {
        width: '31px',
        height: '31px',
        marginRight: theme.spacing(2),
        '& img, & svg': {
            width: '100%',
        },
    },
    newText: {
        color: `${theme.palette.common.white} !important`,
        background: paletteName && theme.palette[paletteName].main,
        borderRadius: '36px',
        padding: '1px 4px',
        marginLeft: theme.spacing(1),
        textTransform: 'uppercase',
        fontSize: '9px',
    },
    caret: {
        // flexbox hack to move item to the end
        marginLeft: 'auto',
    },
}));
const NavigationMenuPanel = ({ label, icon, paletteName, newBadge, children, defaultOpen = false, className, }) => {
    const [menuOpen, setMenuOpen] = useState(defaultOpen);
    const { classes, cx } = useStyles({ paletteName });
    const handleToggleMenu = () => {
        setMenuOpen(!menuOpen);
    };
    return (_jsxs("div", { className: cx(classes.dropdownContainer, { [classes.dropdownActive]: menuOpen }, className), children: [_jsxs("button", { type: "button", onClick: handleToggleMenu, children: [icon && _jsx("div", { className: classes.iconWrapper, children: icon }), label, newBadge && _jsx("span", { className: classes.newText, children: "New" }), _jsx(CaretDownRotate, { rotate: menuOpen, className: classes.caret })] }), _jsx(Collapse, { in: menuOpen, children: _jsx("ul", { className: classes.gameMenu, children: children }) })] }));
};
export default NavigationMenuPanel;
