import { useQuery } from 'react-query';
import getUserGames from '@api/getUserGames';
import { useCallback } from 'react';
/**
 * This hook should only be used to retrieve UserGames of other users.
 * To get the UserGames of the current user, you should instead use UserContext.
 *
 * @param {string} userHandle the user handle of the desired user
 * @returns {Array} list of user-game-regions
 */
const useUserGamesListener = (userHandle = undefined) => {
    const { data: userGames = {}, isLoading, refetch, } = useQuery(['get_user_game_regions', userHandle], () => getUserGames(userHandle !== null && userHandle !== void 0 ? userHandle : ''), {
        enabled: userHandle != null,
    });
    const userGameByGameKey = (gameKey) => {
        var _a;
        return (_a = userGames[gameKey]) !== null && _a !== void 0 ? _a : [];
    };
    const allUserGameRegions = useCallback(() => {
        const results = [];
        const gameKeys = Object.keys(userGames);
        gameKeys.forEach((gameKey) => {
            const userGameRegions = userGames[gameKey] || [];
            userGameRegions.forEach((ugr) => {
                results.push(ugr);
            });
        });
        return results;
    }, [userGames]);
    return {
        loading: isLoading,
        userGames,
        userGameByGameKey,
        allUserGameRegions,
        triggerReload: refetch,
    };
};
export default useUserGamesListener;
