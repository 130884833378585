import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import loadable from '@loadable/component';
const GoToPageIcon = loadable(() => import('./GoToPageIcon'));
const ErrorIcon = loadable(() => import('./ErrorIcon'));
const TrophyIcon = loadable(() => import('./TrophyIcon'));
const TrophyRibbonIcon = loadable(() => import('./TrophyRibbonIcon'));
const HeartOutlineIcon = loadable(() => import('./HeartOutlineIcon'));
const HeartFilledIcon = loadable(() => import('./HeartFilledIcon'));
const HourGlassIcon = loadable(() => import('./HourGlassIcon'));
const StarIcon = loadable(() => import('./StarIcon'));
const ChevronRight = loadable(() => import('./ChevronRight'));
const ChevronLeft = loadable(() => import('./ChevronLeft'));
const ReloadIcon = loadable(() => import('./ReloadIcon'));
const LoadingIcon = loadable(() => import('./LoadingIcon'));
const PodiumIcon = loadable(() => import('./PodiumIcon'));
const SwordIcon = loadable(() => import('./SwordIcon'));
const Coin = loadable(() => import('./Coin'));
const GemBag = loadable(() => import('./GemBag'));
const MapPin = loadable(() => import('./MapPin'));
const MapPinDropped = loadable(() => import('./MapPinDropped'));
const PlayIcon = loadable(() => import('./PlayIcon'));
const LongArrowRight = loadable(() => import('./LongArrowRight'));
const CancelCircle = loadable(() => import('./CancelCircle'));
const CheckCircle = loadable(() => import('./CheckCircle'));
const Sparkle = loadable(() => import('./Sparkle'));
const SteamLogo = loadable(() => import('./SteamLogo'));
const EyeIcon = loadable(() => import('./EyeIcon'));
const Controller = loadable(() => import('./Controller'));
const GiftBox = loadable(() => import('./GiftBox'));
const Ribbon = loadable(() => import('./Ribbon'));
const Server = loadable(() => import('./Server'));
const ModernController = loadable(() => import('./ModernController'));
const Twitch = loadable(() => import('./Twitch'));
const DeleteIcon = loadable(() => import('./DeleteIcon'));
const MobileIcon = loadable(() => import('./MobileIcon'));
const PcIcon = loadable(() => import('./Platforms/PcIcon'));
const PSNIcon = loadable(() => import('./Platforms/PSNIcon'));
const XboxOneIcon = loadable(() => import('./Platforms/XboxOneIcon'));
const CallOfDuty = loadable(() => import('./CallOfDuty'));
const Dota2 = loadable(() => import('./Dota2'));
const Fortnite = loadable(() => import('./Fortnite'));
const Globe = loadable(() => import('./Globe'));
const LeagueOfLegends = loadable(() => import('./LeagueOfLegends'));
const Valorant = loadable(() => import('./Valorant'));
const Profile = loadable(() => import('./Profile'));
const BrawlStars = loadable(() => import('./BrawlStars'));
const GoalScored = loadable(() => import('./GoalScored'));
const Destiny2 = loadable(() => import('./Destiny2'));
const RocketLeague = loadable(() => import('./RocketLeague'));
const NintendoSwitch = loadable(() => import('./NintendoSwitch'));
const ListViewIcon = loadable(() => import('./ListViewIcon'));
const GridViewIcon = loadable(() => import('./GridViewIcon'));
const Twitter = loadable(() => import('./Twitter'));
const YouTube = loadable(() => import('./YouTube'));
const KeyboardAndMouse = loadable(() => import('./KeyboardAndMouse'));
const Touch = loadable(() => import('./Touch'));
const RepeatLogo = loadable(() => import('./Brand/RepeatLogo'));
const RepeatText = loadable(() => import('./Brand/RepeatText'));
const Instagram = loadable(() => import('./Instagram'));
const Battlenet = loadable(() => import('./Battlenet'));
const Coins = loadable(() => import('./Coins'));
const Dollar = loadable(() => import('./Dollar'));
const CheckShield = loadable(() => import('./CheckShield'));
const Padlock = loadable(() => import('./Padlock'));
const CartDuoColour = loadable(() => import('./CartDuoColour'));
const SearchIcon = loadable(() => import('./SearchIcon'));
const Google = loadable(() => import('./Google'));
const StarOutline = loadable(() => import('./StarOutlineIcon'));
const Bell = loadable(() => import('./Bell'));
const Danger = loadable(() => import('./Danger'));
const Discovery = loadable(() => import('./Discovery'));
const Wallet = loadable(() => import('./Wallet'));
const Scan = loadable(() => import('./Scan'));
const Message = loadable(() => import('./Message'));
const Facebook = loadable(() => import('./Facebook'));
const Pubg = loadable(() => import('./Pubg'));
const VerifiedCheckmark = loadable(() => import('../../components/UI/UserVerificationBadge/VerifiedCheckmark'));
const Clock = loadable(() => import('./Clock'));
const AvatarCosmetic = loadable(() => import('./AvatarCosmetic'));
const BackgroundCosmetic = loadable(() => import('./BackgroundCosmetic'));
const BorderCosmetic = loadable(() => import('./BorderCosmetic'));
const CallOfDutyWarzone2 = loadable(() => import('./CallOfDutyWarzone2'));
const MLBTheShow23 = loadable(() => import('./MLBTheShow23'));
const Apple = loadable(() => import('./Apple'));
const Envelope = loadable(() => import('@heroicons/react/24/solid/EnvelopeIcon'));
/** @deprecated - use symfony/assets/js/react/components/UI/Icon/Icon.tsx */
const Icon = ({ icon, className = '', optimise = false, title, color }) => {
    switch (icon.toLowerCase()) {
        case 'apple':
            return _jsx(Apple, { className: className, title: title, color: color });
        case 'avatarcosmetic':
            return _jsx(AvatarCosmetic, { className: className, title: title, color: color });
        case 'backgroundcosmetic':
            return _jsx(BackgroundCosmetic, { className: className, title: title, color: color });
        case 'bell':
            return _jsx(Bell, { className: className, optimise: optimise, title: title });
        case 'bordercosmetic':
            return _jsx(BorderCosmetic, { className: className, title: title, color: color });
        case 'cartduo':
            return _jsx(CartDuoColour, { className: className });
        case 'clock':
            return _jsx(Clock, { className: className, optimise: optimise, title: title, color: color });
        case 'danger':
            return _jsx(Danger, { className: className, optimise: optimise, title: title, color: color });
        case 'discovery':
            return _jsx(Discovery, { className: className, color: color, optimise: optimise });
        case 'padlock':
            return _jsx(Padlock, { className: className });
        case 'checkshield':
            return _jsx(CheckShield, { className: className, title: title });
        case 'gotopage':
            return _jsx(GoToPageIcon, { className: className });
        case 'error':
            return _jsx(ErrorIcon, { className: className });
        case 'trophy':
            return _jsx(TrophyIcon, { className: className, title: title, optimise: optimise, color: color });
        case 'trophyribbon':
            return _jsx(TrophyRibbonIcon, { className: className, title: title, optimise: optimise });
        case 'heartoutline':
            return _jsx(HeartOutlineIcon, { className: className });
        case 'heartfilled':
            return _jsx(HeartFilledIcon, { className: className, title: title, optimise: optimise });
        case 'hourglass':
            return _jsx(HourGlassIcon, { className: className, color: color });
        case 'star':
            return _jsx(StarIcon, { className: className });
        case 'chevronright':
            return _jsx(ChevronRight, { className: className });
        case 'chevronleft':
            return _jsx(ChevronLeft, { className: className, title: title });
        case 'reload':
            return _jsx(ReloadIcon, { className: className });
        case 'loading':
            return _jsx(LoadingIcon, { className: className });
        case 'podium':
            return _jsx(PodiumIcon, { className: className, title: title, optimise: optimise });
        case 'gembag':
            return _jsx(GemBag, { className: className, title: title, optimise: optimise });
        case 'sword':
            return _jsx(SwordIcon, { className: className });
        case 'coin':
            return _jsx(Coin, { className: className, color: color });
        case 'mappin':
            return _jsx(MapPin, { className: className, title: title, optimise: optimise });
        case 'mappindropped':
            return _jsx(MapPinDropped, { className: className });
        case 'message':
            return _jsx(Message, { className: className, title: title, optimise: optimise, color: color });
        case 'play':
            return _jsx(PlayIcon, { className: className });
        case 'longArrowRight':
            return _jsx(LongArrowRight, { className: className });
        case 'scan':
            return _jsx(Scan, { className: className, title: title, optimise: optimise, color: color });
        case 'search':
            return _jsx(SearchIcon, { className: className, title: title });
        case 'staroutline':
            return _jsx(StarOutline, { className: className, color: color });
        case 'steam':
            return _jsx(SteamLogo, { className: className, title: title });
        case 'sparkle':
            return _jsx(Sparkle, { className: className, title: title });
        case 'checkcircle':
            return _jsx(CheckCircle, { className: className, title: title });
        case 'cancelcircle':
            return _jsx(CancelCircle, { className: className, title: title });
        case 'eye':
            return _jsx(EyeIcon, { className: className, title: title, optimise: optimise });
        case 'controller':
            return _jsx(Controller, { className: className, title: title, optimise: optimise });
        case 'giftbox':
            return _jsx(GiftBox, { className: className });
        case 'globe':
            return _jsx(Globe, { className: className, title: title, optimise: optimise });
        case 'keyboardandmouse':
            return _jsx(KeyboardAndMouse, { className: className, title: title, optimise: optimise });
        case 'ribbon':
            return _jsx(Ribbon, { className: className, title: title, optimise: optimise });
        case 'server':
            return _jsx(Server, { className: className, title: title, optimise: optimise });
        case 'moderncontroller':
            return _jsx(ModernController, { className: className, title: title, optimise: optimise });
        case 'touch':
            return _jsx(Touch, { className: className, title: title });
        case 'twitch':
            return _jsx(Twitch, { className: className });
        case 'twitter':
            return _jsx(Twitter, { className: className });
        case 'youtube':
            return _jsx(YouTube, { className: className });
        case 'instagram':
            return _jsx(Instagram, { className: className });
        case 'google':
            return _jsx(Google, { className: className });
        case 'facebook':
            return _jsx(Facebook, { className: className, title: title });
        case 'delete':
            return _jsx(DeleteIcon, { className: className });
        case 'mobile':
            return _jsx(MobileIcon, { className: className, title: title });
        case 'pc':
            return _jsx(PcIcon, { className: className, title: title });
        case 'ps4':
        case 'psn':
        case 'playstation':
            return _jsx(PSNIcon, { className: className, title: title });
        case 'profile':
        case 'verify':
            return _jsx(Profile, { className: className, color: color });
        case 'xboxone':
        case 'xbox':
            return _jsx(XboxOneIcon, { className: className, title: title });
        case 'fortnite':
            return _jsx(Fortnite, { className: className, title: title });
        case 'cod-warzone':
            return _jsx(CallOfDuty, { className: className, title: title });
        case 'cod-warzone-2':
            return _jsx(CallOfDutyWarzone2, { className: className, title: title });
        case 'pc-dota_2':
            return _jsx(Dota2, { className: className, title: title });
        case 'league_of_legends':
            return _jsx(LeagueOfLegends, { className: className, title: title });
        case 'valorant':
            return _jsx(Valorant, { className: className, title: title });
        case 'pubg':
            return _jsx(Pubg, { className: className, title: title, color: color });
        case 'brawl-stars':
            return _jsx(BrawlStars, { className: className, title: title });
        case 'rocket-league':
            return _jsx(RocketLeague, { className: className, title: title });
        case 'mlb-the-show-23':
            return _jsx(MLBTheShow23, { className: className, title: title });
        case 'destiny-2':
            return _jsx(Destiny2, { className: className, title: title });
        case 'listview':
            return _jsx(ListViewIcon, { className: className, title: title });
        case 'gridview':
            return _jsx(GridViewIcon, { className: className, title: title });
        case 'battlenet':
            return _jsx(Battlenet, { className: className, title: title });
        case 'repeat':
            return _jsx(RepeatLogo, { className: className });
        case 'repeatText':
            return _jsx(RepeatText, { className: className });
        case 'coins':
            return _jsx(Coins, { className: className });
        case 'dollar':
            return _jsx(Dollar, { className: className });
        case 'verifiedusercheckmark':
        case 'badge':
            return _jsx(VerifiedCheckmark, { className: className, variant: "verified" });
        case 'verifiedpartnercheckmark':
            return _jsx(VerifiedCheckmark, { className: className, variant: "partner" });
        case 'wallet':
            return _jsx(Wallet, { className: className, optimise: optimise, title: title, color: color });
        case 'goalscored':
            return _jsx(GoalScored, { className: className, title: title });
        case 'nintendo-switch':
        case 'switch':
            return _jsx(NintendoSwitch, { className: className, title: title });
        case 'envelope':
            return _jsx(Envelope, { className: className, viewBox: "0 0 24 24", width: "1.2em", height: "1.2em", color: color });
        default:
            return _jsx(_Fragment, {});
    }
};
export default Icon;
