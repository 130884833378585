// from the Figma Styleguide
const getModalWidth = (size) => {
    switch (size) {
        case 'small':
            return 350;
        case 'medium':
            return 430;
        case 'large':
            return 630;
        case 'extraLarge':
            return 820;
        default:
            throw new Error(`${size} modal size was not recognised`);
    }
};
export default getModalWidth;
