import { useEffect, useState } from 'react';
import axios from 'axios';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import * as Sentry from '@sentry/browser';
import { captureException } from '@sentry/react';
import { doTrackEvent } from '../../../../../../components/Analytics';
import useGetRecaptureEnabled from '@components/Modals/AuthenticationModal/Hooks/useGetRecaptureEnabled';
import checkRegistrationAvailability from '../../../../../../api/checkRegistrationAvailability';
import getSignInServiceProvider from '@helpers/Auth/getSignInServiceProvider';
import ErrorWithCause from '@helpers/ErrorWithCause';
import { isAxiosError } from '@helpers/errorHelper';
import trackHotjarEvent from '@components/Analytics/trackHotjarEvent';
import { getCurrentLoginProvider } from '@components/Modals/AuthenticationModal/Hooks/useCurrentLoginProvider';
import useTrackUserFlow from '@hooks/useTrackUserFlow';
const useRegisterRequest = ({ onSuccess, onFail }) => {
    var _a;
    const [csrfToken, setCsrfToken] = useState(null);
    const trackUserFlow = useTrackUserFlow();
    const recaptchaKey = '6Ldk1cIUAAAAACQiYDy5zcbF33p3e4Iahsmz0FE9';
    const [loading, setLoading] = useState(false);
    const [validation, setValidation] = useState({});
    const { data: recaptureData } = useGetRecaptureEnabled();
    const recaptchaEnabled = (_a = recaptureData === null || recaptureData === void 0 ? void 0 : recaptureData.recaptchaEnabled) !== null && _a !== void 0 ? _a : null;
    const getCsrfToken = async () => {
        var _a;
        try {
            const csrfResponse = await axios.get('/api/security/csrf/v1');
            if (!((_a = csrfResponse === null || csrfResponse === void 0 ? void 0 : csrfResponse.data) === null || _a === void 0 ? void 0 : _a.csrf_token)) {
                throw new Error('No csrf token');
            }
            return csrfResponse.data.csrf_token;
        }
        catch (error) {
            const e = new ErrorWithCause(error);
            captureException(e, { extra: { request: '/api/security/csrf/v1' } });
            throw e;
        }
    };
    const runSetup = async () => {
        setLoading(true);
        try {
            const csrfToken = await getCsrfToken();
            setCsrfToken(csrfToken);
            setLoading(false);
        }
        catch (err) {
            Sentry.addBreadcrumb({
                category: 'auth',
                message: `Failed during runSetup`,
                level: Sentry.Severity.Info,
            });
            if (err instanceof Error) {
                if (onFail) {
                    onFail(err);
                }
            }
            else {
                captureException(err);
            }
        }
        finally {
            setLoading(false);
        }
    };
    // Run once on initial render
    useEffect(() => {
        if (csrfToken == null) {
            runSetup();
        }
    }, []);
    const onSubmit = async (form) => {
        // Prevent loading twice, or before tokens are available
        var _a;
        if (loading || !csrfToken || recaptchaEnabled == null) {
            return;
        }
        const trimmedForm = {
            ...form,
            email: form.email.trim(),
            handle: form.handle.trim(),
        };
        setLoading(true);
        setValidation({});
        const registerWithFirebaseIfRequired = async () => {
            const auth = firebase.auth();
            // Step 1 - Check Repeat API for obvious validation errors
            const checkAvailability = await checkRegistrationAvailability({
                userHandle: trimmedForm.handle,
                userEmail: trimmedForm.email,
            });
            const { available, userEmail, userHandle, isLinkedToProvider } = checkAvailability.data;
            if (!available) {
                const nextValidation = {};
                if (userEmail) {
                    // if the users email is associated with another provider(s) we show them an option to recover the account by resetting password.
                    if (isLinkedToProvider) {
                        const currentSignInServiceProvider = await getSignInServiceProvider(auth);
                        nextValidation.email = `Sorry, this email is already associated with another account. Please sign in to that account and navigate to your social account settings to link your ${currentSignInServiceProvider} account. In case you've forgotten your password, you can reset it.`;
                    }
                    else {
                        nextValidation.email = userEmail;
                    }
                }
                if (userHandle) {
                    nextValidation.handle = userHandle;
                }
                setValidation(nextValidation);
                throw new Error('Fields are already in use');
            }
            // Step 2 - Use the current Firebase user and complete sign up, or register a new user in Firebase Auth
            const userCredential = auth.currentUser
                ? { user: auth.currentUser }
                : await auth.createUserWithEmailAndPassword(form.email, form.password);
            const { user } = userCredential;
            if (user) {
                try {
                    user.updateProfile({ displayName: form.handle.trim() });
                }
                catch (err) {
                    captureException(err, {
                        extra: {
                            action: 'updateProfile',
                            email: form.email,
                            displayName: form.handle,
                        },
                    });
                }
            }
            return user;
        };
        const registerWithSymfonyAndRetryCsrfErrors = async (user) => {
            var _a, _b;
            let requestPayload = {
                ...trimmedForm,
                email: trimmedForm.email,
                handle: trimmedForm.handle,
                csrfToken,
                authToken: await (user === null || user === void 0 ? void 0 : user.getIdToken()),
                'g-recaptcha-response': recaptchaEnabled ? recaptchaKey : undefined,
            };
            const maxRetryCount = 3;
            for (let retryCount = 0; retryCount < maxRetryCount; retryCount++) {
                try {
                    return await axios.post('/api/user/register/v1', requestPayload);
                }
                catch (error) {
                    const e = new ErrorWithCause(error);
                    if (retryCount < maxRetryCount - 1 && isAxiosError(error) && ((_b = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.csrfToken)) {
                        requestPayload.csrfToken = error.response.data.csrfToken;
                        setCsrfToken(error.response.data.csrfToken);
                    }
                    else {
                        captureException(e, { extra: { request: 'register', retryCount } });
                        throw e;
                    }
                }
            }
        };
        try {
            const user = await registerWithFirebaseIfRequired();
            const response = await registerWithSymfonyAndRetryCsrfErrors(user);
            Sentry.addBreadcrumb({
                category: 'auth',
                message: `Successfully registered with Firebase and Symfony`,
                level: Sentry.Severity.Info,
            });
            if ((response === null || response === void 0 ? void 0 : response.status) === 200) {
                doTrackEvent({ type: 'sign_up', userId: user === null || user === void 0 ? void 0 : user.uid });
                trackHotjarEvent('Sign up success');
                let reauthenticateUser = false;
                const auth = firebase.auth();
                try {
                    // force refresh JWT token to ensure all claims are in sync from symfony updating firebase (if required)
                    // throw error to simulate issue
                    await ((_a = auth === null || auth === void 0 ? void 0 : auth.currentUser) === null || _a === void 0 ? void 0 : _a.getIdToken(true));
                }
                catch (err) {
                    reauthenticateUser = true;
                    Sentry.addBreadcrumb({
                        category: 'auth',
                        message: `failed to refresh JWT token, login required.`,
                        level: Sentry.Severity.Info,
                    });
                }
                if (onSuccess) {
                    onSuccess(response.data, trimmedForm, reauthenticateUser);
                    const provider = await getCurrentLoginProvider();
                    if (provider === 'google') {
                        trackUserFlow('account_register_complete', { formStep: 'Registration confirmed automated' });
                    }
                }
            }
        }
        catch (error) {
            trackHotjarEvent('Sign up fail');
            if (error instanceof Error) {
                if (onFail) {
                    onFail(error);
                }
            }
            else {
                captureException(error);
            }
        }
        finally {
            setLoading(false);
        }
    };
    return [
        {
            loading,
            csrfToken,
            recaptchaEnabled,
            recaptchaKey,
            validation,
        },
        onSubmit,
    ];
};
export default useRegisterRequest;
