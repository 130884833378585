import swagbucksTournamentModalLastShown from './swagbucksTournamentModalLastShown';
const showLoLUSwagbucksTournament = (userContext, gameContext, gameSlug) => {
    var _a;
    const isLoL = gameContext.games.find((game) => game.slug === gameSlug);
    const hasLoL = userContext.userHasGame('league_of_legends');
    const userIsFromUS = ((_a = userContext === null || userContext === void 0 ? void 0 : userContext.userDetails) === null || _a === void 0 ? void 0 : _a.country) === 'US';
    if (isLoL &&
        hasLoL &&
        userIsFromUS &&
        (swagbucksTournamentModalLastShown() >= 48 || swagbucksTournamentModalLastShown() === false)) {
        return true;
    }
    return false;
};
export default showLoLUSwagbucksTournament;
