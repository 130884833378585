import { styled } from '@mui/material/styles';
import TournamentEligibility from '@components/Tournament/TournamentEligibility';
import rightBorder from "./RightBorder";
const StyledTournamentEligibility = styled(TournamentEligibility) `
  padding-left: 0;
  justify-content: center;
  margin: 0;
  ${(props) => rightBorder(props)}
`;
export default StyledTournamentEligibility;
