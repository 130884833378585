import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from '@mui/material/styles';
import { useComparisonContext } from './TierComparisonProvider';
import RealMoney from '@components/UI/RealMoney';
import { getTierBoxImg } from './helpers';
const PlayerTierWrapper = styled('div') `
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px;
  margin-top: 20px;

  &:first-of-type {
    border-right: 1px solid rgba(236, 236, 236, 1);
    @media (max-width: 460px) {
      border-right: none;
    }
  }

  & > h1 {
    font-size: 1.5rem;
    color: #000;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  & > h2 {
    font-size: 1.1rem;
    color: ${({ theme }) => theme.palette.grey[400]};
    margin-top: 0px;
    margin-bottom: 10px;

    ${({ theme }) => theme.breakpoints.down('sm')} {
      font-size: 0.9rem;
    }
  }
`;
const shouldForwardProp = (prop) => {
    return !['tierBoxImage', 'tierValue'].includes(prop);
};
const ComparisonGraphic = styled('div', {
    shouldForwardProp,
}) `
  display: flex;
  position: relative;
  box-sizing: border-box;
  min-height: 140px;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
  padding: 12px 20px;
  background-repeat: no-repeat;
  background-image: url(${({ tierBoxImage }) => tierBoxImage});
  background-position: right;
  background-size: cover;
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid rgba(212, 213, 214, 0.55);

  ${({ theme }) => theme.breakpoints.down('sm')} {
    background-position: center;
    padding: 10px;
  }

  &:before {
    content: '${({ tierValue }) => tierValue}';
    font-family: Montserrat, sans-serif;
    font-size: 50px;
    font-weight: bold;
    color: #fff;

    position: absolute;
    left: 185px;
    top: 15%;
    text-align: center;
    display: inline-block;
    width: 80px;

    text-shadow: 0px 0px 7px rgba(0, 0, 0, 0.43);
    transform: rotate(-16.6deg);

    ${({ theme }) => theme.breakpoints.down('md')} {
      left: calc(100% - 70px);
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
      left: 70%;
    }
  }
`;
const ComparisonText = styled('div') `
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  font-size: 12px;

  & > p {
    font-size: 12px;
    margin: 0px;
    width: 10rem;
  }
`;
const PrizeText = styled('div') `
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  font-size: 20px;
`;
const TierComparisonPanel = ({ showUserTier = false, heading }) => {
    const { currentUserScore, userEntryBracketPosition, selectedTierPosition, userTierPrize, selectedTierPrize, selectedScoreTarget, } = useComparisonContext();
    const tierValue = showUserTier ? userEntryBracketPosition : selectedTierPosition;
    const prizeAmountUSD = showUserTier ? userTierPrize : selectedTierPrize;
    return (_jsxs(PlayerTierWrapper, { "data-testid": `comparison-panel-${showUserTier ? 'user-tier' : 'selected-tier'}`, children: [_jsx("h1", { children: heading }), _jsx("h2", { children: showUserTier
                    ? `You currently have ${currentUserScore} Points`
                    : `Reach ${selectedScoreTarget} Points to get this reward` }), _jsx(ComparisonGraphic, { tierBoxImage: getTierBoxImg(tierValue), tierValue: `${tierValue !== null && tierValue !== void 0 ? tierValue : '-'}`, children: _jsxs(ComparisonText, { children: [_jsx(PrizeText, { children: _jsx(RealMoney, { amount: prizeAmountUSD !== null && prizeAmountUSD !== void 0 ? prizeAmountUSD : 0, withDollarSymbol: true }) }), _jsx("p", { children: "For every player in this Bracket" })] }) })] }));
};
export default TierComparisonPanel;
