import { css } from '@mui/material/styles';
const RightBorder = (props) => css `
  position: relative;
  &:after {
    content: '';
    width: 1px;
    height: 44px;
    background-color: ${props.theme.palette.grey[100]};
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;
export default RightBorder;
