import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import classnames from 'classnames';
import CoreButton from '@components/Buttons/CoreButton';
import { useUserState } from '@context/UserContext';
import LoadingLogo from '@components/Modals/LoadingModal/Components/LoadingLogo';
import ModalContent from '@components/Modals/components/ModalContent';
import useWithdrawModalStyles from '../hooks/useWithdrawModalStyles';
const WithdrawBankDetails = ({ confirm, onClose }) => {
    var _a, _b, _c, _d;
    const { classes } = useWithdrawModalStyles();
    const { userDetails, isLoading } = useUserState();
    const [step, setStep] = useState(1);
    // UserDetails is still loading
    if (isLoading) {
        return (_jsx("div", { className: classes.loading, children: _jsx(LoadingLogo, {}) }));
    }
    // UsedDetails has failed to load
    if (userDetails == null) {
        return _jsx(_Fragment, {});
    }
    if (step === 1) {
        return (_jsxs(ModalContent, { children: [_jsx("h3", { children: "Check Policy" }), _jsxs("div", { className: classes.paragraph, children: [_jsxs("p", { children: ["Checks are sent to the full name and mailing address listed in your ", _jsx("span", { "data-ignore": true, children: "Repeat" }), " account at the time you make your withdrawl request."] }), _jsx("p", { children: "If your check gets sent to an incorrect name or mailing address, you will incur a fee to place a stop-payment order on this check." }), _jsx("p", { children: "Maximum 1 check withdraw per week" })] }), _jsx("p", { className: classes.feeLabel, children: "By clicking continue you agree to the above" }), _jsx(CoreButton, { variant: "warning", onClick: () => setStep(2), children: "Next" })] }));
    }
    const shouldDisable = !userDetails.address || !userDetails.country || !userDetails.city || !userDetails.zipCode;
    return (_jsxs(ModalContent, { children: [_jsx("h3", { children: "Confirm your Account Details" }), _jsxs("div", { className: classes.dataTable, children: [_jsx("p", { children: "Full Name" }), _jsxs("p", { className: classes.col2, children: [userDetails.firstName, " ", userDetails.lastName] }), _jsx("p", { children: "Address" }), _jsx("p", { className: classnames(classes.col2, !userDetails.address && classes.errorText), children: (_a = userDetails.address) !== null && _a !== void 0 ? _a : '-' }), _jsx("p", { children: "Country" }), _jsx("p", { className: classnames(classes.col2, !userDetails.country && classes.errorText), children: (_b = userDetails.country) !== null && _b !== void 0 ? _b : '-' }), _jsx("p", { children: "City" }), _jsx("p", { className: classnames(classes.col2, !userDetails.city && classes.errorText), children: (_c = userDetails.city) !== null && _c !== void 0 ? _c : 'Required' }), _jsx("p", { children: "Zip Code" }), _jsx("p", { className: classnames(classes.col2, !userDetails.zipCode && classes.errorText), children: (_d = userDetails.zipCode) !== null && _d !== void 0 ? _d : 'Required' })] }), shouldDisable && _jsx("p", { className: classes.errorText, children: "Please complete your account details before proceeding" }), _jsx(CoreButton, { variant: "grey", size: "sm", className: classes.editDetailsButton, to: "/account/details", onClick: onClose, children: "Edit Details" }), _jsx(CoreButton, { variant: "warning", disabled: shouldDisable, onClick: confirm, children: "Continue" })] }));
};
export default WithdrawBankDetails;
