import 'firebase/compat/auth';
const getSignInServiceProvider = async (auth) => {
    var _a, _b, _c, _d, _e, _f;
    const jwt = await ((_a = auth.currentUser) === null || _a === void 0 ? void 0 : _a.getIdTokenResult());
    const serviceKey = ((_c = (_b = jwt === null || jwt === void 0 ? void 0 : jwt.claims) === null || _b === void 0 ? void 0 : _b.firebase) === null || _c === void 0 ? void 0 : _c.sign_in_provider) === 'custom'
        ? (_d = jwt.claims.custom) === null || _d === void 0 ? void 0 : _d.service_key
        : (_f = (_e = jwt === null || jwt === void 0 ? void 0 : jwt.claims) === null || _e === void 0 ? void 0 : _e.firebase) === null || _f === void 0 ? void 0 : _f.sign_in_provider;
    switch (serviceKey) {
        case 'psn':
            return 'PlayStation Network';
        case 'google.com':
            return 'Google';
        case 'facebook.com':
            return 'Facebook';
        default:
            return serviceKey !== null && serviceKey !== void 0 ? serviceKey : '';
    }
};
export default getSignInServiceProvider;
