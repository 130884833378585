import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
import CoreButton from '@components/Buttons/CoreButton';
import { useInView } from 'react-intersection-observer';
const useStyles = makeStyles()((theme) => ({
    unlimitedTournaments: {
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'center',
        flexDirection: 'column',
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(4),
        },
    },
    unlimitedTournamentsBackground: {
        backgroundImage: `linear-gradient(white, white)`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom',
        backgroundSize: `100% calc(100% - 229px)`,
        [theme.breakpoints.down('md')]: {
            backgroundSize: `100% 75%`,
        },
    },
    image: {
        maxWidth: '100%',
        transition: `transform 0.5s cubic-bezier(0.3, 0, 0, 0.99)`,
    },
    imageHidden: {
        transform: `translateY(200px)`,
    },
    heading: {
        fontSize: '55px',
        fontWeight: 700,
        color: theme.palette.grey[900],
        padding: theme.spacing(14, 8, 0),
        maxWidth: `530px`,
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(5, 0, 0),
            fontSize: '30px',
        },
    },
    textContent: {
        padding: theme.spacing(6, 0),
        maxWidth: `530px`,
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(4, 0),
        },
    },
    joinNowButton: {
        fontSize: '14px',
        borderRadius: 0,
        padding: theme.spacing(2, 8),
        marginBottom: theme.spacing(14),
        borderWidth: '2px',
        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(7),
        },
    },
}));
const UnlimitedTournaments = ({ handleJoinClick, className }) => {
    const { classes, cx } = useStyles();
    const [ref, inView] = useInView({
        triggerOnce: true,
    });
    return (_jsxs("div", { ref: ref, className: cx(classes.unlimitedTournaments, classes.unlimitedTournamentsBackground, className), children: [_jsx("img", { src: "/img/landing/unlimited_tournaments.png", alt: "", className: cx(classes.image, {
                    [classes.imageHidden]: !inView,
                }) }), _jsx("h2", { className: classes.heading, children: "Play Unlimited Tournaments" }), _jsxs("p", { className: classes.textContent, children: ["On ", _jsx("span", { "data-ignore": true, children: "Repeat" }), " you can join an unlimited amount of Tournaments at the same time across all our games. ", _jsx("span", { "data-ignore": true, children: "Repeat" }), " will automatically track and score your relevant matches for every Tournament you've joined."] }), _jsx(CoreButton, { type: "button", onClick: handleJoinClick, className: classes.joinNowButton, addFancyHover: true, children: _jsx("span", { children: "Join Now" }) })] }));
};
export default UnlimitedTournaments;
