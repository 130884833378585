export const getTournamentDefaultBanner = (gameKey, tournament, isMobile) => {
    // If the tournament has a banner, return it.
    if (tournament === null || tournament === void 0 ? void 0 : tournament.banner) {
        return tournament.banner;
    }
    const bannerSuffix = isMobile ? '_small' : '';
    // PR Check if this is still being used, deprecate or add other games?
    const defaultBanners = {
        league_of_legends: `/img/brand/repeat/league_of_legends_banner${bannerSuffix}.png`,
        'pc-dota_2': `/img/brand/repeat/pc-dota_2_banner${bannerSuffix}.png`,
        fortnite: `/img/brand/repeat/fortnite_banner${bannerSuffix}.png`,
        'cod-warzone': `/img/brand/repeat/cod-warzone_banner${bannerSuffix}.png`,
        'cod-warzone-2': `/img/brand/repeat/cod-warzone_banner${bannerSuffix}.png`,
        valorant: `/img/brand/repeat/valorant_banner${bannerSuffix}.png`,
    };
    /* Currently supported images
     * Fortnite: daily, 3day, weekly
     * Dota 2: 3day, weekly, monthly,
     * COD: 3day, weekly, monthly
     * LoL: Doesn't have length_english? potentially deprecating?
     */
    return defaultBanners[gameKey];
};
export default getTournamentDefaultBanner;
