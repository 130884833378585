// 'en' | 'es-LA' | 'ru' | 'pt-BR' | 'fr' | 'de' | 'ja' | 'zh';
const getAdobeLocale = (code) => {
    switch (code) {
        case 'ja':
            return 'jp-ja';
        // Anything not in SupportedLocales should either be bucketed into us-en or jp-ja RPT-2944
        default:
            return 'us-en';
    }
};
export default getAdobeLocale;
