import { useQuery } from 'react-query';
import getTournaments from '@api/getTournaments';
import { useUserState } from '@context/UserContext';
import { captureException } from '@sentry/react';
const useGetTournaments = (filters, pageNumber, resultsPerPage, options = {}) => {
    const userContextState = useUserState();
    const { authenticated } = userContextState;
    return useQuery(['tournaments', filters, pageNumber, resultsPerPage, authenticated], () => getTournaments(filters, pageNumber, resultsPerPage), {
        ...options,
        staleTime: 1000 * 60 * 5,
        onError: (error) => {
            captureException(error, {
                extra: { request: 'tournaments', file: 'useGetTournaments', filters, pageNumber, resultsPerPage },
            });
        },
    });
};
export default useGetTournaments;
