import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
const variantColors = {
    verified: {
        topRight: '#00ACF6',
        bottomLeft: '#90DEFF',
    },
    partner: {
        topRight: '#D58FFF',
        bottomLeft: '#AD06E8',
    },
};
const VerifiedCheckmark = ({ className, variant }) => {
    return (_jsxs("svg", { className: className, viewBox: "0 0 23 21", fill: "none", children: [_jsx("path", { d: "M23 10.5c0-1.092-1.081-1.747-1.944-2.238a6.95 6.95 0 01-.77-.48 5.42 5.42 0 01.333-.863c.38-.874.863-1.955.184-2.84-.678-.884-1.92-.796-2.92-.71a7.157 7.157 0 01-.955 0c-.08-.196-.184-.6-.253-.862-.219-.928-.506-2.085-1.622-2.424-1.115-.338-2.012.426-2.76 1.038a6.815 6.815 0 01-.793.644 6.86 6.86 0 01-.793-.59C9.959.564 9.027-.2 7.947.138 6.865.476 6.543 1.612 6.325 2.54c-.069.262-.173.666-.253.863a7.157 7.157 0 01-.955 0c-1-.088-2.23-.186-2.92.71-.69.895-.196 1.965.183 2.838.136.279.248.567.334.863a6.946 6.946 0 01-.77.48C1.08 8.753 0 9.408 0 10.5c0 1.092 1.081 1.747 1.944 2.239.267.143.525.304.77.48a5.421 5.421 0 01-.333.863c-.38.873-.863 1.954-.184 2.839.678.884 1.92.797 2.92.71.318-.022.637-.022.955 0 .08.196.184.6.253.862.218.928.506 2.086 1.622 2.424 1.115.339 2.012-.426 2.76-1.092.248-.215.514-.412.793-.59.28.178.544.375.793.59a3.784 3.784 0 002.22 1.092c.184-.003.366-.033.54-.087 1.15-.339 1.403-1.496 1.622-2.424.069-.262.172-.666.253-.863a7.17 7.17 0 01.954 0c1.001.087 2.231.186 2.921-.71.69-.895.196-1.965-.184-2.839a5.421 5.421 0 01-.333-.862c.245-.177.503-.337.77-.48C21.92 12.246 23 11.591 23 10.5zm-7.153-2.053l-4.6 5.46c-.1.12-.225.22-.367.291-.143.072-.3.114-.461.124h-.069c-.151 0-.301-.027-.441-.081a1.156 1.156 0 01-.376-.236l-2.3-2.184a1.07 1.07 0 01-.338-.775c0-.29.122-.57.339-.775.216-.206.51-.321.816-.321.306 0 .6.115.816.32l1.392 1.322 3.795-4.499a1.192 1.192 0 011.552-.105c.225.17.375.415.42.685.045.27-.018.546-.178.774z", fill: `url(#backgroundGradient${variant})` }), _jsx("defs", { children: _jsxs("linearGradient", { id: `backgroundGradient${variant}`, x1: "23.961", y1: "-2.499", x2: "5.103", y2: "27.422", gradientUnits: "userSpaceOnUse", children: [_jsx("stop", { stopColor: variantColors[variant].topRight }), _jsx("stop", { offset: "1", stopColor: variantColors[variant].bottomLeft })] }) })] }));
};
export default VerifiedCheckmark;
