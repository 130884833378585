import { styled } from '@mui/material/styles';
import rightBorder from '@domain/TournamentV2/TournamentTable/styled/RightBorder';
import RankRange from '../RankRange';
const StyledRankRangeColumn = styled(RankRange) `
  margin: 8px 0;
  display: flex;
  justify-content: center;
  padding: 0 12px;
  align-items: center;
  ${(props) => rightBorder(props)}
`;
export default StyledRankRangeColumn;
