import { captureException } from '@sentry/react';
import axios, { isAxiosError } from 'axios';
const postRequestOtp = async () => {
    var _a;
    try {
        const response = await axios.post(`${process.env.API_BASE_URL}/otp/send`);
        return response.data;
    }
    catch (error) {
        if (isAxiosError(error) && ((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 429) {
            return { requestRateExceeded: true, valid: false };
        }
        captureException(error, {
            extra: {
                request: 'postRequestOtp',
            },
        });
        throw error;
    }
};
export default postRequestOtp;
