import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classnames from 'classnames';
import CoreButton from "@components/Buttons/CoreButton";
import useStyles from '../useStyles';
import TournamentOverview from './TournamentOverview';
import Navigation from './Navigation';
const Banner = ({ featuredImage, headerBackground, title, intro, tournamentOverview, handleLoginAndJoinClick, handleJoinClick, }) => {
    const { classes } = useStyles({
        headerBackground: headerBackground.startsWith('http') ? `url("${headerBackground}")` : headerBackground,
    });
    return (_jsxs("section", { className: classes.masthead, children: [_jsx("div", { className: classes.container, children: _jsx(Navigation, { handleLoginAndJoinClick: handleLoginAndJoinClick, handleJoinClick: handleJoinClick }) }), _jsxs("div", { className: classes.bannerWrapper, children: [_jsxs("div", { className: classnames(classes.container, classes.bannerContent), children: [_jsx("img", { src: featuredImage, alt: "" }), _jsxs("div", { className: classes.stackedContent, children: [_jsx("h1", { className: classnames(classes.whiteText, classes.textShadow, classes.title), children: title }), _jsx("p", { className: classnames(classes.whiteText, classes.textShadow), children: intro }), _jsxs("p", { className: classnames(classes.whiteText, classes.bannerButtonWrapper), children: [_jsx(CoreButton, { className: classnames(classes.buttonOverride, classes.bannerButton), variant: "white", onClick: handleJoinClick, children: "Play Now" }), _jsxs("span", { children: ["\u2022 Exclusive ", _jsx("span", { "data-ignore": true, children: "Repeat" }), " Tournament"] })] })] })] }), _jsx(TournamentOverview, { ...tournamentOverview })] })] }));
};
export default Banner;
