import { jsx as _jsx } from "react/jsx-runtime";
import { Slider } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme) => ({
    thumb: {
        background: theme.palette.common.white,
        border: `2px solid ${theme.palette.primary.main}`,
        height: '14px',
        marginTop: '-4px',
        width: '14px',
    },
    rail: {
        height: '6px',
        background: '#E4E4E4',
        borderRadius: '10px',
    },
    track: {
        height: '6px',
        background: theme.palette.primary.main,
        borderRadius: '10px',
    },
}));
const SliderField = ({ value, min, max, step, ariaLabelledby, onChange }) => {
    const { classes } = useStyles();
    return (_jsx(Slider, { value: value, min: min, max: max, step: step, "aria-labelledby": ariaLabelledby, onChange: onChange, classes: { track: classes.track, thumb: classes.thumb, rail: classes.rail } }));
};
export default SliderField;
