import { displayMessageFromError, isAxiosError } from './errorHelper';
/**
 * TODO - deprecate this class in RPT-4011
 * Sentry recommended we configure our project with fingerprinting rules https://docs.sentry.io/product/data-management-settings/event-grouping/fingerprint-rules/
 */
class ErrorWithCause extends Error {
    constructor(err) {
        const message = displayMessageFromError(err);
        super(message);
        this.cause = err;
        this.isAxiosError = isAxiosError(err) && !!(err === null || err === void 0 ? void 0 : err.isAxiosError);
        this.response = isAxiosError(err) ? err === null || err === void 0 ? void 0 : err.response : undefined;
    }
}
export default ErrorWithCause;
