const isShoppingCart = (cart) => {
    return (cart === null || cart === void 0 ? void 0 : cart.cartType) === 'shopping';
};
const isPrizeCart = (cart) => {
    return (cart === null || cart === void 0 ? void 0 : cart.cartType) === 'prize';
};
const getCartQuantity = (cart) => {
    if (!cart || !cart.lineItems) {
        return 0;
    }
    if (!isShoppingCart(cart)) {
        return cart.lineItems.length;
    }
    return cart.lineItems.reduce((quantity, lineItem) => {
        return quantity + lineItem.quantity;
    }, 0);
};
export default getCartQuantity;
export { isShoppingCart, isPrizeCart, getCartQuantity };
