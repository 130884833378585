import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { captureException } from '@sentry/react';
import { makeStyles } from 'tss-react/mui';
import CrossCloseIcon from '@components/UI/CrossCloseIcon';
import loadable from '@loadable/component';
import GenericModal from '@components/Modals/GenericModal';
import ModalHeader from '@components/Modals/components/ModalHeader';
import ModalContent from '@components/Modals/components/ModalContent';
import { getAccountLoginSocialMediaTypes } from '@domain/User/constants';
import ModalCarouselContent from '@components/Modals/components/ModalCarouselContent';
import ConnectSocialMediaStatus from '../SocialAccounts/components/ConnectSocialMediaStatus';
import ConnectSocialMediaAccounts from '../SocialAccounts/components/ConnectSocialMediaAccounts';
import ConnectGoogleAccount from './ConnectGoogle/ConnectGoogleAccount';
import ConnectFacebookAccount from './ConnectFacebookAccount';
import ConnectPlayStationAccount from './ConnectPlayStationAccount';
import ErrorCodeModal from '../../../../components/Modals/ErrorCodeModal';
import ConnectAppleAccount from './ConnectAppleAccount';
const Icon = loadable(() => import('@components/Icons/Icon'));
const useStyles = makeStyles()(() => ({
    closeBtn: {
        alignItems: 'center',
        background: 'transparent',
        border: 'none',
        display: 'inline-flex',
        height: '30px',
        justifyContent: 'center',
        lineHeight: '14px',
        position: 'absolute',
        right: '10px',
        top: '10px',
        width: '30px',
        '& .fal::before': {
            fontSize: '20px',
        },
        color: '#a1a1a1',
    },
}));
/**
 * Modal for adding a login account
 *
 * @param {Object} props The component props
 * @param {boolean} props.open determines whether the modal is open or not.
 * @param {Function} props.onClose Invoked anytime the modal is dismissed.
 * @returns The connect login account modal component
 */
const ConnectLoginAccountModal = ({ hiddenTypes, open, onClose }) => {
    const [pages, setPages] = useState(['Select']);
    const currentPage = pages[pages.length - 1];
    const [errorMessage, setErrorMessage] = useState();
    const [selectedSocialProvider, setSelectedSocialProvider] = useState();
    const availableTypes = getAccountLoginSocialMediaTypes('register').filter((type) => !(hiddenTypes === null || hiddenTypes === void 0 ? void 0 : hiddenTypes.includes(type)));
    // deselect social provider on first page
    useEffect(() => {
        if (pages.length === 1) {
            setSelectedSocialProvider(undefined);
        }
    }, [pages, setSelectedSocialProvider]);
    const { classes } = useStyles();
    const handleClose = () => {
        onClose();
        setPages(['Select']);
    };
    const onSelectProvider = (provider) => {
        setSelectedSocialProvider(provider);
        if (provider === 'google') {
            setPages([...pages, 'Google']);
        }
        else if (provider === 'facebook') {
            setPages([...pages, 'Facebook']);
        }
        else if (provider === 'psn') {
            setPages([...pages, 'PlayStation Network']);
        }
        else if (provider === 'apple') {
            setPages([...pages, 'Apple']);
        }
    };
    const onSuccess = () => {
        setPages([...pages, 'Success']);
    };
    const onError = (error) => {
        setErrorMessage(error.message);
        captureException(error, {
            extra: {
                component: 'ConnectLoginAccountModal',
                provider: selectedSocialProvider,
            },
        });
        setPages([...pages, 'Error']);
    };
    const popPage = () => {
        if (pages.length > 1) {
            setPages(pages.slice(0, pages.length - 1));
        }
    };
    const renderPage = (pageName) => {
        switch (pageName) {
            case 'Select':
                return (_jsxs(ModalContent, { align: "center", children: [_jsx("h2", { children: "Login Accounts" }), _jsx("span", { children: "Please select the login provider that you want to connect to your account" }), _jsx(ConnectSocialMediaAccounts, { availableTypes: availableTypes, onSelect: onSelectProvider })] }));
            case 'Google':
                return _jsx(ConnectGoogleAccount, { onSuccess: onSuccess, onError: onError });
            case 'Facebook':
                return _jsx(ConnectFacebookAccount, { onSuccess: onSuccess, onError: onError });
            case 'Apple':
                return _jsx(ConnectAppleAccount, { onSuccess: onSuccess, onError: onError });
            case 'PlayStation Network':
                return _jsx(ConnectPlayStationAccount, {});
            case 'Success':
                if (selectedSocialProvider) {
                    return (_jsx(ConnectSocialMediaStatus, { status: "success", socialMediaType: selectedSocialProvider, onConfirm: handleClose }));
                }
                throw new Error(`No provider was selected`);
            case 'Error':
                if (selectedSocialProvider) {
                    return (_jsx(ConnectSocialMediaStatus, { errorMessage: errorMessage, status: "error", socialMediaType: selectedSocialProvider, onConfirm: popPage, onRetry: popPage }));
                }
                throw new Error(`No provider was selected`);
            default:
                throw new Error(`Modal page "${pageName}" was not recognised!`);
        }
    };
    const onPop = (nextPageKeys, index) => {
        if (index === 0 || index === 1) {
            setPages(['Select']);
        }
        else {
            setPages(nextPageKeys);
        }
    };
    if (open && availableTypes.length === 0) {
        return (_jsx(ErrorCodeModal, { title: "You have added all available login accounts", showContactSupport: false, onClose: handleClose }));
    }
    return (_jsxs(GenericModal, { show: open, size: "small", disableBackdropClick: false, onClose: handleClose, mobileFullScreen: true, children: [selectedSocialProvider && (_jsx(ModalHeader, { title: currentPage, subtitle: "Connect your account", icon: _jsx(Icon, { icon: selectedSocialProvider }), onClose: handleClose, paletteName: selectedSocialProvider })), _jsx(ModalCarouselContent, { loading: false, name: "connect-login-account", pageKeys: pages, onPop: onPop, renderPage: renderPage, hideBreadcrumb: currentPage === 'Success' || currentPage === 'Error' || currentPage === 'Select' }), !selectedSocialProvider && _jsx(CrossCloseIcon, { className: classes.closeBtn, action: handleClose })] }));
};
export default ConnectLoginAccountModal;
