import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from '@mui/system';
const SupportButton = styled('button') `
  border: none;
  background: none;
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: bold;
`;
const TournamentMessage = ({ messageKey, hasFiltersApplied }) => {
    const handleSupportClick = () => {
        var _a;
        if (window.zE) {
            const widget = ((_a = window.zE) === null || _a === void 0 ? void 0 : _a.widget) === 'messenger' ? 'messenger' : 'webWidget';
            window.zE(widget, 'show');
            window.zE(widget, 'open');
        }
    };
    switch (messageKey) {
        case 'my_tournaments':
            return (_jsxs("p", { children: ["No past Tournaments found. ", _jsx("br", {}), " Enter one now and start winning!"] }));
        case 'under_development':
            return _jsx("p", { children: "This game is currently under development, and competitions will be available soon." });
        case 'error':
            return (_jsxs("p", { children: ["An error was encountered while fetching tournaments. Please contact", _jsx(SupportButton, { onClick: handleSupportClick, children: "Support" }), "."] }));
        case 'coming_soon':
            if (hasFiltersApplied) {
                return _jsx("p", { children: "No matching tournaments found" });
            }
            return _jsx("p", { children: "No tournaments currently live, more tournaments coming soon" });
        default:
            throw new Error('Unrecognised MessageKey');
    }
};
export default TournamentMessage;
