import ErrorWithCause from '@helpers/ErrorWithCause';
import axios from 'axios';
const updateUserFirebaseState = async () => {
    try {
        const response = await axios.post(`/api/user/v1/sync-firebase-state`);
        return response.data;
    }
    catch (error) {
        throw new ErrorWithCause(error);
    }
};
export default updateUserFirebaseState;
