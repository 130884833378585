import { differenceInHours } from 'date-fns';
/**
 * @returns number of hours since the date stored in local storage
 */
const swagbucksTournamentModalLastShown = () => {
    const swagbucksTournamentModalLastShownDate = localStorage.getItem('swagbucksTournamentModalLastShown');
    if (!swagbucksTournamentModalLastShownDate) {
        return false;
    }
    return differenceInHours(new Date(), new Date(JSON.parse(swagbucksTournamentModalLastShownDate)));
};
export default swagbucksTournamentModalLastShown;
