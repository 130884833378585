import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
/**
 * An easier way to get & set query string parameters.
 */
const useQueryString = () => {
    const history = useHistory();
    const location = useLocation();
    const queryObject = queryString.parse(location.search);
    const updateQueryString = (object, options) => {
        const newQueryString = queryString.stringify(object, { skipEmptyString: true, ...options });
        const { hash } = location;
        history.push({ search: newQueryString, hash: hash });
    };
    const getString = (key) => {
        const value = queryObject[key];
        if (Array.isArray(value)) {
            return value[0];
        }
        return value !== null && value !== void 0 ? value : undefined;
    };
    const getStringArray = (key) => {
        const value = queryObject[key];
        if (Array.isArray(value)) {
            return value;
        }
        if (value) {
            return [value];
        }
        return undefined;
    };
    const getNumber = (key) => {
        const value = getString(key);
        if (!value) {
            return undefined;
        }
        const numberValue = parseInt(value, 10);
        return Number.isNaN(numberValue) ? undefined : numberValue;
    };
    const getBoolean = (key) => {
        const value = getString(key);
        if (value === undefined) {
            return undefined;
        }
        return value === 'true';
    };
    return {
        queryObject,
        getString,
        getStringArray,
        getNumber,
        getBoolean,
        update: updateQueryString,
    };
};
export default useQueryString;
