import { jsx as _jsx } from "react/jsx-runtime";
import { useMediaQuery, useTheme } from '@mui/material';
import MobileGameFilter from './MobileGameModeFilter';
import DesktopGameModeFilter from './DesktopGameModeFilter';
const GameModeFilter = ({ gameKey, selectedModes, onChange, onClear }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return isMobile ? (_jsx(MobileGameFilter, { gameKey: gameKey, selectedModes: selectedModes, onChange: onChange })) : (_jsx(DesktopGameModeFilter, { gameKey: gameKey, selectedModes: selectedModes, onChange: onChange, onClear: onClear }));
};
export default GameModeFilter;
