import { getSimplifiedRegionCodes } from 'country-provider';
import getRegionsByDisplayRule from '@domain/Tournament/helpers/getRegionsByDisplayRule';
export const getTournamentRegionsDisplay = (tournament, limit) => {
    const { displayRules } = tournament;
    if (displayRules == null) {
        return 'All Regions';
    }
    let regions = getRegionsByDisplayRule(displayRules);
    if (regions.length === 0) {
        return 'All Regions';
    }
    if (limit && regions.length > limit) {
        const regionLength = regions.length;
        regions = regions.slice(0, limit);
        const newRegionLength = regions.length;
        const difference = regionLength - newRegionLength;
        regions.push(`+${difference}`);
    }
    const simplifiedRegionCodes = getSimplifiedRegionCodes(regions);
    if (simplifiedRegionCodes.length > 0) {
        return simplifiedRegionCodes.map((region) => region.toLocaleUpperCase()).join(' • ');
    }
    return regions.map((region) => region.toLocaleUpperCase()).join(' • ');
};
export const getTournamentRegionsTooltip = (tournament, limit) => {
    const { displayRules } = tournament;
    if (displayRules == null || !limit) {
        return undefined;
    }
    const regions = [];
    displayRules.forEach((rule) => {
        if (rule.object === 'user' && ['state', 'country'].includes(rule.attribute)) {
            if (rule.comparison === 'eq' && typeof rule.value === 'string' && rule.value.toLowerCase() !== 'global') {
                regions.push(rule.value);
            }
            else if (rule.comparison === 'in' && Array.isArray(rule.value)) {
                const validRegions = rule.value.filter((v) => typeof v === 'string' && v.toLowerCase() !== 'global');
                regions.push(...validRegions);
            }
        }
    });
    if (regions.length === 0) {
        return undefined;
    }
    const simplifiedRegionCodes = getSimplifiedRegionCodes(regions);
    if (simplifiedRegionCodes.length > 0) {
        return simplifiedRegionCodes.map((region) => region.toLocaleUpperCase()).join(' • ');
    }
    return regions.map((region) => region.toLocaleUpperCase()).join(' • ');
};
export default getTournamentRegionsDisplay;
