import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import ModalContent from '@components/Modals/components/ModalContent';
import Button from '@components/Buttons/Button';
import HelpHeader from '@components/Modals/components/HelpHeader';
import useTrackEvent from '@hooks/useTrackEvent';
export const ContentUpdateEpicSettings = ({ className, onContinue }) => {
    const trackEvent = useTrackEvent();
    const handleClick = () => {
        trackEvent({
            type: 'game_linking',
            data: {
                eventName: 'game linking',
                label: 'Continue',
                moduleName: 'Button',
                position: 0,
            },
        });
        if (onContinue) {
            onContinue();
        }
    };
    return (_jsxs(ModalContent, { children: [_jsxs("section", { children: [_jsx(HelpHeader, { help: "We are automatically tracking your results, but in order to do that you have to set your Fortnite Statistics to Public", children: _jsxs(_Fragment, { children: ["Make your ", _jsx("span", { "data-ignore": true, children: "Fortnite" }), " Stats Public"] }) }), _jsxs("ol", { className: className, children: [_jsxs("li", { children: ["Launch the game ", _jsx("span", { "data-ignore": true, children: "Fortnite" })] }), _jsx("li", { children: "Enter the Battle Royale mode" }), _jsx("li", { children: "Go to your Settings" }), _jsx("li", { children: "Navigate to your Account Settings tab" }), _jsx("li", { children: "Toggle \"Show On Leaderboard\" option to Yes" })] })] }), _jsx(Button, { type: "button", onClick: handleClick, size: "lg", variant: "palette", paletteName: "fortnite", children: "Continue" })] }));
};
export default ContentUpdateEpicSettings;
