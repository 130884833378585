import axios, { isAxiosError } from 'axios';
import { captureException } from '@sentry/react';
const postValidateOtpCode = async (code) => {
    var _a;
    try {
        const response = await axios.post(`${process.env.API_BASE_URL}/otp/validate/${code}`);
        return response.data;
    }
    catch (error) {
        if (isAxiosError(error) && ((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 400) {
            return error.response.data;
        }
        captureException(error, {
            extra: {
                request: 'postValidateOtpCode',
                code,
            },
        });
        throw error;
    }
};
export default postValidateOtpCode;
