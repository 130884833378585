import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
export const ACTION_SHOW_GAME_MODAL = 'show-game-modal-webview';
const LOCAL_STORAGE_KEY = 'mobileAppWebView';
const LOCAL_STORAGE_VALUE = 'true';
const useAddGameWebView = (action, callback) => {
    const [hasCallbackRun, setHasCallbackRun] = useState(false);
    const location = useLocation();
    useEffect(() => {
        if (!hasCallbackRun) {
            const urlSearchParams = new URLSearchParams(location.search);
            if (urlSearchParams.has('webViewAction') && urlSearchParams.get('webViewAction') === action) {
                localStorage.setItem(LOCAL_STORAGE_KEY, LOCAL_STORAGE_VALUE);
                callback();
                setHasCallbackRun(true);
            }
        }
    }, [action, callback, hasCallbackRun, location.search]);
};
const isMobileWebView = () => {
    return localStorage.getItem(LOCAL_STORAGE_KEY) === LOCAL_STORAGE_VALUE;
};
export { useAddGameWebView, isMobileWebView };
