export const setCurrentStepAction = (currentStep) => ({
    type: 'SET_CURRENT_STEP',
    currentStep,
});
export const setIsLoadingAction = (isLoading) => ({
    type: 'SET_IS_LOADING',
    isLoading,
});
export const setFetchedErrorResponse = (response) => ({
    type: 'SET_FETCHED_RESPONSE',
    isError: true,
    response,
    errorMessage: response.result,
});
export const setFetchedSuccessResponse = (response) => ({
    type: 'SET_FETCHED_RESPONSE',
    isError: false,
    response,
});
export const setSelectedGameAction = (selectedGame) => ({
    type: 'SET_SELECTED_GAME',
    selectedGame,
});
export const setSelectedGameStepsAction = (steps) => ({
    type: 'SET_SELECTED_GAME_STEPS',
    steps,
});
export const setIsOverConsentAgeAction = (isOverConsentAge) => ({
    type: 'SET_IS_OVER_CONSENT_AGE',
    isOverConsentAge,
});
export const setInitialStateAction = (initialState) => ({
    type: 'SET_INITIAL_STATE',
    initialState,
});
export const setGameConnectStatusAction = (gameConnectionStatus) => ({
    type: 'SET_GAME_CONNECT_STATUS',
    gameConnectionStatus,
});
export const setRequireAgePromptAction = (requireAgePrompt) => ({
    type: 'SET_REQUIRE_AGE_PROMPT',
    requireAgePrompt,
});
export const setAvailableGameServicesAction = (availableGameServices) => ({
    type: 'SET_AVAILABLE_GAME_SERVICES',
    availableGameServices,
});
export const setConnectRegionAction = (region) => ({
    type: 'SET_CONNECT_REGION',
    region,
});
export const setConnectGameServiceAction = (gameService) => ({
    type: 'SET_CONNECT_GAME_SERVICE',
    gameService,
});
export const setConnectPlatformAction = (platform) => ({
    type: 'SET_CONNECT_PLATFORM',
    platform,
});
export const resetConnectSelectionAction = () => ({
    type: 'RESET_CONNECT_SELECTION',
});
export const setIsProviderReady = (isProviderReady) => ({
    type: 'SET_IS_PROVIDER_READY',
    isProviderReady,
});
