import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classnames from 'classnames';
import Currency from '@components/UI/Currency';
import CoreButton from "@components/Buttons/CoreButton";
import useGame from '@hooks/API/useGame';
import getTournamentCountdown from '../Helpers/getTournamentCountdown';
import OverviewTile from './OverviewTile';
import useStyles from '../useStyles';
import CalendarIcon from './CalendarIcon';
import EntriesIcon from './EntriesIcon';
import PrizePoolIcon from './PrizePoolIcon';
import SlotsIcon from './SlotsIcon';
import GameWordmark from '@components/UI/GameWordmark/GameWordmark';
import useGamePageUrl from '@hooks/useGamePageUrl';
const TournamentOverview = ({ startTime, endTime, recentEntries, prizePool, prizeCurrency, slots, accentColor, gameKey, }) => {
    const countDown = getTournamentCountdown(Date.parse(startTime), Date.parse(endTime));
    const { classes } = useStyles({ accentColor });
    const gameData = useGame(gameKey);
    const tournamentListPageUrl = useGamePageUrl('Tournaments', gameData);
    return (_jsx("section", { children: _jsxs("div", { className: classnames(classes.container, classes.tournamentOverviewWrapper), children: [_jsxs("div", { className: classes.tournamentOverviewIntro, children: [_jsxs("div", { children: [_jsx("h2", { className: classnames(classes.headingText, classes.subheading), children: "Tournament Overview" }), _jsx("p", { children: "Some things you should know about this tournament" })] }), _jsx("div", { className: classes.gameNameButtonWrapper, children: _jsx(CoreButton, { size: "lg", className: classnames(classes.buttonOverride, classes.blackButton, classes.gameNameButton), to: tournamentListPageUrl, children: _jsx(GameWordmark, { gameKey: gameKey }) }) })] }), _jsxs("div", { className: classes.tournamentOverviewTiles, children: [_jsx(OverviewTile, { title: countDown.title, content: countDown.content, Icon: _jsx(CalendarIcon, {}) }), _jsx(OverviewTile, { title: "Players in Tournament", content: recentEntries.toLocaleString(), Icon: _jsx(EntriesIcon, {}) }), _jsx(OverviewTile, { title: "Tournament Prize Pool", content: _jsx(Currency, { amount: prizePool, currency: prizeCurrency }), Icon: _jsx(PrizePoolIcon, {}) }), _jsx(OverviewTile, { title: "Total Slots in Tournament", content: slots ? slots.toLocaleString() : 'Unlimited', Icon: _jsx(SlotsIcon, {}) })] })] }) }));
};
export default TournamentOverview;
