import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import GenericModal from "@components/Modals/GenericModal";
import CoreButton from "@components/Buttons/CoreButton";
import AddGameStatusBanner from '@components/AddGame/AddGameStatusBanner';
import LoadingAnimationPlaceholder from '@components/UI/LoadingAnimationPlaceholder';
import ModalTextContent from './components/ModalTextContent';
const useStyles = makeStyles()((theme) => ({
    widthWrapper: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingTop: theme.spacing(4),
        [theme.breakpoints.up(450)]: {
            maxWidth: '430px',
        },
    },
    button: {
        margin: '20px',
    },
}));
const ResultModal = ({ status, message, customTitle, customButtonContent, onClose, show = false, }) => {
    const { classes } = useStyles();
    const theme = useTheme();
    const isFullscreen = useMediaQuery(theme.breakpoints.down(450));
    const headingContent = customTitle !== null && customTitle !== void 0 ? customTitle : (status === 'success' ? 'Success!' : 'Error!');
    return (_jsx(GenericModal, { size: "small", show: show, children: _jsx("div", { className: classes.widthWrapper, children: status === 'loading' ? (_jsx(LoadingAnimationPlaceholder, { title: "" })) : (_jsxs(_Fragment, { children: [_jsx(AddGameStatusBanner, { status: status }), _jsx(ModalTextContent, { title: headingContent, paragraph: message, fillVertical: isFullscreen }), _jsx(CoreButton, { onClick: onClose, size: "md", className: classes.button, children: customButtonContent !== null && customButtonContent !== void 0 ? customButtonContent : 'Close' })] })) }) }));
};
export default ResultModal;
