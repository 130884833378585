import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
import GenericModal from '@components/Modals/GenericModal';
import CoreButton from '@components/Buttons/CoreButton';
import ModalImageHeader from '@components/Modals/components/ModalImageHeader';
import ModalTextContent from '@components/Modals/components/ModalTextContent';
const useStyles = makeStyles()((theme) => ({
    heading: {
        position: 'relative',
    },
    headerImage: {
        width: '100%',
        height: '100%',
    },
    button: {
        margin: '24px',
        background: theme.palette.operaGX.dark,
        color: 'white',
    },
    bgStyle: {
        backgroundColor: theme.palette.common.black,
        color: 'white',
    },
    content: {
        backgroundColor: theme.palette.common.black,
        color: 'white',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
        '& h2': {
            color: 'white',
            [theme.breakpoints.down('md')]: {
                display: 'none',
            },
        },
    },
}));
const OperaGXUSLoLTournamentModal = ({ show, tournamentLink, className, handleClose, }) => {
    const { classes, cx } = useStyles();
    return (_jsxs(GenericModal, { className: cx(className, classes.bgStyle), show: show, mobileFullScreen: false, children: [_jsx(ModalImageHeader, { imageUrl: "/img/modals/opera-gx-us-lol-tournament-heading.jpg", onClose: handleClose }), _jsx(ModalTextContent, { title: _jsxs(_Fragment, { children: ["Enter the ", _jsx("span", { "data-ignore": true, children: "Opera GX" }), " Clash + $750 ", _jsx("span", { "data-ignore": true, children: "RP" }), " Giveaway"] }), paragraph: _jsxs(_Fragment, { children: ["This tournament is sponsored by ", _jsx("span", { "data-ignore": true, children: "Opera GX" }), ", a browser specifically for gamers. Top 30 will win $25 ", _jsx("span", { "data-ignore": true, children: "RP" }), " cards!"] }), className: classes.content }), _jsx(CoreButton, { variant: "operaGX", to: tournamentLink, className: classes.button, children: "Enter for Free" })] }));
};
export default OperaGXUSLoLTournamentModal;
