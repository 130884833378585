import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
import { StatusIndicator } from '@components/UI';
const useStyles = makeStyles()((theme) => ({
    badge: {
        boxShadow: `0px 1px 10px rgba(0,0,0,0.11)`,
        borderRadius: '4px',
        padding: `${theme.spacing(0.5)} 10px`,
    },
}));
const LiveNowBadge = ({ className }) => {
    const { classes, cx } = useStyles();
    return (_jsx(StatusIndicator, { className: cx(classes.badge, className), status: { name: 'live' }, invertColors: true, children: _jsx("span", { children: "Live Now" }) }));
};
export default LiveNowBadge;
