import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import loadable from '@loadable/component';
import doTrackEvent, { extractTournamentAnalyticsData } from '@components/Analytics/doTrackEvent';
import Currency from '@components/UI/Currency';
import StatusIndicator from '@components/UI/StatusIndicator';
import useGame from '@hooks/API/useGame';
import useTournamentDetailsPageUrl from '@hooks/useTournamentDetailsPageUrl';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import useAppState from '@hooks/API/useAppState';
import getTournamentDefaultBanner from '../helpers/getTournamentDefaultBanner';
import getTournamentBoxBannerImage from '../helpers/getTournamentBoxBannerImage';
import TournamentBoxBanner from './TournamentGrid/TournamentBox/TournamentBoxBanner';
const Tickets = loadable(() => import('@components/UI/Icon/Icons/Tickets'));
const TournamentSuggestionBoxContainer = styled('div') `
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette['primary'].light};
  border-radius: 4px;
`;
const HeadingWrapper = styled('div') `
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(2)};
  font-size: 14px;
  border-bottom: 1px solid ${({ theme }) => theme.palette['grey'][200]};

  p {
    margin: 0;
    color: ${({ theme }) => theme.palette['grey'][900]};
  }

  p:last-of-type {
    display: flex;
    color: ${({ theme }) => theme.palette.primary.main};
    white-space: nowrap;

    svg {
      margin-right: ${({ theme }) => theme.spacing(1)};
      width: 16px;
    }
  }
`;
const TouramentContainer = styled(Link) `
  display: flex;
  padding: ${({ theme }) => theme.spacing(2)};
`;
const TournamentDetailWrapper = styled('div') `
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;

  p {
    margin: 0;
    font-size: 10px;
    color: ${({ theme }) => theme.palette.grey[400]};
  }
`;
const StyledCurrency = styled(Currency) `
  font-size: 21px;
  color: ${({ theme }) => theme.palette.grey[900]};

  svg {
    height: 22px;
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
`;
const StyledStatusIndicator = styled(StatusIndicator) `
  font-weight: bold;
`;
const StyledTournamentBoxBanner = styled(TournamentBoxBanner) `
  width: 168px;
  height: 92px;
  margin-right: ${({ theme }) => theme.spacing(2)};
`;
const TournamentSuggestionBox = ({ gameKey, tournament, className, onClick }) => {
    const appState = useAppState();
    const onBoxClick = () => {
        doTrackEvent({
            ...extractTournamentAnalyticsData(tournament, appState.registrationEndingMinutes),
            type: 'open_tournament',
            section: 'add_game',
            listIndex: '0',
            viewType: 'suggestion',
        });
        if (onClick) {
            onClick();
        }
    };
    const gameData = useGame(gameKey);
    const tournamentImageUrl = getTournamentBoxBannerImage(tournament);
    const defaultTournamentImageUrl = getTournamentDefaultBanner(gameKey, tournament, true);
    const bannerImage = tournamentImageUrl !== null && tournamentImageUrl !== void 0 ? tournamentImageUrl : defaultTournamentImageUrl;
    const detailsLink = useTournamentDetailsPageUrl(tournament.id, gameData);
    return (_jsxs(TournamentSuggestionBoxContainer, { className: className, children: [_jsxs(HeadingWrapper, { children: [_jsx("p", { children: "Suggested Tournament" }), _jsxs("p", { children: [_jsx(Tickets, { color: "primary" }), " Free entry"] })] }), _jsxs(TouramentContainer, { onClick: onBoxClick, to: detailsLink, children: [_jsx(StyledTournamentBoxBanner, { bannerImage: bannerImage, paletteName: "fortnite" }), _jsxs(TournamentDetailWrapper, { children: [_jsx("p", { children: "Total Prize Pool" }), _jsx(StyledCurrency, { amount: tournament.prize_pot, currency: tournament.currencyType.abbreviation }), _jsx(StyledStatusIndicator, { status: "success", children: "Registration Open" })] })] })] }));
};
export default TournamentSuggestionBox;
