import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { captureException } from '@sentry/react';
import axios, { isAxiosError } from 'axios';
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import Currency from '@components/UI/Currency';
import CoreButton from "@components/Buttons/CoreButton";
import useGetBalance from "@components/Hooks/useGetBalance";
import ModalAction from '@components/Modals/components/ModalAction';
import ModalContent from '@components/Modals/components/ModalContent';
import ErrorWithCause from '@helpers/ErrorWithCause';
import { displayMessageFromError } from '@helpers/errorHelper';
const useStyles = makeStyles()((theme) => ({
    spacedCurrencyEl: {
        margin: '0 5px',
    },
    currencyContent: {
        display: 'flex',
        '& dl': {
            flex: 1,
            textAlign: 'center',
            borderTop: `1px solid ${theme.palette.grey[200]}`,
            borderBottom: `1px solid ${theme.palette.grey[200]}`,
            padding: theme.spacing(2),
        },
        '& dl:first-child': {
            borderRight: `1px solid ${theme.palette.grey[200]}`,
        },
        '& dt': {
            fontSize: '12px',
            fontWeight: 600,
        },
        '& dd': {
            color: theme.palette.grey[900],
            fontSize: '16px',
        },
    },
    confirmButton: {
        color: 'white',
        '&:hover, &:focus': {
            color: 'white',
        },
    },
    cancelButton: {},
}));
const TransferConfirmation = ({ transactionAmount, onSuccess, onError, onCancel, }) => {
    const { classes } = useStyles();
    const { refetch } = useGetBalance();
    const [isLoading, setIsLoading] = useState(false);
    const requestName = 'exchange_real_for_play_money';
    const handleConfirm = async () => {
        var _a;
        setIsLoading(true);
        try {
            await axios.post('/api/exchange/v1', {
                amount: transactionAmount.toFixed(2),
            });
            setIsLoading(false);
            refetch();
            if (onSuccess) {
                onSuccess();
            }
        }
        catch (error) {
            const e = new ErrorWithCause(error);
            // Request validation errors don't need to be logged
            if (isAxiosError(e) && ((_a = e.response) === null || _a === void 0 ? void 0 : _a.status) !== 400) {
                captureException(e, {
                    extra: {
                        request: requestName,
                        amount: transactionAmount.toFixed(2),
                    },
                });
            }
            setIsLoading(false);
            if (onError) {
                onError(displayMessageFromError(e));
            }
        }
    };
    return (_jsxs(ModalContent, { align: "center", children: [_jsxs("section", { children: [_jsx("h2", { children: "Confirm your Balance Transfer Request" }), _jsxs("div", { className: classes.currencyContent, children: [_jsxs("dl", { children: [_jsx("dt", { id: "convert-usd", children: "Convert" }), _jsx("dd", { "aria-labelledby": "convert-usd", children: _jsx(Currency, { className: classes.spacedCurrencyEl, currency: "USD", amount: transactionAmount }) })] }), _jsxs("dl", { children: [_jsx("dt", { id: "convert-to-pm", children: "to" }), _jsx("dd", { "aria-labelledby": "convert-to-pm", children: _jsx(Currency, { className: classes.spacedCurrencyEl, currency: "PM", amount: transactionAmount * 10000 }) })] })] }), _jsx("p", { children: "Converting cash into coins is one way - you are not able to convert back to cash for any reason" })] }), _jsxs(ModalAction, { children: [_jsx(CoreButton, { variant: "secondary", onClick: handleConfirm, className: classes.confirmButton, loading: isLoading, children: "Confirm" }), _jsx(CoreButton, { variant: "grey", appearance: "outline", onClick: onCancel, children: "Back" })] })] }));
};
export default TransferConfirmation;
