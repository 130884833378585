import DataLayer from '@sie/global-data-layer';
import { useFeature } from 'flagged';
import { useCallback } from 'react';
const registrationFlowStepName = 'account registration';
const registrationFlows = {
    account_register_start: (_) => ({
        flowStep: 'Select registration method',
        componentName: registrationFlowStepName,
        isUserFlowComplete: false,
        isUserFlowStart: true,
    }),
    account_register_step: (params) => ({
        flowStep: params.formStep,
        componentName: registrationFlowStepName,
        isUserFlowComplete: false,
        isUserFlowStart: false,
    }),
    account_register_complete: (params) => ({
        flowStep: params.formStep,
        componentName: registrationFlowStepName,
        isUserFlowComplete: true,
        isUserFlowStart: false,
    }),
};
const addGameFlowStepName = 'game linking';
const addGameFlows = {
    game_link_start: (params) => ({
        flowStep: params.formStep,
        isUserFlowComplete: false,
        isUserFlowStart: true,
        componentName: addGameFlowStepName,
    }),
    game_link_step: (params) => ({
        flowStep: params.formStep,
        isUserFlowComplete: false,
        isUserFlowStart: false,
        componentName: addGameFlowStepName,
    }),
    game_link_complete: (params) => ({
        flowStep: params.formStep,
        isUserFlowComplete: true,
        isUserFlowStart: false,
        componentName: addGameFlowStepName,
    }),
};
const userFlows = {
    ...registrationFlows,
    ...addGameFlows,
};
const useTrackUserFlow = () => {
    const isAdobeAnalyticsEnabled = !!useFeature('enable_adobe_analytics');
    const userFlowFunc = (trackName, params) => {
        var _a;
        const { flowStep, componentName, isUserFlowStart, isUserFlowComplete } = userFlows[trackName](params);
        if (!isAdobeAnalyticsEnabled || !window.digitalData) {
            return;
        }
        DataLayer.component.form(componentName, {
            formStep: flowStep,
            isUserFlowComplete,
            isUserFlowStart,
        });
        // eslint-disable-next-line no-underscore-dangle
        (_a = window._satellite) === null || _a === void 0 ? void 0 : _a.track(trackName);
    };
    const trackUserFlow = useCallback(userFlowFunc, [isAdobeAnalyticsEnabled]);
    return trackUserFlow;
};
export default useTrackUserFlow;
