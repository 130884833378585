import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useCountdown from '@components/Hooks/useCountdown';
import { useLocalAppContext } from '@context/LocalAppContext';
import formatDurationFromNow from '@helpers/formatDurationFromNow';
import useAppState from '@hooks/API/useAppState';
import { styled, useTheme } from '@mui/material/styles';
import getTournamentPhaseContext from './getTournamentPhaseContext';
const MessageMain = styled('span') `
  margin-right: ${({ theme }) => theme.spacing(0.5)};
  color: ${({ theme }) => theme.palette.grey[900]};
`;
const TournamentPhaseBannerSimple = ({ tournament, className }) => {
    const appState = useAppState();
    const theme = useTheme();
    const { setRefresh } = useLocalAppContext();
    const { text, seconds } = getTournamentPhaseContext({
        tournament,
        theme,
        registrationEndingMinutes: appState.registrationEndingMinutes,
    });
    const { remainingSeconds } = useCountdown({
        startSeconds: seconds,
        onCountdownComplete: () => {
            setTimeout(() => {
                setRefresh(1);
            }, 1000);
        },
    });
    return (_jsxs("span", { className: className, children: [_jsx(MessageMain, { children: text }), seconds !== undefined && _jsx("span", { "data-ignore": true, children: formatDurationFromNow(remainingSeconds, true) })] }));
};
export default TournamentPhaseBannerSimple;
