import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import * as React from 'react';
import classnames from 'classnames';
import { styled } from '@mui/material/styles';
const getValidStepIndex = (stepIndex, maxStepSize) => {
    // reverse.
    if (stepIndex < 0) {
        const reversedStep = maxStepSize + stepIndex;
        return reversedStep > 0 ? reversedStep : 0;
    }
    if (stepIndex >= maxStepSize && maxStepSize > 0) {
        return maxStepSize - 1;
    }
    return stepIndex;
};
const CarouselContainer = styled('div') `
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
`;
const CarouselSlides = styled('ul') `
  /* display: grid; */
  display: flex;
  transition: transform 0.4s ease-in-out;
  padding-inline-start: 0px;
  margin-bottom: 0px;
`;
const CarouselSlide = styled('li') `
  transition: opacity 0.4s;
  opacity: 1;
  list-style: none;
  flex: 0 0 100%;

  &.hidden {
    opacity: 0;

    & > div {
      display: none;
    }
  }
`;
/**
 * Simple carousel that will display each child as a slide inside the carousel.
 * If building a new modal from scratch, take a look at `ModalCarouselContent`
 * It still uses `ContentCarousel` internally, but has a slightly more focused API.
 *
 * @param currentStep index of the slide to display (starts at 0). Negative number will go from the back of the array. (i.e. -1 is the last index, -2 is second last)
 * @param className (string) class name for the container.
 * @param children (ReactNode) Slides to be shown in the carousel. Child must be truthy. If the child component has a `stepName` prop or `data-step-name` prop, it'll be recognised and returned in `onStepChange`. Having data-step-name is to allow compatibility on native html element (i.e. div)
 * @param onStepChange (function(stepIndex: number, stepName: string): number) - Function invoked on step change
 */
const ContentCarousel = ({ name, currentStep, className, children, onStepChange, onTransitionEnd, }) => {
    const childComponents = Array.isArray(children) ? children.filter((childComponent) => childComponent) : [children];
    const childNames = childComponents.map((child, idx) => {
        var _a;
        const isValidElement = React.isValidElement(child);
        if (isValidElement) {
            return ((_a = child === null || child === void 0 ? void 0 : child.props) === null || _a === void 0 ? void 0 : _a.stepName) || (child === null || child === void 0 ? void 0 : child.props['data-step-name']) || `${name}-step-${idx + 1}`;
        }
        if (typeof child === 'string' || typeof child === 'number') {
            return `${child}`;
        }
        return '';
    });
    const maxSteps = childComponents.length;
    const [stepIndex, setStepIndex] = useState(getValidStepIndex(currentStep, maxSteps));
    useEffect(() => {
        setStepIndex(getValidStepIndex(currentStep, maxSteps));
    }, [currentStep, maxSteps]);
    useEffect(() => {
        if (onStepChange) {
            onStepChange(stepIndex, childNames[stepIndex]);
        }
    }, [stepIndex]);
    const getSlideTranslateStyle = useCallback(() => {
        // Infinite scroll not a usecase here.
        return {
            transform: `translate(${-100 * stepIndex}%)`,
        };
    }, [stepIndex]);
    const renderChildComponents = useCallback(() => {
        return childComponents.map((child, idx) => {
            return (_jsx(CarouselSlide, { className: classnames({
                    hidden: stepIndex !== idx,
                }), "aria-label": childNames[idx], "aria-hidden": stepIndex !== idx, onTransitionEnd: stepIndex === idx ? onTransitionEnd : undefined, children: child }, childNames[idx]));
        });
    }, [childComponents]);
    return (_jsx(CarouselContainer, { className: className, children: _jsx(CarouselSlides, { style: {
                ...getSlideTranslateStyle(),
            }, children: renderChildComponents() }) }));
};
export default ContentCarousel;
