import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { columnSizing } from '../style';
function shouldForwardProp(prop) {
    return prop !== 'paletteName' && prop !== 'oddRow';
}
const TournamentContainer = styled(Link, { shouldForwardProp }) `
  font-family: ${(props) => props.theme.typography.fontFamily};
  padding: 12px;
  display: grid;
  ${(props) => columnSizing(props.theme)};
  grid-template-columns: 43% 10% 13% 17% 17%;
  @media (min-width: 1200px) {
    grid-template-columns: 43% 10% 13% 17% 17%;
  }

  &:hover {
    background-image: linear-gradient(
      to right,
      ${(props) => props.theme.palette[props.paletteName].light} 1%,
      ${(props) => (props.oddRow ? '#F9FAFC' : props.theme.palette.common.white)} 50%
    );
  }

  & .hover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateX(105%);
    transition: transform 0.3s;
  }

  &:hover .hover {
    transform: translateX(0%);
  }

  background-color: ${(props) => (props.oddRow ? '#F9FAFC' : props.theme.palette.common.white)};
`;
export default TournamentContainer;
