import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { darken } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import useGamesList from '@hooks/API/useGamesList';
import getMenuPalette from '@helpers/getMenuPalette';
import useSetPageHeaderStyle from '@hooks/useSetPageHeaderStyle';
import GameNavLink from '@components/Navigation/GameNavLink';
import MarketplaceMenu from './components';
import { useFeature } from 'flagged';
const useStyles = makeStyles()((theme, { focusedPaletteName, hoveredPaletteName }) => {
    let backgroundColor = theme.palette.grey[100];
    if (hoveredPaletteName && hoveredPaletteName !== 'primary') {
        backgroundColor = darken(theme.palette[hoveredPaletteName].main, 0.1);
    }
    if (focusedPaletteName || (focusedPaletteName && hoveredPaletteName && hoveredPaletteName === 'primary')) {
        backgroundColor = darken(theme.palette[focusedPaletteName].main, 0.1);
    }
    return {
        submenu: {
            position: 'fixed',
            width: '100%',
            top: '0px',
            left: '0px',
            transform: 'translateY(0px)',
            transition: 'transform 0.2s, background 0.2s',
            zIndex: theme.zIndex.appBar - 1,
            alignItems: 'center',
            padding: theme.spacing(0, 20),
            background: hoveredPaletteName ? darken(theme.palette[hoveredPaletteName].main, 0.1) : theme.palette.grey[100],
            '& ul': {
                height: '50px',
                display: 'flex',
                listStyle: 'none',
                padding: 0,
                margin: 0,
            },
            '&.active': {
                transform: 'translateY(55px)',
                background: backgroundColor,
            },
            '& li': {
                marginRight: theme.spacing(4),
                display: 'flex',
                height: '100%',
            },
            '& li a': {
                color: theme.palette.common.white,
                display: 'flex',
                height: '100%',
                alignItems: 'center',
                padding: theme.spacing('3px', 0.5, 0),
                borderBottom: `3px solid transparent`,
                transition: 'border-bottom 0.2s',
                fontSize: '12px',
                fontWeight: 500,
                '&:hover': {
                    borderBottom: '3px solid rgba(255,255,255, 0.5)',
                },
                '&.selected': {
                    borderBottom: `3px solid ${theme.palette.common.white}`,
                },
            },
        },
    };
});
const NavigationSubMenu = ({ currentGame, focusedMenuItem, hoveredMenuItem, onMouseLeave, onMouseEnter, }) => {
    const { classes, cx } = useStyles({
        focusedPaletteName: getMenuPalette(focusedMenuItem),
        hoveredPaletteName: getMenuPalette(hoveredMenuItem),
    });
    const isPlatformFirstEnabled = !!useFeature('enable_platform_first');
    const { allGames } = useGamesList();
    const hoverGame = allGames.reduce((game, gameData) => {
        if (gameData.gameKey === hoveredMenuItem) {
            return gameData;
        }
        return game;
    }, null);
    let menuData = null;
    if (hoverGame) {
        menuData = hoverGame;
    }
    else if (hoveredMenuItem === 'marketplace' || focusedMenuItem === 'marketplace') {
        menuData = 'marketplace';
    }
    else if (!hoveredMenuItem && currentGame) {
        menuData = currentGame;
    }
    const isVisible = Boolean(menuData && (focusedMenuItem || (hoveredMenuItem && hoveredMenuItem !== 'home'))) &&
        !isPlatformFirstEnabled;
    useSetPageHeaderStyle(focusedMenuItem == null || focusedMenuItem === 'home' || isPlatformFirstEnabled ? 55 : 105, focusedMenuItem && !isPlatformFirstEnabled ? getMenuPalette(focusedMenuItem) : undefined);
    return (_jsx("div", { className: cx(classes.submenu, isVisible && 'active'), "aria-hidden": !isVisible, onMouseLeave: onMouseLeave, onMouseEnter: onMouseEnter, children: menuData === 'marketplace' ? (_jsx(MarketplaceMenu, {})) : (_jsxs("ul", { children: [_jsx("li", { children: _jsx(GameNavLink, { page: "Game Hub", game: menuData !== null && menuData !== void 0 ? menuData : undefined, activeClassName: "selected", region: "header", children: "Game Hub" }) }), _jsx("li", { children: _jsx(GameNavLink, { page: "Tournaments", game: menuData !== null && menuData !== void 0 ? menuData : undefined, activeClassName: "selected", region: "header", children: "Tournaments" }) })] })) }));
};
export default NavigationSubMenu;
