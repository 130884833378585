import ErrorWithCause from '@helpers/ErrorWithCause';
import axios from 'axios';
const getBalance = async () => {
    try {
        const response = await axios.get('/api/user/balance/v1');
        return {
            PM: parseFloat(response.data.PM),
            USD: parseFloat(response.data.USD),
        };
    }
    catch (error) {
        throw new ErrorWithCause(error);
    }
};
export default getBalance;
