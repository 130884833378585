const getLeagueOfLegendsGameModes = (gameModes, allowedModes) => {
    let availableGameModes = gameModes.filter((gameMode) => allowedModes.includes(gameMode.code));
    if (availableGameModes.length === 0) {
        const numericGameModes = [];
        gameModes.forEach((element) => {
            const itemToCopy = { ...element };
            switch (element.code) {
                case 'Ranked':
                    itemToCopy.code = '420';
                    numericGameModes.push(itemToCopy);
                    break;
                case 'Flex':
                    itemToCopy.code = '440';
                    numericGameModes.push(itemToCopy);
                    break;
                case 'ARAM':
                    itemToCopy.code = '450';
                    numericGameModes.push(itemToCopy);
                    break;
                default:
                    numericGameModes.push(itemToCopy);
                    break;
            }
        });
        availableGameModes = numericGameModes.filter((gameMode) => allowedModes.includes(gameMode.code));
        return { gameModes: numericGameModes, availableGameModes };
    }
    return { gameModes, availableGameModes };
};
export default getLeagueOfLegendsGameModes;
