export const getTournamentPlatformsDisplay = (tournament) => {
    // Fortnite platforms come from input methods
    if (tournament.score_template.game.game_key === 'fortnite') {
        const scoreTemplateJson = tournament.score_template.json;
        if (scoreTemplateJson.inputMethods == null || scoreTemplateJson.inputMethods.length === 0) {
            return [{ label: 'All Platforms' }];
        }
        return scoreTemplateJson.inputMethods.map((method) => {
            switch (method) {
                case 'keyboardmouse':
                    return { icon: 'keyboardandmouse' };
                case 'gamepad':
                    return { icon: 'moderncontroller' };
                case 'touch':
                    return { icon: 'touch' };
                default:
                    throw new Error('input method was not recognised!');
            }
        });
    }
    // There is currently no way for a tournament to restrict users by platform.
    // For now, default to showing all platforms
    return [{ label: 'All Platforms' }];
};
export default getTournamentPlatformsDisplay;
