import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from '@mui/system';
const StyledSVG = styled('svg') `
  polygon {
    stroke: ${({ theme }) => theme.palette.primary.main};
    fill: transparent;
    transition: fill 0.3s ease-in-out;
    stroke-width: 10;
  }

  rect {
    animation-duration: 2s;
    animation-name: slidein;
    animation-iteration-count: infinite;
    fill: ${({ theme }) => theme.palette.primary.main};
  }
`;
const LoadingLogo = () => {
    return (_jsxs(StyledSVG, { height: "57", viewBox: "0 0 1000 1000", width: "57", children: [_jsx("title", { children: "loading" }), _jsx("polygon", { points: "157 139 340 861 525 704 484 541 425 585 355 313 693 360 564 469 605 631 842 665 842 514 755 501 842 429 841 234 157 140" }), _jsx("clipPath", { id: "star", children: _jsx("polygon", { points: "157 139 340 861 525 704 484 541 425 585 355 313 693 360 564 469 605 631 842 665 842 514 755 501 842 429 841 234 157 140" }) }), _jsx("g", { clipPath: "url(#star)", children: _jsx("rect", { height: "100%", width: "100%" }) })] }));
};
export default LoadingLogo;
