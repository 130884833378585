import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import updateNotifications from '@api/updateNotifications';
import doTrackEvent from '@components/Analytics/doTrackEvent';
import CoreButton from '@components/Buttons/CoreButton';
import LoadingLogo from '@components/Modals/LoadingModal/Components/LoadingLogo';
import { makeStyles } from 'tss-react/mui';
import { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import NotificationEmpty from './NotificationEmpty';
import NotificationItem from './NotificationItem';
const useStyles = makeStyles()((theme) => ({
    menu: {
        display: 'grid',
        height: '100%',
        minWidth: '320px',
        gridTemplateRows: 'auto 1fr auto',
        '& ul': {
            paddingInlineStart: 0,
        },
        '& li': {
            listStyle: 'none',
        },
    },
    menuHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.grey[100]}`,
    },
    menuHeading: {
        fontSize: '14px',
        color: theme.palette.grey[900],
        padding: theme.spacing(2),
        fontWeight: 'bold',
        margin: 0,
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        '& button': {
            textDecoration: 'underline',
        },
    },
    menuList: {
        overflowY: 'auto',
    },
    menuFooter: {
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
        fontWeight: 600,
        background: theme.palette.grey[100],
        '& a': {
            color: theme.palette.grey[500],
            fontSize: '12px',
        },
    },
    markAsOpenButton: {
        display: 'block',
    },
    loadingIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
            width: '30px',
        },
    },
}));
const NotificationMenuList = ({ items, totalUnopened = 0, totalNew = 0, refetchNotifications, fetchNextPage, hasNextPage, isLoading, }) => {
    const { classes } = useStyles();
    const boundaryRef = useRef(null);
    const { mutate } = useMutation((params) => updateNotifications(params), {
        onSuccess: () => refetchNotifications(),
    });
    const handleOpenUnopenedNotifications = () => {
        var _a;
        const firstUserNotification = (_a = items.find((item) => item.priority === 0)) !== null && _a !== void 0 ? _a : items[0];
        mutate({
            timestamp: new Date(firstUserNotification.displayDate).getTime() / 1000,
            opened: true,
            isNewNotification: false,
        });
        doTrackEvent({
            type: 'notification_marked_read',
        });
    };
    const [loadMoreNotificationsRef, inView] = useInView({
        delay: 250,
        root: boundaryRef.current,
    });
    useEffect(() => {
        if (inView) {
            doTrackEvent({
                type: 'notifications_load_next_page',
            });
            fetchNextPage();
        }
    }, [inView]);
    const openAllNotificationsButtonText = totalUnopened ? (_jsxs(_Fragment, { children: ["Mark ", _jsx("span", { "data-ignore": true, children: totalUnopened }), " as Opened"] })) : (_jsx(_Fragment, { children: " Mark all as Opened" }));
    return (_jsxs("div", { className: classes.menu, children: [_jsx("header", { className: classes.menuHeader, children: _jsxs("h2", { className: classes.menuHeading, children: ["Notifications", ' ', (totalUnopened > 0 || totalNew > 0) && (_jsx(CoreButton, { variant: "error", appearance: "subdued", size: "sm", onClick: handleOpenUnopenedNotifications, className: classes.markAsOpenButton, ariaLabel: "Mark all notifications as opened", children: openAllNotificationsButtonText }))] }) }), _jsx("div", { className: classes.menuList, ref: boundaryRef, children: items.length > 0 ? (_jsxs("ul", { children: [items.map((notification) => (_jsx("li", { children: _jsx(NotificationItem, { notification: notification }) }, `menu-${notification.id}`))), hasNextPage && (_jsx("div", { ref: loadMoreNotificationsRef, className: classes.loadingIcon, children: _jsx(LoadingLogo, {}) }))] })) : (_jsx(NotificationEmpty, { isLoading: isLoading })) }), _jsx("footer", { className: classes.menuFooter, children: _jsx(Link, { to: `/account/notifications`, children: "View All Notifications" }) })] }));
};
export default NotificationMenuList;
