import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme) => ({
    button: {
        background: theme.palette.common.white,
        color: theme.palette.success.main,
        width: '100%',
        padding: '10px 0',
        border: '0',
        borderTop: `1px solid ${theme.palette.grey[100]}`,
        '& i': {
            marginRight: theme.spacing(1),
        },
    },
}));
const ApplyFilterButton = ({ onClick, className = '' }) => {
    const { classes, cx } = useStyles();
    return (_jsxs("button", { type: "button", className: cx(classes.button, className), onClick: onClick, "data-testid": "apply filter", children: [_jsx(CheckCircleIcon, { width: 24, height: 24 }), "Apply Filter"] }));
};
export default ApplyFilterButton;
