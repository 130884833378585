import { styled, css } from '@mui/system';
const nonStandardPaletteMap = {
    grey: {
        light: 300,
        main: 500,
        dark: 700,
    },
};
const getStrength = (color, strength) => {
    return color in nonStandardPaletteMap ? nonStandardPaletteMap[color][strength] : strength;
};
const StyledSVG = styled('svg', {
    shouldForwardProp: (prop) => prop !== 'color' && prop !== 'strength',
}) `
  color: ${({ color, strength, theme }) => theme.palette[color !== null && color !== void 0 ? color : 'grey'][getStrength(color !== null && color !== void 0 ? color : 'grey', strength !== null && strength !== void 0 ? strength : 'main')]};
  height: ${({ height }) => height
    ? css `
          ${height}px
        `
    : 'auto'};
  width: ${({ width }) => width
    ? css `
          ${width}px
        `
    : 'auto'};
`;
export default StyledSVG;
