import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import getAddGameCallbackParams from '@helpers/getAddGameCallbackParams';
import removeQueryParams from '@helpers/removeQueryParams';
/**
 * @returns A function that will remove the add game params.
 */
const useRemoveAddGameParams = () => {
    const addGameStatus = getAddGameCallbackParams();
    const history = useHistory();
    const removeAddGameParams = useCallback(() => {
        if (history && !!addGameStatus) {
            const { search, hash } = removeQueryParams([
                'added_game',
                'add_game_error',
                'add_game_region_code',
                'add_game_service_key',
            ]);
            history.replace({ search, hash });
        }
    }, [history, addGameStatus]);
    return removeAddGameParams;
};
export default useRemoveAddGameParams;
