import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
import GenericModal from "@components/Modals/GenericModal";
import CoreButton from "@components/Buttons/CoreButton";
import ModalImageHeader from '@components/Modals/components/ModalImageHeader';
import ModalTextContent from '@components/Modals/components/ModalTextContent';
const useStyles = makeStyles()((theme) => ({
    heading: {
        position: 'relative',
    },
    headerImage: {
        width: '100%',
        height: '100%',
    },
    button: {
        margin: '24px',
        backgroundColor: '#E5BC2C',
        background: theme.palette.pouchDark.main,
        color: 'white',
    },
    bgStyle: {
        backgroundColor: theme.palette.pouchDark.dark,
        color: 'white',
    },
    content: {
        backgroundColor: theme.palette.pouchDark.dark,
        color: 'white',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
        '& h2': {
            backgroundColor: theme.palette.pouchDark.dark,
            color: 'white',
            [theme.breakpoints.down('md')]: {
                display: 'none',
            },
        },
    },
}));
const PouchTournamentModal = ({ show, tournamentLink, className, handleClose, }) => {
    const { classes, cx } = useStyles();
    return (_jsxs(GenericModal, { className: cx(className, classes.bgStyle), show: show, mobileFullScreen: false, children: [_jsx(ModalImageHeader, { imageUrl: "/img/modals/pouch-tournament-heading.jpg", onClose: handleClose }), _jsx(ModalTextContent, { title: _jsxs(_Fragment, { children: ["Enter the $1,000 ", _jsx("span", { "data-ignore": true, children: "Pouch" }), " tournament"] }), paragraph: "This free entry event is exclusively for UK users and currently live!", className: classes.content }), _jsx(CoreButton, { variant: "pouchDark", to: tournamentLink, className: classes.button, children: "Join Now" })] }));
};
export default PouchTournamentModal;
