import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { captureException } from '@sentry/react';
import { displayMessageFromError } from '@helpers/errorHelper';
import updateUserFirebaseState from '@api/updateUserFirebaseState';
import refreshUserToken from '@helpers/Auth/refreshUserToken';
import { getAuth } from 'firebase/auth';
export const updateUserFirebaseStateKey = 'updateUserFirebaseState';
const useSyncFirebaseState = async (userDetails) => {
    var _a, _b;
    const [jwt, setJwt] = useState();
    const auth = getAuth();
    const { hasVerifiedEmail, verified } = (_a = userDetails === null || userDetails === void 0 ? void 0 : userDetails.verification) !== null && _a !== void 0 ? _a : {};
    const hasCurrentUser = !!auth.currentUser;
    const hasEmailVerified = !!hasVerifiedEmail && !((_b = auth.currentUser) === null || _b === void 0 ? void 0 : _b.emailVerified);
    const hasVerified = verified === true && (jwt === null || jwt === void 0 ? void 0 : jwt.claims.verified) !== undefined && (jwt === null || jwt === void 0 ? void 0 : jwt.claims.verified) === false;
    // only run when symfony has a verified email but user firebase token doesn't
    const syncFirebase = hasCurrentUser && (hasEmailVerified || hasVerified);
    useEffect(() => {
        const getUserJWT = async () => {
            var _a;
            const userJwt = await ((_a = auth.currentUser) === null || _a === void 0 ? void 0 : _a.getIdTokenResult());
            if (userJwt) {
                setJwt(userJwt);
            }
        };
        getUserJWT().catch((error) => captureException(error));
    }, [hasCurrentUser]);
    useQuery([updateUserFirebaseStateKey], updateUserFirebaseState, {
        enabled: syncFirebase,
        staleTime: 1000 * 60 * 1,
        onSuccess: (data) => {
            if (data.updated) {
                refreshUserToken().catch((error) => {
                    captureException(error);
                });
            }
        },
        onError: (error) => {
            captureException(error, {
                extra: {
                    request: updateUserFirebaseStateKey,
                    errorMessage: displayMessageFromError(error),
                },
            });
        },
    });
};
export default useSyncFirebaseState;
