import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classnames from 'classnames';
import LinkButton from "@components/Buttons/LinkButton";
import useStyles from '../useStyles';
import { styled } from '@mui/system';
const ProvisoText = styled('div') `
  position: absolute;
  bottom: ${({ theme }) => theme.spacing(1.5)};
  right: ${({ theme }) => theme.spacing(1.5)};
  color: ${({ theme }) => theme.palette.grey[300]};
`;
const Container = styled('div') `
  position: relative;
`;
const Banner = () => {
    const { classes } = useStyles();
    return (_jsx("header", { className: classes.bannerWrapper, children: _jsxs("div", { className: classnames(classes.container, classes.bannerContainer), children: [_jsxs("div", { className: classes.bannerContent, children: [_jsx("p", { className: classes.bannerPreHeading, "data-testid": "banner-pre-heading-p", children: "Build Brand Equity \u00B7 Increase Brand Loyalty \u00B7 Drive Conversions" }), _jsx("h1", { className: classes.bannerTitle, "data-testid": "banner-title-h1", children: "Engage millions of gamers with Always-On esports campaigns" }), _jsx("p", { className: classes.bannerText, "data-testid": "banner-text-p", children: "Repeat's all-in-one solution helps you target & engage grassroots and professional gamers, whether you're a brand, organization, team or influencer" }), _jsx(LinkButton, { href: "#contact", size: "lg", className: classes.button, "data-testid": "banner-work-with-us-button", children: "Work With Us" })] }), _jsxs(Container, { className: classes.byTheNumbers, children: [_jsx("h2", { className: classes.byTheNumbersTitle, "data-testid": "banner-by-the-numbers-title", children: "Repeat by the Numbers*" }), _jsxs("ul", { className: classes.theNumbers, "data-testid": "banner-the-numbers-ul", children: [_jsxs("li", { className: classes.displayNumbers, children: [_jsx("span", { className: classes.displayNumbersHeading, children: "Tournaments Hosted" }), "115,000+"] }), _jsxs("li", { className: classes.displayNumbers, children: [_jsx("span", { className: classes.displayNumbersHeading, children: "Tournament Entrants" }), " 4,910,000+"] }), _jsxs("li", { className: classes.displayNumbers, children: [_jsx("span", { className: classes.displayNumbersHeading, children: "Games Played on Repeat" }), " 67,400,000+"] })] }), _jsx(ProvisoText, { "data-testid": "banner-proviso-text", children: "*numbers valid as of 2 May 2023" })] })] }) }));
};
export default Banner;
