import { styled } from '@mui/material/styles';
function shouldForwardProp(prop) {
    return prop !== 'paletteName' && prop !== 'oddRow';
}
const TournamentHeading = styled('div', { shouldForwardProp }) `
  display: flex;
  align-items: center;
  padding: ${(props) => props.theme.spacing(0, 1.5)};
  color: ${(props) => props.theme.palette.grey[900]};

  &:hover {
    color: ${(props) => props.theme.palette[props.paletteName].main};
  }

  & p {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
`;
export default TournamentHeading;
