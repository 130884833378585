import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import { ClickAwayListener, Popper, Fade, Paper } from '@mui/material';
import classnames from 'classnames';
import CoreButton from "@components/Buttons/CoreButton";
import useRouteChanged from "@hooks/useRouteChanged";
const ButtonPopper = ({ buttonProps: { className: buttonPropClassName, ...buttonProps }, onClickAway, onClick, className = '', containerClassName = '', children, placement = 'bottom-end', open, }) => {
    const buttonRef = useRef(null);
    const [internalOpen, setInternalOpen] = useState(false);
    useRouteChanged(() => {
        setInternalOpen(false);
        if (onClickAway) {
            onClickAway();
        }
    });
    const handleButtonClick = (event) => {
        setInternalOpen(!internalOpen);
        if (onClick) {
            onClick();
        }
    };
    const handleMenuClose = () => {
        setInternalOpen(false);
        if (onClickAway) {
            onClickAway();
        }
    };
    return (_jsx(ClickAwayListener, { onClickAway: handleMenuClose, children: _jsxs("div", { className: containerClassName, children: [_jsx(CoreButton, { ...buttonProps, onClick: handleButtonClick, className: classnames(buttonPropClassName, (open !== null && open !== void 0 ? open : internalOpen) && 'open'), ref: buttonRef, children: buttonProps.children }), _jsx(Popper, { placement: placement, transition: true, anchorEl: buttonRef.current, open: open !== null && open !== void 0 ? open : internalOpen, className: className, popperOptions: { strategy: 'fixed' }, children: ({ TransitionProps }) => (_jsx(Fade, { ...TransitionProps, timeout: 350, children: _jsx(Paper, { square: true, children: children }) })) })] }) }));
};
export default ButtonPopper;
