import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
import PageTitle from '@helpers/PageTitle';
import GameAccounts from '@domain/User/Profile/components/GameAccounts';
import { useUserState } from '@context/UserContext';
import SectionContainer from "@components/UI/Section/components/SectionContainer";
import Help from './components/Help';
import { TournamentBanner, Container } from './styles';
const useStyles = makeStyles()((theme) => ({
    leftColumn: {
        display: 'grid',
        gridAutoRows: 'min-content',
        [theme.breakpoints.down('lg')]: {
            paddingBottom: '20px',
        },
    },
    rightColumn: {
        display: 'grid',
        gridAutoRows: 'min-content',
        [theme.breakpoints.down('md')]: {
            gap: '20px',
        },
        padding: theme.spacing(3),
    },
}));
const AccountGames = () => {
    const { userProfile } = useUserState();
    const { classes } = useStyles();
    return (_jsx(TournamentBanner, { children: _jsxs(Container, { children: [_jsx(PageTitle, {}), _jsx("div", { className: classes.leftColumn, children: _jsx(GameAccounts, { isCurrentUser: true, userHandle: userProfile === null || userProfile === void 0 ? void 0 : userProfile.handle }) }), _jsx(SectionContainer, { className: classes.rightColumn, children: _jsx(Help, {}) })] }) }));
};
export default AccountGames;
