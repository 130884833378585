import { useTheme } from '@mui/material';
import { useLayoutEffect } from 'react';
/**
 * The current navigation header components require an offset to be set
 * in order to prevent overlapping content.
 * This hook allows these header components to control this offset to any desired height.
 *
 * @param height current height of the header bar
 * @param backgroundColor background colour behind navigation submenu
 */
const useSetPageHeaderStyle = (height, backgroundColor) => {
    const theme = useTheme();
    useLayoutEffect(() => {
        var _a;
        const { body } = document;
        const originalBodyStyle = body.getAttribute('style');
        body.setAttribute('style', `padding: ${height}px 0px 0px 0px; background: ${(_a = (backgroundColor && theme.palette[backgroundColor].dark)) !== null && _a !== void 0 ? _a : 'white'}`);
        return () => {
            // we should always remove the styling we've added, otherwise it will cause unwanted side effects
            body.setAttribute('style', originalBodyStyle || '');
        };
    }, [height, backgroundColor]);
};
export default useSetPageHeaderStyle;
