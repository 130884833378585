import useGamesList from '@hooks/API/useGamesList';
import { isPlatformSlug } from '@Types/platform';
import { useRouteMatch } from 'react-router-dom';
/**
 * Reads from the current route, localStorage, and gamesList data to select a
 * platform.
 */
const useSelectedPlatform = () => {
    var _a;
    const routeMatch = useRouteMatch(`/:slug`);
    const slug = (_a = routeMatch === null || routeMatch === void 0 ? void 0 : routeMatch.params) === null || _a === void 0 ? void 0 : _a.slug;
    const games = useGamesList();
    const game = games.allGames.find((maybeGame) => maybeGame.slug === slug);
    const localStorageItem = window.localStorage.getItem('selectedPlatformSlug');
    const localStoragePlatform = localStorageItem && JSON.parse(localStorageItem);
    // route has platform
    if (isPlatformSlug(slug)) {
        return {
            platformSlug: slug,
            isLoading: false,
        };
    }
    // game data needs to load
    if (games.isLoading) {
        return { isLoading: true };
    }
    // route does not have game
    if (!game) {
        return {
            isLoading: false,
            platformSlug: isPlatformSlug(localStoragePlatform) ? localStoragePlatform : undefined,
        };
    }
    // route has game with only 1 platform
    if (game.platforms.length === 1) {
        return { platformSlug: game.platforms[0].slug, isLoading: false };
    }
    // route has game that supports localStorage platform
    const gamePlatform = game.platforms.find((platform) => platform.slug === localStoragePlatform);
    if (gamePlatform) {
        return { platformSlug: gamePlatform.slug, isLoading: false };
    }
    return { isLoading: false };
};
export default useSelectedPlatform;
