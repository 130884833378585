import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme, { textAlign }) => ({
    error: {
        opacity: 0,
        transition: 'opacity 0.3s',
        color: theme.palette.error.main,
    },
    showError: {
        display: 'block',
        opacity: 1,
        fontSize: '11px',
        fontWeight: 600,
        textAlign: textAlign !== null && textAlign !== void 0 ? textAlign : 'right',
        paddingBottom: theme.spacing(1),
    },
}));
const ErrorMessage = ({ error, errorMessage, textAlign, children, className }) => {
    const { classes, cx } = useStyles({ textAlign });
    return (_jsx("span", { role: "alert", className: cx(classes.error, className, {
            [classes.showError]: error,
        }), children: children !== null && children !== void 0 ? children : errorMessage }));
};
export default ErrorMessage;
