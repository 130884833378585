import getGamePalette from '@helpers/getGamePalette';
const getTournamentEntryTypeDisplay = (tournamentData) => {
    const paletteName = getGamePalette(tournamentData.score_template.game.game_key);
    if (tournamentData.entry_types.length > 0) {
        const [entryType] = tournamentData.entry_types;
        switch (entryType.type) {
            case 'video':
            case 'video_link':
                return { label: 'Video', palette: paletteName };
            case 'password':
            case 'individual_password':
                return { label: 'Password', palette: paletteName };
            case 'video_postcode':
                return { label: 'Video/Postcode', palette: paletteName };
            case 'app_download':
                return { label: 'App Download', palette: paletteName };
            case 'install_browser_extension':
                return { label: 'Extension Install', palette: paletteName };
            case 'url_timeout':
                if (typeof entryType.instance_data.previewTag === 'string') {
                    return { label: entryType.instance_data.previewTag, palette: paletteName };
                }
                return { label: 'Sign-up', palette: paletteName };
            default:
                return undefined;
        }
    }
    return undefined;
};
export default getTournamentEntryTypeDisplay;
