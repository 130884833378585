import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import LoadingLogo from "@components/Modals/LoadingModal/Components/LoadingLogo";
import { styled } from '@mui/material/styles';
const LoadingPanelContainer = styled('div') `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 465px;

  & svg {
    [${({ theme }) => theme.breakpoints.up('sm')}] {
      left: 40px;
    }
  }

  & svg polygon {
    stroke: ${({ theme }) => theme.palette.primary.main};
  }
  & svg rect {
    fill: ${({ theme }) => theme.palette.primary.main};
  }
`;
const LoadingPanel = forwardRef(({ className, title }, ref) => {
    return (_jsxs(LoadingPanelContainer, { className: className, ref: ref, children: [_jsx(LoadingLogo, {}), title && _jsx("p", { children: title })] }));
});
export default LoadingPanel;
