import { SupportedLanguages } from '@Types';
const getLanguageFromCode = (storedLanguageCode) => {
    if (storedLanguageCode) {
        const foundLanguage = SupportedLanguages.find((language) => language.code === storedLanguageCode);
        if (foundLanguage) {
            return foundLanguage;
        }
    }
    return SupportedLanguages[0];
};
export default getLanguageFromCode;
