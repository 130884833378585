import { styled } from '@mui/system';
const SectionFooter = styled('footer') `
  align-items: center;
  background: ${({ theme }) => theme.palette.grey[50]};
  border: 1px solid ${({ theme }) => theme.palette.grey[100]};
  display: flex;
  height: 50px;
  justify-content: center;
  margin: 0px;
  width: 100%;
`;
export default SectionFooter;
