import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CoreButton from '@components/Buttons/CoreButton';
import { ButtonStack } from './styles';
import useTrackEvent from '@hooks/useTrackEvent';
const Actions = ({ ageConfirmed, onClick, onClose, isOverConsentAge }) => {
    const trackEvent = useTrackEvent();
    const handleClick = () => {
        trackEvent({
            type: 'game_linking',
            data: {
                eventName: 'game linking',
                label: 'Continue',
                moduleName: 'Button',
                position: 0,
            },
        });
        onClick();
    };
    const handleClose = (label, moduleName) => {
        trackEvent({
            type: 'game_linking',
            data: {
                eventName: 'game linking',
                label,
                moduleName: moduleName === 'Button' ? 'Button' : 'Text link',
                position: 0, // one is a button, one is a text link so in context they're both position 0
            },
        });
        onClose();
    };
    if (!isOverConsentAge) {
        return (_jsxs(ButtonStack, { as: "div", children: [_jsx(CoreButton, { variant: "cod-2", to: "/compete", onClick: () => handleClose('View Other Games', 'Text lint'), children: "View Other Games" }), _jsx(CoreButton, { appearance: "subdued", variant: "grey", onClick: () => handleClose('Close', 'Button'), children: "Close" })] }));
    }
    return (_jsx(CoreButton, { variant: "cod-2", onClick: handleClick, disabled: !ageConfirmed, children: "Continue" }));
};
export default Actions;
