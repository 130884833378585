import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme, { gridTemplateColumns }) => ({
    modalAction: {
        display: 'grid',
        gap: theme.spacing(3),
        gridTemplateColumns,
    },
}));
const ModalAction = ({ children, className }) => {
    const childArray = Array.isArray(children) ? children : [children];
    const gridTemplateColumns = new Array(childArray.length).fill(`1fr`).join(' ');
    const { classes, cx } = useStyles({ gridTemplateColumns });
    return _jsx("div", { className: cx(classes.modalAction, className), children: children });
};
export default ModalAction;
