export const getTierBoxImg = (position) => {
    switch (position) {
        case 1:
            return '/img/ranks/threshold/box-no-border-gold.png';
        case 2:
            return '/img/ranks/threshold/box-no-border-blue.png';
        case 3:
            return '/img/ranks/threshold/box-no-border-orange.png';
        default:
            return '/img/ranks/threshold/box-no-border-silver.png';
    }
};
