import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useUserState } from '@context/UserContext';
import { captureException } from '@sentry/react';
import getUserLinkedServices from '@api/getUserLinkedServices';
import deleteUserLinkedService from '@api/deleteUserLinkedService';
export const userLinkedServicesKey = ['userLinkedServices'];
const useUserLinkedServices = () => {
    const queryClient = useQueryClient();
    const { userDetails } = useUserState();
    const queryKey = [userLinkedServicesKey, userDetails === null || userDetails === void 0 ? void 0 : userDetails.id];
    const getUserLinkedServicesQuery = useQuery(queryKey, () => getUserLinkedServices(), {
        staleTime: 1000 * 60 * 5,
        enabled: !!(userDetails === null || userDetails === void 0 ? void 0 : userDetails.id),
    });
    if (getUserLinkedServicesQuery.isError) {
        captureException(getUserLinkedServicesQuery.error, {
            extra: {
                request: 'get_user_linked_services',
                userId: userDetails === null || userDetails === void 0 ? void 0 : userDetails.id,
            },
        });
    }
    const deleteUserLinkedServicesMutation = useMutation((id) => deleteUserLinkedService(id), {
        onSuccess: (_, deletedLinkedServiceId) => {
            // remove deleted linked service from query cache
            queryClient.setQueryData(queryKey, (prev = []) => {
                if (Array.isArray(prev)) {
                    return [...prev].filter((linkedService) => linkedService.id !== deletedLinkedServiceId);
                }
                return prev;
            });
        },
    });
    if (deleteUserLinkedServicesMutation.isError) {
        captureException(deleteUserLinkedServicesMutation.error, {
            extra: {
                request: 'delete_user_linked_service',
                userId: userDetails === null || userDetails === void 0 ? void 0 : userDetails.id,
            },
        });
    }
    return { getLinkedServices: getUserLinkedServicesQuery, deleteLinkedService: deleteUserLinkedServicesMutation };
};
export default useUserLinkedServices;
