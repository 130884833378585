import { z } from 'zod';
import { currencyCodes } from './Currency';
export const productImageSchema = z.object({
    id: z.string().optional(),
    position: z.number(),
    /** Horizontal dimensions of the image */
    width: z.number(),
    /** Vertical dimensions of the image */
    height: z.number(),
    /** URL for the actual image */
    src: z.string(),
    /** alt text for image */
    alt: z.string().optional(),
});
const priceSchema = z.object({
    currencyCode: z.enum(currencyCodes),
    /** Amount stored as integer cents (no decimal) */
    amount: z.number(),
});
export const presentmentPriceSchema = z.object({
    price: priceSchema,
    compareAtPrice: priceSchema.optional(),
});
