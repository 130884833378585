import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useComparisonContext } from './TierComparisonProvider';
import TierComparisonPanel from './TierComparisonPanel';
import { XCircleIcon } from '@heroicons/react/24/outline';
import TournamentBracketCarousel from '@domain/Tournament/TournamentDetails/components/TournamentBracketCarousel';
const StyledDialog = styled(Dialog) `
  & .MuiPaper-root {
    ${({ theme }) => theme.breakpoints.down('sm')} {
      max-height: calc(100% - 14px);
    }
  }
`;
const Container = styled('div') `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  flex: 1;
  padding: 20px;
  min-width: 480px;
  min-height: 215px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    min-width: 300px;
    min-height: 215px;
  }
`;
const HeadingWrapper = styled('div') `
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(236, 236, 236, 1);
  margin-bottom: 15px;
`;
const Heading = styled('h1') `
  font-size: 18px;
  margin-top: 10px;
`;
const CloseButton = styled(Button) `
  min-width: auto;
  padding: 0;
  & > svg {
    width: 25px;
    fill: none;
    stroke: ${({ theme }) => theme.palette.grey[400]};
  }
`;
const CarouselContainer = styled('div') `
  display: flex;
  flex-direction: row;
`;
const ComparisonContainer = styled('div') `
  display: flex;
  flex-direction: row;

  @media (max-width: 460px) {
    flex-direction: column;
  }
`;
const ThresholdPrizePoolModal = ({ open, onClose, userEntryBracketPosition, brackets, currentUserScore, }) => {
    const { selectedTierPosition, setSelectedTierPosition } = useComparisonContext();
    return (_jsx(StyledDialog, { open: open, children: _jsxs(Container, { children: [_jsxs(HeadingWrapper, { children: [_jsx(Heading, { children: "Prize Pool" }), _jsx(CloseButton, { onClick: onClose, children: _jsx(XCircleIcon, {}) })] }), _jsx(TournamentBracketCarousel, { brackets: brackets, onItemSelected: (value) => {
                        setSelectedTierPosition(value === undefined ? 1 : value);
                    }, userBracket: userEntryBracketPosition !== null && userEntryBracketPosition !== void 0 ? userEntryBracketPosition : undefined, initialTier: selectedTierPosition }), _jsxs(ComparisonContainer, { children: [_jsx(TierComparisonPanel, { heading: 'Your tier', showUserTier: true }), _jsx(TierComparisonPanel, { heading: 'Selected tier' })] })] }) }));
};
export default ThresholdPrizePoolModal;
