import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from 'react-query';
import { useState } from 'react';
import Validator from 'validatorjs';
import validatorRuleEmail from '@helpers/Validation/validatorRuleEmail';
import { isAxiosError } from '@helpers/errorHelper';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { useHistory } from 'react-router-dom';
import useLocalStorage from '@components/Hooks/useLocalStorage';
import changeSignupEmail from '../api/changeSignupEmail';
const useChangeSignupEmail = ({ onSuccess, originalEmail }) => {
    const [errorMessage, setErrorMessage] = useState();
    const auth = getAuth();
    const history = useHistory();
    const [completeRegistrationRedirectUrl] = useLocalStorage('complete-registration-initial-route', '/compete');
    const { mutate, isLoading, isError } = useMutation({
        mutationFn: async (email) => {
            const { firebaseCustomToken } = (await changeSignupEmail(email)).data;
            await signInWithCustomToken(auth, firebaseCustomToken);
        },
        onError: (error) => {
            var _a;
            if (isAxiosError(error) && ((_a = error.response) === null || _a === void 0 ? void 0 : _a.data.result) === 'Your email is already verified') {
                setTimeout(() => {
                    history.push(completeRegistrationRedirectUrl);
                }, 5000);
                setErrorMessage('Email address has been verified, this page will automatically reload in 5 seconds, or you can manually reload this page');
            }
            else {
                setErrorMessage(_jsxs(_Fragment, { children: ["An error has occurred. Please check your spam/junk, or", ' ', _jsx("a", { href: "https://support.repeat.gg/hc/en-us/requests/new", rel: "noopener noreferrer", target: "_blank", children: "contact support" }), ' ', "for assistance."] }));
            }
        },
        onSuccess,
    });
    const tryToChangeEmail = (email) => {
        const validation = new Validator({ email }, validatorRuleEmail);
        if (validation.fails() || !email) {
            setErrorMessage(validation.errors.first('email') || 'The email provided is invalid');
            return;
        }
        setErrorMessage('');
        if (originalEmail === email) {
            onSuccess();
            return;
        }
        mutate(email);
    };
    return { changeEmail: tryToChangeEmail, clearError: () => setErrorMessage(''), isLoading, isError, errorMessage };
};
export default useChangeSignupEmail;
