import pouchTournamentGbModalLastShown from './pouchTournamentGbModalLastShown';
const showFortniteGBPouchTournament = (userContext, gameContext, gameSlug) => {
    var _a;
    const isFortnite = gameContext.games.find((game) => game.slug === gameSlug);
    const hasFortnite = userContext.userHasGame('fortnite');
    const userIsFromGB = ((_a = userContext === null || userContext === void 0 ? void 0 : userContext.userDetails) === null || _a === void 0 ? void 0 : _a.country) === 'GB';
    if (isFortnite &&
        hasFortnite &&
        userIsFromGB &&
        (pouchTournamentGbModalLastShown() === false || pouchTournamentGbModalLastShown() >= 48)) {
        return true;
    }
    return false;
};
export default showFortniteGBPouchTournament;
