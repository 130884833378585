import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import loadable from '@loadable/component';
import { styled } from '@mui/system';
import ModalContent from '@components/Modals/components/ModalContent';
import getGamePalette from '@helpers/getGamePalette';
import { AddGameStatusBanner } from './AddGameStatusBanner';
import BoxButton from '@components/Buttons/BoxButton';
import { useRouteMatch } from 'react-router-dom';
import useSuggestedTournaments from '@hooks/API/useSuggestedTournaments';
import LoadingPanel from '@domain/Tournament/components/LoadingPanel';
import TournamentSuggestionBox from '@domain/Tournament/components/TournamentSuggestionBox';
import CoreButton from '@components/Buttons/CoreButton';
import getMakeStatsPublicGuide from '@helpers/getMakeStatsPublicGuide';
import useGame from '@hooks/API/useGame';
import messageReactNative from '@helpers/messageReactNative';
import { useEffect } from 'react';
import useTrackEvent from '@hooks/useTrackEvent';
const CircleArrowRight = loadable(() => import('@components/UI/Icon/Icons/CircleArrowRight'));
const Title = styled('h2') `
  font-weight: 800;
  font-size: 26px;
  line-height: 128.9%;
  margin-top: ${({ theme }) => theme.spacing(5)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;
const SuccessButton = styled(BoxButton) `
  align-self: stretch;
`;
const StyledLoadingPanel = styled(LoadingPanel) `
  min-height: 177px;
`;
const ViewTournmanentsLink = styled(CoreButton) `
  width: 100%;

  svg {
    width: 16px;
    color: white;
    margin-left: ${({ theme }) => theme.spacing(1)};
  }
`;
const StyledTournamentSuggestionBox = styled(TournamentSuggestionBox) `
  margin-top: ${({ theme }) => theme.spacing(3)};
`;
const ContactSupportSection = ({ onSupportLinkClick }) => {
    return (_jsxs(_Fragment, { children: ["If you believe this is an error, or you have a valid reason for requiring additional changes, you can raise a support ticket", ' ', _jsx("a", { onClick: onSupportLinkClick, href: "https://support.repeat.gg/hc/en-us/requests/new", rel: "noopener noreferrer", target: "_blank", children: "here" }), "."] }));
};
const AddGameConnectStatus = ({ gameKey, status, stepName, errorMessage, className, onConfirm, onRetry, successButtonLabel = 'Go to Tournaments', failButtonLabel = 'Try Again', isLoading = false, }) => {
    var _a, _b;
    const trackEvent = useTrackEvent();
    const match = useRouteMatch({
        path: '/:slug/tournaments/:tournamentId',
        strict: true,
    });
    const platformMatch = useRouteMatch({
        path: '/:platformSlug/:slug/tournaments/:tournamentId',
        strict: true,
    });
    const { tournamentId } = (_b = (_a = match === null || match === void 0 ? void 0 : match.params) !== null && _a !== void 0 ? _a : platformMatch === null || platformMatch === void 0 ? void 0 : platformMatch.params) !== null && _b !== void 0 ? _b : { tournamentId: undefined };
    const gameData = useGame(gameKey);
    // We only want to show suggested tournaments when the user is not on a tournament page
    const showSuggestedTournaments = tournamentId === undefined;
    const suggestedTournamentsLimit = 1;
    const { suggestedTournamentsQuery: { data, isLoading: isSuggestedTournamentLoading }, } = useSuggestedTournaments(gameKey, showSuggestedTournaments, suggestedTournamentsLimit);
    const isSuccess = status === 'success';
    const hasSuggestedTournaments = isSuccess && (data === null || data === void 0 ? void 0 : data.tournaments) && data.tournaments.length > 0;
    useEffect(() => {
        if (isSuccess) {
            messageReactNative({ flow: 'addGame', event: 'success' });
        }
    }, [isSuccess]);
    const gameName = gameData === null || gameData === void 0 ? void 0 : gameData.name;
    const handleClick = () => {
        if (isSuccess) {
            trackEvent({
                type: 'game_linking',
                data: {
                    eventName: 'game linking',
                    label: hasSuggestedTournaments ? 'View Tournaments' : successButtonLabel,
                    moduleName: 'Button',
                    position: 0,
                },
            });
            onConfirm();
        }
        else {
            trackEvent({
                type: 'game_linking',
                data: {
                    eventName: 'game linking',
                    label: failButtonLabel,
                    moduleName: 'Button',
                    position: 0,
                },
            });
            onRetry();
        }
    };
    const handleSupportLinkClick = () => {
        trackEvent({
            type: 'user_engagement',
            data: {
                eventName: 'select text link',
                label: 'here',
                moduleName: 'Text link',
                position: 0,
                linkURL: 'https://support.repeat.gg/hc/en-us/requests/new',
            },
        });
    };
    const handleGuideLinkClick = (link) => {
        trackEvent({
            type: 'user_engagement',
            data: {
                eventName: 'select text link',
                label: 'Click here for a guide on how to make your results public.',
                moduleName: 'Text link',
                position: 1,
                linkURL: link,
            },
        });
    };
    let bodyDisplay;
    if (isSuggestedTournamentLoading) {
        bodyDisplay = _jsx(StyledLoadingPanel, {});
    }
    else if (hasSuggestedTournaments) {
        bodyDisplay = (_jsxs(_Fragment, { children: [_jsxs("p", { children: ["You've connected your ", _jsx("span", { "data-ignore": true, children: gameName }), " Account, here is a tournament you might like"] }), _jsx(StyledTournamentSuggestionBox, { gameKey: gameKey, tournament: data.tournaments[0], onClick: handleClick })] }));
    }
    else if (isSuccess) {
        bodyDisplay = (_jsxs("p", { children: ["Congrats, you've connected your ", _jsx("span", { "data-ignore": true, children: gameName || '' }), " Account, start playing now!"] }));
    }
    else if (errorMessage === 'Game ID was recently disconnected') {
        bodyDisplay = (_jsxs("p", { children: ["You recently disconnected your Game ID and must wait 30 days before you can reconnect it or add a new Game ID on this account.", _jsx("br", {}), _jsx("br", {}), _jsx(ContactSupportSection, { onSupportLinkClick: handleSupportLinkClick })] }));
    }
    else if (errorMessage === null || errorMessage === void 0 ? void 0 : errorMessage.toString().includes('was associated with a user who has previously been banned (or currently suspended) and cannot be used')) {
        bodyDisplay = (_jsxs("p", { children: [errorMessage, _jsx("br", {}), _jsx("br", {}), _jsx(ContactSupportSection, { onSupportLinkClick: handleSupportLinkClick })] }));
    }
    else if (errorMessage === 'Failed to find details for this player') {
        const link = getMakeStatsPublicGuide(gameKey);
        bodyDisplay = (_jsxs("p", { children: ["Failed to find details for this player, please check your stats are public", _jsx("br", {}), _jsx("br", {}), "If you believe this is an error, you can raise a support ticket", ' ', _jsx("a", { href: "https://support.repeat.gg/hc/en-us/requests/new", rel: "noopener noreferrer", target: "_blank", onClick: handleSupportLinkClick, children: "here" }), ".", _jsx("br", {}), _jsx("br", {}), link && (_jsx("a", { href: link, onClick: () => handleGuideLinkClick(link), children: "Click here for a guide on how to make your results public." }))] }));
    }
    else if (errorMessage) {
        bodyDisplay = errorMessage;
    }
    else {
        bodyDisplay = 'Oops! something went wrong, please refresh the page';
    }
    return (_jsxs(ModalContent, { className: className, align: "center", stepName: stepName, children: [_jsx(AddGameStatusBanner, { status: status }), _jsx(Title, { children: isSuccess ? 'Game Connected!' : 'Not Connected' }), _jsx("section", { children: bodyDisplay }), hasSuggestedTournaments ? (_jsxs(ViewTournmanentsLink, { to: `/${gameData === null || gameData === void 0 ? void 0 : gameData.slug}/tournaments/${data.tournaments[0].id}`, onClick: handleClick, children: ["View Tournament ", _jsx(CircleArrowRight, {})] })) : (_jsx(SuccessButton, { onClick: handleClick, variant: getGamePalette(gameKey || 'primary'), size: "md", loading: isLoading, children: isSuccess ? successButtonLabel : failButtonLabel }))] }));
};
export default AddGameConnectStatus;
