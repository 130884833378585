import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
import { useUserState } from '@context/UserContext';
import NavItem from './NavItem';
const useStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        fontFamily: theme.typography.fontFamily,
        borderRight: `1px solid ${theme.palette.grey[100]}`,
        paddingRight: '7px',
    },
    navigationLink: {
        color: theme.palette.grey[400],
        marginLeft: '3px',
        marginRight: '3px',
        paddingRight: '10px',
        paddingLeft: '10px',
        height: '55px',
        whiteSpace: 'nowrap',
        '& span': {
            fontSize: '12px',
            fontWeight: 600, // override MUI
        },
        '&.selected': {
            color: theme.palette.primary.main,
        },
        '&:hover': {
            backgroundColor: '#F6F7FA',
            borderRadius: '4px',
            height: '35px',
        },
    },
    myTournaments: {
        display: 'none',
        '@media (min-width: 1400px)': {
            display: 'flex',
        },
    },
    rewards: {
        display: 'none',
        '@media (min-width: 1180px)': {
            display: 'flex',
        },
    },
}));
const NavigationSiteRight = () => {
    const { classes, cx } = useStyles();
    const { authenticated, userProfile } = useUserState();
    if (!authenticated) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs("div", { className: classes.container, children: [userProfile && (_jsx(NavItem, { className: cx(classes.navigationLink, classes.myTournaments), to: `/user/profile/${encodeURI(userProfile.handle)}/tournaments`, linkText: "My Tournaments" })), _jsx(NavItem, { className: cx(classes.navigationLink, classes.rewards), to: "/coins", linkText: "Rewards" })] }));
};
export default NavigationSiteRight;
