import pouchTournamentGbModalLastShown from './pouchTournamentGbModalLastShown';
const showLoLGBPouchTournament = (userContext, gameContext, gameSlug) => {
    var _a;
    const isLoL = gameContext.games.find((game) => game.slug === gameSlug);
    const hasLoL = userContext.userHasGame('league_of_legends');
    const userIsFromGB = ((_a = userContext === null || userContext === void 0 ? void 0 : userContext.userDetails) === null || _a === void 0 ? void 0 : _a.country) === 'GB';
    if (isLoL &&
        hasLoL &&
        userIsFromGB &&
        (pouchTournamentGbModalLastShown() >= 48 || pouchTournamentGbModalLastShown() === false)) {
        return true;
    }
    return false;
};
export default showLoLGBPouchTournament;
