import axios from 'axios';
/**
 * Fetch data from the /notifications/recent endpoint
 *
 * @returns response from the API with metadata with notification counts for new and global
 */
const fetchNewNotificationsCount = async () => {
    return axios
        .get(`${process.env.API_BASE_URL}/notifications/notification-count`)
        .then((response) => response.data);
};
export default fetchNewNotificationsCount;
