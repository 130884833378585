import { useRouteMatch } from 'react-router-dom';
import useGamesList from '@hooks/API/useGamesList';
import { useFeature } from 'flagged';
const useFocusedMenuItem = () => {
    const { allGames } = useGamesList();
    const isPlatformFirstEnabled = !!useFeature('enable_platform_first');
    const matchGameSlug = useRouteMatch({
        path: '/:gameSlug/',
        sensitive: true,
    });
    const matchHomeRoute = useRouteMatch({
        path: '',
        sensitive: true,
        exact: true,
    });
    const matchMarketplaceRoute = useRouteMatch({
        path: '/marketplace',
        sensitive: true,
    });
    if (isPlatformFirstEnabled) {
        return null;
    }
    let focusedMenuItem = null;
    const currentGame = allGames.find((game) => { var _a; return game.slug === ((_a = matchGameSlug === null || matchGameSlug === void 0 ? void 0 : matchGameSlug.params) === null || _a === void 0 ? void 0 : _a.gameSlug); });
    if (isPlatformFirstEnabled) {
        focusedMenuItem = null;
    }
    else if (currentGame) {
        focusedMenuItem = currentGame.gameKey;
    }
    else if (matchHomeRoute) {
        focusedMenuItem = 'home';
    }
    else if (matchMarketplaceRoute) {
        focusedMenuItem = 'marketplace';
    }
    return focusedMenuItem;
};
export default useFocusedMenuItem;
