export const getTournamentModesDisplay = (tournament) => {
    const { game_modes } = tournament.score_template;
    // Do not show game modes if data is missing
    if (game_modes == null) {
        return null;
    }
    // No game mode restriction
    if (game_modes.length === 0) {
        return 'All Modes';
    }
    return game_modes.join(' • ');
};
export default getTournamentModesDisplay;
