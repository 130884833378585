import { useLocation } from 'react-router';
import queryString from 'query-string';
const useOauthReturnPath = (additionalParams, ignoreParams) => {
    const { search, pathname, hash } = useLocation();
    const params = queryString.parse(queryString.exclude(search, ignoreParams));
    const returnPath = queryString.stringifyUrl({
        url: pathname,
        query: {
            ...params,
            ...additionalParams,
        },
    });
    return `${returnPath}${hash}`;
};
export default useOauthReturnPath;
