import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { TssCacheProvider } from 'tss-react';
import { FlagsProvider } from 'flagged';
import { UserProvider } from '@context/UserContext';
import initFirebase from '@helpers/initFirebase';
import RepeatTheme from '@themes';
import FirebaseAuthGuard from '@domain/App/FirebaseAuthGuard';
import LocalAppContextProvider from '@context/LocalAppContext';
import ErrorPage from '@domain/Errors/components/ErrorPage';
import ModalRenderer from '@components/Modals/ModalRenderer';
import queryClient from '@helpers/initQueryClient';
import PlatformSelectAnimation from '@domain/Platform/components/PlatformSelectAnimation';
import PlatformSelectAnimationProvider from '@context/PlatformSelectAnimationContext';
import RepeatAppScreen from './RepeatAppScreen';
import forcePolyfill from './forcePolyfill';
// Added here to force injection of an Object.fromEntries polyfill
// as this method is used by `flagged`, but it doesn't include the
// polyfill in the bundle.
forcePolyfill();
Sentry.init({
    enabled: process.env.SENTRY_ENV !== 'dev',
    release: process.env.REPEAT_RELEASE || '2021-10.03',
    dsn: 'https://bec1af7aab7b45b6af8850880edb9102@o470953.ingest.sentry.io/5502314',
    integrations: [
        new Integrations.BrowserTracing({
            tracingOrigins: ['repeat.gg'],
        }),
    ],
    environment: process.env.SENTRY_ENV,
    // 25% chance of any error sending through the entire application trace.
    // See https://docs.sentry.io/product/performance/distributed-tracing/ for more details
    tracesSampleRate: 0.25,
    ignoreErrors: ['ResizeObserver loop limit exceeded', 'ResizeObserver loop completed with undelivered notifications.'],
    denyUrls: [
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Indicative
        /api.indicative.com\//i,
        // Localize Library
        /localizecdn.com\//i,
    ],
});
initFirebase();
const muiCache = createCache({
    key: 'mui',
    prepend: true,
});
const tssCache = createCache({
    key: 'tss',
});
ReactDOM.render(_jsx(FlagsProvider, { features: window.feature_flags, children: _jsx(LocalAppContextProvider, { children: _jsx(QueryClientProvider, { client: queryClient, children: _jsx(BrowserRouter, { children: _jsx(CacheProvider, { value: muiCache, children: _jsx(TssCacheProvider, { value: tssCache, children: _jsx(ThemeProvider, { theme: RepeatTheme, children: _jsx(FirebaseAuthGuard, { children: _jsx(Sentry.ErrorBoundary, { fallback: ({ error }) => _jsx(ErrorPage, { error: error }), children: _jsx(Suspense, { fallback: _jsx("div", {}), children: _jsxs(UserProvider, { children: [_jsxs(PlatformSelectAnimationProvider, { children: [_jsx(RepeatAppScreen, {}), _jsx(PlatformSelectAnimation, {})] }), _jsx(ModalRenderer, {})] }) }) }) }) }) }) }) }) }) }) }), document.getElementById('repeat-app'));
