import { useQuery } from 'react-query';
import getAppContext from '@api/getAppContext';
const useAppState = () => {
    var _a, _b, _c, _d;
    const { data, isLoading, error, isFetching } = useQuery('appContext', getAppContext, {
        suspense: true,
        staleTime: 1000 * 60 * 60 * 8, // 8 hours
    });
    return {
        paymentMethods: data === null || data === void 0 ? void 0 : data.paymentMethods,
        tournaments: (_a = data === null || data === void 0 ? void 0 : data.tournaments) !== null && _a !== void 0 ? _a : {},
        registrationEndingMinutes: (_b = data === null || data === void 0 ? void 0 : data.registrationEndingMinutes) !== null && _b !== void 0 ? _b : 0,
        exitWindowDuration: (_c = data === null || data === void 0 ? void 0 : data.exitWindowDuration) !== null && _c !== void 0 ? _c : 0,
        siteAlerts: (_d = data === null || data === void 0 ? void 0 : data.siteAlerts) !== null && _d !== void 0 ? _d : [],
        isLoading,
        isFetching,
        error: error && {
            error: error.message,
            errorCode: error.name,
        },
    };
};
export default useAppState;
