import validateRegister from './validateRegister';
/**
 * Validates the initial data and returns only the fields that are valid.
 * @param completeSignUpInitialData The initial data
 * @returns Only the fields of the given initial data that are valid
 */
const getValidCompleteSignUpInitialData = (completeSignUpInitialData = {}) => {
    var _a, _b, _c, _d, _e, _f;
    const initialDataValidator = validateRegister(completeSignUpInitialData !== null && completeSignUpInitialData !== void 0 ? completeSignUpInitialData : {});
    initialDataValidator.passes();
    return {
        email: (_a = (initialDataValidator.errors.has('email') ? undefined : completeSignUpInitialData === null || completeSignUpInitialData === void 0 ? void 0 : completeSignUpInitialData.email)) !== null && _a !== void 0 ? _a : '',
        DOBDay: (_b = (initialDataValidator.errors.has('DOBDay') ? undefined : completeSignUpInitialData === null || completeSignUpInitialData === void 0 ? void 0 : completeSignUpInitialData.DOBDay)) !== null && _b !== void 0 ? _b : '',
        DOBMonth: (_c = (initialDataValidator.errors.has('DOBMonth') ? undefined : completeSignUpInitialData === null || completeSignUpInitialData === void 0 ? void 0 : completeSignUpInitialData.DOBMonth)) !== null && _c !== void 0 ? _c : '',
        DOBYear: (_d = (initialDataValidator.errors.has('DOBYear') ? undefined : completeSignUpInitialData === null || completeSignUpInitialData === void 0 ? void 0 : completeSignUpInitialData.DOBYear)) !== null && _d !== void 0 ? _d : '',
        country: (_e = (initialDataValidator.errors.has('country') ? undefined : completeSignUpInitialData === null || completeSignUpInitialData === void 0 ? void 0 : completeSignUpInitialData.country)) !== null && _e !== void 0 ? _e : '',
        state: (_f = (initialDataValidator.errors.has('state') ? undefined : completeSignUpInitialData === null || completeSignUpInitialData === void 0 ? void 0 : completeSignUpInitialData.state)) !== null && _f !== void 0 ? _f : '',
    };
};
export default getValidCompleteSignUpInitialData;
