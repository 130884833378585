import { jsx as _jsx } from "react/jsx-runtime";
import { FormControlLabel, Switch } from '@mui/material';
import { styled } from '@mui/system';
const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&:before': {
            left: 12,
        },
        '&:after': {
            right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
    '& .MuiFormControlLabel-root': {
        fontSize: '12px',
        fontWeight: 600,
        color: theme.palette.grey[400],
    },
}));
const StyledLabel = styled(FormControlLabel) `
  & .MuiFormControlLabel-label {
    padding: ${({ theme }) => theme.spacing(1)};
    font-size: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.palette.grey[400]};
  }
`;
const ToggleSwitch = ({ checked, label, onChange, className = '' }) => (_jsx(StyledLabel, { value: checked, control: _jsx(Android12Switch, { checked: checked, onChange: onChange }), label: label, className: className, labelPlacement: "start" }));
export default ToggleSwitch;
