const loadFiltersFromStorage = () => {
    try {
        const existingFilter = localStorage.getItem('repeatTournamentFilters');
        return existingFilter ? JSON.parse(existingFilter) : {};
    }
    catch (err) {
        // silence error
        return {};
    }
};
export default loadFiltersFromStorage;
