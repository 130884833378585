import { useState } from 'react';
import axios from 'axios';
import { useQueryClient } from 'react-query';
import { captureException, captureMessage } from '@sentry/react';
import ErrorWithCause from '../../../../helpers/ErrorWithCause';
const displayed400Messages = [
    'Too small a withdrawal.',
    'It appears that you have insufficient funds',
    'Cannot withdraw money - either your email is not verified or you are suspended.',
    'Check needs address',
];
const useWithdrawAPI = (userDetails, onSuccess, onError) => {
    const [status, setStatus] = useState('READY');
    const [errorMessage, setErrorMessage] = useState(null);
    const queryClient = useQueryClient();
    const handle400Response = (message) => {
        const canDisplayReturnedMessage = displayed400Messages.filter((msg) => message.startsWith(msg)).length > 0;
        setErrorMessage(canDisplayReturnedMessage ? message : 'Something went wrong with your withdrawal request.');
        captureMessage(message);
    };
    const requestWithdraw = async (payload) => {
        var _a, _b, _c, _d, _e;
        if (status === 'LOADING') {
            return;
        }
        setErrorMessage(null);
        setStatus('LOADING');
        const requestName = 'withdrawal';
        try {
            await axios.post('/account/withdrawal', payload);
            queryClient.invalidateQueries({ queryKey: ['transactions'] });
            queryClient.invalidateQueries({ queryKey: ['balance'] });
            setStatus('SUCCESS');
            onSuccess();
        }
        catch (error) {
            const e = new ErrorWithCause(error);
            switch ((_a = e.response) === null || _a === void 0 ? void 0 : _a.status) {
                case 400:
                    handle400Response((_d = (_c = (_b = e === null || e === void 0 ? void 0 : e.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.result) !== null && _d !== void 0 ? _d : '');
                    break;
                case 401:
                    console.warn('401');
                    setErrorMessage('Unauthorized access - please log out and log in again');
                    break;
                case 403:
                    setErrorMessage('Unauthorized access - please log out and log in again');
                    break;
                default:
                    setErrorMessage('Something went wrong with your withdrawal request.');
                    captureException(e, {
                        extra: {
                            request: requestName,
                            gateway: payload.gateway,
                            userId: payload.detailsId,
                        },
                    });
            }
            setStatus('ERROR');
            if (onError) {
                onError((_e = e === null || e === void 0 ? void 0 : e.response) === null || _e === void 0 ? void 0 : _e.status);
            }
        }
    };
    const withdrawCheck = (amount) => {
        var _a;
        if (status === 'LOADING') {
            return;
        }
        requestWithdraw({
            email: '',
            token: null,
            detailsId: null,
            gateway: 'check',
            address: (_a = userDetails === null || userDetails === void 0 ? void 0 : userDetails.address) !== null && _a !== void 0 ? _a : undefined,
            amount,
        });
    };
    const withdrawPaypal = (amount, email) => {
        var _a;
        requestWithdraw({
            email,
            token: null,
            detailsId: null,
            gateway: 'paypal',
            address: (_a = userDetails === null || userDetails === void 0 ? void 0 : userDetails.address) !== null && _a !== void 0 ? _a : undefined,
            amount,
        });
    };
    const withdrawTipalti = (amount) => {
        var _a, _b;
        requestWithdraw({
            email: (_a = userDetails === null || userDetails === void 0 ? void 0 : userDetails.email) !== null && _a !== void 0 ? _a : '',
            token: null,
            detailsId: null,
            gateway: 'tipalti',
            address: (_b = userDetails === null || userDetails === void 0 ? void 0 : userDetails.address) !== null && _b !== void 0 ? _b : undefined,
            amount,
        });
    };
    const reset = () => {
        setStatus('READY');
        setErrorMessage(null);
    };
    return {
        status,
        errorMessage,
        withdrawCheck,
        withdrawPaypal,
        withdrawTipalti,
        reset,
    };
};
export default useWithdrawAPI;
