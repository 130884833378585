import ErrorWithCause from '@helpers/ErrorWithCause';
import axios from 'axios';
const getCart = async () => {
    try {
        const response = await axios.get(`${process.env.SHOPPING_CART_API_BASE_URL}/cart`);
        return response.data;
    }
    catch (error) {
        throw new ErrorWithCause(error);
    }
};
export default getCart;
