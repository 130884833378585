import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CoreButton from "@components/Buttons/CoreButton";
import { styled } from '@mui/material/styles';
const Heading = styled('h1') `
  font-size: 24px;
  font-weight: 600;
  color: #000;
  margin-bottom: 25px;
`;
const StyledParagraph = styled('p') `
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.grey[500]};

  &:last-of-type {
    margin-bottom: 45px;
  }
`;
const PageContainer = styled('div') `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: 10%;
    padding: 15px;
    text-align: center;
  }
`;
const ImageContainer = styled('div') `
  align-items: center;
  justify-content: center;

  && img {
    ${({ theme }) => theme.breakpoints.down('sm')} {
      width: 100%;
    }
  }
`;
const DisabledMarketplaceScreen = () => {
    return (_jsxs(PageContainer, { "data-testid": "marketplace-disabled-component", children: [_jsx(ImageContainer, { children: _jsx("img", { src: '/img/marketplace/marketplace-disabled.png', alt: 'Marketplace will be back soon!' }) }), _jsx(Heading, { children: "Marketplace will be back soon!" }), _jsx(StyledParagraph, { children: "Our Marketplace is currently under maintenance as we upgrade are working on improving the experience & adding new features." }), _jsxs(StyledParagraph, { children: ["If you are experiencing any issues", ' ', _jsx("a", { href: "https://support.repeat.gg/hc/en-us/requests/new", rel: "noopener noreferrer", target: "_blank", children: "contact support" })] }), _jsx(CoreButton, { type: "button", onClick: () => {
                    window.history.back();
                }, size: "lg", variant: "primary", children: _jsx("span", { children: "Return to last page" }) })] }));
};
export default DisabledMarketplaceScreen;
