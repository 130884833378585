import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
import ModalContent from '@components/Modals/components/ModalContent';
import LoadingLogo from '@components/Modals/LoadingModal/Components/LoadingLogo';
const useStyles = makeStyles()((theme) => ({
    container: {
        minWidth: '444px',
        minHeight: '300px',
        maxHeight: '100%',
    },
}));
export const AddGameInterstitial = ({ loadingText = 'Connecting...', className }) => {
    const { classes, cx } = useStyles();
    return (_jsx(ModalContent, { className: cx(classes.container, className), align: "center", verticalAlign: "center", children: _jsxs("section", { children: [_jsx(LoadingLogo, {}), _jsx("p", { children: loadingText })] }) }));
};
export default AddGameInterstitial;
