import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import loadable from '@loadable/component';
import { css, styled } from '@mui/system';
const Dollar = loadable(() => import('@components/Icons/Dollar'));
function shouldForwardProp(propName) {
    return !['centerVertical', 'iconSize', 'iconGap'].includes(propName);
}
const Wrapper = styled('span', { shouldForwardProp }) `
  align-items: center;
  display: inline-flex;
  & svg {
    width: ${({ iconSize }) => iconSize}px;
    height: ${({ iconSize }) => iconSize}px;
    margin-right: ${({ iconGap }) => iconGap}px;
  }
  & img {
    width: ${({ iconSize }) => iconSize}px;
    height: ${({ iconSize }) => iconSize}px;
    margin-right: ${({ iconGap }) => iconGap}px;
  }

  ${(props) => props.centerVertical &&
    css `
      display: inline-flex;
      vertical-align: middle;
      padding-bottom: 2px;
    `}
`;
const RealMoney = ({ amount, className, optimise = false, centerVertical = false, hideIcon = false, withDollarSymbol, iconSize = 16, iconGap = 5, }) => {
    return (_jsxs(Wrapper, { iconSize: iconSize, iconGap: iconGap, centerVertical: centerVertical, "data-ignore": true, "data-testid": "USD", className: className, children: [!hideIcon && (optimise ? _jsx("img", { src: "/img/icons/dollar.svg", alt: "dollar" }) : _jsx(Dollar, {})), withDollarSymbol && '$', amount.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: withDollarSymbol ? 0 : 2 })] }));
};
export default RealMoney;
