import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from '@mui/material';
const Container = styled('div') `
  display: flex;
  flex-direction: row;
  gap: 5px;
  height: 6px;
  margin: ${({ theme }) => theme.spacing(1, 0)};
`;
const ProgressDot = styled('div', { shouldForwardProp: (prop) => prop != 'active' }) `
  flex: 1;
  background-color: ${({ theme, active }) => (active ? theme.palette.primary.main : theme.palette.grey[100])};
  border-radius: 12px;
`;
const ProgressDots = ({ current, total, className }) => {
    return (_jsx(Container, { className: className, children: Array.apply(null, Array(total)).map((_value, index) => (_jsx(ProgressDot, { active: index < current }, index))) }));
};
export default ProgressDots;
