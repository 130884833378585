import { jsx as _jsx } from "react/jsx-runtime";
import RealMoney from './RealMoney';
import PlayMoney from './PlayMoney';
const Currency = ({ currency, amount, className, optimise = false, hideIcon = false, withDollarSymbol = false, iconSize, iconGap, }) => {
    switch (currency.toLowerCase()) {
        case 'usd':
            return (_jsx(RealMoney, { withDollarSymbol: withDollarSymbol, optimise: optimise, className: className, amount: amount, hideIcon: hideIcon, iconSize: iconSize, iconGap: iconGap }));
        case 'pm':
            return (_jsx(PlayMoney, { optimise: optimise, className: className, amount: amount, hideIcon: hideIcon, iconSize: iconSize, iconGap: iconGap }));
        default:
            return null;
    }
};
export default Currency;
