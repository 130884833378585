import { styled } from '@mui/system';
import { Container } from '@mui/material';
const FullLayout = styled(Container) `
  display: grid;
  grid-row-gap: ${({ theme }) => theme.spacing(2.5)};
  column-gap: ${({ theme }) => theme.spacing(3)};
  max-width: ${({ theme }) => theme.layout.contentMaxWidth + 'px'};
  padding: 0;
  padding-top: ${({ theme }) => theme.spacing(3)};

  grid-auto-flow: row;
  grid-template-rows: auto;
  grid-template-columns: 1fr;

  ${({ theme }) => theme.breakpoints.down('md')} {
    grid-template-columns: minmax(1fr);
    grid-auto-flow: column;
  } ;
`;
export default FullLayout;
