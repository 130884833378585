import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid } from '@mui/material';
import PageTitle from '@helpers/PageTitle';
import Button from "@components/Buttons/Button";
import LinkButton from "@components/Buttons/LinkButton";
const Error404 = () => {
    const goBack = () => {
        window.history.go(-1);
    };
    return (_jsxs("div", { className: "repeat404Wrapper", "data-testid": "404 page", children: [_jsx(PageTitle, {}), _jsxs("div", { className: "repeat404Content", children: [_jsx("img", { src: "/img/repeat-404.svg", alt: "" }), _jsx("h1", { children: "404: Page Not Found" }), _jsx("p", { children: "The page you are trying to reach does not exist. I am willing to bet you are a button mashing noob that got way too excited when typing in the URL." }), _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, sm: 6, children: _jsx(Button, { type: "button", block: true, size: "lg", variant: "brand", onClick: goBack, children: "Go Back" }) }), _jsx(Grid, { item: true, xs: 12, sm: 6, children: _jsx(LinkButton, { href: "/", block: true, size: "lg", variant: "brand", children: "Go Home" }) })] })] })] }));
};
export default Error404;
