const getGameBackground = (game) => {
    switch (game) {
        case 'pubg':
            return '/img/games/fps/pubg/background_578x324.png';
        case 'pubg-mobile':
            return '/img/games/fps/pubg-mobile/game-tile-background.jpg';
        case 'league_of_legends':
            return '/img/games/strategy/league_of_legends/background_576x324.png';
        case 'fortnite':
            return '/img/games/strategy/fortnite/fortnite-bg.png';
        case 'rocket-league':
            return '/img/games/sports/rocket_league/background_580x334.jpg';
        case 'brawl-stars':
            return '/img/games/fps/brawl-stars/background_412x336.png';
        case 'pc-dota_2':
            return '/img/games/strategy/dota/background_768x360.png';
        case 'cod-warzone-2':
            return '/img/games/fps/cod-warzone-2/background_576x324.png';
        case 'destiny-2':
            return '/img/games/fps/destiny-2/background_576x324.png';
        case 'cod-warzone':
            return '/img/games/fps/cod-warzone/background_488_592.png';
        case 'mlb-the-show-23':
            return '/img/games/sports/mlb-the-show-23/background_576x324.png';
        default:
            return '';
    }
};
export default getGameBackground;
