import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import ModalBody from "@components/Modals/components/ModalBody";
import ModalBreadcrumb from "@components/Modals/components/ModalBreadcrumb";
import ModalHeader from "@components/Modals/components/ModalHeader";
import { ContentCarousel } from '@components/UI';
import trackHotjarEvent from '@components/Analytics/trackHotjarEvent';
import completePaypalDeposit from '@api/payment/completePaypalDeposit';
import Amount from '@components/Modals/AddFunds/components/BuyCoins/components/Amount';
import PaymentMethod from '@components/Modals/AddFunds/components/PaymentMethod';
import Complete from '@components/Modals/AddFunds/components/Complete';
import TransferConfirmation from '@components/Modals/AddFunds/components/BuyCoins/components/TransferConfirmation';
const useStyles = makeStyles()((theme) => ({
    closeButton: {
        height: '30px',
    },
    icon: {
        display: 'flex',
        flexDirection: 'column',
        '& img': {
            height: '20px',
            marginBottom: theme.spacing(1),
        },
    },
    label: {
        display: 'inline-flex',
        '& img': {
            height: '14px',
            marginRight: theme.spacing(0.5),
        },
    },
}));
const BuyCoins = ({ onClose, onResetModal, preSelectedCurrency = false }) => {
    const { classes } = useStyles();
    const [breadcrumbIndex, setBreadcrumbIndex] = useState(1);
    const [transactionAmount, setTransactionAmount] = useState(0);
    const [paymentStatus, setPaymentStatus] = useState('WAITING');
    const [error, setError] = useState({
        errorMessage: 'Something went wrong with your payment.',
        allowRetry: true,
    });
    const [paypalOrderId, setPaypalOrderId] = useState(undefined);
    const hideCrumbs = preSelectedCurrency ? [0] : [];
    const handleBalanceTransferRequested = () => {
        setBreadcrumbIndex(3);
    };
    const breadcrumbs = [
        {
            id: '0',
            component: 'Select Currency',
            onClick: () => onResetModal(),
        },
        {
            id: '1',
            component: 'Amount',
            onClick: () => {
                setBreadcrumbIndex(1);
                setTransactionAmount(0);
                setPaymentStatus('WAITING');
            },
        },
        {
            id: '2',
            component: 'Payment Method',
            onClick: () => {
                if (transactionAmount) {
                    setBreadcrumbIndex(3);
                }
            },
        },
    ];
    const handleClose = () => {
        if (paypalOrderId && paymentStatus === 'WAITING') {
            // If the user closed the whole modal when there was a paypal order ID but the state suggests
            // nothing is completed or in process, cancel the payment_order.
            // Best effort - kick it off and ignore any errors.
            completePaypalDeposit({
                paypalOrderId,
                newOrderState: 'cancelled',
            });
        }
        onClose();
    };
    const handleCoinSelection = (transactionValue) => {
        setTransactionAmount(transactionValue);
        setBreadcrumbIndex(2);
        trackHotjarEvent(`Click buy/deposit currency`);
    };
    // Called on PayPal success/Error
    const handlePaymentMethod = () => {
        // This is pointing to the "Success" step, which does not have a breadcrumb
        setBreadcrumbIndex(4);
    };
    const handleTransferSuccess = () => {
        setPaymentStatus('COMPLETE');
        handlePaymentMethod();
        trackHotjarEvent(`Purchases currency success`);
    };
    const handleTransferError = (message) => {
        setPaymentStatus('ERROR');
        setError({ errorMessage: message, allowRetry: true });
        handlePaymentMethod();
    };
    const handleTransferCancel = () => {
        setBreadcrumbIndex(2);
    };
    const playMoneyFromUSDMapping = {
        5: 50000,
        10: 100000,
        25: 250000,
        50: 500000,
        100: 1000000,
        500: 5000000,
    };
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { title: "Buy Coins", subtitle: "Add Coins to your account", icon: _jsx("img", { src: "/img/icons/coins.svg", alt: "coins", className: classes.closeButton }), onClose: handleClose, paletteName: "secondary" }), _jsx(ModalBreadcrumb, { currentIndex: breadcrumbIndex, breadcrumbs: breadcrumbs, hideCrumbs: hideCrumbs, isVisible: true }), _jsx(ModalBody, { children: _jsxs(ContentCarousel, { currentStep: breadcrumbIndex - 1, name: "Buy Coins", children: [_jsx(Amount, { onSelect: handleCoinSelection }), _jsx(PaymentMethod, { onError: handlePaymentMethod, onSuccess: handlePaymentMethod, setError: setError, setPaymentStatus: setPaymentStatus, setPaypalOrderId: setPaypalOrderId, paypalOrderId: paypalOrderId, currencyAmount: playMoneyFromUSDMapping[transactionAmount], currency: "PM", transactionAmount: transactionAmount, paymentStatus: paymentStatus, onBalanceTransfer: handleBalanceTransferRequested }), _jsx(TransferConfirmation, { transactionAmount: transactionAmount, onSuccess: handleTransferSuccess, onError: handleTransferError, onCancel: handleTransferCancel }), _jsx(Complete, { onClose: onClose, onRetry: () => setBreadcrumbIndex(2), paymentStatus: paymentStatus, currency: "PM", transactionAmount: transactionAmount, currencyAmount: playMoneyFromUSDMapping[transactionAmount], error: error })] }) })] }));
};
export default BuyCoins;
