import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from 'react-query';
import loadable from '@loadable/component';
import ModalIconBanner from '@components/Modals/components/ModalIconBanner';
import ModalContent from '@components/Modals/components/ModalContent';
import { makeStyles } from 'tss-react/mui';
import ConnectGameButton from '@components/AddGame/ConnectGameButton';
import connectAppleAccount from '../helpers/connectAppleAccount';
const Icon = loadable(() => import('@components/Icons/Icon'));
const useStyles = makeStyles()(() => ({
    appleLogo: {
        height: '85px !important',
    },
}));
/**
 * This is one of the steps in the add social media account modal flow
 *
 * @param props The component props
 * @param props.onSuccess Called when the Apple account is successfully connected
 * @param props.onError Called when there is a problem connecting the account
 * @returns The component
 */
const ConnectAppleAccount = ({ onSuccess, onError }) => {
    const { classes } = useStyles();
    const { isLoading, mutate } = useMutation(connectAppleAccount, { onSuccess, onError });
    return (_jsxs(ModalContent, { stepName: "connect-apple", align: "center", children: [_jsx(ModalIconBanner, { children: _jsx(Icon, { icon: "apple", className: classes.appleLogo }) }), _jsxs("h2", { children: [_jsx("span", { "data-ignore": true, children: "Apple" }), "\u00A0Sign In"] }), _jsx("section", { children: _jsxs("p", { children: ["You'll be redirected to the\u00A0", _jsx("span", { "data-ignore": true, children: "Apple" }), "\u00A0sign in page in order to complete this process."] }) }), _jsx(ConnectGameButton, { isLoading: isLoading, paletteName: "apple", onClick: () => mutate(), children: _jsxs("span", { "data-ignore": true, children: ["Sign in with ", _jsx("span", { "data-ignore": true, children: "Apple" }), isLoading] }) })] }));
};
export default ConnectAppleAccount;
