import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme) => ({
    regionAvatar: {
        fontSize: '22px',
        color: theme.palette.grey[200],
        padding: theme.spacing(1),
    },
}));
const RegionAvatar = ({ regionCode }) => {
    const { classes } = useStyles();
    return (_jsx("div", { className: classes.regionAvatar, "data-ignore": true, "aria-hidden": true, children: regionCode }));
};
export default RegionAvatar;
