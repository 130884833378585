import axios from 'axios';
import ErrorWithCause from '../helpers/ErrorWithCause';
const getGameServiceRegions = async (gameKey) => {
    try {
        const response = await axios.get(`/api/game/${gameKey}/service-regions/v1`);
        return response.data;
    }
    catch (error) {
        throw new ErrorWithCause(error);
    }
};
export default getGameServiceRegions;
