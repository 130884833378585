import getRecentlyValidatedState from '@api/otp/getRecentlyValidatedState';
import postRequestOtp from '@api/otp/postRequestOtp';
import postValidateOtpCode from '@api/otp/postValidateOtpCode';
import useCountdown from '@components/Hooks/useCountdown';
import useLocalStorage from '@components/Hooks/useLocalStorage';
import { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
export const OTP_EXPIRATION_SECONDS = 600; // 10 minutes
// the time in seconds to wait before allowing the user to resend the code
export const TIME_TO_NEXT_RESEND_CODE = 30;
export const useResendVerification = ({ resetOtpInputAndError, setOtpCodeRemainingSeconds, }) => {
    const [resendPressed, setResendPressed] = useState(false);
    const [resendBtnError, setResendBtnError] = useState(null);
    const onSuccess = (data, variables, context) => {
        if (data === null || data === void 0 ? void 0 : data.requestRateExceeded) {
            setResendBtnError('requestRateExceeded');
        }
        else {
            setRemainingSeconds(TIME_TO_NEXT_RESEND_CODE);
            setOtpCodeRemainingSeconds(OTP_EXPIRATION_SECONDS);
            // resets the input field value
            resetOtpInputAndError();
            setResendBtnError(null);
        }
    };
    const { mutate: requestOtpCode, isLoading } = useMutation(postRequestOtp, { onSuccess });
    const { remainingSeconds: resendBtnRemainingSeconds, setRemainingSeconds } = useCountdown({
        startSeconds: resendPressed ? TIME_TO_NEXT_RESEND_CODE : 0,
        onCountdownComplete: () => {
            setRemainingSeconds(0);
        },
    });
    const resendCodeHandler = async () => {
        requestOtpCode();
        setResendPressed(true);
    };
    return {
        disableResendButton: resendBtnRemainingSeconds > 0,
        resendCodeHandler,
        resendBtnRemainingSeconds,
        resendBtnError,
        resendBtnLoading: isLoading,
    };
};
export const useOtpModal = ({ onSuccess }) => {
    const [otpInputValue, setOtpInputValue] = useState('');
    const [otpInputDisabled, setOtpInputDisabled] = useState(false);
    const [isOtpValid, setIsOtpValid] = useState();
    // Back-end error messages related to multiple attempts of validation and request rate exceeded
    const [otpErrorMessage, setOtpErrorMessage] = useState(null);
    const [lastOtpRequestTimeStamp, setLastOtpRequestTimeStamp] = useLocalStorage('last_otp_request_sent', '');
    const requestExceededHandler = (type) => {
        setOtpInputDisabled(true);
        setOtpErrorMessage(type);
    };
    // Validates the OTP code - user input
    const { mutate: postValidateOtpCodeMutation } = useMutation({
        mutationFn: (otpCode) => postValidateOtpCode(otpCode),
        onSuccess: (data) => {
            setIsOtpValid(data.valid);
            if (!data.valid && (data === null || data === void 0 ? void 0 : data.reason) === 'too-many-attempts') {
                requestExceededHandler('validationError');
            }
            if (data.valid) {
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
            }
        },
        onError: () => {
            setIsOtpValid(false);
        },
    });
    // Requests a new OTP code
    const { mutate: requestOtpCode } = useMutation(postRequestOtp, {
        onSuccess: (data, variables, context) => {
            if (data === null || data === void 0 ? void 0 : data.requestRateExceeded) {
                requestExceededHandler('requestRateExceeded');
            }
        },
    });
    const startSeconds = 600;
    const timeRef = useRef(startSeconds);
    const otpCodeExpiration = useCountdown({
        startSeconds,
        onCountdownComplete: () => {
            setOtpInputDisabled(true);
        },
        onCountdownProgress(remaining) {
            timeRef.current = remaining;
        },
    });
    useEffect(() => {
        if (lastOtpRequestTimeStamp) {
            const lastTimeSent = new Date(lastOtpRequestTimeStamp);
            const tenMinutesAgo = new Date(Date.now() - 10 * 60 * 1000);
            if (lastTimeSent > tenMinutesAgo) {
                const now = new Date();
                const secondsPassed = Math.floor((now.getTime() - lastTimeSent.getTime()) / 1000);
                const remainingSeconds = Math.max(600 - secondsPassed, 0);
                otpCodeExpiration.setRemainingSeconds(remainingSeconds);
            }
            else {
                requestOtpCode();
            }
        }
        else {
            // Mark the time when the OTP was requested for the first time
            setLastOtpRequestTimeStamp(new Date().toISOString());
            requestOtpCode();
        }
        return () => {
            if (timeRef.current > 0) {
                const otpRequestTime = new Date(Date.now() - (600 - timeRef.current) * 1000);
                setLastOtpRequestTimeStamp(otpRequestTime.toISOString());
            }
        };
    }, []);
    const onSubmitHandler = async () => {
        if (otpInputValue === '') {
            return;
        }
        postValidateOtpCodeMutation(otpInputValue);
    };
    const resetOtpInputAndError = () => {
        setOtpInputValue('');
        setOtpInputDisabled(false);
        setIsOtpValid(undefined);
        setOtpErrorMessage(null);
    };
    // Resend button hoook
    const resendBtn = useResendVerification({
        resetOtpInputAndError,
        setOtpCodeRemainingSeconds: otpCodeExpiration.setRemainingSeconds,
    });
    return {
        otpInputValue,
        setOtpInputValue,
        otpInputDisabled,
        otpCodeExpiration,
        onSubmitHandler,
        loading: false,
        isOtpValid,
        otpErrorMessage,
        resendBtn,
        resetOtpInputAndError,
    };
};
// -  Checks if the user has recently been validated
// - if not: Triggers an OTP request
export const useValidateOtpModal = () => {
    const { data: recentlyValidated, isLoading: isRecentlyValidatedLoading } = useQuery(['recently_validated'], getRecentlyValidatedState);
    return {
        recentlyValidated: recentlyValidated === null || recentlyValidated === void 0 ? void 0 : recentlyValidated.validatedRecently,
        isRecentlyValidatedLoading,
    };
};
