import { captureException } from '@sentry/react';
import { useQuery } from 'react-query';
import getUserSocialMediaAccounts from '@api/getUserSocialMediaAccounts';
/**
 * @param userHandle fetch social media accounts for this user, disable the network fetch if not provided.
 * @param enabled set to `false` to disable the network fetch.
 */
const useGetUserSocialMediaAccounts = (userHandle, enabled = true) => {
    const queryKey = 'userSocialMediaAccounts';
    const query = useQuery([queryKey, userHandle], () => getUserSocialMediaAccounts(userHandle), {
        enabled: Boolean(enabled && userHandle),
        staleTime: 60 * 60 * 24,
        onError: (error) => captureException(error),
    });
    return query;
};
export default useGetUserSocialMediaAccounts;
