const getAnalyticsFlowStep = (gameConnectionStatus, changedStepIndex, gameStepBreadcrumbs) => {
    let analyticsFlowStep;
    if (gameConnectionStatus === 'error' && changedStepIndex >= gameStepBreadcrumbs.length) {
        analyticsFlowStep = 'game link error';
    }
    else if (gameConnectionStatus === 'success' && changedStepIndex > gameStepBreadcrumbs.length - 1) {
        analyticsFlowStep = 'account connected';
    }
    else {
        analyticsFlowStep = gameStepBreadcrumbs[changedStepIndex].analyticsFlowStep;
    }
    return analyticsFlowStep;
};
export default getAnalyticsFlowStep;
