import { differenceInHours } from 'date-fns';
import { useEffect } from 'react';
import useLocalStorage from '@components/Hooks/useLocalStorage';
import { under13ErrorMessage } from '../helpers/validateRegister';
const useRegistrationTimeout = (dispatch) => {
    const [registrationTimeoutDate, setRegistrationTimeoutDate] = useLocalStorage('registration_timeout', undefined);
    const isRegistrationTimeout = !!registrationTimeoutDate && differenceInHours(new Date(), new Date(registrationTimeoutDate)) < 2;
    const checkRegistrationTimeout = (errors, dispatch) => {
        const validationErrors = [errors.first('DOBYear'), errors.first('DOBMonth'), errors.first('DOBDay')];
        const isUnder13 = validationErrors.every((error) => error === under13ErrorMessage);
        if (isUnder13) {
            setRegistrationTimeoutDate(new Date());
            dispatch({
                type: 'handleFormError',
                payload: {
                    validationErrors: {
                        form: under13ErrorMessage,
                    },
                },
            });
        }
    };
    useEffect(() => {
        if (isRegistrationTimeout) {
            dispatch({
                type: 'handleFormError',
                payload: {
                    validationErrors: {
                        form: under13ErrorMessage,
                    },
                },
            });
        }
    }, [isRegistrationTimeout]);
    return {
        isRegistrationTimeout,
        checkRegistrationTimeout,
    };
};
export default useRegistrationTimeout;
