import axios from 'axios';
import trackHotjarEvent from '@components/Analytics/trackHotjarEvent';
function trimSocialMediaWhitespace(payload) {
    if ('username' in payload) {
        return {
            username: payload.username.trim(),
        };
    }
    return {
        url: payload.url.trim(),
    };
}
/**
 * Add social media account via internal API.
 *
 * @param {SocialMediaType} socialMediaType The social media type
 * @param {Object} payload The request payload
 * @returns The response
 */
const addSocialMediaAccount = async (socialMediaType, payload) => {
    trackHotjarEvent(`Connect social ${socialMediaType}`);
    return axios.post(`/api/user/social_accounts/v1/${socialMediaType}`, trimSocialMediaWhitespace(payload));
};
export default addSocialMediaAccount;
