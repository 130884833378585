import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
/**
 * Invoke the callback on route change
 *
 * @param callback The action to execute on route change.
 */
const useRouteChanged = (callback) => {
    const history = useHistory();
    useEffect(() => {
        const unListen = history.listen(() => {
            callback();
        });
        return unListen;
    }, [history]);
};
export default useRouteChanged;
