export const userProfileReducer = (state, action) => {
    switch (action.type) {
        case 'SET_PROFILE_DATA':
            return {
                ...state,
                ...action.profileSummary,
            };
        case 'SET_STATISTICS':
            return {
                ...state,
                statistics: action.statistics,
            };
        case 'SET_PAST_TOURNAMENTS':
            return {
                ...state,
                pastTournaments: action.tournaments,
            };
        case 'SET_ACTIVE_TOURNAMENTS':
            return {
                ...state,
                activeTournaments: action.tournaments,
            };
        case 'SET_IS_CURRENT_USER':
            return {
                ...state,
                isCurrentUser: action.isCurrentUser,
            };
        case 'SET_USER_PROFILE':
            return {
                ...state,
                userProfile: action.userProfile,
            };
        case 'setAvatar':
            return {
                ...state,
                userProfile: state.userProfile ? { ...state.userProfile, profileUrl: action.payload.url } : null,
            };
        case 'setBanner':
            return {
                ...state,
                userProfile: state.userProfile ? { ...state.userProfile, bannerUrl: action.payload.url } : null,
            };
        default:
            throw new Error('Unhandled action type');
    }
};
export default userProfileReducer;
