import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTheme, useMediaQuery } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Select from 'react-select';
import { SROnly, ErrorMessage } from '@components/UI';
const useStyles = makeStyles()((theme) => ({
    label: {
        fontFamily: theme.typography.fontFamily,
        width: '100%',
        fontSize: '12px',
        marginBottom: '0px',
        // fixes some weird visual bug with the select dropdowns
        '&:before': {
            width: 'auto !important',
        },
        '&.error': {
            '& select': {
                border: `1px solid ${theme.palette.error.main}`,
            },
        },
    },
    option: {
        display: 'flex',
        padding: theme.spacing(1),
        alignItems: 'center',
        '& > img': {
            marginRight: theme.spacing(1),
            maxWidth: '100%',
        },
    },
    optionFocused: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    inputError: {
        borderColor: `${theme.palette.error.main}`,
        borderWidth: '2px',
        margin: theme.spacing(2, 0, 1, 0),
        '&:hover': {
            marginBottom: theme.spacing(1),
        },
        '&:focus': {
            marginBottom: theme.spacing(1),
        },
    },
}));
const SelectField = ({ value, onChange, options, name = 'select', id, label, placeholder, hideLabel = false, errorMessage = '', error = false, className = '', ...props }) => {
    const { classes, cx } = useStyles();
    const theme = useTheme();
    /** Custom styles: https://react-select.com/styles#style-object */
    const customSelectStyles = {
        menuPortal: (provided, _) => ({
            ...provided,
            zIndex: 999999, // Higher than <GenericModal />
        }),
        control: (provided, state) => {
            let boxShadowStyle = state.isFocused ? `0 0 0 1px ${theme.palette.primary.main} !important` : 'none';
            let borderStyle = state.isFocused
                ? `1px solid ${theme.palette.primary.main} !important`
                : `1px solid ${theme.palette.grey[300]}`;
            if (error) {
                boxShadowStyle = `0 0 0 1px ${theme.palette.error.main}`;
                borderStyle = `1px solid ${theme.palette.error.main}`;
            }
            return {
                ...provided,
                backgroundColor: '#fff',
                height: '41px',
                margin: error ? theme.spacing(2, 0, 1, 0) : theme.spacing(2, 0),
                width: '100%',
                boxShadow: boxShadowStyle,
                border: borderStyle,
                fontSize: '12px',
                fontWeight: 600,
                '&:hover': {
                    boxShadow: `0 0 0 1px ${theme.palette.grey[300]}`,
                    border: `1px solid ${theme.palette.grey[300]}`,
                },
            };
        },
        placeholder: (provided, _) => ({
            ...provided,
            color: theme.palette.grey[400],
            fontFamily: theme.typography.fontFamily,
            fontSize: '12px',
            fontWeight: 600,
            margin: 0,
            padding: 0,
        }),
        valueContainer: (provided, _) => ({
            ...provided,
            padding: '0 0 0 16px',
            margin: 0,
            fontFamily: theme.typography.fontFamily,
            fontSize: '12px',
            fontWeight: 600,
            color: theme.palette.grey[900],
        }),
        indicatorSeparator: (provided, _) => ({
            ...provided,
            backgroundColor: 'transparent',
        }),
    };
    const renderedLabel = (labelEl) => (hideLabel ? _jsx(SROnly, { children: labelEl }) : labelEl);
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (
    // eslint-disable-next-line react/destructuring-assignment
    _jsxs("div", { "data-testid": props['data-testid'], className: className, children: [label &&
                renderedLabel(_jsx("label", { className: !hideLabel ? classes.label : '', htmlFor: id, children: label })), _jsx(Select, { options: options, onChange: onChange, components: {
                    /**
                     * Custom components: https://react-select.com/components#components
                     * innerProps are not part of the OptionProps type...
                     * but there are no typedef for it, therefore added an 'any'
                     */
                    Option: ({ innerProps, innerRef, data, isFocused, isSelected }) => {
                        return (_jsxs("div", { ref: innerRef, ...innerProps, className: cx(classes.option, {
                                [classes.optionFocused]: isFocused || isSelected,
                                [classes.inputError]: error,
                            }), children: [data.img && _jsx("img", { src: data.img, alt: data.label }), _jsx("div", { children: data.label })] }));
                    },
                }, value: options.find((option) => (option === null || option === void 0 ? void 0 : option.value) === value), styles: { ...customSelectStyles }, menuPortalTarget: document.body, placeholder: placeholder || label, name: name, inputId: id, isClearable: false, isSearchable: !isMobile, openMenuOnFocus: true }), _jsx(ErrorMessage, { error: error, errorMessage: errorMessage })] }));
};
export default SelectField;
