import { jsx as _jsx } from "react/jsx-runtime";
import { SROnly } from '@components/UI';
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme) => ({
    dot: {
        height: theme.spacing(1),
        width: theme.spacing(1),
        border: 'none',
        background: theme.palette.grey[100],
        borderRadius: '100%',
        padding: 0,
        margin: '20px 5px',
    },
    active: {
        background: theme.palette.primary.main,
    },
}));
const Dot = ({ value, children, onClick, active = false }) => {
    const { classes, cx } = useStyles();
    const handleClick = () => {
        onClick(value);
    };
    return (_jsx("button", { type: "button", onClick: handleClick, "aria-current": active ? 'true' : 'false', className: cx(classes.dot, active && classes.active), children: _jsx(SROnly, { children: children }) }));
};
export default Dot;
