import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from '@mui/material/styles';
import Currency from '@components/UI/Currency';
import TournamentMetric from '@domain/Tournament/components/TournamentMetric';
import getTournamentAwardRankType from '@helpers/getTournamentAwardRankType';
const tournamentMetricCaptions = (tournamentMetricType, isTournamentBoxUI, isMatchmakingLeaderboard) => {
    const leaderboardText = isTournamentBoxUI ? 'Prize Pool Per Leaderboard' : 'Per Leaderboard';
    const captions = {
        rank: 'My Rank',
        prizePool: isMatchmakingLeaderboard ? leaderboardText : 'Prize Pool',
        paidOut: 'Earnings',
    };
    return captions[tournamentMetricType];
};
const StyledTournamentMetric = styled(TournamentMetric) `
  font-size: 16px;
  color: ${(props) => props.theme.palette.grey[900]};
  font-weight: 600;
  padding: ${(props) => props.theme.spacing(0, 3)};
  text-align: ${(props) => props.align};
`;
const StyledTournamentMetricValue = styled('span') `
  margin-bottom: 4px;
  display: block;
`;
const TournamentAwardRankCell = ({ tournament, isTournamentBoxUI = false, align = 'center', className, withDollarSymbol = false, withCaption = true, }) => {
    var _a, _b, _c, _d;
    const tournamentMetricType = getTournamentAwardRankType(tournament);
    const tournamentCurrency = tournament.currencyType.abbreviation;
    return (_jsxs(StyledTournamentMetric, { id: `${tournament.id}-entry-metric`, caption: 
        // RPT-3467 - re-add prize pool text for all games
        (withCaption || (!withCaption && tournamentMetricType == 'prizePool')) &&
            tournamentMetricCaptions(tournamentMetricType, isTournamentBoxUI, !!tournament.leaderboardTemplate), className: className, align: align, children: [tournamentMetricType === 'prizePool' && (_jsx(Currency, { withDollarSymbol: withDollarSymbol, optimise: true, amount: tournament.prize_pot, currency: tournamentCurrency })), tournamentMetricType === 'rank' && (_jsx(StyledTournamentMetricValue, { children: _jsx("span", { children: ((_a = tournament === null || tournament === void 0 ? void 0 : tournament.user_entry) === null || _a === void 0 ? void 0 : _a.rank) && ((_b = tournament === null || tournament === void 0 ? void 0 : tournament.user_entry) === null || _b === void 0 ? void 0 : _b.eligible) ? tournament.user_entry.rank : '-' }) })), tournamentMetricType === 'paidOut' && (_jsx(Currency, { withDollarSymbol: withDollarSymbol, optimise: true, amount: ((_c = tournament === null || tournament === void 0 ? void 0 : tournament.user_entry) === null || _c === void 0 ? void 0 : _c.paidOutValue) && ((_d = tournament === null || tournament === void 0 ? void 0 : tournament.user_entry) === null || _d === void 0 ? void 0 : _d.eligible)
                    ? tournament.user_entry.paidOutValue
                    : 0, currency: tournamentCurrency }))] }));
};
export default TournamentAwardRankCell;
