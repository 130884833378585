import { useEffect, useState } from 'react';
import { findOptionById, findOptionByPath } from './helper';
const useTreeSelect = ({ selectedId, featuredOptionId, options, optionTitles, onSelect, onCancel, }) => {
    var _a;
    const [isOpen, setOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(findOptionById(options, selectedId));
    const [selectedOptionIds, setSelectedOptionIds] = useState([]);
    useEffect(() => {
        setSelectedOption(findOptionById(options, selectedId));
    }, [options, selectedId]);
    const currentOptions = !selectedOptionIds.length ? options : (_a = findOptionByPath(options, selectedOptionIds)) === null || _a === void 0 ? void 0 : _a.children;
    const optionTitle = optionTitles ? optionTitles[selectedOptionIds.length] : undefined;
    const isInRoot = selectedOptionIds.length === 0;
    const cleanAndClose = () => {
        setOpen(false);
        setSelectedOptionIds([]);
    };
    const handleSelect = (optionId) => {
        var _a;
        const newSelectedOptionIds = [...selectedOptionIds, optionId];
        setSelectedOptionIds(newSelectedOptionIds);
        if (!((_a = findOptionByPath(options, newSelectedOptionIds)) === null || _a === void 0 ? void 0 : _a.children)) {
            cleanAndClose();
            const selected = currentOptions === null || currentOptions === void 0 ? void 0 : currentOptions.find((o) => o.id === optionId);
            setSelectedOption(selected);
            if (selected && onSelect)
                onSelect(selected.id);
        }
    };
    const handleSelectFeaturedOption = () => {
        cleanAndClose();
        setSelectedOption(findOptionById(options, featuredOptionId));
        if (onSelect)
            onSelect(featuredOptionId);
    };
    const handleCancel = () => {
        cleanAndClose();
        if (onCancel)
            onCancel();
    };
    const handleClearFilter = () => {
        cleanAndClose();
        setSelectedOption(undefined);
        if (onSelect)
            onSelect(undefined);
    };
    const handleGoParent = () => {
        setSelectedOptionIds(selectedOptionIds.slice(0, -1));
    };
    return {
        selectedOption,
        isOpen,
        setOpen,
        isInRoot,
        handleSelect,
        handleSelectFeaturedOption,
        handleCancel,
        handleClearFilter,
        handleGoParent,
        currentOptions,
        optionTitle,
    };
};
export default useTreeSelect;
