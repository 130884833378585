import getTournamentEntryTypeDisplay from '@domain/Tournament/helpers/getTournamentEntryTypeDisplay';
import getTournamentModesDisplay from '@domain/Tournament/helpers/getTournamentModesDisplay';
import getTournamentRegionsDisplay, { getTournamentRegionsTooltip, } from '@domain/Tournament/helpers/getTournamentRegionsDisplay';
import getTournamentServersDisplay, { getTournamentServerTooltips, } from '@domain/Tournament/helpers/getTournamentServersDisplay';
const getTournamentTags = (tournament, gameData, noModes) => {
    const results = [
        {
            icon: 'mappin',
            label: getTournamentRegionsDisplay(tournament, 3),
            tooltip: getTournamentRegionsTooltip(tournament, 3),
        },
        {
            icon: 'server',
            label: getTournamentServersDisplay(tournament, gameData, 3),
            tooltip: getTournamentServerTooltips(tournament, gameData, 3),
        },
    ];
    const entryType = getTournamentEntryTypeDisplay(tournament);
    if (entryType) {
        results.unshift(entryType);
    }
    const modesDisplay = getTournamentModesDisplay(tournament);
    if (modesDisplay && !noModes) {
        results.push({ icon: 'trophy', label: modesDisplay });
    }
    return results;
};
export default getTournamentTags;
