import { captureException } from '@sentry/react';
import { getAuth } from 'firebase/auth';
const refreshUserToken = async () => {
    var _a;
    try {
        const auth = getAuth();
        await ((_a = auth.currentUser) === null || _a === void 0 ? void 0 : _a.getIdToken(true));
    }
    catch (error) {
        captureException(error);
    }
};
export default refreshUserToken;
