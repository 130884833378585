export const getTournamentMessageKey = ({ tournaments, gameData, isMyTournaments, error }) => {
    if (error) {
        return 'error';
    }
    if (tournaments.length <= 0) {
        if (isMyTournaments) {
            return 'my_tournaments';
        }
        return (gameData === null || gameData === void 0 ? void 0 : gameData.status) === 'coming' ? 'under_development' : 'coming_soon';
    }
    return null; // No conditions met
};
export default getTournamentMessageKey;
