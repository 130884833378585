import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Flex from '@components/Layout/Flex';
import { Checkbox, FormControlLabel } from '@mui/material';
import { styled } from '@mui/system';
const StyledFormControlLabel = styled(FormControlLabel) `
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: ${({ height }) => height};
  border: 1px solid ${({ theme }) => theme.palette.grey[100]};
  border-radius: ${({ theme }) => theme.spacing(1)};
  padding-left: ${({ theme }) => theme.spacing(1)};
  margin: ${({ theme }) => theme.spacing(0)};

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.light};
  }
`;
const StyledCheckbox = styled(Checkbox) `
  // checkbox rectangle icon
  & .MuiSvgIcon-root {
    font-size: 20px;
  }
`;
const StyledImg = styled('img') `
  width: 28px;
  height: 28px;
  object-fit: contain;
`;
const StyledFlex = styled(Flex) `
  font-size: 14px;
`;
const IconCheckBox = ({ testId = 'iconCheckBox', checked, onChange, text, imgSrc, height }) => (_jsx(StyledFormControlLabel, { "data-testid": testId, value: "start", control: _jsx(StyledCheckbox, { "data-testid": `${testId}-checkbox`, checked: checked, onChange: (_, checked) => onChange(checked) }), label: _jsxs(StyledFlex, { flexDirection: "row", align: "center", children: [_jsx(StyledImg, { src: imgSrc, alt: text }), text] }), labelPlacement: "start", height: height }));
export default IconCheckBox;
