import { jsx as _jsx } from "react/jsx-runtime";
import axios from 'axios';
import { useUpdateUserState, useUserState } from '@context/UserContext';
import firebase from 'firebase/compat/app';
import { Redirect, useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import LoadingModal from "@components/Modals/LoadingModal/LoadingModal";
import { useEffect, useState } from 'react';
import EmailConfirmationError from './EmailConfirmationError';
import useLocalStorage from '@components/Hooks/useLocalStorage';
import { useLocalAppContext } from '@context/LocalAppContext';
import useTrackUserFlow from '@hooks/useTrackUserFlow';
const EmailConfirmationAuthentication = () => {
    var _a;
    const [isSigningIn, setIsSigningIn] = useState(false);
    const { authenticated, userDetails, isLoading: isLoadingUserState, userProfile } = useUserState();
    const { setUserDetailsCache } = useUpdateUserState();
    const { refresh } = useUpdateUserState();
    const { token } = useParams();
    const [completeRegistrationRedirectUrl] = useLocalStorage('complete-registration-initial-route', undefined);
    const { openModal } = useLocalAppContext();
    const trackUserFlow = useTrackUserFlow();
    const { isError, data: verifyEmailResponse, isSuccess, mutate: verifyEmailToken, error, } = useMutation({
        mutationFn: () => {
            return axios.post(`/api/register/v1/confirm/${token}`);
        },
        onSuccess: () => {
            var _a, _b, _c, _d;
            setUserDetailsCache({
                verification: {
                    verificationDate: (_b = (_a = userDetails === null || userDetails === void 0 ? void 0 : userDetails.verification) === null || _a === void 0 ? void 0 : _a.verificationDate) !== null && _b !== void 0 ? _b : null,
                    verified: (_d = (_c = userDetails === null || userDetails === void 0 ? void 0 : userDetails.verification) === null || _c === void 0 ? void 0 : _c.verified) !== null && _d !== void 0 ? _d : false,
                    hasVerifiedEmail: true,
                },
            });
        },
    });
    const hasVerifiedEmail = (_a = userDetails === null || userDetails === void 0 ? void 0 : userDetails.verification) === null || _a === void 0 ? void 0 : _a.hasVerifiedEmail;
    // on render, attempt to verify token
    useEffect(() => {
        if (token && !hasVerifiedEmail && !isLoadingUserState) {
            verifyEmailToken();
        }
    }, [token, hasVerifiedEmail, isLoadingUserState]);
    useEffect(() => {
        if (!authenticated && verifyEmailResponse) {
            setIsSigningIn(true);
            firebase
                .auth()
                .signInWithCustomToken(verifyEmailResponse.data.customToken)
                .then(() => refresh())
                .finally(() => setIsSigningIn(false));
        }
    }, [authenticated, verifyEmailResponse]);
    const handleLoginSuccessAction = () => {
        verifyEmailToken();
        refresh();
    };
    if (isSigningIn) {
        return (_jsx("div", { children: _jsx(LoadingModal, { loadingText: 'Signing you in...', show: true }) }));
    }
    if (authenticated && (isSuccess || hasVerifiedEmail)) {
        const redirectUrl = completeRegistrationRedirectUrl ? completeRegistrationRedirectUrl : '/register/confirmed';
        trackUserFlow('account_register_complete', { formStep: 'Registration confirmed manual' });
        if (userProfile === null || userProfile === void 0 ? void 0 : userProfile.handle) {
            openModal({ type: 'welcome', username: userProfile.handle });
        }
        return _jsx(Redirect, { to: redirectUrl });
    }
    if (isError && (error === null || error === void 0 ? void 0 : error.response)) {
        const errorMessage = error.response.data.message;
        const errorCode = error.response.data.errorCode;
        return (_jsx("div", { children: _jsx(EmailConfirmationError, { errorMessage: errorMessage, errorCode: errorCode, onLoginSuccess: handleLoginSuccessAction }) }));
    }
    return (_jsx("div", { children: _jsx(LoadingModal, { loadingText: 'Verifying your email...', show: true }) }));
};
export default EmailConfirmationAuthentication;
