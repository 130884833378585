import { jsx as _jsx } from "react/jsx-runtime";
import { Paper } from '@mui/material';
import RankRange from '@domain/TournamentV2/TournamentTable/RankRange';
const rankRestrictedGameList = ['league_of_legends', 'rocket-league'];
const TournamentBoxPillGameRank = ({ tournament }) => {
    var _a, _b;
    const gameKey = tournament.score_template.game.game_key;
    return rankRestrictedGameList.includes(gameKey) ? (_jsx(Paper, { elevation: 2, sx: { padding: 1 }, children: _jsx(RankRange, { lowestRank: (_a = tournament.allowed_ranks) === null || _a === void 0 ? void 0 : _a.lowest_rank, highestRank: (_b = tournament.allowed_ranks) === null || _b === void 0 ? void 0 : _b.highest_rank, iconOnly: gameKey === 'rocket-league' }) })) : (_jsx("div", {}));
};
export default TournamentBoxPillGameRank;
