import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
import CoreButton from '@components/Buttons/CoreButton';
import Currency from '@components/UI/Currency';
const useStyles = makeStyles()((theme) => ({
    mobileProfileBalancesContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1.5, 2),
        background: theme.palette.grey[50],
        width: '100%',
    },
    yourFunds: {
        position: 'relative',
        height: '30px',
        width: '30px',
        padding: '0px',
        background: theme.palette.primary.light,
        border: `1px solid ${theme.palette.primary.main} !important`,
        fontSize: '20px',
        color: `${theme.palette.primary.main} !important`, // override corebutton color base styles
    },
    yourFundsText: {
        textAlign: 'left',
        fontWeight: 600,
        fontSize: '12px',
        '& p': {
            margin: '0px',
            color: theme.palette.grey[400],
            fontSize: '10px',
            fontWeight: 600,
        },
        '& > span:first-child': {
            marginRight: theme.spacing(1),
        },
    },
}));
/*

A mobile optimised menu for users to top up their accounts

*/
export const MobileProfileBalances = ({ balances, onClose, onShowFundsModal, }) => {
    const { classes } = useStyles();
    const handleShowFundsModal = () => {
        onClose();
        onShowFundsModal();
    };
    const { data, isLoading } = balances;
    return (_jsxs("div", { className: classes.mobileProfileBalancesContainer, children: [_jsxs("div", { className: classes.yourFundsText, children: [!isLoading && data && _jsx(Currency, { amount: data.USD, currency: "USD" }), !isLoading && data && _jsx(Currency, { amount: data.PM, currency: "PM" }), _jsx("p", { children: "Your Funds" })] }), _jsx(CoreButton, { className: classes.yourFunds, variant: "primary", size: "sm", onClick: handleShowFundsModal, children: "+" })] }));
};
export default MobileProfileBalances;
