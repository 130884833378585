import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { useFeature } from 'flagged';
import { styled } from '@mui/material';
import { doTrackEvent } from '@components/Analytics';
import getAddGameCallbackParams from '@helpers/getAddGameCallbackParams';
import { useUpdateUserState, useUserState } from '@context/UserContext';
import useRemoveAddGameParams from '@hooks/useRemoveAddGameParams';
import GenericModal from '../GenericModal';
import AddCODWarzone from '@components/AddGame/AddCODWarzone/AddCODWarzone';
import AddDota2 from '@components/AddGame/AddDota2/AddDota2';
import AddFortnite from '@components/AddGame/AddFortnite/AddFortnite';
import AddLOL from '@components/AddGame/AddLOL/AddLOL';
import AddGameProvider from '@components/AddGame/context/AddGameProvider';
import AddCODWarzone2 from '../../AddGame/AddCODWarzone2/AddCODWarzone2';
import ModalBody from '../components/ModalBody';
import SelectGames from './Components/SelectGames';
import useTrackModalVisibilityEvent from '@hooks/Modals/useTrackModalVisibilityEvent';
import AddPUBG from '@components/AddGame/AddPUBG/AddPUBG';
import AddBrawlStars from '@components/AddGame/AddBrawlStars/AddBrawlStars';
import AddDestiny2 from '@components/AddGame/AddDestiny2/AddDestiny2';
import useUserGamesListener from '@components/Hooks/useUserGamesListener';
import AddRocketLeague from '@components/AddGame/AddRocketLeague/AddRocketLeague';
import AddMLBShow23 from '@components/AddGame/AddMLBShow23/AddMLBShow23';
import AddValorant from '@components/AddGame/AddValorant/AddValorant';
import CrossCloseIcon from '@components/UI/CrossCloseIcon';
import AddGameDisallowed from '@components/AddGame/AddGameDisallowed';
import trackHotjarEvent from '@components/Analytics/trackHotjarEvent';
import postTrackAddGameStarted from '@api/postTrackAddGameStarted';
import messageReactNative from '@helpers/messageReactNative';
import useTrackUserFlow from '@hooks/useTrackUserFlow';
const CloseButton = styled(CrossCloseIcon) `
  align-self: end;
`;
/**
 * Modal for adding a RepeatGame. Modal has an internal AddGameProvider to manage shared context with all its child components.
 */
const AddGameModal = ({ gameKey, open, onClose, successButtonLabel = 'Close', oauthCallbackParams = {}, filterByPlatform = false, }) => {
    const addGameStatus = getAddGameCallbackParams();
    const { refresh: refreshUserState } = useUpdateUserState();
    const { userDetails, userProfile } = useUserState();
    const [isAdobeStartFlow, setIsAdobeStartFlow] = useState(true);
    const [isAdobeFlowComplete, setIsAdobeFlowComplete] = useState(false);
    const { triggerReload: reloadUserGames } = useUserGamesListener(userProfile === null || userProfile === void 0 ? void 0 : userProfile.handle);
    const fortniteABTestEnabled = Boolean(useFeature('test_fortnite_connection_methods'));
    const trackUserFlow = useTrackUserFlow();
    const macroGroup = fortniteABTestEnabled && gameKey === 'fortnite' ? userDetails === null || userDetails === void 0 ? void 0 : userDetails.segments.macroGroup : undefined;
    const [selectedGameKey, setSelectedGameKey] = useState((addGameStatus === null || addGameStatus === void 0 ? void 0 : addGameStatus.gameKey) || gameKey || undefined);
    const [currentAddGameStepName, setCurrentAddGameStepName] = useState(null);
    useTrackModalVisibilityEvent(open, (isVisible) => {
        if (currentAddGameStepName) {
            if (isVisible) {
                doTrackEvent({ type: 'show_game_modal' });
            }
            else {
                doTrackEvent({
                    type: 'close_game_modal',
                    step: currentAddGameStepName,
                    game: selectedGameKey,
                });
            }
        }
    });
    useEffect(() => {
        if (open && selectedGameKey) {
            trackHotjarEvent(`Click add game ${selectedGameKey}`);
        }
    }, [open, selectedGameKey]);
    const resetModal = () => {
        // Reset game key to prop
        setSelectedGameKey(gameKey);
    };
    const removeAddGameParams = useRemoveAddGameParams();
    const handleAddGameSuccess = (result, { region, gameService }) => {
        var _a, _b, _c;
        refreshUserState();
        reloadUserGames();
        if (selectedGameKey) {
            doTrackEvent({
                type: 'add_game_success',
                game: selectedGameKey,
                region: (_b = (_a = region === null || region === void 0 ? void 0 : region.regionCode) !== null && _a !== void 0 ? _a : region === null || region === void 0 ? void 0 : region.region_code) !== null && _b !== void 0 ? _b : null,
                gameServiceKey: (_c = gameService === null || gameService === void 0 ? void 0 : gameService.gameServiceKey) !== null && _c !== void 0 ? _c : null,
                macroGroup,
            });
            setIsAdobeFlowComplete(true);
        }
        trackHotjarEvent('Add game success');
        window.dispatchEvent(new CustomEvent('userGamesChange'));
    };
    const handleAddGameFail = (result, { region, gameService }) => {
        var _a, _b, _c, _d, _e;
        doTrackEvent({
            type: 'add_game_error',
            error: (_b = (_a = result === null || result === void 0 ? void 0 : result.response) === null || _a === void 0 ? void 0 : _a.result) !== null && _b !== void 0 ? _b : result === null || result === void 0 ? void 0 : result.errorMessage,
            game: selectedGameKey,
            region: (_d = (_c = region === null || region === void 0 ? void 0 : region.regionCode) !== null && _c !== void 0 ? _c : region === null || region === void 0 ? void 0 : region.region_code) !== null && _d !== void 0 ? _d : null,
            gameServiceKey: (_e = gameService === null || gameService === void 0 ? void 0 : gameService.gameServiceKey) !== null && _e !== void 0 ? _e : null,
            stepName: currentAddGameStepName,
            macroGroup,
        });
        trackHotjarEvent('Add game fail');
    };
    const handleGameStepChange = (stepIndex, stepName, analyticsFlowStep, totalBreadcrumbSteps) => {
        if (currentAddGameStepName) {
            doTrackEvent({
                type: 'progress_adding_game',
                game: selectedGameKey,
                previousStep: currentAddGameStepName,
                nextStep: stepName,
                macroGroup,
            });
        }
        setCurrentAddGameStepName(stepName);
        /**
         * If the analyticsFlowStep does not exist in the trackName types the TS error might be misleading.
         *
         * When an add game modal opens it should initiate an game_link_start flow, subsequent flows should trigger
         * a game_link_step until a successful game_link_complete flow is triggered
         */
        if ((addGameStatus === null || addGameStatus === void 0 ? void 0 : addGameStatus.status) === 'success' && stepIndex > totalBreadcrumbSteps - 1) {
            trackUserFlow('game_link_complete', { formStep: 'account connected' });
        }
        else if ((addGameStatus === null || addGameStatus === void 0 ? void 0 : addGameStatus.status) === 'error') {
            trackUserFlow('game_link_step', { formStep: analyticsFlowStep });
            setIsAdobeStartFlow(false);
        }
        else if (isAdobeStartFlow && !(addGameStatus === null || addGameStatus === void 0 ? void 0 : addGameStatus.status)) {
            trackUserFlow('game_link_start', { formStep: analyticsFlowStep });
            setIsAdobeStartFlow(false);
        }
        else {
            trackUserFlow('game_link_step', { formStep: analyticsFlowStep });
        }
    };
    const handleClose = (didAddGame = false, isSuccessPage = false) => {
        var _a, _b;
        removeAddGameParams();
        if ((_a = addGameStatus === null || addGameStatus === void 0 ? void 0 : addGameStatus.isSuccess) !== null && _a !== void 0 ? _a : didAddGame) {
            messageReactNative({ flow: 'addGame', event: 'success' });
        }
        else {
            messageReactNative({ flow: 'addGame', event: 'end' });
        }
        if (didAddGame && !isSuccessPage) {
            // the account connected flow will be emitted during handleGameStepChange if the user doesn't exit early
            trackUserFlow('game_link_complete', { formStep: 'account connected' });
        }
        if (onClose) {
            onClose((_b = addGameStatus === null || addGameStatus === void 0 ? void 0 : addGameStatus.isSuccess) !== null && _b !== void 0 ? _b : didAddGame);
        }
    };
    const handleConnectConfirm = () => {
        handleClose(true, true);
    };
    const handleSelectGame = (selected) => {
        setSelectedGameKey(selected);
        postTrackAddGameStarted(selected);
    };
    const handleDismissSelectedGame = () => {
        resetModal();
    };
    const defaultAddGameCarouselProps = {
        onConnectConfirm: handleConnectConfirm,
        shouldGoToConnectionStatus: addGameStatus !== null,
        shouldShowSelectBreadcrumb: !gameKey,
        successButtonLabel,
        onStepChange: handleGameStepChange,
        onBackToSelect: handleDismissSelectedGame,
        onClose: handleClose,
    };
    // If game key prop changes - This is necessary in current onboarding page.
    // Note that if `addGameStatus?.gameKey` is set, this will not change
    useEffect(() => {
        if (gameKey && gameKey !== selectedGameKey && !(addGameStatus === null || addGameStatus === void 0 ? void 0 : addGameStatus.gameKey)) {
            setSelectedGameKey(gameKey);
            postTrackAddGameStarted(gameKey);
        }
    }, [gameKey]);
    useEffect(() => {
        if (gameKey) {
            postTrackAddGameStarted(gameKey);
        }
    }, []);
    if (userDetails === null || userDetails === void 0 ? void 0 : userDetails.isSuspended) {
        return _jsx(AddGameDisallowed, { open: open, onClose: handleClose, resetModal: resetModal });
    }
    return (_jsxs(GenericModal, { show: open, size: selectedGameKey ? 'medium' : 'small', disableBackdropClick: false, onClose: handleClose, mobileFullScreen: true, onExited: resetModal, children: [!selectedGameKey && (_jsxs(_Fragment, { children: [_jsx(CloseButton, { action: () => handleClose() }), _jsx(ModalBody, { children: _jsx(SelectGames, { onSelect: handleSelectGame, filterByPlatform: filterByPlatform, 
                            // TODO: may need to be more dynamic in the future https://repeatgg.atlassian.net/browse/RPT-3608?focusedCommentId=32159
                            handleGameStepChange: () => handleGameStepChange(0, 'select', 'select a game from dropdown', 4) }) })] })), selectedGameKey && (_jsxs(AddGameProvider, { selectedGameKey: selectedGameKey, onAddGameSuccess: handleAddGameSuccess, onAddGameError: handleAddGameFail, oauthCallbackParams: oauthCallbackParams, children: [selectedGameKey === 'cod-warzone' && _jsx(AddCODWarzone, { ...defaultAddGameCarouselProps }), selectedGameKey === 'cod-warzone-2' && _jsx(AddCODWarzone2, { ...defaultAddGameCarouselProps }), selectedGameKey === 'pc-dota_2' && _jsx(AddDota2, { ...defaultAddGameCarouselProps }), selectedGameKey === 'fortnite' && _jsx(AddFortnite, { ...defaultAddGameCarouselProps }), selectedGameKey === 'league_of_legends' && _jsx(AddLOL, { ...defaultAddGameCarouselProps }), selectedGameKey === 'valorant' && _jsx(AddValorant, { ...defaultAddGameCarouselProps }), selectedGameKey === 'pubg' && _jsx(AddPUBG, { ...defaultAddGameCarouselProps }), selectedGameKey === 'brawl-stars' && _jsx(AddBrawlStars, { ...defaultAddGameCarouselProps }), selectedGameKey === 'rocket-league' && _jsx(AddRocketLeague, { ...defaultAddGameCarouselProps }), selectedGameKey === 'destiny-2' && _jsx(AddDestiny2, { ...defaultAddGameCarouselProps }), selectedGameKey === 'mlb-the-show-23' && _jsx(AddMLBShow23, { ...defaultAddGameCarouselProps })] }))] }));
};
export default AddGameModal;
