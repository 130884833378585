import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { css, styled } from '@mui/material/styles';
import isPropValid from '@emotion/is-prop-valid';
import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import Flex from '@components/Layout/Flex';
import React from 'react';
import IconBackground from '@components/UI/Icon/IconBackground';
const StyledButton = styled('button', { shouldForwardProp: isPropValid }) `
  color: ${({ theme }) => theme.palette.grey[900]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(1)};
  font-size: 14px;
  font-weight: 600;
  text-align: start;
  line-height: 1;
  background: transparent;
  border: 1px solid ${({ theme }) => theme.palette.grey[100]};
  border-radius: ${({ theme }) => theme.spacing(1)};
  height: ${({ size }) => (size === 'sm' ? '46px' : '52px')};
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.light};
    border-color: ${({ theme }) => theme.palette.grey[100]};
  }

  ${({ disabled }) => disabled &&
    css `
      opacity: 0.2;
    `}

  & * {
    margin: 0;
  }
`;
const FilterName = styled('p') `
  color: ${({ theme }) => theme.palette.grey[400]};
  font-size: 10px;
`;
const StyledIconBackground = styled(IconBackground) `
  background-color: transparent;
`;
const StyledImg = styled('img') `
  width: 28px;
  height: 28px;
  object-fit: contain;
`;
const FilterToggleButton = React.forwardRef(({ size, onClick, iconUrl, title, text }, ref) => {
    return (_jsxs(StyledButton, { onClick: onClick, size: size, ref: ref, children: [_jsxs(Flex, { align: "center", children: [_jsx(StyledIconBackground, { size: size, children: _jsx(StyledImg, { src: iconUrl }) }), _jsxs(Flex, { flexDirection: "column", align: "flex-start", gap: 0, children: [_jsx(FilterName, { children: title }), _jsx("p", { children: text })] })] }), size === 'md' ? _jsx(ChevronRightIcon, { width: 16, height: 16 }) : _jsx(ChevronDownIcon, { width: 16, height: 16 })] }));
});
export default FilterToggleButton;
