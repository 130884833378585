import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme) => ({
    stepIcon: {
        width: '8px',
        height: '8px',
        margin: '5px',
        background: theme.palette.grey[200],
        borderRadius: '50%',
        position: 'relative',
        '&::after': {
            content: '""',
            height: '16px',
            width: '16px',
            display: 'block',
            border: `1px solid ${theme.palette.grey[200]}`,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '50%',
        },
    },
    active: {
        background: theme.palette.success.main,
        '&::after': {
            borderColor: theme.palette.success.main,
        },
    },
    completed: {
        background: theme.palette.error.main,
        '&::after': {
            borderColor: theme.palette.error.main,
        },
    },
}));
const StepIcon = ({ active, completed }) => {
    const { classes, cx } = useStyles();
    return (_jsx("div", { className: cx(classes.stepIcon, {
            [classes.active]: active,
            [classes.completed]: completed,
        }) }));
};
export default StepIcon;
