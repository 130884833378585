import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CoreButton from "@components/Buttons/CoreButton";
import classnames from 'classnames';
import useStyles from '../useStyles';
import FooterCallout from './FooterCallout';
const BodyContent = ({ background, footerCalloutImage, accentColor, handleJoinClick, className, }) => {
    const { classes } = useStyles({ staticSectionBackground: background });
    return (_jsxs("div", { className: classnames(classes.staticContent, className), children: [_jsx("div", { className: classes.bgGrey, children: _jsxs("div", { className: classes.narrowContainer, children: [_jsx("img", { src: "/img/branded-landing-page/static-leaderboard.png", alt: "" }), _jsxs("div", { className: classes.stackedContent, children: [_jsx("h2", { className: classnames(classes.heading, classes.headingText), children: "Climb to the top of the leaderboard" }), _jsx("p", { children: "Your position on the Leaderboard is based on your best qualified matches, so keep grinding for higher placement. You'll never go backwards after having a bad match, your tournament score can only ever get better or stay the same." }), _jsx("p", { children: _jsx(CoreButton, { className: classes.buttonOverride, onClick: () => handleJoinClick(3, 'Join Now'), addFancyHover: true, children: _jsx("span", { children: "Join Now" }) }) })] })] }) }), _jsxs("div", { className: classnames(classes.narrowContainer, classes.narrowContainerReverse, classes.whiteText, 'automated-result-tracking'), children: [_jsx("img", { src: "/img/branded-landing-page/static-results-tracking.png", alt: "" }), _jsxs("div", { className: classes.stackedContent, children: [_jsx("h2", { className: classes.heading, children: "Automated Result Tracking" }), _jsx("p", { children: "No downloads, no installs, no hassles. Once you've connected your Game account you're good to go. Join tournaments, play the right game modes and we'll take care of the rest by automatically tracking your in-game results." }), _jsx("p", { children: _jsx(CoreButton, { className: classes.buttonOverride, onClick: () => handleJoinClick(4, 'Join Now'), addFancyHover: true, children: _jsx("span", { children: "Join Now" }) }) })] })] }), _jsx(FooterCallout, { handleJoinClick: () => handleJoinClick(5, 'Join Now'), footerCalloutImage: footerCalloutImage, accentColor: accentColor })] }));
};
export default BodyContent;
