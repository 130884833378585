import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme) => ({
    titleBaseStyle: {
        fontWeight: 700,
        fontSize: '26px',
        lineHeight: '33.5px',
        color: theme.palette.grey[900],
        marginTop: '0px',
        marginBottom: theme.spacing(3),
    },
    paragraphBaseStyle: {},
    modalContent: {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.grey[400],
        textAlign: 'center',
        fontWeight: 600,
        fontSize: '12px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: theme.spacing(4),
    },
    fillVertical: {
        flex: 1,
        justifyContent: 'center',
    },
}));
/**
 * ModalTextContent can be used as an alternative to the ModalContent component.
 * It will only render a heading and text with styles consistent to our modal designs.
 */
const ModalTextContent = ({ title, paragraph, fillVertical = false, className, }) => {
    const { classes, cx } = useStyles();
    return (_jsxs("div", { className: cx(classes.modalContent, { [classes.fillVertical]: fillVertical }, className), children: [title && _jsx("h2", { className: classes.titleBaseStyle, children: title }), paragraph && _jsx("span", { className: classes.paragraphBaseStyle, children: paragraph })] }));
};
export default ModalTextContent;
