import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import loadable from '@loadable/component';
import ModalIconBanner from '@components/Modals/components/ModalIconBanner';
import ModalContent from '@components/Modals/components/ModalContent';
import { makeStyles } from 'tss-react/mui';
import ConnectGameButton from '@components/AddGame/ConnectGameButton';
const Icon = loadable(() => import('@components/Icons/Icon'));
const useStyles = makeStyles()((theme) => ({
    googleLogo: {
        height: '85px !important',
        fill: theme.palette.google.main,
    },
}));
/**
 * This is one of the steps in the add social media account modal flow
 *
 * @param {Object} props The component props
 * @param {Function} props.onSuccess Callback for success
 * @param {Function} props.onError Callback for error
 * @returns The connect google account component
 */
const ConnectGoogleAccount = ({ onSuccess, onError }) => {
    const { classes } = useStyles();
    const [loading, setLoading] = useState(false);
    const requestGoogle = async () => {
        var _a;
        try {
            setLoading(true);
            const auth = firebase.auth();
            if (!auth.currentUser) {
                throw new Error('Must be logged in');
            }
            const googleProvider = new firebase.auth.GoogleAuthProvider();
            const result = await auth.currentUser.linkWithPopup(googleProvider);
            if ((_a = result.user) === null || _a === void 0 ? void 0 : _a.email) {
                // Accounts successfully linked.
                onSuccess(result.user.email);
            }
            else {
                throw new Error('User data was missing');
            }
        }
        catch (error) {
            onError(error);
        }
        finally {
            setLoading(false);
        }
    };
    return (_jsxs(ModalContent, { stepName: "connect-google", align: "center", children: [_jsx(ModalIconBanner, { children: _jsx(Icon, { icon: "google", className: classes.googleLogo }) }), _jsxs("h2", { children: [_jsx("span", { "data-ignore": true, children: "Google" }), " Login"] }), _jsx("section", { children: _jsxs("p", { children: ["You'll be redirect to ", _jsx("span", { "data-ignore": true, children: "Google" }), " Login page in order to complete this process."] }) }), _jsx(ConnectGameButton, { isLoading: loading, paletteName: "google", onClick: requestGoogle, children: _jsxs("span", { "data-ignore": true, children: ["Login to Your ", _jsx("span", { "data-ignore": true, children: "Google" }), loading] }) })] }));
};
export default ConnectGoogleAccount;
