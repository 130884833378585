import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme, { paletteName }) => ({
    core: {
        fontFamily: theme.typography.fontFamily,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '12px',
        fontWeight: 700,
        borderRadius: '4px',
        padding: theme.spacing(0.5, 1),
    },
    light: {
        background: theme.palette[paletteName].light,
        color: theme.palette[paletteName].main,
        '& svg': {
            fill: theme.palette[paletteName].main,
            width: '14px',
            height: '14px',
            marginRight: '4px',
        },
    },
    solid: {
        background: theme.palette[paletteName].main,
        color: 'white',
    },
}));
const Badge = ({ children, variant, className, colorMode = 'light' }) => {
    let paletteName;
    switch (variant) {
        case 'featured':
        case 'gold':
            paletteName = 'secondary';
            break;
        case 'charity':
        case 'purple':
            paletteName = 'purple';
            break;
        case 'sponsored':
        case 'green':
            paletteName = 'success';
            break;
        case 'influencer':
        case 'red':
            paletteName = 'error';
            break;
        case 'community':
        case 'orange':
            paletteName = 'warning';
            break;
        case 'blue':
        case 'event':
        default:
            paletteName = 'primary';
    }
    const { classes, cx } = useStyles({ paletteName });
    return (_jsx("span", { className: cx(classes.core, className, {
            [classes[colorMode]]: true,
        }), children: children }));
};
export default Badge;
