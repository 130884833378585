import Validator from 'validatorjs';
const customMessages = {
    required: 'This is a required field',
};
const rules = {
    paypalEmail: 'required|email',
};
const validatePaypalEmail = (data) => {
    const validation = new Validator(data, rules, customMessages);
    return validation;
};
export default validatePaypalEmail;
