import getGameRanks from '@api/getGameRanks';
import { displayMessageFromError } from '@helpers/errorHelper';
import { useState } from 'react';
import { useQuery } from 'react-query';
const useGameRanks = (gameKey) => {
    const [error, setError] = useState();
    const onError = (err) => {
        setError(displayMessageFromError(err));
    };
    const onSuccess = () => {
        setError(undefined);
    };
    const { data, isLoading, isError } = useQuery({
        queryKey: ['game-rank', gameKey],
        queryFn: () => getGameRanks(gameKey),
        onError,
        onSuccess,
    });
    return {
        gameRanks: data !== null && data !== void 0 ? data : [],
        isLoading,
        isError,
        error,
    };
};
export default useGameRanks;
