import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import SectionContainer from '@components/UI/Section/components/SectionContainer';
import RocketLeagueRow from '@domain/TournamentV2/TournamentTable/RocketLeagueRow';
import { css, styled } from '@mui/material/styles';
import SectionHead from '@components/UI/Section/components/SectionHead';
import isPropValid from '@emotion/is-prop-valid';
import LeagueOfLegendsRow from '@domain/TournamentV2/TournamentTable/LeagueOfLegendsRow';
import TournamentRow from './TournamentRow';
import { StyledSectionHeading, CenteredSectionHeading } from './style';
const rightBorder = (props) => css `
  position: relative;
  &:after {
    content: '';
    width: 1px;
    height: 44px;
    background-color: ${props.theme.palette.grey[100]};
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;
const StyledSectionContainer = styled(SectionHead, { shouldForwardProp: isPropValid }) `
  display: grid;
  grid-template-columns: ${({ hasGameModeColumn }) => hasGameModeColumn ? '35% 20% 5% 10% 15% 15%' : '43% 10% 13% 17% 17%'};
  @media (min-width: ${({ theme }) => theme.breakpoints.values.lg}px) {
    grid-template-columns: ${({ hasGameModeColumn }) => hasGameModeColumn ? '35% 20% 5% 10% 15% 15%' : '43% 10% 13% 17% 17%'};
  }
  padding: 18px;
  ${(props) => rightBorder(props)}
`;
const TournamentTable = ({ tournaments, gameKey }) => {
    let RowComponent = TournamentRow;
    const hasGameModeColumn = gameKey === 'rocket-league' || gameKey === 'league_of_legends';
    if (gameKey === 'rocket-league') {
        RowComponent = RocketLeagueRow;
    }
    else if (gameKey === 'league_of_legends') {
        RowComponent = LeagueOfLegendsRow;
    }
    return (_jsxs(SectionContainer, { children: [_jsxs(StyledSectionContainer, { bottomBorder: true, hasGameModeColumn: hasGameModeColumn, children: [_jsx(StyledSectionHeading, { children: "Tournament" }), _jsx(StyledSectionHeading, { children: "Participants" }), hasGameModeColumn ? _jsx(CenteredSectionHeading, { children: "Mode" }) : null, _jsx(CenteredSectionHeading, { children: "Rank" }), _jsx(CenteredSectionHeading, { children: "Prize Pool" }), _jsx(CenteredSectionHeading, { children: "Entry Fee" })] }), _jsx("div", { children: tournaments.map((tournament, index) => {
                    return _jsx(RowComponent, { section: "Page", listIndex: index, tournament: tournament }, tournament.id);
                }) })] }));
};
export default TournamentTable;
