import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { ClickAwayListener, Drawer, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ErrorWithCause from '@helpers/ErrorWithCause';
import { captureException } from '@sentry/react';
import trackHotjarEvent from '@components/Analytics/trackHotjarEvent';
import useFetchNewNotificationsCount from '@hooks/API/useFetchNewNotificationsCount';
import useUpdateNewNotificationsCount from '@hooks/API/useUpdateNewNotificationsCount';
import useRouteChanged from '@hooks/useRouteChanged';
import useFetchInfiniteNotifications from '@hooks/API/useFetchInfiniteNotifications';
import CoreButton from '@components/Buttons/CoreButton';
import SROnly from '@components/UI/SROnly';
import ButtonPopper from '@components/UI/ButtonPopper';
import doTrackEvent from '@components/Analytics/doTrackEvent';
import useUpdateNewNotificationsWhenDrawerOpens from './hooks/useUpdateNewNotificationsWhenDrawerOpens';
import NotificationMenuList from './components/NotificationMenuList';
import NotificationAlert from './components/NotificationAlert';
const useStyles = makeStyles()((theme) => ({
    drawerRoot: {
        [theme.breakpoints.down('md')]: {
            top: `72px !important`,
            zIndex: `${theme.zIndex.chat + 1} !important`,
        },
    },
    menuContainer: {
        display: 'flex',
        background: theme.palette.background.paper,
        maxWidth: '410px',
        maxHeight: '70vh',
        zIndex: 999,
        [theme.breakpoints.down('md')]: {
            borderTop: `1px solid ${theme.palette.grey[100]}`,
            position: 'absolute',
            width: '100%',
            maxHeight: '100vh',
            height: `calc(100vh - 72px)`,
            overflow: 'hidden',
        },
    },
    menuButtonContainer: {
        display: 'flex',
        alignSelf: 'stretch',
    },
    menuButton: {
        '& svg': {
            fill: theme.palette.grey[400],
        },
        '&.open': {
            background: theme.palette.primary.light,
        },
        '&.open svg': {
            fill: theme.palette.grey[900],
        },
        borderRadius: 0,
        padding: theme.spacing(0, 2),
        justifyContent: 'center',
        flexDirection: 'column',
        borderRight: `1px solid ${theme.palette.grey[100]}`,
        [theme.breakpoints.down('lg')]: {
            height: '100%',
        },
    },
}));
const NotificationMenu = ({ className, buttonClassName }) => {
    var _a, _b, _c;
    const { classes, cx } = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [openDrawer, setOpenDrawer] = useState(false);
    const [hasOpenedDrawer, setHasOpenedDrawer] = useState(false);
    useRouteChanged(() => setOpenDrawer(false));
    const { data, refetch, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage, isLoading } = useFetchInfiniteNotifications(openDrawer);
    const updateNewNotificationsCount = useUpdateNewNotificationsCount();
    const { count } = useFetchNewNotificationsCount();
    const defaultMeta = {
        totalUnopened: 0,
    };
    const notificationMeta = (_b = (_a = data === null || data === void 0 ? void 0 : data.pages[data.pages.length - 1]) === null || _a === void 0 ? void 0 : _a.meta.notification) !== null && _b !== void 0 ? _b : defaultMeta;
    const { totalGlobal, totalNew } = count;
    const { totalUnopened: latestTotalUnopenedNotifications } = notificationMeta;
    const notifications = (_c = data === null || data === void 0 ? void 0 : data.pages.flatMap((page) => page.payload)) !== null && _c !== void 0 ? _c : [];
    const hasGlobalNotificationsOnly = totalGlobal > 0 && totalNew < 1;
    useUpdateNewNotificationsWhenDrawerOpens(openDrawer, hasOpenedDrawer, refetch, (updatedEntries) => {
        if (updatedEntries) {
            updateNewNotificationsCount(totalNew - updatedEntries);
        }
    });
    const toggleDrawerOpen = () => {
        if (!hasOpenedDrawer && openDrawer) {
            setHasOpenedDrawer(true);
        }
        const shouldOpen = !openDrawer;
        setOpenDrawer(shouldOpen);
        if (shouldOpen) {
            doTrackEvent({
                type: 'notifications_opened',
            });
            trackHotjarEvent(`Click notification panel opened`);
        }
    };
    const handleClose = () => {
        setOpenDrawer(false);
    };
    const handleFetchNextPage = () => {
        if (!isFetching && !isFetchingNextPage && hasNextPage) {
            fetchNextPage().catch((e) => {
                const error = new ErrorWithCause(e);
                captureException(error);
            });
        }
    };
    const controlName = 'Notification Menu';
    return isMobile ? (_jsx(ClickAwayListener, { onClickAway: handleClose, children: _jsxs("div", { className: classes.menuButtonContainer, children: [_jsxs(CoreButton, { appearance: "text", onClick: toggleDrawerOpen, className: cx(classes.menuButton, {
                        open: openDrawer,
                    }), children: [_jsx(SROnly, { children: controlName }), _jsx(NotificationAlert, { count: totalNew, hasGlobalNotificationsOnly: hasGlobalNotificationsOnly })] }), _jsx(Drawer, { anchor: "right", open: openDrawer, classes: {
                        root: classes.drawerRoot,
                        paper: classes.menuContainer,
                    }, elevation: 0, onClose: handleClose, ModalProps: {
                        hideBackdrop: true,
                    }, children: _jsx(NotificationMenuList, { items: notifications, totalUnopened: latestTotalUnopenedNotifications, totalNew: totalNew, refetchNotifications: refetch, fetchNextPage: handleFetchNextPage, hasNextPage: hasNextPage, isLoading: isLoading }) })] }) })) : (_jsx(ButtonPopper, { buttonProps: {
            children: (_jsx(_Fragment, { children: _jsx(NotificationAlert, { count: totalNew, hasGlobalNotificationsOnly: hasGlobalNotificationsOnly }) })),
            className: cx(classes.menuButton, buttonClassName),
            ariaLabel: controlName,
            'aria-controls': 'simple-menu',
            'aria-haspopup': 'true',
            appearance: 'text',
        }, className: cx(classes.menuContainer, className), containerClassName: classes.menuButtonContainer, onClick: () => toggleDrawerOpen(), onClickAway: () => handleClose(), open: openDrawer, children: _jsx(NotificationMenuList, { items: notifications, totalUnopened: latestTotalUnopenedNotifications, totalNew: totalNew, refetchNotifications: refetch, fetchNextPage: handleFetchNextPage, hasNextPage: hasNextPage, isLoading: isLoading }) }));
};
export default NotificationMenu;
