import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import axios from 'axios';
import { captureException } from '@sentry/react';
import ErrorWithCause from '../../../../helpers/ErrorWithCause';
import SectionContainer from "@components/UI/Section/components/SectionContainer";
import SectionHead from "@components/UI/Section/components/SectionHead";
import SectionHeading from "@components/UI/Section/components/SectionHeading";
import SectionSubheading from "@components/UI/Section/components/SectionSubheading";
import useGetUserSocialMediaAccounts from "@components/Hooks/useGetUserSocialMediaAccounts";
import { useUserState } from '@context/UserContext';
import EditSocialMediaAccounts from '../SocialAccounts/components/EditSocialMediaAccounts';
import NoLinkedAccounts from './NoLinkedAccounts';
import ConfirmDeleteSocialMediaAccountModal from '../SocialAccounts/components/ConfirmDeleteSocialMediaAccountModal';
const useStyles = makeStyles()((theme) => {
    return {
        bodyWrapper: {
            fontFamily: theme.typography.fontFamily,
            padding: theme.spacing(0, 3, 3),
        },
    };
});
const MySocial = ({ className, handleConnectSocialAccounts }) => {
    const { classes, cx } = useStyles();
    const { userProfile } = useUserState();
    const { data, refetch: refreshSocialMedia } = useGetUserSocialMediaAccounts(userProfile === null || userProfile === void 0 ? void 0 : userProfile.handle);
    const socialMediaAccounts = data !== null && data !== void 0 ? data : [];
    const [accountToDelete, setAccountToDelete] = useState(undefined);
    const [deleting, setDeleting] = useState(false);
    const handleDeleteAccount = async (account) => {
        if (!account) {
            return;
        }
        try {
            setDeleting(true);
            const response = await axios.delete(`/api/user/social_accounts/v1/${account.type}`);
            if (response && response.status === 200) {
                refreshSocialMedia();
                setAccountToDelete(undefined);
            }
        }
        catch (error) {
            const err = new ErrorWithCause(error);
            captureException(err, {
                extra: {
                    component: 'MySocial',
                    provider: account.type,
                },
            });
        }
        finally {
            setDeleting(false);
        }
    };
    return _jsxs(_Fragment, { children: [_jsx(ConfirmDeleteSocialMediaAccountModal, { accountToDelete: accountToDelete, deleting: deleting, handleClose: () => setAccountToDelete(undefined), handleConfirm: () => handleDeleteAccount(accountToDelete) }), _jsxs(SectionContainer, { className: cx(className), children: [_jsxs(SectionHead, { children: [_jsx(SectionHeading, { children: "My Socials" }), _jsx(SectionSubheading, { children: "Social accounts I've connected" })] }), _jsx("div", { className: classes.bodyWrapper, children: socialMediaAccounts.length === 0 ? (_jsx(NoLinkedAccounts, { handleConnectAccounts: handleConnectSocialAccounts, isCurrentUser: true })) : (_jsx(EditSocialMediaAccounts, { socialMediaAccounts: socialMediaAccounts, handleConnectAccounts: handleConnectSocialAccounts, handleDeleteAccount: (account) => setAccountToDelete(account) })) })] })] });
};
export default MySocial;
