import { useQuery } from 'react-query';
import getSuggestedTournaments from '@api/getSuggestedTournaments';
import useTournamentDetailsPageUrl from '@hooks/useTournamentDetailsPageUrl';
import useGame from './useGame';
export const suggestedTouramentKey = 'suggestedTournament';
const useSuggestedTournaments = (gameKey, enabled, limit) => {
    var _a, _b;
    const suggestedTournamentsQuery = useQuery([suggestedTouramentKey, gameKey], () => getSuggestedTournaments(gameKey, limit), {
        enabled,
    });
    const gameData = useGame(gameKey);
    const suggestedTournamentUrl = useTournamentDetailsPageUrl((_b = (_a = suggestedTournamentsQuery.data) === null || _a === void 0 ? void 0 : _a.tournaments[0]) === null || _b === void 0 ? void 0 : _b.id, gameData);
    return { suggestedTournamentsQuery, suggestedTournamentUrl };
};
export default useSuggestedTournaments;
