const getDisplayText = (gameModes, selectedModes) => {
    var _a, _b;
    if (!selectedModes || selectedModes.length === 0) {
        return 'All Modes';
    }
    if (selectedModes.length === 1) {
        return (_b = (_a = gameModes.find((gameMode) => gameMode.code === selectedModes[0])) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '1 Selected';
    }
    return `${selectedModes.length} Selected`;
};
export default getDisplayText;
