import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    text: {
        alignSelf: 'stretch',
        display: 'flex',
        alignItems: 'center',
        '&:before': {
            flex: 'auto',
            content: '""',
            backgroundColor: theme.palette.grey[100],
            height: '2px',
            marginRight: '20px',
        },
        '&:after': {
            content: '""',
            backgroundColor: theme.palette.grey[100],
            height: '2px',
            flex: 'auto',
            marginLeft: '20px',
        },
    },
}));
const SidelineText = ({ className = '', children }) => {
    const { classes, cx } = useStyles();
    return _jsx("p", { className: cx(classes.text, className), children: children });
};
export default SidelineText;
