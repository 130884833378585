import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import { makeStyles } from 'tss-react/mui';
import SimpleBar from 'simplebar-react';
import getGamePalette from '@helpers/getGamePalette';
import removeEmptyProps from '@helpers/removeEmptyProps';
import LoadingLogo from '@components/Modals/LoadingModal/Components/LoadingLogo';
import Advertisement from '@components/UI/Ads/Advertisement';
import { captureException } from '@sentry/react';
import useGetAdvertisements from "@components/Hooks/useGetAdvertisements";
const useStyles = makeStyles()((theme, { paletteName }) => ({
    wrapper: {
        height: '180px',
        '& .simplebar-content': {
            whiteSpace: 'nowrap',
            display: 'flex',
        },
        '& .simplebar-track': {
            backgroundColor: theme.palette[paletteName].dark,
            borderRadius: '4px',
        },
        '& .simplebar-scrollbar': {
            visibility: 'inherit',
        },
        '& .simplebar-scrollbar::before': {
            backgroundColor: 'white',
            opacity: 1,
        },
        '& .promo-banner': {
            marginRight: theme.spacing(2.5),
            paddingBottom: '35px',
            minHeight: '100px',
        },
        '& .promo-banner:last-child': {
            marginRight: '0px',
        },
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '175px',
        '& svg': {
            [theme.breakpoints.up('sm')]: {
                left: '40px',
            },
        },
        '& svg polygon': {
            stroke: theme.palette[paletteName].dark,
        },
        '& svg rect': {
            fill: theme.palette[paletteName].dark,
        },
    },
}));
const AdvertisementBanner = ({ gameKey, pageType = 'tournament_list', position, className = '', }) => {
    const { classes, cx } = useStyles({ paletteName: getGamePalette(gameKey !== null && gameKey !== void 0 ? gameKey : 'primary') });
    const routeParams = removeEmptyProps({
        gameKey,
        pageType,
        position,
    });
    const { data: ads, isLoading, isFetching, error } = useGetAdvertisements(routeParams);
    if (error) {
        captureException(error);
    }
    // Currently types as any, as the types for SimpleBar are incorrect.
    // They've been fixed in the project, but not published yet.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ref = useRef(null);
    const onLoad = () => {
        if (ref.current) {
            ref.current.recalculate();
        }
    };
    useEffect(() => {
        onLoad();
    });
    if (isLoading || isFetching) {
        return (_jsx("div", { className: classes.loading, children: _jsx(LoadingLogo, {}) }));
    }
    if (ads.length === 0) {
        return null;
    }
    return (_jsx("div", { className: cx(classes.wrapper, className), children: _jsx(SimpleBar, { style: { maxHeight: 175 }, ref: ref, children: ads.length > 0 &&
                ads.map((advert) => (_jsx("div", { className: "promo-banner", children: _jsx(Advertisement, { ad: advert, onLoad: onLoad, gameKey: gameKey }) }, advert.id))) }) }));
};
export default AdvertisementBanner;
