/**
 * Takes a string, and if its length is less than the minLength, return the string with a prepended '0'
 * Main usecase is time formatting, padZeros('0', 2) => '00'
 *
 * @param {string} text The text to pad
 * @param {number} minLength The minimum length of the result
 * @returns {string}
 */
const padZeros = (text, minLength) => {
    if (text.length < minLength) {
        return `0${text}`;
    }
    return text;
};
export default padZeros;
