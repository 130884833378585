export const modalStateReducer = (state, action) => {
    switch (action.type) {
        case 'setCurrentStep':
            return {
                ...state,
                currentStep: action.currentStep,
            };
        case 'setBreadcrumbs':
            return {
                ...state,
                breadcrumbs: [...action.breadcrumbs],
            };
        case 'setHeader':
            return {
                ...state,
                header: action.header
                    ? {
                        ...action.header,
                    }
                    : null,
            };
        case 'setBreadcrumbIndex':
            return {
                ...state,
                breadcrumbIndex: action.breadcrumbIndex,
            };
        case 'setBreadcrumbsVisible':
            return {
                ...state,
                breadCrumbsVisible: action.breadcrumbsVisible,
            };
        default:
            return {
                ...state,
            };
    }
};
export default modalStateReducer;
