import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
const initFirebase = () => {
    // For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
    const apiKey = process.env.FIREBASE_API_KEY;
    const authDomain = process.env.FIREBASE_AUTH_DOMAIN;
    const projectId = process.env.FIREBASE_PROJECT_ID;
    const storageBucket = process.env.FIREBASE_STORAGE_BUCKET;
    const messagingSenderId = process.env.FIREBASE_MESSAGING_SENDER_ID;
    const appId = process.env.FIREBASE_APP_ID;
    if (!apiKey || !authDomain || !projectId || !storageBucket || !messagingSenderId || !appId) {
        throw new Error('One of the required Firebase config values is missing');
    }
    const firebaseConfig = {
        apiKey,
        authDomain,
        projectId,
        storageBucket,
        messagingSenderId,
        appId,
    };
    // Initialize Firebase
    const initializedApp = firebase.initializeApp(firebaseConfig);
    return initializedApp;
};
export default initFirebase;
