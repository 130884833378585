import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { styled } from '@mui/material/styles';
import isPropValid from '@emotion/is-prop-valid';
import { ChevronLeftIcon, XMarkIcon } from '@heroicons/react/24/outline';
import SheetModal from "@components/Modals/SheetModal";
import useGameModeFilterState from './hooks/useGameModeFilterState';
import GameModeFilterOptions from './GameModeFilterOptions';
import FilterToggleButton from '../FilterToggleButton';
const Header = styled('div') `
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[100]};
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(2)};
  width: 100%;

  h3 {
    margin: 0 !important;
  }
`;
const DrawerContent = styled('div') `
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  margin: ${({ theme }) => theme.spacing(2)};
`;
const HeaderButton = styled('button', { shouldForwardProp: isPropValid }) `
  align-items: center;
  aspect-ratio: 1;
  background: transparent;
  background-color: transparent;
  border: none;
  display: inline-flex;
  justify-content: center;
  line-height: 0;
  padding: 0;
  width: 44px;

  & > svg {
    stroke: ${({ theme }) => theme.palette.grey[400]};
    height: 30px;
  }
`;
const MobileGameFilter = ({ gameKey, selectedModes, onChange }) => {
    const { isOpen, displayText, displayIcon, options, buttonRef, close, toggleOpen } = useGameModeFilterState(gameKey, selectedModes);
    return (_jsxs(_Fragment, { children: [_jsx(FilterToggleButton, { iconUrl: displayIcon, title: "Mode", text: displayText, ref: buttonRef, onClick: toggleOpen, size: "md" }), _jsx(SheetModal, { isOpen: isOpen, onClose: close, children: _jsxs(_Fragment, { children: [_jsxs(Header, { children: [_jsx(HeaderButton, { onClick: close, "aria-label": "Back", children: _jsx(ChevronLeftIcon, {}) }), _jsx("h3", { children: "Mode" }), _jsx(HeaderButton, { onClick: close, "aria-label": "Close", children: _jsx(XMarkIcon, {}) })] }), _jsx(DrawerContent, { children: _jsx(GameModeFilterOptions, { onChange: onChange, options: options }) })] }) })] }));
};
export default MobileGameFilter;
