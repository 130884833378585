import { useQuery } from 'react-query';
import { useUserState } from '@context/UserContext';
import { captureException } from '@sentry/react';
import { displayMessageFromError } from '@helpers/errorHelper';
import fetchNewNotificationsCount from '@api/fetchNewNotificationsCount';
export const newNotificationsCountKey = 'newNotificationsCount';
const useFetchNewNotificationsCount = (enabled = true) => {
    var _a;
    const { authenticated } = useUserState();
    const { data } = useQuery([newNotificationsCountKey], () => fetchNewNotificationsCount(), {
        enabled: authenticated && enabled,
        staleTime: 1000 * 60 * 1,
        onError: (error) => {
            captureException(error, {
                extra: {
                    request: newNotificationsCountKey,
                    errorMessage: displayMessageFromError(error),
                },
            });
        },
    });
    return {
        count: (_a = data === null || data === void 0 ? void 0 : data.meta) !== null && _a !== void 0 ? _a : { totalNew: 0, totalGlobal: 0 },
    };
};
export default useFetchNewNotificationsCount;
