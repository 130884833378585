import { jsx as _jsx } from "react/jsx-runtime";
import isPropValid from '@emotion/is-prop-valid';
import withHideInMobileAppWebView from '@helpers/withHideInMobileAppWebView';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { styled } from '@mui/system';
const StyledButton = styled('button', { shouldForwardProp: isPropValid }) `
  align-items: center;
  background: transparent;
  border: none;
  display: inline-flex;
  justify-content: center;
  font-size: 24px;
  background-color: transparent;
  height: 44px;
  width: 44px;

  & > svg {
    fill: ${({ theme, fillColor }) => (fillColor ? fillColor : theme.palette.grey[400])};
    width: 30px;
    margin-top: 2px;
  }
`;
const CrossCloseIcon = ({ action, className = '', fillColor }) => {
    return (_jsx(StyledButton, { "aria-label": "Close modal", className: className, fillColor: fillColor, onClick: action, type: "button", children: _jsx(XMarkIcon, {}) }));
};
export default withHideInMobileAppWebView(CrossCloseIcon);
