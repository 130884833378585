import { getSimplifiedRegionCodes } from 'country-provider';
import getRegionsByDisplayRule from '@domain/Tournament/helpers/getRegionsByDisplayRule';
export const getTournamentRegions = (tournament) => {
    const { displayRules } = tournament;
    if (displayRules == null) {
        return ['All Regions'];
    }
    const regions = getRegionsByDisplayRule(displayRules);
    if (regions.length === 0) {
        return ['All Regions'];
    }
    const simplifiedRegionCodes = getSimplifiedRegionCodes(regions);
    if (simplifiedRegionCodes.length > 0) {
        return simplifiedRegionCodes.map((region) => region.toLocaleUpperCase()).sort((a, b) => a.localeCompare(b));
    }
    return regions.map((region) => region.toLocaleUpperCase()).sort((a, b) => a.localeCompare(b));
};
export const getTournamentRegionsTagData = (tournament, limit = 2) => {
    const regions = getTournamentRegions(tournament);
    if (regions.length <= limit)
        return { label: regions.join(' • ') };
    const limitedValues = regions.slice(0, limit - 1);
    limitedValues.push(`+ ${regions.length - limit}`);
    return { label: `${regions.length} regions`, tooltip: regions.join(' • ') };
};
export default getTournamentRegions;
