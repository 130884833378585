export const getDefaultUserProfileContext = () => ({
    userProfile: null,
    statistics: null,
    isFetching: false,
    isCurrentUser: null,
    activeTournaments: [],
    pastTournaments: [],
    error: null,
});
export default getDefaultUserProfileContext;
