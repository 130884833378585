import { useQueryClient } from 'react-query';
import { newNotificationsCountKey } from './useFetchNewNotificationsCount';
const useUpdateNewNotificationsCount = () => {
    const queryClient = useQueryClient();
    const updateNotificationCount = (totalNew, totalGlobal) => {
        queryClient.setQueryData([newNotificationsCountKey], (prevState) => {
            var _a;
            return { meta: { totalGlobal: (_a = totalGlobal !== null && totalGlobal !== void 0 ? totalGlobal : prevState === null || prevState === void 0 ? void 0 : prevState.meta.totalGlobal) !== null && _a !== void 0 ? _a : 0, totalNew } };
        });
    };
    return updateNotificationCount;
};
export default useUpdateNewNotificationsCount;
