import padZeros from '@helpers/padZeros';
import { useEffect, useState } from 'react';
/**
 * Used to countdown from a provided amount of seconds.
 */
function useCountdown({ startSeconds = 0, onCountdownProgress = () => undefined, onCountdownComplete = () => undefined, }) {
    const [remainingSeconds, setRemainingSeconds] = useState(startSeconds);
    const [isCounting, setIsCounting] = useState(startSeconds > 0);
    const [pauseCountdown, setPauseCountdown] = useState(false);
    useEffect(() => {
        setRemainingSeconds(startSeconds);
        setIsCounting(startSeconds > 0);
    }, [startSeconds]);
    useEffect(() => {
        const tickHandler = () => {
            if (remainingSeconds > 0) {
                const nextSeconds = remainingSeconds - 1;
                setRemainingSeconds(nextSeconds);
                onCountdownProgress(nextSeconds);
                if (nextSeconds === 0) {
                    onCountdownComplete();
                }
            }
        };
        const interval = setInterval(tickHandler, 1000);
        if (remainingSeconds <= 0 || pauseCountdown) {
            clearInterval(interval);
            if (!pauseCountdown) {
                onCountdownComplete();
            }
        }
        return () => clearInterval(interval);
    }, [remainingSeconds, pauseCountdown]);
    const setRemainingSecondsExternal = (seconds) => {
        setRemainingSeconds(seconds);
        if (seconds > 0 && !isCounting && !pauseCountdown) {
            setIsCounting(true);
        }
    };
    const displayHour = String(Math.floor(remainingSeconds / 3600));
    const displayMin = String(Math.floor((remainingSeconds % 3600) / 60));
    const displaySec = String(Math.floor((remainingSeconds % 3600) % 60));
    // 00:00:00 or 00:00 depending if hours exist
    const remainingSecondsFormatted = `${displayHour !== '0' ? `${padZeros(displayHour, 2)}:` : ''}${padZeros(displayMin, 2)}:${padZeros(displaySec, 2)}`;
    return {
        remainingSeconds,
        setRemainingSeconds: setRemainingSecondsExternal,
        remainingSecondsFormatted,
        pauseCountdown: setPauseCountdown,
    };
}
export default useCountdown;
