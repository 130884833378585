import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef } from 'react';
import loadable from '@loadable/component';
import CoreButton from '@components/Buttons/CoreButton';
import ModalContent from '@components/Modals/components/ModalContent';
import SheetModal from '@components/Modals/SheetModal';
import { CrossCloseIcon } from '@components/UI';
import Dropdown from '@components/UI/Dropdown';
import { Divider } from '@mui/material';
import { styled } from '@mui/system';
import IconButton from './IconButton';
import useTreeSelect from './useTreeSelect';
import { findOptionById } from './helper';
const ChevronLeft = loadable(() => import('@components/Icons/ChevronLeft'));
const BackButton = styled(CoreButton) `
  margin-top: ${({ theme }) => theme.spacing(1)};
  svg {
    height: 12px;
    fill: ${({ theme }) => theme.palette.grey[200]};
    margin-right: ${({ theme }) => theme.spacing(2)};
  }
`;
const ClearFilterButton = styled(CoreButton) `
  width: 100%;
`;
const DrawerHeader = styled('div') `
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(2, 0)};
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[100]};

  svg {
    margin-left: auto;
  }

  h3 {
    margin: 0;
  }
`;
const MobileBackButton = styled(CoreButton) `
  position: absolute;
  left: 20px;
  svg {
    height: 12px;
    fill: ${({ theme }) => theme.palette.grey[200]};
    margin-right: ${({ theme }) => theme.spacing(2)};
  }
`;
const StyledCrossCloseIcon = styled(CrossCloseIcon) `
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
`;
const StyledDivider = styled(Divider) `
  margin-top: ${({ theme }) => theme.spacing(1)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;
const StyledDesktopDropdownBox = styled('div') `
  padding-top: ${({ theme }) => theme.spacing(1)};
  padding-bottom: ${({ theme }) => theme.spacing(1)};
`;
const TreeSelect = ({ title, options, selectedId, featuredOptionId, featuredOptionTitle, optionTitles, isMobile, defaultIcon, onSelect, onCancel, ignore = false, }) => {
    var _a, _b;
    const { isOpen, setOpen, selectedOption, isInRoot, handleSelect, handleSelectFeaturedOption, handleCancel, handleClearFilter, handleGoParent, currentOptions, optionTitle, } = useTreeSelect({
        optionTitles,
        selectedId,
        options,
        onSelect,
        onCancel,
        featuredOptionId: featuredOptionId,
    });
    const buttonRef = useRef(null);
    const choices = currentOptions === null || currentOptions === void 0 ? void 0 : currentOptions.map((option) => (_jsx(IconButton, { iconUrl: option.iconUrl, text: option.name, onClick: () => {
            handleSelect(option.id);
        }, size: isMobile ? 'md' : 'sm', chevron: option.children ? 'right' : undefined }, option.id)));
    const buttonBack = (_jsxs(BackButton, { appearance: "text", onClick: () => handleGoParent(), children: [_jsx(ChevronLeft, {}), _jsx("span", { children: "Back" })] }));
    const buttonBackMobile = (_jsx(MobileBackButton, { appearance: "text", onClick: () => handleGoParent(), children: _jsx(ChevronLeft, {}) }));
    const desktopHeader = (_jsxs(_Fragment, { children: [optionTitle && (_jsxs(_Fragment, { children: [_jsx("span", { children: optionTitle }), _jsx(StyledDivider, {})] })), !isInRoot && buttonBack] }));
    const mobileHeader = (_jsxs(DrawerHeader, { children: [!isInRoot && buttonBackMobile, _jsx("h3", { children: optionTitle }), _jsx(StyledCrossCloseIcon, { action: () => {
                    setOpen(false);
                    if (onCancel)
                        onCancel();
                } })] }));
    const featuredOption = findOptionById(options, featuredOptionId);
    const content = (_jsxs(_Fragment, { children: [isInRoot && featuredOption && (_jsx(IconButton, { variant: "outlined", iconUrl: featuredOption.iconUrl, title: featuredOptionTitle, text: featuredOption.name, onClick: handleSelectFeaturedOption, size: isMobile ? 'md' : 'sm' })), choices, _jsx(StyledDivider, {}), _jsx(ClearFilterButton, { onClick: handleClearFilter, variant: selectedOption ? 'error' : 'grey', disabled: !selectedOption, appearance: selectedOption ? 'fill' : 'outline', size: "md", children: "Clear Filters" })] }));
    if (isMobile) {
        return (_jsxs(_Fragment, { children: [_jsx(IconButton, { iconUrl: (_a = selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.iconUrl) !== null && _a !== void 0 ? _a : defaultIcon, variant: "outlined", title: title, text: (selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.name) ? selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.name : 'Not Selected', innerRef: buttonRef, chevron: "down", size: "md", onClick: () => setOpen(!isOpen) }), _jsx(SheetModal, { isOpen: isOpen, onClose: () => setOpen(false), children: _jsxs(ModalContent, { ...(ignore ? { 'data-notranslate': true } : {}), children: [mobileHeader, content] }) })] }));
    }
    return (_jsx(Dropdown, { buttonRef: buttonRef.current, isOpen: isOpen, onClickOutside: handleCancel, placement: "bottom-start", dropdownButton: _jsx(IconButton, { iconUrl: (_b = selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.iconUrl) !== null && _b !== void 0 ? _b : defaultIcon, variant: "outlined", title: title, text: (selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.name) ? selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.name : 'Not Selected', innerRef: buttonRef, chevron: "down", onClick: () => setOpen(!isOpen) }), children: _jsxs(StyledDesktopDropdownBox, { ...(ignore ? { 'data-notranslate': true } : {}), children: [desktopHeader, content] }) }));
};
export default TreeSelect;
