import { jsx as _jsx } from "react/jsx-runtime";
import useGameRanks from '@hooks/API/useGameRanks';
import TreeSelect from '../../../components/UI/TreeSelect/TreeSelect';
import { convertToTreeOptions } from './helpers/gameRank';
const RankFilter = ({ gameKey, ...restProps }) => {
    const { gameRanks } = useGameRanks(gameKey);
    const treeOptions = convertToTreeOptions(gameRanks);
    return (_jsx(TreeSelect, { title: "Rank", optionTitles: ['Rank', 'Division'], options: treeOptions, defaultIcon: "/img/icons/trophyribbon.svg", ignore: true, ...restProps }));
};
export default RankFilter;
