import isMenuGame from '@helpers/isMenuGame';
/**
 * Filter the games down to only ones that should appear in the menu
 *
 * @param games - List of games to filter
 * @returns games that should appear in menu
 */
const getMenuGames = (games) => {
    return games.filter((game) => isMenuGame(game));
};
export default getMenuGames;
