import { createTheme } from '@mui/material/styles';
import WebFont from 'webfontloader';
// Is this the best place to load it?
WebFont.load({
    google: {
        families: ['Bai Jamjuree:500,600,700'],
    },
    events: false,
    classes: false,
});
const themeFont = ['Bai Jamjuree', 'Roboto', 'Helvetica', 'sans-serif'].join(',');
const { typography: MarketingThemeTypography } = createTheme({
    typography: {
        fontFamily: themeFont,
        h1: {
            fontFamily: themeFont,
            fontSize: '80px',
            lineHeight: 1.86,
            fontWeight: 700,
        },
        h2: {
            fontFamily: themeFont,
            fontSize: '55px',
            lineHeight: 1.07,
            fontWeight: 700,
        },
        button: {
            textTransform: 'none',
        },
    },
});
const RepeatMarketingTheme = {
    typography: MarketingThemeTypography,
};
export default RepeatMarketingTheme;
