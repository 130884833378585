import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useRef } from 'react';
import { makeStyles } from 'tss-react/mui';
import loadable from '@loadable/component';
import { useUpdateUserState, useUserState } from '@context/UserContext';
import TextInput from '@components/Fields/TextInput';
import CrossCloseIcon from '@components/UI/CrossCloseIcon';
import useGetBalance from '@components/Hooks/useGetBalance';
import useWithdrawAPI from '@components/Modals/WithdrawalModal/hooks/useWithdrawAPI';
import ModalBody from '@components/Modals/components/ModalBody';
import ModalContent from '@components/Modals/components/ModalContent';
import ModalHeader from '@components/Modals/components/ModalHeader';
import ModalCarouselContent from '@components/Modals/components/ModalCarouselContent';
import validatePaypalEmail from '@components/Modals/WithdrawalModal/helpers/validatePaypalEmail';
import useWithdrawModalStyles from '@components/Modals/WithdrawalModal/hooks/useWithdrawModalStyles';
import SelectTransactionAmount from '@components/Modals/components/SelectTransactionAmount';
import { Choice } from '@components/UI/Choice';
import Button from '@components/Buttons/Button';
import GenericModal from '@components/Modals/GenericModal';
import WithdrawBankDetails from '@components/Modals/WithdrawalModal/components/WithdrawBankDetails';
import WithdrawComplete from '@components/Modals/WithdrawalModal/components/WithdrawComplete';
import WithdrawConfirmPayment from '@components/Modals/WithdrawalModal/components/WithdrawConfirmPayment';
import WithdrawalServerError from '@components/Modals/WithdrawalModal/components/WithdrawalServerError';
import ControlledReauthenticateModal from '@components/Modals/AuthenticationModal/Components/Login/ControlledReauthenticateModal';
import useCurrentLoginProvider from '@components/Modals/AuthenticationModal/Hooks/useCurrentLoginProvider';
const PayPal = loadable(() => import('@components/Icons/PayPal'));
const Bank = loadable(() => import('@components/Icons/Bank'));
const StarTick = loadable(() => import('@components/Icons/StarTick'));
const useStyles = makeStyles()((theme) => ({
    paypalNextFill: {
        fill: theme.palette.paypal.main,
    },
    bankNextFill: {
        fill: theme.palette.warning.main,
    },
    closeBtn: {
        fontSize: '26px',
        position: 'absolute',
        right: '0',
        top: '0',
    },
    securityBox: {
        backgroundColor: theme.palette.primary.light,
        borderRadius: theme.shape.borderRadius,
        alignSelf: 'stretch',
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& p': {
            fontSize: '10px',
            margin: '0px',
            marginLeft: '6px',
            color: theme.palette.primary.main,
        },
    },
}));
const WithdrawalModal = ({ open, onClose }) => {
    var _a;
    const { classes } = useStyles();
    const { classes: sharedClasses } = useWithdrawModalStyles();
    const { currentLoginProvider } = useCurrentLoginProvider();
    const { isLoading, data: balances, refetch: refetchBalance } = useGetBalance();
    const { userDetails } = useUserState();
    const { refresh: refetchUserState } = useUpdateUserState();
    const [pages, setPages] = useState(['Select']);
    const isPaypal = pages.length >= 2 && pages[1] === 'PayPal';
    const [transactionAmount, setTransactionAmount] = useState(null);
    const [paypalEmail, setPaypalEmail] = useState((_a = userDetails === null || userDetails === void 0 ? void 0 : userDetails.payPalEmail) !== null && _a !== void 0 ? _a : '');
    const [paypalError, setPaypalError] = useState(null);
    const emailFieldRef = useRef(null);
    const [reauthenticateState, setReauthenticateState] = useState('unused');
    const handleClose = () => onClose(false);
    const handleWithdrawalSuccess = () => {
        setPages([...pages, 'Success']);
        refetchBalance();
        refetchUserState();
    };
    const handleWithdrawalError = (code) => {
        // Reauthenticate and try again if error was 401
        if (code === 401 && reauthenticateState === 'unused' && currentLoginProvider === 'Email') {
            setReauthenticateState('reauthenticating');
        }
        else {
            setPages([...pages, 'Error']);
        }
    };
    const retry = () => setPages(['Select']);
    const withdrawAPI = useWithdrawAPI(userDetails, handleWithdrawalSuccess, handleWithdrawalError);
    const confirmPaypalEmail = () => {
        var _a;
        const paypalValidation = validatePaypalEmail({ paypalEmail });
        paypalValidation.check();
        if (paypalValidation.errors.get('paypalEmail').length > 0) {
            setPaypalError((_a = paypalValidation.errors.get('paypalEmail')[0]) !== null && _a !== void 0 ? _a : null);
        }
        else {
            setPages([...pages, 'Confirm']);
        }
    };
    const handleSelectType = (a) => {
        setPages(['Select', a === 'Check' ? 'Bank' : 'PayPal', 'Amount']);
    };
    const onPaypalEmailKeyPress = (event) => {
        if (event.key === 'Enter') {
            confirmPaypalEmail();
        }
    };
    const submitRequest = () => {
        if (!transactionAmount) {
            throw new Error('Amount was not set!');
        }
        if (isPaypal) {
            withdrawAPI.withdrawPaypal(transactionAmount, paypalEmail);
        }
        else {
            withdrawAPI.withdrawCheck(transactionAmount);
        }
    };
    const renderPage = (pageName) => {
        var _a, _b, _c;
        switch (pageName) {
            case 'Amount':
                return (_jsx(ModalContent, { children: _jsx(SelectTransactionAmount, { palette: isPaypal ? 'paypal' : 'warning', amountsArray: isPaypal ? [10, 20, 50, 100, 500] : [200, 250, 300, 400, 500], minAmount: isPaypal ? 10 : 200, maxAmount: balances === null || balances === void 0 ? void 0 : balances.USD, onAmountSelected: (value) => {
                            setTransactionAmount(value);
                            setPages([...pages, isPaypal ? 'Email' : 'Details']);
                        }, isLoading: isLoading }) }));
            case 'Email':
                return (_jsxs(ModalContent, { children: [_jsxs("h3", { children: ["Complete your ", _jsx("span", { "data-ignore": true, children: "PayPal" }), " Payment"] }), _jsxs("p", { className: sharedClasses.paragraph, children: ["Enter the email address for your ", _jsx("span", { "data-ignore": true, children: "PayPal" }), " account below and click withdraw to continue."] }), _jsx(TextInput, { ref: emailFieldRef, label: _jsxs(_Fragment, { children: [_jsx("span", { "data-ignore": true, children: "PayPal" }), " Email Address:"] }), id: "paypal-email", placeholder: "Enter your paypal email...", value: paypalEmail, error: paypalError != null, errorMessage: paypalError, onChange: (ev) => {
                                setPaypalEmail(ev.target.value);
                                setPaypalError(null);
                            }, onKeyPressCapture: onPaypalEmailKeyPress }), _jsx(Button, { variant: "palette", paletteName: isPaypal ? 'paypal' : 'warning', onClick: confirmPaypalEmail, children: "Next" })] }));
            case 'Details':
                return _jsx(WithdrawBankDetails, { confirm: () => setPages([...pages, 'Confirm']), onClose: handleClose });
            case 'Confirm':
                return (_jsx(WithdrawConfirmPayment, { loading: withdrawAPI.status === 'LOADING', type: isPaypal ? 'paypal' : 'bank', amount: transactionAmount !== null && transactionAmount !== void 0 ? transactionAmount : 0, onSubmit: submitRequest }));
            case 'Success':
                return (_jsx(WithdrawComplete, { type: ((_a = userDetails === null || userDetails === void 0 ? void 0 : userDetails.verification) === null || _a === void 0 ? void 0 : _a.verified) ? 'SUCCESS' : 'VERIFY', amount: transactionAmount !== null && transactionAmount !== void 0 ? transactionAmount : 0, onSubmit: handleClose, email: (_b = userDetails === null || userDetails === void 0 ? void 0 : userDetails.email) !== null && _b !== void 0 ? _b : '', palette: isPaypal ? 'paypal' : 'warning' }));
            case 'Select':
            case 'PayPal':
            case 'Bank':
                return _jsx(_Fragment, {});
            case 'Error':
                return (_jsx(WithdrawalServerError, { palette: isPaypal ? 'paypal' : 'warning', retry: retry, errorMessage: (_c = withdrawAPI.errorMessage) !== null && _c !== void 0 ? _c : '' }));
            default:
                throw new Error(`Modal page "${pageName}" was not recognised!`);
        }
    };
    const onPop = (nextPageKeys, index) => {
        if (index === 0 || index === 1) {
            setPages(['Select']);
        }
        else {
            setPages(nextPageKeys);
        }
    };
    const onCarouselTransitionEnd = (pageKey) => {
        var _a;
        if (pageKey === 'Email') {
            (_a = emailFieldRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    };
    const onReauthenticate = () => {
        setReauthenticateState('reauthenticated');
        submitRequest();
    };
    if (reauthenticateState === 'reauthenticating') {
        return _jsx(ControlledReauthenticateModal, { show: true, handleClose: handleClose, handleSuccess: onReauthenticate });
    }
    if (pages.length === 1) {
        const selectableGames = [
            {
                id: 'paypal',
                value: 'PayPal',
                label: 'PayPal',
                icon: _jsx(PayPal, {}),
                paletteName: 'paypal',
            },
            {
                id: 'check',
                value: 'Check',
                label: 'Check',
                icon: _jsx(Bank, {}),
                paletteName: 'warning',
            },
        ];
        return (_jsxs(GenericModal, { show: open, onClose: handleClose, size: pages.length === 1 ? 'small' : 'medium', children: [_jsx(CrossCloseIcon, { className: classes.closeBtn, action: handleClose }), _jsx(ModalBody, { children: _jsxs(ModalContent, { align: "center", children: [_jsx("h2", { children: "Withdraw Cash" }), _jsx("p", { children: "Please select one of the actions below in order to continue with the process" }), _jsx(Choice, { choices: selectableGames, onSelect: handleSelectType }), _jsxs("div", { className: classes.securityBox, children: [_jsx(StarTick, { className: sharedClasses.starTick }), _jsx("p", { children: "Secured by AES-256 SSL" })] })] }) })] }));
    }
    return (_jsxs(GenericModal, { show: open, onClose: handleClose, size: pages.length === 1 ? 'small' : 'medium', children: [_jsx(ModalHeader, { paletteName: isPaypal ? 'paypal' : 'warning', icon: isPaypal ? _jsx(PayPal, {}) : _jsx(Bank, {}), onClose: handleClose, title: isPaypal ? (_jsxs(_Fragment, { children: [_jsx("span", { "data-ignore": true, children: "PayPal" }), " Withdrawal"] })) : ('Bank Withdrawal'), subtitle: "Withdraw Cash from your account" }), _jsx(ModalCarouselContent, { loading: withdrawAPI.status === 'LOADING', name: "withdraw", pageKeys: pages, onPop: onPop, renderPage: renderPage, hideBreadcrumb: pages[pages.length - 1] === 'Success', onTransitionEnd: onCarouselTransitionEnd })] }));
};
export default WithdrawalModal;
