import axios from 'axios';
import queryString from 'query-string';
import ErrorWithCause from '../helpers/ErrorWithCause';
/**
 * Update notifications via the /notifications/notification endpoint
 *
 * @param params The request parameters
 * @returns response from the API with metadata
 * @throws ErrorWithCause
 */
const updateNotifications = async (params) => {
    const { notificationIds, timestamp, ...body } = params;
    const queryParams = {};
    if (notificationIds) {
        queryParams.notification_id = notificationIds;
    }
    if (timestamp) {
        queryParams.timestamp = timestamp;
    }
    const queryParamsString = queryString.stringify(queryParams);
    try {
        const { data } = await axios.patch(`${process.env.API_BASE_URL}/notifications/notification?${queryParamsString}`, {
            ...body,
        });
        return data;
    }
    catch (error) {
        throw new ErrorWithCause(error);
    }
};
export default updateNotifications;
