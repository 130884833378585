const getRegionsByDisplayRule = (displayRules) => {
    const regions = [];
    displayRules.forEach((rule) => {
        if (rule.object === 'user' && ['state', 'country'].includes(rule.attribute)) {
            if (rule.comparison === 'eq' && typeof rule.value === 'string' && rule.value.toLowerCase() !== 'global') {
                regions.push(rule.value);
            }
            else if (rule.comparison === 'in' && Array.isArray(rule.value)) {
                const validRegions = rule.value.filter((v) => typeof v === 'string' && v.toLowerCase() !== 'global');
                regions.push(...validRegions);
            }
        }
    });
    return regions;
};
export default getRegionsByDisplayRule;
