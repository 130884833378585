import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { styled } from '@mui/system';
import BracketPositionIcon from '@domain/Tournament/TournamentDetails/TournamentEntry/styled/BracketIcon';
import useThresholdBracketBreakdown from '@domain/Tournament/TournamentDetails/hooks/useThresholdBracketBreakdown';
const TierSubHeading = styled('div') `
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
`;
const TierHeading = styled('div') `
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
`;
const TierContainer = styled('div') `
  background: #ffffff;
  border-width: 1px 0;
  border-style: solid;
  border-color: ${({ theme }) => theme.palette.primary.light};
  overflow-x: auto;
  overflow-y: visible;
  white-space: nowrap;
`;
const TierItem = styled('button') `
  width: 100px;
  height: 150px;
  border: none;
  border-bottom: 3px solid #e4e4e4;
  background-color: white;
  position: relative;
  &:hover,
  &:active,
  &:focus-visible,
  &.selected {
    background: linear-gradient(0deg, rgba(0, 172, 246, 0.1), rgba(0, 172, 246, 0.1)), #ffffff;
    border: none;
    border-bottom: 3px solid ${({ theme }) => theme.palette.primary.main};
  }
`;
const RankBadge = styled(BracketPositionIcon) `
  margin: 12px auto;
`;
const YouMarker = styled('img') `
  position: absolute;
  left: calc(50% - 16px);
  top: 8px;
`;
const TournamentBracketCarousel = ({ onItemSelected, userBracket, initialTier, brackets, allowNoSelection, }) => {
    const localBrackets = useThresholdBracketBreakdown(brackets);
    const [filteredBracketPosition, setFilteredBracketPosition] = useState(initialTier);
    return (_jsx(TierContainer, { children: localBrackets.map((bracket, index) => {
            return (_jsxs(TierItem, { "data-testid": `tier-item-${bracket.bracketPosition}`, className: filteredBracketPosition === bracket.bracketPosition ? 'selected' : '', onClick: () => {
                    if (filteredBracketPosition === bracket.bracketPosition && allowNoSelection) {
                        onItemSelected(undefined);
                        setFilteredBracketPosition(undefined);
                    }
                    else {
                        onItemSelected(bracket.bracketPosition);
                        setFilteredBracketPosition(bracket.bracketPosition);
                    }
                }, children: [userBracket === bracket.bracketPosition && (_jsx(YouMarker, { src: "/img/ranks/threshold/marker-you.png", alt: "you" })), _jsx(RankBadge, { bracketPosition: bracket.bracketPosition, title: `rank badge for position ${bracket.bracketPosition}`, children: _jsx("span", { children: bracket.bracketPosition }) }), _jsxs(TierHeading, { children: ["Tier ", bracket.bracketPosition] }), _jsx(TierSubHeading, { children: bracket.rankDisplay })] }, `tier-${bracket.scoreThreshold}`));
        }) }));
};
export default TournamentBracketCarousel;
