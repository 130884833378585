import Validator from 'validatorjs';
const rules = {
    email: 'required|email',
    password: 'required',
};
const validateLogin = (data) => {
    const validation = new Validator(data, rules);
    return validation;
};
export default validateLogin;
