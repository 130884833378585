import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from '@mui/material/styles';
import Flex from '@components/Layout/Flex';
import { Checkbox, FormControlLabel } from '@mui/material';
import IconBackground from '@components/UI/Icon/IconBackground';
const shouldForwardProp = (prop) => prop !== 'size';
const StyledIconBackground = styled(IconBackground) `
  background-color: transparent;
`;
const StyledImg = styled('img') `
  height: 20px;
  object-fit: contain;
`;
const TextContent = styled('span') `
  margin-left: ${({ theme }) => theme.spacing(1)};
  overflow: hidden;
  white-space: nowrap;
`;
const StyledFormControlLabel = styled(FormControlLabel, { shouldForwardProp }) `
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: ${({ size }) => (size === 'sm' ? '46px' : '52px')};
  border-radius: ${({ theme }) => theme.spacing(1)};
  padding-left: ${({ theme }) => theme.spacing(1)};
  margin: ${({ theme }) => theme.spacing(0)};

  > span {
    font-size: 14px;
    font-weight: 600;
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.light};
  }
`;
const StyledCheckbox = styled(Checkbox) `
  & .MuiSvgIcon-root {
    font-size: 20px;
  }
`;
const CheckboxOption = ({ iconUrl, text, onChange, value, disabled = false, size = 'sm', checked, }) => {
    const handleChange = (e) => onChange(e.currentTarget.value, e.currentTarget.checked);
    return (_jsx(StyledFormControlLabel, { value: "start", control: _jsx(StyledCheckbox, { checked: checked, onChange: handleChange, value: value }), label: _jsxs(Flex, { flexDirection: "row", align: "center", gap: 0, children: [_jsx(StyledIconBackground, { size: size, children: iconUrl && _jsx(StyledImg, { src: iconUrl }) }), _jsx(Flex, { flexDirection: "column", align: "flex-start", gap: 0, children: _jsx(TextContent, { children: text }) })] }), disabled: disabled, size: size, labelPlacement: "start" }));
};
export default CheckboxOption;
