import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CrossCloseIcon from "@components/UI/CrossCloseIcon";
import SheetModal from "@components/Modals/SheetModal";
import useLocalStorage from '@components/Hooks/useLocalStorage';
import useGamesList from '@hooks/API/useGamesList';
import PlatformLinkButton from '@components/Buttons/PlatformLinkButton';
import { styled } from '@mui/material/styles';
import useGame from '@hooks/API/useGame';
const MobileHeading = styled('div') `
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > h2 {
    margin: 0;
    color: ${({ theme }) => theme.palette.grey[900]};
    font-size: 14px;
    font-weight: 600;
  }
`;
const ModalContent = styled('div') `
  padding: ${({ theme }) => theme.spacing(1.5)};
`;
const PlatformSelectModal = ({ isOpen, onClose, gameKey }) => {
    var _a;
    const [_, setSelectedPlatformSlug] = useLocalStorage('selectedPlatformSlug', undefined);
    const { allPlatforms } = useGamesList();
    const game = useGame(gameKey);
    const gamePlatforms = (_a = game === null || game === void 0 ? void 0 : game.platforms.map((platform) => platform.abbreviation)) !== null && _a !== void 0 ? _a : [];
    const useGetLink = (gameKey, platform) => {
        if (!gameKey) {
            return `/${platform.slug}/compete`;
        }
        else if (gameKey && !gamePlatforms.includes(platform.abbreviation)) {
            return '';
        }
        else {
            return `/${platform.slug}/${game === null || game === void 0 ? void 0 : game.slug}`;
        }
    };
    const handleButtonClick = (platform) => {
        setSelectedPlatformSlug(platform.slug);
        onClose && onClose();
    };
    return (_jsx(SheetModal, { isOpen: isOpen, onClose: onClose, children: _jsxs(ModalContent, { children: [_jsxs(MobileHeading, { children: [_jsx("h2", { children: "Choose Platform" }), _jsx(CrossCloseIcon, { action: onClose })] }), allPlatforms.map((platform) => (_jsx(PlatformLinkButton, { size: "md", disabled: gameKey && !gamePlatforms.includes(platform.abbreviation), to: useGetLink(gameKey, platform), onClick: () => {
                        handleButtonClick(platform);
                    }, platform: platform, chevron: "right" }, platform.id)))] }) }));
};
export default PlatformSelectModal;
