import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import CoreButton from "@components/Buttons/CoreButton";
import { isAxiosError } from '@helpers/errorHelper';
import Error404 from './Error404';
import Error401 from './Error401';
const useStyles = makeStyles()((theme) => ({
    wrapper: {
        color: theme.palette.grey[900],
        display: 'flex',
        fontFamily: 'Montserrat',
        height: '85vh',
        justifyContent: 'center',
    },
    content: {
        margin: 'auto',
        maxWidth: '400px',
        textAlign: 'center',
    },
    img: {
        maxWidth: '175px',
    },
    title: {
        fontWeight: 800,
        margin: theme.spacing(4, 0),
        fontSize: '24px',
    },
    buttonContainer: {
        margin: theme.spacing(2, 0),
    },
}));
const ErrorPage = ({ error }) => {
    var _a;
    const { classes } = useStyles();
    const goBack = () => {
        window.history.back();
    };
    const errorCode = (isAxiosError(error) && ((_a = error.response) === null || _a === void 0 ? void 0 : _a.status.toString())) || '500';
    if (errorCode === '401') {
        return _jsx(Error401, {});
    }
    else if (errorCode === '404') {
        return _jsx(Error404, {});
    }
    return (_jsx("div", { className: classes.wrapper, children: _jsxs("div", { className: classes.content, children: [_jsx("img", { src: "/img/repeat-404.svg", className: classes.img, alt: "" }), _jsxs("h1", { className: classes.title, children: [errorCode, ": Unexpected Error"] }), _jsx("p", { children: "Yikes! Something went wrong. Clearly our developers are a bunch of n00bs." }), _jsxs(Grid, { className: classes.buttonContainer, container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, sm: 6, children: _jsx(CoreButton, { type: "button", block: true, size: "lg", variant: "primary", onClick: goBack, children: "Go Back" }) }), _jsx(Grid, { item: true, xs: 12, sm: 6, children: _jsx(CoreButton, { block: true, size: "lg", variant: "primary", "data-testid": "login-link", onClick: () => {
                                    window.location.href = '/user/login';
                                }, children: "Login" }) })] })] }) }));
};
export default ErrorPage;
