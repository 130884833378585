import { captureException } from '@sentry/react';
import axios from 'axios';
import ErrorWithCause from '../../helpers/ErrorWithCause';
const beginPaypalDeposit = async (request) => {
    const requestPath = '/api/deposit/paypal/v1';
    try {
        const response = await axios.post(requestPath, request);
        return response.data;
    }
    catch (error) {
        const e = new ErrorWithCause(error);
        captureException(e, {
            extra: {
                requestMethod: 'POST',
                requestPath,
                function: 'beginPaypalDeposit',
                paypalOrderId: request.paypalOrderId,
            },
        });
        // If the order couldn't be recorded in symfony, throw an error and the process should fail.
        throw e;
    }
};
export default beginPaypalDeposit;
