import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CoreButton from "@components/Buttons/CoreButton";
import { useUserState } from '@context/UserContext';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import useStyles from '../useStyles';
const Navigation = ({ handleLoginAndJoinClick, handleJoinClick }) => {
    const { classes } = useStyles({});
    const { authenticated } = useUserState();
    return (_jsxs("nav", { className: classes.navigation, children: [_jsxs("div", { children: [_jsx(Link, { className: classes.logo, to: "/tournaments", children: _jsx("img", { src: "/img/logos/repeat_white_logo.svg", alt: "Repeat Logo" }) }), _jsxs(CoreButton, { className: classnames(classes.buttonOverride, classes.hiddenSM, classes.displayInline), size: "sm", appearance: "outline", variant: "white", to: "/tournaments", children: ["\u27F5 Go to ", _jsx("span", { "data-ignore": true, children: "Repeat" })] })] }), _jsxs("div", { children: [!authenticated && (_jsx(CoreButton, { className: classnames(classes.buttonOverride, classes.loginButton, classes.hiddenXS), variant: "white", appearance: "outline", onClick: handleLoginAndJoinClick, children: "Login to Play" })), _jsx(CoreButton, { className: classes.buttonOverride, variant: "orange", onClick: handleJoinClick, children: "Join Now" })] })] }));
};
export default Navigation;
