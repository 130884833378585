import { useState, useEffect } from 'react';
/**
 * Currently does not work in Chrome.
 * If this is needed again, might be worth looking at:
 * https://www.detectadblock.com
 *
 * @returns {boolean}
 */
function useDetectAdsenseAdsBlocked() {
    const [adBlocked, setAdBlocked] = useState(false);
    useEffect(() => {
        if (!Array.isArray(window.adsbygoogle) && window.adsbygoogle !== undefined) {
            setAdBlocked(true);
        }
    }, [window.adsbygoogle]);
    return adBlocked;
}
export default useDetectAdsenseAdsBlocked;
