import queryString from 'query-string';
const getSocialMediaServiceOAuthUrlFromType = (type, returnPath) => {
    switch (type) {
        case 'twitch': {
            const params = { return_path: returnPath };
            return `/oauth/twitch/authorize${returnPath ? `?${queryString.stringify(params)}` : ''}`;
        }
        default:
            throw new Error(`Invalid social media type: ${type}`);
    }
};
export default getSocialMediaServiceOAuthUrlFromType;
