import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import removeQueryParams from '@helpers/removeQueryParams';
const getPageNumberFromSearch = (search) => { var _a; return parseInt((_a = new URLSearchParams(search).get('page')) !== null && _a !== void 0 ? _a : '1', 10) - 1 || 0; };
/**
 * Returns current page from URL (starting at 0) and a function to update it.
 * These functions assume the page number in the URL starts at 1 (so not to confuse the end user)
 * but the internal page state starts at 0 (makes things cleaner in code).
 *
 * @returns The page number and a function to update the page number
 */
const usePageNumber = () => {
    const history = useHistory();
    const pageNumber = getPageNumberFromSearch(history.location.search);
    const updatePageNumber = (newPageNumber) => {
        // if the page number is undefined, remove the page param
        if (!newPageNumber) {
            const { search, hash } = removeQueryParams(['page']);
            history.replace({ search, hash });
        }
        else {
            const parsed = queryString.parse(history.location.search);
            parsed.page = String(newPageNumber + 1);
            history.push({ search: queryString.stringify(parsed) });
        }
    };
    return [pageNumber, updatePageNumber];
};
export default usePageNumber;
