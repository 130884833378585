import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useMemo, useState } from 'react';
export const ComparisonContext = createContext(undefined);
const findBracketByPosition = (brackets, position) => {
    return brackets.find((bracket) => bracket.bracketPosition === position);
};
// Aceptance Criteria: Default selection is "Tier 1" when first visit the page
const DEFAULT_TIER_POSITION = 1;
export const TierComparisonProvider = ({ children, userEntryBracketPosition, brackets, currentUserScore }) => {
    var _a, _b, _c;
    const bracketsWithRanges = brackets.map((bracket, index, arr) => {
        const startThreshold = bracket.scoreThreshold;
        const endThreshold = index === 0 ? undefined : arr[index - 1].scoreThreshold;
        return { ...bracket, startThreshold, endThreshold };
    });
    const [selectedTierPosition, setSelectedTierPosition] = useState(DEFAULT_TIER_POSITION);
    const selectedBracket = findBracketByPosition(brackets, selectedTierPosition);
    const userBracket = findBracketByPosition(brackets, userEntryBracketPosition !== null && userEntryBracketPosition !== void 0 ? userEntryBracketPosition : -1);
    const userTierPrize = (_a = userBracket === null || userBracket === void 0 ? void 0 : userBracket.totalValue) !== null && _a !== void 0 ? _a : 0;
    const selectedTierPrize = (_b = selectedBracket === null || selectedBracket === void 0 ? void 0 : selectedBracket.totalValue) !== null && _b !== void 0 ? _b : 0;
    const selectedScoreTarget = (_c = selectedBracket === null || selectedBracket === void 0 ? void 0 : selectedBracket.scoreThreshold) !== null && _c !== void 0 ? _c : 0;
    const comparisonProviderValue = useMemo(() => ({
        userEntryBracketPosition,
        bracketsWithRanges,
        selectedTierPosition,
        setSelectedTierPosition,
        currentUserScore,
        userTierPrize,
        selectedTierPrize,
        selectedScoreTarget,
    }), [selectedTierPosition, selectedTierPrize, selectedScoreTarget]);
    return _jsx(ComparisonContext.Provider, { value: comparisonProviderValue, children: children });
};
export const useComparisonContext = () => {
    const context = useContext(ComparisonContext);
    if (context === undefined) {
        throw new Error('useComparisonContext must be used within a TierComparisonProvider');
    }
    return context;
};
