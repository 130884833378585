import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import loadable from '@loadable/component';
import { makeStyles } from 'tss-react/mui';
import CoreButton from '@components/Buttons/CoreButton';
const TrophyIcon = loadable(() => import('@components/Icons/TrophyIcon'));
const createButtonOffsetTop = 120;
const useStyles = makeStyles()((theme) => ({
    container: {
        width: '100%',
        height: '50vh',
        minHeight: '750px',
        display: 'flex',
        color: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    foreground: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        background: 'rgba(0,0,0,0.6)',
        [theme.breakpoints.up('md')]: {
            paddingBottom: `160px`,
        },
        [theme.breakpoints.down('md')]: {
            paddingTop: '100px',
        },
    },
    videoBackground: {
        position: 'fixed',
        // Adding important because xyg_styles.scss overrides it
        width: 'auto !important',
        height: 'auto !important',
        minWidth: '100%',
        minHeight: '100%',
    },
    background: {
        backgroundImage: `linear-gradient(352deg, #000, #656565)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed',
    },
    title: {
        fontSize: '80px',
        fontWeight: 700,
        color: 'white',
        lineHeight: 'normal',
        textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        position: 'relative',
        textTransform: 'uppercase',
        textAlign: 'center',
        '&>span': {
            position: 'relative',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '62px',
            lineHeight: 1.04,
        },
        [`@media (max-width: ${theme.breakpoints.values.sm}px) and (min-height: 500px)`]: {
            maxWidth: '300px',
        },
        // iPhone 4 normal
        [`@media (max-width: 320px)`]: {
            fontSize: '50px',
            maxWidth: '300px',
        },
        // iPhone 4 rotated
        [`@media (max-width: 480px) and (max-height: 320px)`]: {
            fontSize: '50px',
        },
    },
    titleWordHighlightHidden: {
        '&:before': {
            transform: 'translateY(250px)',
            opacity: 0,
        },
    },
    titleWordHighlight: {
        position: 'relative',
        display: 'inline-block',
        '&:before': {
            content: `""`,
            width: '110%',
            height: '90%',
            top: '10%',
            right: '-5%',
            position: 'absolute',
            display: 'inline-block',
            background: theme.palette.primary.main,
            transition: `transform 1s ${theme.transitions.easing.easeInOut}, opacity 1s`,
        },
        '&>span': {
            position: 'relative',
        },
    },
    tagline: {
        maxWidth: '650px',
        margin: theme.spacing(3, 0, 0),
        textAlign: 'center',
        textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        [theme.breakpoints.up('md')]: {
            fontSize: '24px',
        },
        [`@media (max-width: ${theme.breakpoints.values.sm}px) and (min-height: 500px)`]: {
            maxWidth: '300px',
        },
    },
    divider: {
        background: 'white',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        width: '22px',
        height: '3px',
        margin: theme.spacing(5, 0),
        transition: `transform 1s ${theme.transitions.easing.easeInOut}, opacity 1s`,
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    dividerHidden: {
        transform: 'translateX(-200px)',
        opacity: 0,
    },
    // Create Account button
    pageSection: {
        display: 'grid',
        gridTemplateAreas: '"fillerLeft content fillerRight"',
        gridTemplateColumns: 'minmax(0,1fr) minmax(auto, 1420px) minmax(0,1fr)',
        '&:before': {
            content: '""',
            gridArea: 'fillerLeft',
        },
        '&:after': {
            content: '""',
            gridArea: 'fillerRight',
        },
    },
    createAccountBtnSection: {
        position: 'absolute',
        width: '100%',
        zIndex: 1,
        bottom: `${createButtonOffsetTop}px`,
        [theme.breakpoints.down('md')]: {
            bottom: 'unset',
            position: 'relative',
            padding: theme.spacing(4, 0),
        },
        [`@media (max-width: ${theme.breakpoints.values.sm}px) and (min-height: 500px)`]: {
            padding: theme.spacing(6, 0),
        },
    },
    createAccountBtnContainer: {
        gridArea: 'content',
        display: 'flex',
        justifyContent: 'flex-end',
        overflow: 'hidden',
        '&>button': {
            borderRadius: 0,
        },
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
        },
    },
    createAccountBtnTagline: {
        color: `#b5e9ff`,
        fontSize: '14px',
        lineHeight: '17.5px',
        gridArea: 'tagline',
    },
    createAccountBtnText: {
        fontSize: '20px',
        lineHeight: '25px',
        gridArea: 'text',
    },
    createAccountBtn: {
        transition: `transform 1s ${theme.transitions.easing.easeInOut}, opacity 1s`,
        display: 'grid',
        gridTemplateAreas: `"icon tagline"
                        "icon text"`,
        textAlign: 'left',
        columnGap: theme.spacing(2),
        boxShadow: ` 0px 4px 10px rgba(0, 172, 246, 0.27)`,
    },
    createAccountBtnHidden: {
        transform: `translateX(400px)`,
        opacity: 0,
    },
    circledTrophy: {
        gridArea: 'icon',
        height: '50px',
        width: '50px',
        padding: '12px',
        borderRadius: '50px',
        border: `3px solid white`,
        '& svg': {
            fill: 'white',
        },
    },
}));
const LandingPageBanner = ({ handleJoinClick }) => {
    const { classes, cx } = useStyles();
    const [animate, setAnimate] = useState(false);
    // Page Load animations
    useEffect(() => {
        const beginAnimation = setTimeout(() => {
            setAnimate(true);
        }, 500);
        return () => clearTimeout(beginAnimation);
    }, []);
    return (_jsxs("div", { className: cx(classes.background, classes.container), children: [_jsx("video", { autoPlay: true, muted: true, loop: true, id: "background_video", className: classes.videoBackground, children: _jsx("source", { src: "/video/repeat-gaming.webm", type: "video/webm" }) }), _jsxs("div", { className: classes.foreground, children: [_jsxs("h1", { className: classes.title, children: ["Compete on", ' ', _jsx("span", { className: cx(classes.titleWordHighlight, {
                                    [classes.titleWordHighlightHidden]: !animate,
                                }), children: _jsx("span", { children: "Repeat" }) })] }), _jsx("p", { className: classes.tagline, children: "Play the games you love. Compete in free tournaments. Win real money & prizes." }), _jsx("div", { className: cx(classes.pageSection, classes.createAccountBtnSection), children: _jsx("div", { className: classes.createAccountBtnContainer, children: _jsxs(CoreButton, { type: "button", size: "lg", className: cx(classes.createAccountBtn, {
                                    [classes.createAccountBtnHidden]: !animate,
                                }), onClick: handleJoinClick, variant: "primary", children: [_jsx("div", { className: classes.circledTrophy, children: _jsx(TrophyIcon, {}) }), _jsx("span", { className: classes.createAccountBtnTagline, children: "Start playing Now!" }), _jsx("span", { className: classes.createAccountBtnText, children: "Create Account" })] }) }) })] })] }));
};
export default LandingPageBanner;
