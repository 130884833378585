export const getDefaultAddGameContextState = () => ({
    selectedGame: null,
    isOverConsentAge: null,
    requireAgePrompt: true,
    gameConnectionStatus: null,
    currentStep: 0,
    steps: 2,
    isLoading: false,
    isProviderReady: false,
});
export default getDefaultAddGameContextState;
