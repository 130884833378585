import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from '@mui/system';
const Border = styled('div') `
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
`;
const BorderImage = styled('img') `
  width: 100%;
  height: 100%;
  // needs to be slightly bigger than image
  // used steam profile as example
  transform: scale(1.22);
`;
const AvatarImg = styled('img') `
  align-items: center;
  border: none;
  border-radius: 4px;
  display: flex;
  height: 100%;
  width: 32px;
`;
const Container = styled('div') `
  position: relative;
  margin: auto 0;
  margin-left: ${(props) => props.theme.spacing(2)};
`;
const Avatar = ({ borderUrl, avatarUrl, handle, className }) => {
    return (_jsxs(Container, { className: className, children: [borderUrl && (_jsx(Border, { children: _jsx(BorderImage, { "data-notranslate": true, src: borderUrl, alt: `${handle} avatar border` }) })), _jsx(AvatarImg, { "data-notranslate": true, src: avatarUrl, alt: `${handle} avatar` })] }));
};
export default Avatar;
