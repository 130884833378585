import { jsxs as _jsxs } from "react/jsx-runtime";
import { createElement } from 'react';
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme) => ({
    container: {},
    label: {
        fontFamily: theme.typography.fontFamily,
        fontSize: '1.1rem',
        lineHeight: '1.3rem',
        fontWeight: 600,
        color: theme.palette.grey[300],
        marginTop: '0px',
        marginBottom: theme.spacing(1),
    },
}));
export const LabelledContent = ({ label, labelComponent = 'h4', className, children, }) => {
    const { classes, cx } = useStyles();
    const componentProps = {
        className: classes.label,
    };
    return (_jsxs("div", { className: cx(classes.container, className), children: [createElement(labelComponent, componentProps, label), children] }));
};
export default LabelledContent;
