import { styled } from '@mui/system';
const shouldForwardProp = (prop) => {
    const disallowedHtmlAttributes = [
        'bottomBorder',
        'topBorder'
    ];
    return disallowedHtmlAttributes.indexOf(prop) === -1;
};
const SectionHead = styled('header', { shouldForwardProp }) `
  padding: ${({ theme }) => theme.spacing(3)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: ${({ bottomBorder, theme }) => (bottomBorder ? `1px solid ${theme.palette.grey[100]}` : 'none')};
  border-top: ${({ topBorder, theme }) => (topBorder ? `1px solid ${theme.palette.grey[100]}` : 'none')};
  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: ${({ theme }) => theme.spacing(2, 3)};
  }
`;
export default SectionHead;
