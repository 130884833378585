import axios from 'axios';
import ErrorWithCause from '@helpers/ErrorWithCause';
const getBrandedTournamentContent = async (slug) => {
    try {
        return await axios
            .get(`/api/influencer-page/v1/${slug}`)
            .then((response) => response.data);
    }
    catch (error) {
        throw new ErrorWithCause(error);
    }
};
export default getBrandedTournamentContent;
