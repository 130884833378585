import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
import GenericModal from '../GenericModal';
import LoadingLogo from './Components/LoadingLogo';
const useStyles = makeStyles()(() => ({
    innerContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: '90px 70px',
        display: 'flex',
        backgroundColor: 'white',
        flexDirection: 'column',
        borderRadius: '4px',
    },
}));
export const LoadingModal = ({ show, loadingText, className }) => {
    const { classes, cx } = useStyles();
    return (_jsx(GenericModal, { className: cx('repeat-loading-modal', className), show: show, size: "small", "data-testid": "repeat loading modal", disableBackdropClick: true, disableEscapeKeyDown: true, ariaLabelledBy: "loading-modal-container", mobileFullScreen: false, children: _jsxs("div", { className: classes.innerContainer, id: "loading-modal-container", "aria-label": "Repeat loading modal", children: [_jsx(LoadingLogo, {}), _jsx("p", { children: _jsx("strong", { children: loadingText }) })] }) }));
};
export default LoadingModal;
