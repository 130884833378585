import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classnames from 'classnames';
import { styled } from '@mui/system';
import ExclamationTriangleIcon from '@heroicons/react/24/solid/ExclamationTriangleIcon';
import Icon from '@components/Icons/Icon';
const StyledHelperText = styled('div') `
  display: flex;
  justify-content: end;
  gap: 0.3em;
`;
const StyledIconContainer = styled('div') `
  display: flex;
`;
/**
 * @deprecated - see TextInput
 */
const TextField = ({ type = 'text', name, onChange, onBlur = () => undefined, value, label, icon, hasError = false, helperText, className = '', placeholder = label, disable = false, min = 0, autoComplete, autoFocus, }) => {
    return (_jsxs("div", { className: classnames('textField', className), "data-testid": "textField", children: [_jsx("label", { htmlFor: name, children: label }), _jsxs("div", { className: classnames('input-group', hasError && 'error'), children: [icon && (_jsx(StyledIconContainer, { className: "input-group-prepend", "data-testid": "textFieldIcon", children: _jsx(Icon, { icon: icon, color: "#bbbbbb" }) })), _jsx("input", { type: type, name: name, onChange: (e) => onChange(e), onBlur: (e) => onBlur(e), value: value, className: classnames('form-control'), "aria-label": label, placeholder: placeholder, id: name, disabled: disable, min: min, autoComplete: autoComplete, autoFocus: autoFocus })] }), helperText && (_jsxs(StyledHelperText, { className: classnames('helperText', hasError && 'error'), "data-testid": "textFieldHelperText", children: [_jsx("p", { children: helperText }), hasError && _jsx(ExclamationTriangleIcon, { viewBox: "0 0 24 24", width: "1.2em", height: "1.2em" })] }))] }));
};
export default TextField;
