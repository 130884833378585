import axios from 'axios';
import { isAxiosError } from '@helpers/errorHelper';
import ErrorWithCause from './ErrorWithCause';
import SaveGameClientError from './SaveGameClientError';
/**
 * Does a POST request to the `save_game_details` endpoint in Symfony App.
 * This is used for adding Epic IDs
 *
 * @param {object} gameDetails JSON object of the game detail to pass in.
 * @param {string|undefined} gameDetails.additionalDetails additional kay value pairs to pass in.
 * @param {string|undefined} gameDetails.lookupValue the lookup value e.g. Summoner name.
 * @param {string|undefined} gameDetails.regionId the region ID
 * @param {string|undefined} gameDetails.platformTag the platform tag e.g. pc
 * @param {string|undefined} gameDetails.gameServiceKey the game service key e.g. epic
 * @param {string|undefined} gameDetails.macroGroup the macro group, an analytics related variable to record user marketing segment
 * @param selectedGameKey string of the available Game Keys
 * @returns AxiosResponse of Usergame
 * @throws Throws ErrorWithCause
 */
const postSaveGameDetails = async (gameDetails, selectedGameKey) => {
    var _a;
    const payload = {
        game_key: selectedGameKey,
        ...gameDetails,
    };
    try {
        return await axios.post('/api/user_games/save-details', payload);
    }
    catch (error) {
        if (isAxiosError(error) && ((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) && error.response.status < 500) {
            throw new SaveGameClientError(error);
        }
        throw new ErrorWithCause(error);
    }
};
export default postSaveGameDetails;
