export const findOptionById = (options, id) => {
    for (const option of options) {
        if (option.id === id)
            return option;
        if (option.children) {
            const found = findOptionById(option.children, id);
            if (found)
                return found;
        }
    }
    return undefined;
};
export const findOptionByPath = (options, optionIds) => optionIds.reduce((acc, optionId) => {
    if (acc && acc.children) {
        return acc.children.find((child) => child.id === optionId);
    }
    return options.find((option) => option.id === optionId);
}, undefined);
