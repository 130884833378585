import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import ModalContent from '@components/Modals/components/ModalContent';
import { useState } from 'react';
import { Stack, AgeRestrictionIcon } from './styles';
import Actions from './Actions';
import AgeSpecificContent from './AgeSpecificContent';
import useTrackEvent from '@hooks/useTrackEvent';
const AgeRestriction = ({ onClick, onClose, stepName, isOverConsentAge, selectedGame }) => {
    const [ageConfirmed, setAgeConfirmed] = useState(false);
    const trackEvent = useTrackEvent();
    const knowledgeBaseText = 'Read the knowledge base article here';
    const handleClick = () => {
        trackEvent({
            type: 'user_engagement',
            data: {
                eventName: 'select text link',
                label: knowledgeBaseText,
                moduleName: 'Text link',
                position: 0,
            },
        });
    };
    return (_jsxs(ModalContent, { stepName: stepName, children: [_jsxs(Stack, { children: [_jsxs(AgeRestrictionIcon, { children: [selectedGame === null || selectedGame === void 0 ? void 0 : selectedGame.ageRating, "+"] }), _jsx("h3", { children: "Tournaments for this game have an age restriction" }), _jsx(AgeSpecificContent, { isOverConsentAge: isOverConsentAge, onChange: setAgeConfirmed }), _jsxs("p", { children: [_jsx("span", { "data-ignore": "true", children: "Activision" }), " have a strict +", selectedGame === null || selectedGame === void 0 ? void 0 : selectedGame.ageRating, " policy that Repeat must adhere to when running tournaments."] }), _jsxs("p", { children: ["Users found to be in violation of this policy will forfeit all ", _jsx("span", { "data-ignore": "true", children: "Warzone" }), ' ', "tournament winnings and risk their Repeat account being banned."] }), _jsx("p", { children: _jsx("a", { onClick: handleClick, href: "https://support.repeat.gg/hc/en-us/articles/18905617889691-Activision-Age-restricted-tournaments", rel: "noopener noreferrer", target: "_blank", children: knowledgeBaseText }) })] }), _jsx(Actions, { ageConfirmed: ageConfirmed, onClick: onClick, onClose: onClose, isOverConsentAge: !!isOverConsentAge })] }));
};
export default AgeRestriction;
