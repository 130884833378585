import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
import { styled } from '@mui/material/styles';
import Spinner from '@components/Icons/Spinner';
const StyledLoadingSpinner = styled(Spinner) `
  font-size: 15px;
  width: 1.2em;
  height: 1.2em;
`;
const useStyles = makeStyles()((theme, { paletteName = 'primary' }) => ({
    buttonBase: {
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: theme.typography.fontFamily,
        fontSize: '12px',
        border: 'none',
        borderRadius: '4px',
        '&:hover': {
            color: 'currentColor',
        },
        fontWeight: 600,
    },
    success: {
        background: theme.palette.success.main,
        color: theme.palette.common.white,
        '&:hover': {
            background: theme.palette.success.dark,
            color: theme.palette.common.white,
        },
    },
    brand: {
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover': {
            background: theme.palette.primary.dark,
            color: theme.palette.common.white,
        },
    },
    danger: {
        background: theme.palette.error.main,
        color: theme.palette.common.white,
        '&:hover': {
            background: theme.palette.error.dark,
            color: theme.palette.common.white,
        },
    },
    gray: {
        background: theme.palette.grey[50],
        color: theme.palette.grey[300],
        border: `1px solid ${theme.palette.grey[100]}`,
        '&:hover': {
            background: theme.palette.grey[300],
        },
    },
    transparent: {
        background: 'transparent',
        color: 'currentColor',
        border: 'none',
    },
    palette: {
        background: theme.palette[paletteName].main,
        color: 'white',
        '&:hover': {
            background: theme.palette[paletteName].dark,
            color: 'white',
        },
    },
    disabled: {
        opacity: 0.5,
        cursor: 'not-allowed',
    },
    block: {
        width: '100%',
    },
    sm: {
        padding: '6px 16px',
        // 35px
    },
    base: {
        padding: '8px 24px',
        // 40px
    },
    md: {
        padding: '12px 32px',
        // 45px
    },
    lg: {
        padding: '14px 40px',
        height: '50px',
    },
    textAppearance: {
        background: 'transparent',
        border: 'none',
        color: theme.palette.grey[900],
        padding: 0,
    },
}));
/**
 *
 * @deprecated - See CoreButton
 */
export const Button = ({ type = 'button', variant = 'brand', children, className = '', loading = false, disable = false, label, size = 'base', id, block = false, appearance = 'button', onClick = () => undefined, paletteName, }) => {
    const { classes, cx } = useStyles({ paletteName });
    return (_jsx("button", { className: cx(appearance === 'text'
            ? classes.textAppearance
            : [classes.buttonBase, classes[size], variant !== 'none' && classes[variant]], className, disable && classes.disabled, block && classes.block), type: type, onClick: onClick, disabled: disable || loading, "aria-label": label, id: id, children: loading ? _jsx(StyledLoadingSpinner, { title: "loading" }) : children }));
};
export default Button;
