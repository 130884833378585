import { styled } from '@mui/material/styles';
import getBracketPositionIcon from '../helpers/getBracketPositionIcon';
const shouldForwardProp = (prop) => prop !== 'bracketPosition';
const BracketPositionIcon = styled('div', { shouldForwardProp }) `
  height: 50px;
  width: 50px;
  background: url(${({ bracketPosition }) => getBracketPositionIcon(bracketPosition)}) center no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing(0.5)};
  flex-shrink: 0;

  span {
    color: ${({ theme }) => theme.palette.common.white};
    text-shadow: 0px 0px 7px rgba(0, 0, 0, 0.43);
    font-weight: 700;
    font-size: 16px;
  }
`;
export default BracketPositionIcon;
