import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme, { selectedColor }) => ({
    switchContainer: {
        background: theme.palette.grey[100],
        borderRadius: theme.shape.borderRadius,
        display: 'flex',
        position: 'relative',
        padding: '4px 6px',
    },
    radioInput: {
        left: '-100%',
        position: 'absolute',
        opacity: 0,
    },
    radioLabel: {
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        background: 'transparent',
        alignItems: 'center',
        padding: `4px 10px`,
        color: theme.palette.grey[400],
        margin: 0,
        '&:hover': {
            cursor: 'pointer',
        },
        '& svg': {
            fill: theme.palette.grey[400],
            height: '10px',
            marginRight: '4px',
        },
    },
    radioLabelSelected: {
        background: selectedColor,
        borderRadius: theme.shape.borderRadius,
        color: 'white',
        '& svg': {
            fill: 'white',
        },
    },
}));
/**
 * UI that allows user to toggle between options. Behaves like radio button, so it accepts multiple options.
 * Should not be confused with ToggleSwitch, which behaves more like checkbox
 * @param name - Name used to group the radio buttons.
 * @param options - Options used to render the toggle.
 * @param value - Selected value.
 */
const Toggle = ({ name, options, value, onChange, selectedColor }) => {
    const [selectedValue, setSelectedValue] = useState(value);
    const theme = useTheme();
    const { classes, cx } = useStyles({ selectedColor: selectedColor !== null && selectedColor !== void 0 ? selectedColor : theme.palette.primary.main });
    const handleChange = (option) => {
        setSelectedValue(option.value);
        if (onChange) {
            onChange(option.value);
        }
    };
    const renderedOptions = options.map((option) => {
        return (_jsxs("label", { htmlFor: option.id, className: cx(classes.radioLabel, {
                [classes.radioLabelSelected]: option.value === selectedValue,
            }), children: [option === null || option === void 0 ? void 0 : option.icon, _jsx("input", { id: option.id, type: "radio", name: name, value: option.value, className: classes.radioInput, onChange: () => handleChange(option), checked: option.value === selectedValue }), !!option.label && _jsx("span", { children: option.label })] }, option.id));
    });
    return (_jsx("div", { role: "radiogroup", "aria-label": name, className: classes.switchContainer, children: renderedOptions }));
};
export default Toggle;
