const getGamePalette = (gameKey) => {
    switch (gameKey) {
        case 'fortnite':
            return 'fortnite';
        case 'valorant':
            return 'valorant';
        case 'pc-dota_2':
            return 'dota2';
        case 'league_of_legends':
            return 'lol';
        case 'cod-warzone':
            return 'cod';
        case 'cod-warzone-2':
            return 'cod-2';
        case 'pubg':
            return 'pubg';
        case 'apex-legends':
            return 'apex';
        case 'brawl-stars':
            return 'brawlStars';
        case 'destiny-2':
            return 'destiny-2';
        case 'mlb-the-show-23':
            return 'mlb-the-show-23';
        case 'rocket-league':
            return 'rocketLeague';
        case 'pubg-mobile':
            return 'pubg-mobile';
        default:
            return 'primary';
    }
};
export default getGamePalette;
