import { useQuery } from 'react-query';
import { captureException, captureMessage } from '@sentry/react';
import triggerLoggedOutRedirect from '@domain/User/Login/helpers/triggerLoggedOutRedirect';
import getBalance from '@api/getBalance';
import { useUserState } from '@context/UserContext';
const useGetBalance = (enabled = true, queryOptions) => {
    const queryKey = 'balance';
    const { authenticated } = useUserState();
    const query = useQuery(queryKey, getBalance, {
        enabled: authenticated && enabled,
        staleTime: Infinity,
        // isLoading will always be false, the response will return these false while fetching
        ...queryOptions,
    });
    if (query.error && query.error.code === '401') {
        captureMessage(`Failed to retrieve balance. User will be logged out.`);
        triggerLoggedOutRedirect();
    }
    else if (query.error) {
        captureException(query.error, {
            extra: {
                request: 'balance',
            },
        });
    }
    return query;
};
export default useGetBalance;
