import { useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { infiniteNotificationKey } from '@hooks/API/useFetchInfiniteNotifications';
import updateNotifications from '@api/updateNotifications';
const useUpdateNewNotificationsWhenDrawerOpens = (openDrawer, hasOpenedDrawer, refetch, onSuccessCallback) => {
    const queryClient = useQueryClient();
    const notificationsCache = queryClient.getQueryData(infiniteNotificationKey);
    const { mutate } = useMutation((params) => updateNotifications(params), {
        onSuccess: (response) => {
            const updatedEntries = response.meta.updatedEntriesCount;
            if (onSuccessCallback) {
                onSuccessCallback(updatedEntries);
            }
        },
    });
    // When the drawer opens, update notifications new = false silently
    useEffect(() => {
        if ((notificationsCache === null || notificationsCache === void 0 ? void 0 : notificationsCache.pages) && notificationsCache.pages.length > 0 && openDrawer) {
            const latestFilteredIsNewNotifications = notificationsCache.pages[notificationsCache.pages.length - 1].payload
                .filter((notification) => notification.isNewNotification)
                .map((notification) => notification.id);
            if (latestFilteredIsNewNotifications.length > 0) {
                mutate({
                    notificationIds: latestFilteredIsNewNotifications,
                    isNewNotification: false,
                });
            }
        }
    }, [openDrawer, notificationsCache === null || notificationsCache === void 0 ? void 0 : notificationsCache.pages]);
    // Refetch notifications when the drawer re-opens (2nd time)
    useEffect(() => {
        if (refetch && hasOpenedDrawer && openDrawer) {
            refetch();
        }
    }, [openDrawer, hasOpenedDrawer]);
};
export default useUpdateNewNotificationsWhenDrawerOpens;
