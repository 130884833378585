import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { doTrackEvent } from '@components/Analytics';
import { useInView } from 'react-intersection-observer';
const useStyles = makeStyles()(() => ({
    imageSize: {
        minWidth: '100px',
    },
}));
const Advertisement = ({ ad, alt, target = '_blank', className, classes, gameKey, brand, onLoad, onEnterView, onClick, }) => {
    const { classes: styles, cx } = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { url, image_url, imageUrlMobile, id } = ad;
    const linkUrl = !/^https?:\/\//i.test(url) ? `http://${url}` : url;
    const [ref, inView] = useInView({
        triggerOnce: true,
        rootMargin: '200px 0px',
    });
    useEffect(() => {
        doTrackEvent({
            type: 'ad_impression',
            adId: id,
            page: ad.page,
            position: ad.position,
            brand,
            game: gameKey,
        });
        if (onEnterView && inView) {
            onEnterView();
        }
    }, [inView]);
    const handleClick = () => {
        doTrackEvent({
            type: 'ad_click',
            adId: id,
            page: ad.page,
            position: ad.position,
            brand,
            game: gameKey,
        });
        if (onClick) {
            onClick();
        }
    };
    return (_jsx("a", { ref: ref, onClick: handleClick, href: linkUrl, target: target, className: cx(className, classes === null || classes === void 0 ? void 0 : classes.anchor), rel: "noreferrer noopener", children: _jsx("img", { className: cx('img', classes === null || classes === void 0 ? void 0 : classes.img, styles.imageSize), src: isMobile && imageUrlMobile ? imageUrlMobile : image_url, alt: alt ? `promotion: ${alt}` : `promo image`, onLoad: onLoad }) }));
};
export default Advertisement;
