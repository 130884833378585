import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import { useMutation } from 'react-query';
import updateNotifications from '@api/updateNotifications';
import NotificationIcon from './NotificationIcon';
import NotificationTag from './NotificationTag';
import NotificationStatus from './NotificationStatus';
import { useFeature } from 'flagged';
import useParseHtml from '@hooks/useParseHtml';
import useGetTournament from '@hooks/useGetTournament';
import CoreButton from '@components/Buttons/CoreButton';
import { useUserState } from '@context/UserContext';
import doTrackEvent from '@components/Analytics/doTrackEvent';
import TournamentBoxlette from '@domain/Tournament/components/TournamentGrid/TournamentBox/TournamentBoxlette';
import Spinner from '@components/Icons/Spinner';
const useStyles = makeStyles()((theme, { hasActions, actionsClassName }) => ({
    notificationItemContainer: {
        display: 'grid',
        gridTemplateAreas: hasActions
            ? `
      'icon title status'
      'content content content'
      'actions actions actions'
      'footer footer footer'
    `
            : `
    'icon title status'
    'content content content'
    'footer footer footer'
  `,
        gap: theme.spacing(2),
        gridTemplateColumns: '45px 1fr 8px',
        padding: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.grey[100]}`,
    },
    title: {
        gridArea: 'title',
        display: 'flex',
        alignItems: 'center',
        margin: 0,
        fontSize: 13,
        fontWeight: 600,
        color: theme.palette.grey[900],
    },
    content: {
        gridArea: 'content',
        color: theme.palette.grey[600],
        fontWeight: 600,
        fontSize: '12px',
    },
    footer: {
        display: 'flex',
        alignItems: 'center',
        gridArea: 'footer',
        fontSize: '11px',
        fontWeight: 600,
        color: theme.palette.grey[400],
        '& span:not(:first-child):before': {
            content: `' • '`,
            color: theme.palette.grey[400],
        },
    },
    icon: {
        gridArea: 'icon',
        '&.pinned': {
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '&.error': {
            border: `1px solid ${theme.palette.error.main}`,
        },
    },
    body: {
        fontSize: '12px',
        color: theme.palette.grey[600],
        fontWeight: 600,
        '& a': {
            color: theme.palette.grey[600],
            textDecoration: 'underline',
        },
    },
    actions: {
        gridArea: 'actions',
        display: 'flex',
        justifyContent: 'space-between',
        [`& .${actionsClassName}:last-child`]: {
            marginRight: '0px',
        },
    },
    action: {
        flex: 1,
        fontWeight: 600,
        fontSize: '12px',
        marginRight: theme.spacing(1.5),
        height: '40px',
        '&.default': {
            color: theme.palette.grey[500],
            background: theme.palette.grey[100],
            border: '0',
        },
    },
    tags: {
        textTransform: 'capitalize',
    },
    newNotification: {
        background: '#FAFAFA',
    },
    pinnedNotification: {
        background: theme.palette.primary.light,
    },
    alertNotification: {
        background: theme.palette.error.light,
    },
    suggestedTournament: {
        padding: '0px',
        marginTop: theme.spacing(1),
    },
}));
const NotificationItem = ({ className, actionsClassName, notification, isClickable = true }) => {
    const hasActions = notification.actions.length > 0;
    // Temporary measure to hide actions on a tournament prize award notification until we turn enable_rewards on
    const hideRewardActions = !useFeature('enable_rewards') &&
        hasActions &&
        !!notification.actions.find((action) => action.text === 'Claim Prizes');
    const { classes, cx } = useStyles({ hasActions, actionsClassName });
    const { authenticated } = useUserState();
    // If notification has isClickable true (in Settings view) or has actions(buttons) or has tournaments (tournaments group) then it should be displayed as clickable items
    // Temporarily short circuited to return true for a tournament prize award notification while enable_rewards is off
    const shouldDisplayAsLink = hideRewardActions || (!hasActions && !(notification === null || notification === void 0 ? void 0 : notification.tournament) && isClickable && !!notification.bodyLink);
    const NotificationContainer = shouldDisplayAsLink ? 'a' : 'div';
    const anchorLinkProps = shouldDisplayAsLink
        ? {
            href: notification.bodyLink,
        }
        : {};
    const tournamentId = notification.tournament || '';
    const { data: tournament, isLoading, error } = useGetTournament(tournamentId, authenticated, Boolean(tournamentId));
    const { mutate: handleOpenNotification } = useMutation((params) => updateNotifications(params));
    const handleNotificationClick = (notificationId) => {
        if (isClickable && !notification.opened) {
            handleOpenNotification({
                notificationIds: notificationId,
                opened: true,
            });
        }
    };
    const notificationBody = useParseHtml(notification.bodyHtml);
    if (tournamentId && error && !shouldDisplayAsLink) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs(NotificationContainer, { ...anchorLinkProps, className: cx(classes.notificationItemContainer, className, {
            [classes.newNotification]: notification.isNewNotification,
            [classes.pinnedNotification]: notification.priority > 0,
            [classes.alertNotification]: notification.type === 'alert',
        }), onClick: () => handleNotificationClick(notification.id), children: [_jsx("div", { className: classes.icon, children: _jsx(NotificationIcon, { className: cx(classes.icon, {
                        pinned: notification.priority > 0,
                        error: notification.type === 'alert',
                    }), variant: notification.iconPalette !== 'default' ? notification.iconPalette : 'primary', src: notification.image, icon: notification.icon }) }), _jsx("h3", { className: classes.title, children: notification.title }), _jsxs("div", { className: classes.content, children: [_jsx("div", { className: classes.body, "data-testid": "notification body", children: notificationBody }), notification.tournament && (_jsxs(_Fragment, { children: [!isLoading && !error && tournament && tournament.data && (_jsx(TournamentBoxlette, { gameKey: tournament.data.score_template.game.game_key, tournament: tournament.data, section: "notification", listIndex: 0, className: classes.suggestedTournament })), !isLoading && error && shouldDisplayAsLink && 'Click here to view tournament', isLoading && _jsx(Spinner, {})] }))] }), hasActions && !hideRewardActions && (_jsx("div", { className: cx(classes.actions, actionsClassName), children: notification.actions.map((action) => {
                    if (action.type === 'link') {
                        return (_jsx(CoreButton, { variant: action.style !== 'default' ? action.style : undefined, className: cx(classes.action, {
                                default: action.style === 'default',
                            }), to: action.link, onClick: () => {
                                doTrackEvent({
                                    type: 'notification_button',
                                    notification_id: notification.id,
                                    link: action.link,
                                });
                            }, children: action.text }, action.text));
                    }
                }) })), _jsxs("div", { className: classes.footer, children: [_jsxs("span", { "data-notranslate": true, children: [formatDistanceToNowStrict(new Date(notification.displayDate)), " ago"] }), notification.tags.map((tag) => (_jsx(NotificationTag, { variant: notification.tags[0] === 'Suggested Tournament' ? 'warning' : undefined, children: tag }, tag))), _jsx(NotificationStatus, { notification: notification })] })] }));
};
export default NotificationItem;
