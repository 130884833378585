import signOut from '@helpers/Auth/signOut';
import { useUserState } from '@context/UserContext';
import { useLocalAppContext } from '@context/LocalAppContext';
import { captureException } from '@sentry/react';
const useSignOut = () => {
    const { openModal } = useLocalAppContext();
    const { refetch } = useUserState();
    const handleSignOut = async () => {
        try {
            await signOut();
        }
        catch (error) {
            openModal({ type: 'errorCode', title: 'Oops, we encountered an error', errorCode: 'WONDERING_MAMBA' });
            captureException(error);
        }
        refetch();
    };
    return { signOut: handleSignOut };
};
export default useSignOut;
