import { jsx as _jsx } from "react/jsx-runtime";
import { useLocalAppContext } from '@context/LocalAppContext';
import ErrorCodeModal from './ErrorCodeModal';
import WelcomeModal from './Onboarding/WelcomeModal';
import ConnectSocialMediaAccountsModal from '@domain/User/Profile/SocialAccounts/components/ConnectSocialMediaAccountsModal';
import QueryActionModals from './QueryActionModals/QueryActionModals';
import HowToParticipateModal from './HowToParticipateModal';
import AddGameModal from "@components/Modals/AddGameModal/AddGameModal";
import AuthenticationModal from "@components/Modals/AuthenticationModal/AuthenticationModal";
const ModalRenderer = () => {
    const { modalData, openModal, closeModal } = useLocalAppContext();
    const handleSuccess = (action) => {
        if (((modalData === null || modalData === void 0 ? void 0 : modalData.type) === 'login' || (modalData === null || modalData === void 0 ? void 0 : modalData.type) === 'register') && (modalData === null || modalData === void 0 ? void 0 : modalData.onSuccess)) {
            modalData.onSuccess(action);
        }
    };
    // Prioritise query action modals
    const queryActionModal = QueryActionModals();
    if (queryActionModal) {
        return queryActionModal;
    }
    switch (modalData === null || modalData === void 0 ? void 0 : modalData.type) {
        case 'login':
        case 'register':
            return (_jsx(AuthenticationModal, { show: true, view: modalData.type, handleSuccess: () => handleSuccess('login'), handleRegistered: () => handleSuccess('register'), handleClose: closeModal, changeAuthenticationForm: (action) => openModal({ type: action, onSuccess: modalData.onSuccess }) }));
        case 'errorCode':
            return (_jsx(ErrorCodeModal, { onClose: closeModal, title: modalData.title, content: modalData.content, errorCode: modalData.errorCode, showContactSupport: modalData.showContactSupport, testId: modalData.testId }));
        case 'welcome':
            return _jsx(WelcomeModal, { username: modalData.username, onClose: closeModal });
        case 'connect-social':
            return _jsx(ConnectSocialMediaAccountsModal, { open: true, socialMedia: modalData.socialMedia, onClose: closeModal });
        case 'how-to-participate':
            return _jsx(HowToParticipateModal, { onClose: closeModal });
        case 'add-game': {
            const handleClose = (didAddGame) => {
                if (modalData === null || modalData === void 0 ? void 0 : modalData.onClose) {
                    modalData.onClose(didAddGame);
                }
                closeModal();
            };
            return (_jsx(AddGameModal, { open: true, onClose: handleClose, gameKey: modalData.gameKey, filterByPlatform: modalData.filterByPlatform, successButtonLabel: modalData.successButtonLabel, oauthCallbackParams: modalData.oauthCallbackParams }));
        }
        default:
            return null;
    }
};
export default ModalRenderer;
