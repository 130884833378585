import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import loadable from '@loadable/component';
import { useFeature } from 'flagged';
import getGameName from '@helpers/getGameName';
import getGamePalette from '@helpers/getGamePalette';
import ModalHeader from '@components/Modals/components/ModalHeader';
import useRemoveAddGameParams from '@hooks/useRemoveAddGameParams';
import useAddGameBreadcrumbManager from '@hooks/useAddGameBreadcrumbManager';
import ModalBreadcrumb from '@components/Modals/components/ModalBreadcrumb';
import ModalBody from '@components/Modals/components/ModalBody';
import ContentCarousel from '@components/UI/ContentCarousel';
import ContentConnectFortnite from './ContentConnectFortnite';
import { useAddGameContext } from '../context/AddGameProvider';
import AddGameConnectStatus from '../AddGameConnectStatus';
import AddGameInterstitial from '../AddGameInterstitial';
import AddGameConnectEpicContent from './AddGameConnectEpicContent';
import ContentUpdateEpicSettings from './ContentUpdateEpicSettings';
import useSetDefaultGameService from '../hooks/useSetDefaultGameService';
import useHandleAddGameEvents from '../hooks/useHandleAddGameEvents';
import getAnalyticsFlowStep from '@domain/Analytics/helpers/getAnalyticsFlowStep';
const GameIcon = loadable(() => import('@components/Icons/GameIcon'));
const gameStepBreadcrumbs = [
    {
        id: 'connect-lol',
        component: 'Connect',
        analyticsFlowStep: 'connect game',
    },
    {
        id: 'public-data',
        component: 'Public Data',
        analyticsFlowStep: 'public data',
    },
];
const AddFortnite = ({ onStepChange, onConnectConfirm, shouldShowSelectBreadcrumb = true, onBackToSelect, successButtonLabel = 'Close', failButtonLabel = 'Retry', onClose, shouldGoToConnectionStatus, }) => {
    const isOAuthEnabled = !!useFeature('enable_epic_oauth');
    const addGameContext = useAddGameContext();
    const breadcrumbStepOffset = shouldShowSelectBreadcrumb ? 1 : 0;
    const { requireAgePrompt, isProviderReady, gameConnectionStatus, fetched } = addGameContext;
    const connectionStatusPage = gameConnectionStatus === 'success' ? 1 : -1;
    const [currentStepIndex, setCurrentStepIndex] = useState(shouldGoToConnectionStatus ? connectionStatusPage : 0);
    const gameKey = 'fortnite';
    const removeAddGameParams = useRemoveAddGameParams();
    // NOTE: Age check for breadcrumb is managed by the hook below.
    const { breadcrumbIndex, breadcrumbs, breadcrumbIsVisible, setBreadcrumbIsVisible, displayBreadcrumbsUpTo } = useAddGameBreadcrumbManager({
        gameStepBreadcrumbs,
        startingIndex: breadcrumbStepOffset,
        includeSelectBreadcrumb: shouldShowSelectBreadcrumb,
        requireAgePrompt,
    });
    // Set default game service.
    useSetDefaultGameService();
    const handleBreadcrumbClick = (idx, breadcrumb) => {
        if (breadcrumb.id === 'select') {
            // Invoke onBackToSelect to dismiss current view
            if (onBackToSelect) {
                onBackToSelect(gameKey);
            }
        }
        else if (idx >= breadcrumbStepOffset) {
            setCurrentStepIndex(idx - breadcrumbStepOffset);
        }
    };
    const handleStepChange = (changedStepIndex, stepName) => {
        if (stepName === 'game-connect-status') {
            setBreadcrumbIsVisible(false);
        }
        else {
            setBreadcrumbIsVisible(true);
            displayBreadcrumbsUpTo(stepName);
        }
        if (onStepChange) {
            const analyticsFlowStep = getAnalyticsFlowStep(gameConnectionStatus, changedStepIndex, gameStepBreadcrumbs);
            onStepChange(changedStepIndex, stepName, analyticsFlowStep, gameStepBreadcrumbs.length);
        }
    };
    const handleContinue = () => {
        setCurrentStepIndex(currentStepIndex + 1);
    };
    /**
     * Should be deleted with enable_epic_oauth
     * This is to get around some funky state with the old username submission failing but the component can't get the update in time
     */
    useHandleAddGameEvents({
        state: addGameContext,
        onAddGameError: () => setCurrentStepIndex(-1),
    });
    const handleConnectSuccessClick = () => {
        if (onConnectConfirm) {
            onConnectConfirm();
        }
    };
    const handleConnectRetryClick = () => {
        removeAddGameParams();
        setCurrentStepIndex(0);
    };
    const handleClose = () => {
        const isSuccessPage = breadcrumbIndex === -1;
        if (onClose) {
            onClose(gameConnectionStatus === 'success', isSuccessPage);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { title: _jsx("span", { "data-ignore": true, children: getGameName(gameKey) }), icon: _jsx(GameIcon, { game: gameKey, showGameColor: true }), subtitle: "Connect your account", paletteName: getGamePalette(gameKey), onClose: handleClose }), _jsx(ModalBreadcrumb, { currentIndex: breadcrumbIndex, breadcrumbs: breadcrumbs, onClick: handleBreadcrumbClick, isVisible: breadcrumbIsVisible }), _jsxs(ModalBody, { children: [isProviderReady && (_jsxs(ContentCarousel, { name: "connect-fortnite-account", currentStep: currentStepIndex, onStepChange: handleStepChange, children: [isOAuthEnabled ? _jsx(AddGameConnectEpicContent, {}) : _jsx(ContentConnectFortnite, { onSubmit: handleContinue }), _jsx(ContentUpdateEpicSettings, { onContinue: handleContinue }), _jsx(AddGameConnectStatus, { onConfirm: handleConnectSuccessClick, onRetry: handleConnectRetryClick, status: gameConnectionStatus || 'error', gameKey: gameKey, successButtonLabel: successButtonLabel, failButtonLabel: failButtonLabel, errorMessage: (fetched === null || fetched === void 0 ? void 0 : fetched.isError) ? fetched.errorMessage : undefined, stepName: "game-connect-status" })] })), !isProviderReady && _jsx(AddGameInterstitial, {})] })] }));
};
export default AddFortnite;
