import AlertBar from '@components/UI/AlertBar';
import SectionContainer from '@components/UI/Section/components/SectionContainer';
import SectionHead from '@components/UI/Section/components/SectionHead';
import { styled } from '@mui/system';
const Container = styled('div') `
  display: grid;
  width: 100%;
  flex: 1;
  gap: ${({ theme }) => theme.spacing(2.5)};
  grid-template-columns: 1fr 340px;
  padding: ${({ theme }) => theme.spacing(3, theme.layout.contentDesktopSidePadding)};
  max-width: ${({ theme }) => theme.layout.contentMaxWidth}px;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    grid-template-columns: 1fr;
    gap: 0px;
    padding: ${({ theme }) => theme.spacing(3, 0)};
  } ;
`;
const TournamentBanner = styled('div') `
  display: flex;
  justify-content: center;
`;
const LeftColumn = styled('div') `
  display: grid;
  gap: ${({ theme }) => theme.spacing(2.5)};
  grid-auto-rows: min-content;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding-bottom: 20px;
  }
`;
const RightColumn = styled(SectionContainer) `
  display: grid;
  gap: ${({ theme }) => theme.spacing(2.5)};
  grid-auto-rows: min-content;
  padding: ${({ theme }) => theme.spacing(3)};
  ${({ theme }) => theme.breakpoints.down('lg')} {
    gap: 20px;
  }
`;
const BorderlessRightColumn = styled('div') `
  display: grid;
  gap: ${({ theme }) => theme.spacing(2.5)};
  grid-auto-rows: min-content;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    gap: 20px;
  }
`;
const Alert = styled(AlertBar) `
  margin-bottom: 20px;
`;
const PaginationContainer = styled('div') `
  margin: 20px;
  display: flex;
  justify-content: flex-end;
`;
const StyledSectionHead = styled(SectionHead) `
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  & > div {
    align-items: flex-start;
    width: 100%;
  }
`;
export { Container, TournamentBanner, LeftColumn, RightColumn, BorderlessRightColumn, Alert, PaginationContainer, StyledSectionHead, };
