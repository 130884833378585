import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Container } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import getGamePalette from '@helpers/getGamePalette';
import { useLocalAppContext } from '@context/LocalAppContext';
import GameNavLink from '@components/Navigation/GameNavLink';
import useTrackEvent from '@hooks/useTrackEvent';
import TournamentBanner from './TournamentBanner';
import AdvertisementBanner from './AdvertisementBanner';
const useStyles = makeStyles()((theme, { paletteName }) => ({
    containerWrapper: {
        fontFamily: theme.typography.fontFamily,
        maxWidth: theme.layout.containerMaxWidth,
        padding: `20px ${theme.layout.contentDesktopSidePadding} 0px ${theme.layout.contentDesktopSidePadding}`,
        '@media (min-width: 740px)': {
            padding: `20px ${theme.layout.contentDesktopSidePadding} 0px ${theme.layout.contentDesktopSidePadding}`,
        },
    },
    tournamentsBanner: {
        backgroundColor: theme.palette[paletteName].main,
    },
    linksWrapper: {
        display: 'flex',
        marginTop: theme.spacing(3),
    },
    link: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 600,
        fontSize: '14px',
        height: '70px',
        width: '215px',
        color: theme.palette.common.white,
        border: `1px solid ${theme.palette[paletteName].main}`,
        backgroundColor: theme.palette[paletteName].dark,
        '&:hover': {
            color: theme.palette.common.white,
        },
        [`&.activeClassName`]: {
            border: `1px solid ${theme.palette.grey[100]}`,
            color: theme.palette.grey[900],
            backgroundColor: theme.palette.common.white,
            '&:hover': {
                color: theme.palette.grey[900],
            },
        },
    },
    activeLink: {},
}));
const TournamentListHeader = ({ game }) => {
    var _a;
    const { openModal } = useLocalAppContext();
    const trackEvent = useTrackEvent();
    const { classes } = useStyles({
        paletteName: getGamePalette((_a = game === null || game === void 0 ? void 0 : game.gameKey) !== null && _a !== void 0 ? _a : 'primary'),
    });
    const handleShowAddGameModal = (label) => {
        openModal({ type: 'add-game', filterByPlatform: true, gameKey: game === null || game === void 0 ? void 0 : game.gameKey, onClose: () => { } });
        trackEvent({
            type: 'game_linking',
            data: {
                eventName: 'initiate game linking',
                label,
                moduleName: 'game linking',
                position: 1,
                component: (game === null || game === void 0 ? void 0 : game.gameKey) && {
                    componentCategory: 'game key',
                    componentName: game.gameKey,
                },
            },
        });
    };
    return (_jsx("div", { className: classes.tournamentsBanner, children: _jsxs(Container, { fixed: true, className: classes.containerWrapper, children: [game && _jsx(TournamentBanner, { gameData: game, onAddGameClick: handleShowAddGameModal }), _jsx(AdvertisementBanner, { gameKey: game === null || game === void 0 ? void 0 : game.gameKey }), _jsxs("div", { className: classes.linksWrapper, children: [_jsx(GameNavLink, { page: "Tournaments", className: classes.link, activeClassName: "activeClassName", game: game, region: "tab", children: "Tournaments" }), _jsx(GameNavLink, { page: "Game Hub", className: classes.link, activeClassName: "activeClassName", game: game, region: "tab", children: "Game Hub" })] })] }) }));
};
export default TournamentListHeader;
