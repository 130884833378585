import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import loadable from '@loadable/component';
import trackHotjarEvent from '@components/Analytics/trackHotjarEvent';
import FilterButton from '@components/Buttons/FilterButton';
import Flex from '@components/Layout/Flex';
import MobileFiltersDrawer from '@components/Modals/MobileFiltersDrawer';
import IconCheckBox from '@components/UI/IconCheckBox';
import { SearchFilter } from '@domain/Tournament/components/Filters';
import { Paper, ToggleButton, ToggleButtonGroup, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import GameModeFilter from './GameModeFilter/GameModeFilter';
import MobileModalButtons from './MobileModalButtons';
import RankFilter from './RankFilter';
import useTournamentFilterState from './useTournamentFilterState';
const GridView = loadable(() => import('@components/UI/Icon/Icons/GridView'));
const ListView = loadable(() => import('@components/UI/Icon/Icons/ListView'));
const height = (isMobile) => (isMobile ? '52px' : '46px');
const Container = styled('div') `
  background: #fff;

  position: sticky;
  top: ${({ theme }) => `${theme.layout.headerDesktopHeight}px`};
  z-index: 1099; // 1 less than appBar -> https://mui.com/customization/z-index/
`;
const StyledSearchFilter = styled(SearchFilter, {
    shouldForwardProp: (prop) => prop !== 'isMobile',
}) `
  padding: ${({ theme }) => theme.spacing(1)};
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.grey[100]};
  border-radius: ${({ theme }) => theme.spacing(1)};
  display: flex;
  position: relative;
  height: ${({ isMobile }) => (isMobile ? '52px' : '46px')};

  .styled-search-icon {
    font-size: 22px;
    margin: ${({ theme }) => theme.spacing(0.5)};
  }

  input {
    all: unset;
    margin-left: ${({ theme }) => theme.spacing(1)};
    font-size: 12px;
    width: 100%;
  }
`;
const StyledToggleButtonGroup = styled(ToggleButtonGroup) `
  border: 1px solid ${({ theme }) => theme.palette.grey[100]};
  border-radius: ${({ theme }) => theme.spacing(1)};

  & .MuiToggleButtonGroup-grouped {
    margin: ${({ theme }) => theme.spacing(0.5)};
    border: 0;

    &:not(:first-of-type) {
      border-radius: ${({ theme }) => theme.spacing(1)};
    }

    &:first-of-type {
      border-radius: ${({ theme }) => theme.spacing(1)};
    }
  }
`;
const TabBar = styled('div') `
  ${({ theme }) => theme.breakpoints.down('header')} {
    display: flex;
    position: fixed;
    bottom: 80px;
    left: 0px;
    width: 100%;
    justify-content: center;
    z-index: 10;
  }
`;
const MobileFilterContainer = styled('div') `
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;
const StylePrizePoolCheckboxes = styled(Flex) `
  margin-top: ${({ theme }) => theme.spacing(1)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;
const StyleDesktopContainer = styled(Flex) `
  padding-left: ${({ theme }) => theme.spacing(1)};
  padding-right: ${({ theme }) => theme.spacing(1)};
`;
const StyleDesktopRankFilter = styled('div') `
  min-width: 200px;
`;
const countActiveFilters = (filters) => {
    return Object.entries(filters).reduce((previous, [key, value]) => {
        if (!value || key === 'gameKey' || (Array.isArray(value) && value.length === 0)) {
            return previous;
        }
        return previous + 1;
    }, 0);
};
const TournamentsListFilters = ({ gameKey, displayMode = 'list', onToggleDisplayMode = (mode) => mode, showBoxToggle = true, }) => {
    const [openMobileDrawer, setOpenMobileDrawer] = useState(false);
    const theme = useTheme();
    const doesShowGameRankFilter = gameKey && ['league_of_legends', 'rocket-league'].includes(gameKey);
    const doesShowGameModeFilter = gameKey && ['league_of_legends', 'rocket-league'].includes(gameKey);
    // This must match the desktop threshold inside Header.tsx
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { filterState, updateFilterState, localFilterState, resetLocalFilterState, resetFilterState } = useTournamentFilterState();
    const handleApply = () => {
        updateFilterState(localFilterState);
        setOpenMobileDrawer(false);
    };
    const handleReset = () => {
        resetFilterState();
    };
    const updateGameModeFilters = (gameModeCode, checked) => {
        var _a, _b, _c;
        if (checked && !((_a = localFilterState.gameModeCode) === null || _a === void 0 ? void 0 : _a.includes(gameModeCode))) {
            updateFilterState({ gameModeCode: [...((_b = localFilterState.gameModeCode) !== null && _b !== void 0 ? _b : []), gameModeCode] }, isMobile);
        }
        if (!checked) {
            updateFilterState({ gameModeCode: (_c = localFilterState.gameModeCode) === null || _c === void 0 ? void 0 : _c.filter((key) => key !== gameModeCode) }, isMobile);
        }
        trackHotjarEvent('Search tournament game mode');
    };
    const updatePrizePoolFilters = (prizePoolType, checked) => {
        if (prizePoolType === 'USD') {
            updateFilterState({ USD: checked ? {} : undefined }, isMobile);
        }
        if (prizePoolType === 'PM') {
            updateFilterState({ PM: checked ? {} : undefined }, isMobile);
        }
        trackHotjarEvent('Search tournament prize pool');
    };
    const updateRankFilters = (rankKey) => {
        updateFilterState({ rankKey }, isMobile);
        trackHotjarEvent('Search tournament game rank');
    };
    const clearGameModeFilters = () => {
        updateFilterState({ gameModeCode: [] }, isMobile);
    };
    const SearchFilterBox = (_jsx(StyledSearchFilter, { initialValue: isMobile ? localFilterState.search : filterState.search, onSearch: (searchValue) => {
            updateFilterState({ search: searchValue }, isMobile);
        }, noTranslate: true, isMobile: isMobile, placeholder: "Find Tournament..." }));
    const PrizePoolCheckboxes = (_jsxs(StylePrizePoolCheckboxes, { "data-testid": "prizePoolFilter", flexDirection: "row", align: "center", children: [_jsx(IconCheckBox, { text: "Cash", imgSrc: "/img/icons/dollar.svg", checked: isMobile ? !!localFilterState.USD : !!filterState.USD, onChange: (checked) => {
                    updatePrizePoolFilters('USD', checked);
                }, height: height(isMobile) }), _jsx(IconCheckBox, { text: "Coins", imgSrc: "/img/icons/coins.svg", checked: isMobile ? !!localFilterState.PM : !!filterState.PM, onChange: (checked) => {
                    updatePrizePoolFilters('PM', checked);
                }, height: height(isMobile) })] }));
    const DesktopViewModes = showBoxToggle && (_jsxs(StyledToggleButtonGroup, { value: displayMode, exclusive: true, onChange: (_, selectedValue) => {
            if (selectedValue !== 'grid' && selectedValue !== 'list')
                return;
            onToggleDisplayMode(selectedValue);
        }, children: [_jsx(ToggleButton, { value: "list", "aria-label": "list", children: _jsx(ListView, { height: 12 }) }), _jsx(ToggleButton, { value: "grid", "aria-label": "grid", children: _jsx(GridView, { height: 12 }) })] }));
    if (isMobile) {
        return (_jsxs(TabBar, { children: [_jsx(FilterButton, { onClick: () => setOpenMobileDrawer(true), filterCount: countActiveFilters(filterState) }), _jsxs(MobileFiltersDrawer, { isOpen: openMobileDrawer, onClose: () => {
                        resetLocalFilterState();
                        setOpenMobileDrawer(false);
                    }, children: [SearchFilterBox, PrizePoolCheckboxes, doesShowGameModeFilter && (_jsx(MobileFilterContainer, { children: _jsx(GameModeFilter, { gameKey: gameKey, selectedModes: localFilterState.gameModeCode, onChange: updateGameModeFilters, onClear: clearGameModeFilters }) })), doesShowGameRankFilter && (_jsx(RankFilter, { gameKey: gameKey, selectedId: localFilterState.rankKey, isMobile: isMobile, height: height(isMobile), onSelect: (rankKey) => {
                                updateRankFilters(rankKey);
                            } })), _jsx(MobileModalButtons, { height: height(isMobile), onApply: handleApply, onReset: handleReset })] })] }));
    }
    return (_jsx(Container, { id: "tournament-list-filters", children: _jsx(Paper, { children: _jsxs(StyleDesktopContainer, { flexDirection: "row", align: "center", children: [SearchFilterBox, doesShowGameModeFilter && (_jsx(StyleDesktopRankFilter, { children: _jsx(GameModeFilter, { gameKey: gameKey, selectedModes: filterState.gameModeCode, onChange: updateGameModeFilters, onClear: clearGameModeFilters }) })), doesShowGameRankFilter && (_jsx(StyleDesktopRankFilter, { children: _jsx(RankFilter, { gameKey: gameKey, selectedId: filterState.rankKey, isMobile: isMobile, height: height(isMobile), onSelect: (rankKey) => {
                                updateRankFilters(rankKey);
                            } }) })), PrizePoolCheckboxes, DesktopViewModes] }) }) }));
};
export default TournamentsListFilters;
