import { jsx as _jsx } from "react/jsx-runtime";
import loadable from '@loadable/component';
import { Badge } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
const Bell = loadable(() => import('@components/Icons/Bell'));
const useStyles = makeStyles()((theme) => ({
    standard: {
        height: 18,
        width: 18,
        padding: 0,
        fontSize: '8px',
        lineHeight: '6px',
        fontWeight: 500,
        border: `1px solid white`,
        borderRadius: theme.shape.borderRadius,
    },
    icon: {
        width: 14,
        height: 18,
        fill: theme.palette.grey[400],
    },
}));
/**
 * The bell icon with number badge.
 */
const NotificationAlert = ({ className, count = 0, hasGlobalNotificationsOnly, }) => {
    const { classes } = useStyles();
    return (_jsx(Badge, { "data-testid": "notification-badge", color: hasGlobalNotificationsOnly ? 'primary' : 'error', variant: hasGlobalNotificationsOnly ? 'dot' : 'standard', invisible: false, badgeContent: count ? count : null, classes: {
            standard: classes.standard,
        }, className: className, children: _jsx(Bell, { className: classes.icon }) }));
};
export default NotificationAlert;
