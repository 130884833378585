import ErrorWithCause from '@helpers/ErrorWithCause';
import axios from 'axios';
const getRecaptureEnabled = async () => {
    try {
        const { data } = await axios.get('/recaptcha');
        return data;
    }
    catch (error) {
        throw new ErrorWithCause(error);
    }
};
export default getRecaptureEnabled;
